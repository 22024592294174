import { IncrementalEventType } from './incremental-event-data-type';

/** 媒体动作类型 */
export enum MediaInteraction {
	Play,
	Pause,
}

/** 媒体数据 */
export type MediaInteractionData = {
	type: MediaInteraction;
	id: number;
};
/** 媒体抓取数据 */
export type MediaInteractionEventData = {
	source: IncrementalEventType.MediaInteraction;
} & MediaInteractionData;
