import React from 'react';
import styled from 'styled-components';
import RRWebPlayer from '../trace/rrweb-player';
import { Details } from '../trace/trace-view-types';

const RRWeb = styled.div`
	width: 100%;
	background-color: var(--video-bg-color);
	border-radius: var(--radius);
	outline: none;
	> .rr-player {
		background-color: var(--video-bg-color);
	}
`;
const VideoContainer = styled.div`
	width: 100%;
	video {
		background-color: var(--video-bg-color);
		border-radius: var(--radius);
		outline: none;
	}
`;

export default (props: { details: Details }) => {
	const { details } = props;

	let player;
	if (details.loaded) {
		if (details.events?.length !== 0) {
			player = <RRWeb>
				<RRWebPlayer autoPlay={true} events={details.events}/>
			</RRWeb>;
		} else {
			player = <div>没有视频被录制, 请联系系统管理员查询. 联系时请提供TraceNo.</div>;
		}
	} else {
		player = <div className="tips"></div>;
	}

	return <VideoContainer>
		{player}
	</VideoContainer>;
}
