import React, { useReducer, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
	Button,
	ButtonBar,
	ButtonBarAlignType,
	ButtonType,
	DateTimeRange,
	FormSectionGapVertical,
	PanelSeparator,
	QueryPanel,
	QueryResultColumns,
	QueryResultPanel,
	QueryResultRow,
	Text,
	WorkAreaTitle
} from '../component';
import Path from '../path';
import { endOfDate, formatFull, startOfDate } from '../service/date';
import {
	findTraceDataPage,
	TraceCriteria,
	TraceDataPage,
	findTenantDynamicQueryCriteria,
	DynamicCriteria
} from '../service/trace-service';
import { useShares } from '../hooks/shares';
import {isCCICDisplay} from "../utils";
import {doExport} from "../service/ajax";

const TraceQuery = styled.div`
	display: flex;
	flex-direction: column;
`;

const ButtonWrapper = styled.div`
	width: max-content;
	text-align: center;
	> *:last-child {
		display: inline-block;
		margin-left: var(--gap-thin);
	}
`;

const columns: QueryResultColumns = [
	{ label: '#', width: '40px', name: 'seq' },
	{ label: '视频生成时间', width: '150px', name: 'createTime' },
	{ label: '保险产品计划', width: '1fr', name: 'productName' },
	{
		label: <React.Fragment>
			<div>报价单号</div>
			<div>保单号</div>
			<div>订单号</div>
		</React.Fragment>, width: '200px', name: [ 'quoteNo', 'policyNo', 'orderNo' ]
	},
	{
		label: <React.Fragment>
			<div>付款号</div>
			<div>支付订单号</div>
		</React.Fragment>, width: '200px', name: [ 'payNo', 'payOrderNo' ]
	},
	{
		label: <React.Fragment>
			<div>投保人</div>
			<div>投保人证件号码</div>
			<div>投保人手机号码</div>
		</React.Fragment>,
		width: '160px',
		name: [ 'policyHolderName', 'policyHolderIdNo', 'policyHolderMobile' ]
	},
	{ label: '来源', width: '100px', name: 'source' },
	{ label: '', width: '100px', name: 'actions' }
];

type InputCriteria = Exclude<keyof TraceCriteria, 'pageSize' | 'pageNumber'>

export default () => {
	const shares = useShares();
	const history = useHistory();
	const now = new Date();

	const [ isDateReset, setisDateReset ] = useState(false);

	const [ , forceUpdate ] = useReducer(x => x + 1, 0);
	const [ data, setData ] = useState<TraceDataPage | null>(null);
	const [ searched, setSearched ] = useState(false);
	const [ dynamicCriteria, setDynamicCriteria ] = useState<Array<DynamicCriteria> | null>(null);
	const [ criteria ] = useState<TraceCriteria>(shares.reachout('__trace_query_criteria_keeped') || {
		createFrom: formatFull(startOfDate(now)),
		createTo: formatFull(endOfDate(now)),
		traceNo: '',
		productName: '',
		policyNo: '',
		orderNo: '',
		policyHolderName: '',
		policyHolderIdNo: '',
		policyHolderMobile: '',
		source: '',
		quoteNo: '',
		payNo: '',
		payOrderNo: '',
		uniqueNo1: '',
		uniqueNo2: '',
		uniqueNo3: '',
		uniqueNo4: '',
		uniqueNo5: '',
		uniqueNo6: '',
		uniqueNo7: '',
		uniqueNo8: '',
		uniqueNo9: '',
		uniqueNo10: ''
	});

	useEffect(() => {
		(async () => {
			const dynamicCriterias = await findTenantDynamicQueryCriteria();
			setDynamicCriteria(dynamicCriterias);
		})();
	}, []);

	const [ currentCriteria, setCurrentCriteria ] = useState<TraceCriteria | null>(null);

	const onChanged = (propName: InputCriteria) => (event: React.ChangeEvent) => {
		// @ts-ignore
		criteria[propName] = (event.target as HTMLInputElement).value;
		forceUpdate();
	};
	const onDateChanged = (startDate?: Date, endDate?: Date) => {
		criteria.createFrom = formatFull(startOfDate(startDate));
		criteria.createTo = formatFull(endOfDate(endDate));
		forceUpdate();
	};

	// doGet('https://saletrace-test.ccic-net.com.cn/saletrace/api/trace/list');
	const doSearch = async (pagination: TraceCriteria) => {
		// 设置当前查询
		setCurrentCriteria(pagination);
		const data = await findTraceDataPage(pagination);
		setData(data);
		setSearched(true);
	};
	const onSearchClicked = async (criteria: TraceCriteria) => {
		if(criteria.createFrom! > criteria.createTo!){
			alert('资料生成开始日期不得大于结束日期，请重新选择');
			return;
		}
		if(isCCICDisplay()){
			if(!criteria.traceNo && !criteria.orderNo && !criteria.productName && !criteria.policyNo
				&& !criteria.payNo && !criteria.quoteNo && !criteria.payOrderNo && !criteria.policyHolderName
				&& !criteria.policyHolderIdNo && !criteria.policyHolderMobile){
				alert('请至少录入一项查询条件');
				return;
			}
			if(criteria.productName || criteria.policyHolderName){
				if(!criteria.traceNo && !criteria.orderNo && !criteria.policyNo
					&& !criteria.payNo && !criteria.quoteNo && !criteria.payOrderNo
					&& !criteria.policyHolderIdNo && !criteria.policyHolderMobile && !criteria.source){
					alert('保险产品计划或投保人姓名需搭配其他查询条件使用');
					return;
				}
			}
			const from = criteria.createFrom!.split("-"),
				to = criteria.createTo!.split("-"),
				year1 = parseInt(from[0]),
				month1 = parseInt(from[1]),
				year2 = parseInt(to[0]),
				month2 = parseInt(to[1]);
				//通过年,月差计算月份差
				//months = (year2 - year1) * 12 + (month2 - month1) + 1;
			if(year1 !== year2 || month1 !== month2){
				alert('仅支持一个月内的查询，请重新选择日期');
				return;
			}
		}
		shares.share('__trace_query_criteronResetClickedia_keeped', criteria);
		const pagination = {
			...criteria,
			pageNumber: 1,
			pageSize: 10
		};
		await doSearch(pagination);
	};
	const onResetClicked = () => {
		shares.discard('__trace_query_criteria_keeped');
		// @ts-ignore
		Object.keys(criteria).filter((key) => key !== 'archiveStatuses').forEach((key) => criteria[key as InputCriteria] = '');
		criteria.createFrom = formatFull(startOfDate(now));
		criteria.createTo = formatFull(endOfDate(now));
		setisDateReset(true);
		forceUpdate();
	};
	const onPageChanged = async (pageNumber: number) => {
		const pagination = {
			...currentCriteria,
			pageNumber
		};
		await doSearch(pagination);
	};

	const dataFound = data || { pageNumber: 1, pageSize: 10, totalPages: 1, totalItemCount: 0, rows: [] };
	// const onArchiveClicked = async (row: QueryResultRow) => {
	// 	await archiveTraceData(row.traceId);
	// 	row.archived = true;
	// 	forceUpdate();
	// };
	const rows = (dataFound.rows || []).map((row): QueryResultRow => {
		const data = row as QueryResultRow;
		data.actions = <ButtonWrapper>
			<Button label="明细数据" type={ButtonType.LINK}
					onClick={() => history.push(Path.FULL_TRACE_DETAIL
						.replace(':traceId', `${row.traceId}`)
						.replace(':createAt', `${row.createTime}`))}/>
		</ButtonWrapper>;
		return data;
	});

	const traceUpdate = () => {
		forceUpdate();
	};

	const onExportClicked = async () => {
		if(dataFound.totalItemCount > 300){
			alert('不支持导出超过300条记录，请缩小查询范围');
			return;
		}
		await doExport('/api/trace/traceExport',criteria);
	};

	// noinspection DuplicatedCode
	return <TraceQuery>
		<WorkAreaTitle title="全流程回溯管理"/>
		<QueryPanel>
			<DateTimeRange label="资料生成时间" columns={2} startDate={criteria.createFrom!} endDate={criteria.createTo!} isReset={isDateReset}
			               onChange={onDateChanged} update={traceUpdate} />
			<Text label="业务回溯码" placeholder="请输入" value={criteria.traceNo} onChange={onChanged('traceNo')}/>
			<Text label="保险产品计划" placeholder="请输入" value={criteria.productName} onChange={onChanged('productName')}/>
			<Text label="保单号" placeholder="请输入" value={criteria.policyNo} onChange={onChanged('policyNo')}/>
			<Text label="报价单号" placeholder="请输入" value={criteria.quoteNo} onChange={onChanged('quoteNo')}/>
			<Text label="订单号" placeholder="请输入" value={criteria.orderNo} onChange={onChanged('orderNo')}/>
			<Text label="付款号" placeholder="请输入" value={criteria.payNo} onChange={onChanged('payNo')}/>
			<Text label="支付订单号" placeholder="请输入" value={criteria.payOrderNo} onChange={onChanged('payOrderNo')}/>
			<Text label="来源" placeholder="请输入" value={criteria.source} onChange={onChanged('source')}/>
			<Text label="投保人姓名" placeholder="请输入" value={criteria.policyHolderName}
			      onChange={onChanged('policyHolderName')}/>
			<Text label="投保人证件号码" placeholder="请输入" value={criteria.policyHolderIdNo}
			      onChange={onChanged('policyHolderIdNo')}/>
			<Text label="投保人手机号码" placeholder="请输入" value={criteria.policyHolderMobile}
			      onChange={onChanged('policyHolderMobile')}/>
			{dynamicCriteria?.map((dynamicCriteria, idx) => {
				// @ts-ignore
				return <Text key={`dynamicCriteria_${idx}`}
				             label={`${dynamicCriteria.displayName}`}
				             placeholder="请输入"
				             value={(criteria as any)[`uniqueNo${dynamicCriteria.columnSeq}`]}
				             onChange={onChanged(`uniqueNo${dynamicCriteria.columnSeq}` as any)}/>;
			})}
			<PanelSeparator columns={3}/>
			<ButtonBar align={ButtonBarAlignType.CENTER}>
				<Button label="查询" onClick={() => onSearchClicked(criteria)}/>
				<Button label="重置" type={ButtonType.WAIVE} onClick={onResetClicked}/>
				<Button label="导出" onClick={onExportClicked}/>
			</ButtonBar>
			<PanelSeparator columns={3}/>
		</QueryPanel>
		<FormSectionGapVertical/>
		<QueryResultPanel columns={columns} rows={rows} pagination={true} searched={searched}
		                  pageCount={dataFound.totalPages} pageNumber={dataFound.pageNumber}
		                  totalItemCount={dataFound.totalItemCount}
		                  pageChange={onPageChanged}/>
	</TraceQuery>;
}