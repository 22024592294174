const standard = {
	sideMargin: 32,

	bgColor: '#fcfcfc',
	fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif',
	fontSize: 14,
	fontSizeSmall: 12,
	fontColor: '#666',

	primaryColor: 'rgb(60, 130, 153)',
	primaryColorHover: 'rgb(80, 150, 173)',
	primaryColorActive: 'rgb(20, 90, 113)',
	waiveColor: 'rgb(211, 211, 211)',
	waiveColorHover: 'rgb(231, 231, 231)',
	waiveColorActive: 'rgb(171, 171, 171)',
	questionColor: 'rgb(230, 106, 50)',
	questionColorHover: 'rgb(250, 126, 70)',
	questionColorActive: 'rgb(190, 66, 10)',
	successColor: 'rgb(44, 217, 94)',
	successColorHover: 'rgb(64, 237, 114)',
	successColorActive: 'rgb(4, 177, 54)',
	dangerColor: 'rgb(212, 60, 56)',
	dangerColorHover: 'rgb(232, 80, 76)',
	dangerColorActive: 'rgb(172, 20, 16)',
	invertColor: '#fff',

	blurredColorOpacity: 0.7,

	gap: 16,
	gapThin: 8,
	gapThinner: 4,
	gapThinnest: 2,

	radius: 4,

	padding: 16,

	headerBgColor: '#24292e',
	headerColor: '#fff',
	headerHeight: 60,
	headerZIndex: 100,

	sideBarBgColor: '#f7f7f7',
	sideBarBorder: '1px solid #ececec',
	sideBarWidth: 200,
	sideBarMenuHeight: 40,
	sideBarMenuFontSize: 14 * 1.1,
	sideBarActiveBgColor: 'rgba(60, 130, 153, 0.7)',
	sideBarActiveColor: '#fff',
	sideBarActiveBorderColor: 'rgba(60, 130, 153, 0.5)',
	sideBarHoverBgColor: '#ececec',
	sideBarHoverColor: 'rgba(60, 130, 153)',
	sideBarHoverBorderColor: 'rgba(60, 130, 153, 0.2)',
	sideBarHoverBoxShadowColor: 'rgba(60, 130, 153, 0.1)',

	titleHeight: 40,
	titleFontSize: 14 * 1.1,
	titleBorderColor: 'rgba(60, 130, 153, 0.5)',

	formColumnGap: 32,
	formRowGap: 8,
	formLineHeight: 40,
	formLabelWidth: 140,
	formSectionGapVertical: 24,

	separatorColor: 'rgba(60, 130, 153, 0.1)',

	inputHeight: 32,
	inputLineHeight: 18,
	inputBorder: '1px solid rgba(60, 130, 153, 0.4)',
	inputPaddingVertical: 6,
	inputPaddingHorizontal: 10,
	inputFocusBoxShadowColor: 'rgba(60, 130, 153, 0.2)',
	inputFocusBorderColor: 'rgba(60, 130, 153, 0.8)',
	inputFocusBorder: '1px solid rgba(60, 130, 153, 0.8)',
	inputHoverBorder: '1px solid rgba(60, 130, 153, 0.8)',

	buttonHeight: 32,
	buttonLineHeight: 22,
	buttonPaddingVertical: 6,
	buttonPaddingHorizontal: 16,

	gridHeaderLineHeight: 24,
	gridHeaderBgColor: 'rgba(20, 90, 113, 0.05)',
	gridLineColor: 'rgba(60, 130, 153, 0.3)',
	gridCellPaddingVertical: 8,
	gridCellPaddingHorizontal: 8,
	gridCellFontSize: 12,
	gridBodyLineHeight: '1.4',

	calendarDaySize: 40,

	videoBgColor: 'black',
	playerIframeBgColor: '#f9f9f9',
	traceInfoBgColor: 'rgba(20, 90, 113, 0.05)',
	traceInfoTitleHeight: 40,
	traceStepBgColor: 'rgba(60, 130, 153, 0.3)',

	footerBgColor: '#24292e',
	footerColor: '#fff',
	footerHeight: 40,
	footerZIndex: 100
};

export type Theme = typeof standard;
export default standard;