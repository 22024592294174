import React from 'react';
import styled from 'styled-components';
import { useResponsive } from './hooks/responsive';
import SideBar from './side-bar';
import WorkArea from './work-area';

const Content = styled.div`
	display: flex;
	min-height: calc(100vh - var(--header-height) - var(--footer-height));
`;

export default () => {
	const responsive = useResponsive();

	return <Content>
		{responsive.mobile
			? null : <SideBar/>}
		<WorkArea/>
	</Content>;
}