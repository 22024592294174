import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

export type QueryResultColumn = {
	width: string;
	label: string | ReactNode;
	name: string | Array<string>;
}
export type QueryResultColumns = Array<QueryResultColumn>;
export type QueryResultRow = { seq: number, actions?: React.ReactNode } & { [key in string]: any }
export type QueryResultRows = Array<QueryResultRow>

const Panel = styled.div`
	border: 1px solid var(--grid-line-color);
	border-radius: var(--radius);
`;

const PanelRow = styled.div`
	display: grid;
	grid-template-columns: ${(props: any) => props.columns.map((column: QueryResultColumn) => column.width).join(" ")};
	> div:not(:first-child) {
		border-left: 1px solid var(--grid-line-color);
	}
	border-bottom: 1px solid var(--grid-line-color);
`;
const PanelHeaderRow = styled(PanelRow)`
	background-color: var(--grid-header-bg-color);
`;
const PanelHeaderCell = styled.div`
	line-height: var(--grid-header-line-height);
	padding: var(--grid-cell-padding-vertical) var(--grid-cell-padding-horizontal);
`;
const PanelBodyRow = styled(PanelRow)`
	&:last-child {
		border-bottom: 0;
	}
`;
const PanelBodyCell = styled.div`
	line-height: var(--grid-body-line-height);
	padding: var(--grid-cell-padding-vertical) var(--grid-cell-padding-horizontal);
	font-size: var(--grid-cell-font-size);
	button {
		padding: 0;
		font-size: var(--grid-cell-font-size);
		line-height: var(--grid-body-line-height);
		height: unset;
	}
`;
const NoData = styled.div`
	grid-column: span 9999;
	line-height: var(--grid-header-line-height);
	padding: var(--grid-cell-padding-vertical) var(--grid-cell-padding-horizontal);
`;

const Pagination = styled.div`
	display: flex;
	margin-top: var(--gap-thin);
	> div:first-child {
		line-height: var(--button-height);
		font-size: var(--font-size-small);
	}
`;
const PaginationPlaceholder = styled.div`
	flex-grow: 1;
`;
const PageButton = styled.button`
	border-style: solid;
	border-width: 1px;
	border-top-color: var(--grid-line-color);
	border-bottom-color: var(--grid-line-color);
	border-left-color: var(--grid-line-color);
	border-right-width: 0;
	width: var(--button-height);
	height: var(--button-height);
	line-height: calc(var(--button-height) - 2px);
	text-align: center;
	cursor: pointer;
	outline: none;
	appearance: none;
	color: var(--primary-color);
	background-color: transparent;
	transition: all 300ms ease-in-out;
	&:nth-child(3) {
		border-top-left-radius: var(--radius);
		border-bottom-left-radius: var(--radius);
	}
	&:last-child {
		border-right-width: 1px;
		border-right-color: var(--grid-line-color);
		border-top-right-radius: var(--radius);
		border-bottom-right-radius: var(--radius);
	}
	&[data-active=true] {
		color: var(--invert-color);
		background-color: var(--primary-color);
		&:hover,
		 &:active {
			background-color: var(--primary-color);
		}
	}
	&:hover {
		color: var(--invert-color);
		background-color: var(--primary-color-hover);
	}
	&:active {
		color: var(--invert-color);
		background-color: var(--primary-color-active);
	}
`;

export default (props: {
	pagination?: boolean,
	pageNumber?: number,
	pageCount?: number,
	totalItemCount?: number,
	pageChange?: (pageNumber: number) => void,
	searched?: boolean,
	columns: QueryResultColumns,
	rows?: QueryResultRows
}) => {
	const {
		pagination = false, pageNumber = 1, pageCount = 1, totalItemCount = 0,
		pageChange = () => {
		},
		searched = true, columns, rows = []
	} = props;
	// @ts-ignore
	const header = <PanelHeaderRow columns={columns}>
		{columns.map((column, index) => {
			return <PanelHeaderCell key={`${column.label}-${index}`}>{column.label}</PanelHeaderCell>;
		})}
	</PanelHeaderRow>;

	const body = rows.map((row, rowIndex) => {
		// @ts-ignore
		return <PanelBodyRow columns={columns} key={rowIndex}>
			<React.Fragment>
				{columns.map(column => column.name).map((name, cellIndex) => {
					const values = Array.isArray(name) ? name.map(key => row[key]) : [ row[name] ];
					const value = values.find(value => value);
					return <PanelBodyCell key={`${rowIndex}-${cellIndex}`}>{value}</PanelBodyCell>;
				})}
			</React.Fragment>
		</PanelBodyRow>;
	});
	// @ts-ignore
	const noData = <PanelBodyRow columns={columns}>
		<NoData>没有数据需要显示.</NoData>
	</PanelBodyRow>;
	// @ts-ignore
	const pleaseSearch = <PanelBodyRow columns={columns}>
		<NoData>请先执行查询操作.</NoData>
	</PanelBodyRow>;

	const switchPage = (toPageNumber: number) => {
		let pn = toPageNumber;
		
		if (toPageNumber === pageNumber) {
			// do nothing
			return;
		} else if (toPageNumber > pageCount) {
			pn = Math.max(pageCount, 1);
		} else if (toPageNumber < 1) {
			pn = 1;
		}
		if (pn === pageNumber) {
			return;
		} else {
			pageChange(pn);
		}
	};

	const pageButtons = [];
	if (pageNumber >= 3) {
		pageButtons.push(<PageButton key={pageNumber - 2}
		                             onClick={() => switchPage(pageNumber - 2)}>{pageNumber - 2}</PageButton>);
		pageButtons.push(<PageButton key={pageNumber - 1}
		                             onClick={() => switchPage(pageNumber - 1)}>{pageNumber - 1}</PageButton>);
	} else if (pageNumber === 2) {
		pageButtons.push(<PageButton key={pageNumber - 1}
		                             onClick={() => switchPage(pageNumber - 1)}>{pageNumber - 1}</PageButton>);
	}
	pageButtons.push(<PageButton key={pageNumber} data-active={true}
	                             onClick={() => switchPage(pageNumber)}>{pageNumber}</PageButton>);
	if (pageCount - pageNumber >= 2) {
		pageButtons.push(<PageButton key={pageNumber + 1}
		                             onClick={() => switchPage(pageNumber + 1)}>{pageNumber + 1}</PageButton>);
		pageButtons.push(<PageButton key={pageNumber + 2}
		                             onClick={() => switchPage(pageNumber + 2)}>{pageNumber + 2}</PageButton>);
	} else if (pageCount - pageNumber === 1) {
		pageButtons.push(<PageButton key={pageNumber + 1}
		                             onClick={() => switchPage(pageNumber + 1)}>{pageNumber + 1}</PageButton>);
	}
	return <React.Fragment>
		<Panel>
			{header}
			{searched ? (rows.length === 0 ? noData : body) : pleaseSearch}
		</Panel>
		{pagination ? <Pagination>
			<div>共 {totalItemCount}条 / {pageCount}页</div>
			<PaginationPlaceholder/>
			<PageButton onClick={() => switchPage(1)}><FontAwesomeIcon icon={faAngleDoubleLeft}/></PageButton>
			<PageButton onClick={() => switchPage(pageNumber - 1)}><FontAwesomeIcon icon={faAngleLeft}/></PageButton>
			{pageButtons}
			<PageButton onClick={() => switchPage(pageNumber + 1)}><FontAwesomeIcon
				icon={faAngleRight}/></PageButton>
			<PageButton onClick={() => switchPage(pageCount)}><FontAwesomeIcon icon={faAngleDoubleRight}/></PageButton>
		</Pagination> : null}
	</React.Fragment>;
}