import { doPost, doGet } from './ajax';

export interface AuditCriteria {
	traceNo?: string;
	policyNo?: string;
	orderNo?: string;
	quoteNo?: string;
	payNo?: string;
	payOrderNo?: string;
	source?: string;

	uniqueNo1?: string;
	uniqueNo2?: string;
	uniqueNo3?: string;
	uniqueNo4?: string;
	uniqueNo5?: string;
	uniqueNo6?: string;
	uniqueNo7?: string;
	uniqueNo8?: string;
	uniqueNo9?: string;
	uniqueNo10?: string
}

export interface AuditData {
	traceId: number,
	createTime: string,
	productName: string,
	policyNo: string,
	orderNo: string,
	policyHolderName: string,
	policyHolderIdNo: string,
	policyHolderMobile: string,
	traceNo: string,
	fileCount: number,
	fileHashList?: Array<string>,
	chainContractNo?: string,
	quoteNo?: string,
	payNo?: string,
	payOrderNo?: string,
	source?: string;

	uniqueNo1?: string,
	uniqueNo2?: string,
	uniqueNo3?: string,
	uniqueNo4?: string,
	uniqueNo5?: string,
	uniqueNo6?: string,
	uniqueNo7?: string,
	uniqueNo8?: string,
	uniqueNo9?: string,
	uniqueNo10?: string

}

export const findAuditData = async (criteria: AuditCriteria): Promise<AuditData | null> => {
	const response = await doPost(`/api/trace/audit/view`, criteria);

	if (response.body) {
		return Promise.resolve(response.body);
	} else {
		return Promise.resolve(null);
	}
}

export const blockChainVerify = async (traceId: number): Promise<string> => {
	const response = await doGet(`/api/trace/blockchain/validation/${traceId}`);
	return Promise.resolve(response?.body);
}
