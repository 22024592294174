export const formatAsDate = (date?: Date): string => {
	if (!date) {
		return '';
	}

	const month = `${date.getMonth() + 1}`.padStart(2, '0');
	const dateOfMonth = `${date.getDate()}`.padStart(2, '0');
	return `${date.getFullYear()}-${month}-${dateOfMonth}`;
};
export const formatAsTime = (date?: Date): string => {
	if (!date) {
		return '';
	}

	const hour = `${date.getHours()}`.padStart(2, '0');
	const minute = `${date.getMinutes()}`.padStart(2, '0');
	const second = `${date.getSeconds()}`.padStart(2, '0');
	return `${hour}:${minute}:${second}`;
};
export const formatFull = (date?: Date): string => {
	if (!date) {
		return '';
	}

	return `${formatAsDate(date)} ${formatAsTime(date)}`;
};
export const startOfDate = (date?: Date): Date | undefined => {
	if (!date) {
		return date;
	}

	return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
};
export const endOfDate = (date?: Date): Date | undefined => {
	if (!date) {
		return date;
	}

	return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
};
export const formatAsYearMonth = (date?: Date): string => {
	if (!date) {
		return '';
	}
	const month = `${date.getMonth() + 1}`.padStart(2, '0');
	return `${date.getFullYear()}-${month}`;
};
export const startOfMonth = (date?: Date): Date | undefined => {
	if (!date) {
		return date;
	}
	return new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
};
export const endOfMonth = (date?: Date): Date | undefined => {
	if (!date) {
		return date;
	}
	return new Date(date.getFullYear(), date.getMonth()+1, 0, 23, 59, 59);
};