import { NodeType, SerializedNodeWithId } from '../../types';
import { CommonDataSet } from '../common/types';
import { buildStandardCheckbox } from './checkbox';
import { buildBooleanValue, buildDefaultAttributes, buildFontStyle } from './utils';

//  <switch type="checkbox" checked="{{switch2Checked}}" data-text="1" data-checked="false" data-disabled="false"/>
export type StandSwitchDataSet = {
	text?: string;
	checked?: 'true' | 'false' | boolean;
	disabled?: 'true' | 'false' | boolean;
	kind?: 'switch' | 'checkbox';
} & CommonDataSet;
export const buildStandardSwitch = (dataset: StandSwitchDataSet, nodeIdIncrementer: () => number): Array<SerializedNodeWithId> => {
	const kind = dataset.kind || 'switch';
	if (kind === 'checkbox') {
		// 渲染一个checkbox
		return buildStandardCheckbox(dataset, nodeIdIncrementer);
	} else {
		return [ {
			type: NodeType.Element,
			id: nodeIdIncrementer(),
			tagName: 'div',
			attributes: {
				...buildDefaultAttributes('switch', dataset)
			},
			// 如果选中, 需要一个图片渲染. 如果不选中就不需要, 那么推入一个空数组
			childNodes: [ {
				type: NodeType.Element,
				id: nodeIdIncrementer(),
				tagName: 'div',
				attributes: {
					'data-checked': buildBooleanValue(dataset.checked),
					'data-disabled': buildBooleanValue(dataset.disabled)
				},
				childNodes: dataset.text ? [ {
					type: NodeType.Element,
					id: nodeIdIncrementer(),
					tagName: 'span',
					attributes: {
						style: buildFontStyle(dataset)
					},
					childNodes: dataset.text
						? [ {
							type: NodeType.Text,
							id: nodeIdIncrementer(),
							textContent: dataset.text
						} ]
						: []
				} ] : []
			} ]
		} as SerializedNodeWithId ];
	}
};

export const SwitchStyles = `
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=switch] {
	display: -webkit-inline-flex;
	display: inline-flex;
	-webkit-align-items: center;
	align-items: center;
	vertical-align: middle;
}
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=switch] div{
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    width: 52px;
    height: 32px;
    margin-right: 5px;
    border: 1px solid #DFDFDF;
    outline: 0;
    border-radius: 16px;
    box-sizing: border-box;
    background-color: #DFDFDF;
    transition: background-color 0.1s, border 0.1s;
    background-color: rgb(4, 190, 2);
}
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=switch] div:before{
	content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 30px;
    border-radius: 15px;
    background-color: #FDFDFD;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
}
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=switch] div:after{
	content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
}
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=switch] div[data-checked=true]:after{
	-webkit-transform: translateX(20px);
    transform: translateX(20px);
}
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=switch] div[data-checked=true]:before{
	-webkit-transform: scale(0);
    transform: scale(0);
}
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=switch] > span {
	display: inline-block;
	margin-left: 56px;
    line-height: 32px;
}
`;