import React from 'react';
import styled from 'styled-components';
import { WorkAreaTitle } from '../component';
import { TraceViewContextProvider } from './trace-view-context';
import { useData } from './trace-view-data';
import { useDataForFastdfs } from './trace-view-data-fastdfs';
import DataPanel from './trace-view-data-panel';
import PlayerPanel from './trace-view-player-panel';

const TraceView = styled.div`
	display: grid;
	grid-template-columns: calc(75%) 1fr;
	grid-column-gap: var(--gap);
	flex-direction: column;
	> div:first-child {
		grid-column: span 2;
	}
`;

export default (props: { traceId: string ,createAt: string ,taskName?:string}) => {
	const { traceId , createAt, taskName} = props;

	const { trace, details,account,ossToken } = process.env.REACT_APP_CUSTOM_STORAGE && process.env.REACT_APP_CUSTOM_STORAGE == 'fastdfs'
		? useDataForFastdfs(traceId,createAt.substring(0,7).replace('-',''))
		: useData(traceId,createAt.substring(0,7).replace('-',''));

	return <TraceView>
		<WorkAreaTitle title="销售资料回溯" taskName={taskName} createAt={createAt.substring(0,7).replace('-','')} back={true} ossToken={ossToken} trace={trace} account={account}/>
		<TraceViewContextProvider>
			<PlayerPanel trace={trace} details={details}/>
			<DataPanel trace={trace} details={details}/>
		</TraceViewContextProvider>
	</TraceView>;
}