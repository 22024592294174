import React, { forwardRef } from 'react';
import styled from 'styled-components';
import FormField from './form-field';

const Input = styled.input`
	color: var(--font-color);
	height: var(--input-height);
	line-height: var(--input-line-height);
	border: var(--input-border);
	outline: none;
	appearance: none;
	padding: var(--input-padding-vertical) var(--input-padding-horizontal);
	border-radius: var(--radius);
	transition: all 300ms ease-in-out;
	&:hover {
		border: var(--input-hover-border);
	}
	&:focus {
		border: var(--input-focus-border);
		box-shadow: 1px 1px 1px 1px var(--input-focus-box-shadow-color), \
			-1px -1px 1px 1px var(--input-focus-box-shadow-color), \
			1px -1px 1px 1px var(--input-focus-box-shadow-color), \
			-1px 1px 1px 1px var(--input-focus-box-shadow-color);
	}
`;

type Props = {
	label?: string, columns?: number,
	placeholder?: string,
	value?: string;
	defaultValue?: string,
	onChange?: (event: React.ChangeEvent) => void,
	type?: 'text' | 'password',
	disabled?:boolean
};
export default forwardRef((props: Props, ref: any) => {
	const { label, columns = 1, placeholder, value, defaultValue, onChange, type = 'text',disabled } = props;

	return <FormField data-columns={columns}>
		<label>{label}</label>
		<Input type={type} placeholder={placeholder} disabled={disabled}  value={value} defaultValue={defaultValue} onChange={onChange}
		       ref={ref}/>
	</FormField>;
});

