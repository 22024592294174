import React from 'react';
import styled from 'styled-components';

export enum ButtonBarAlignType {
	CENTER = 'center',
	LEFT = 'left',
	RIGHT = 'right'
}

const ButtonBar = styled.div`
	padding-top: calc((var(--form-line-height) - var(--button-height)) / 2);
	height: var(--form-line-height);
	&[data-columns="2"] {
		grid-column: span 2;
	}
	&[data-columns="3"] {
		grid-column: span 3;
	}
	&[data-align=center] {
		text-align: center;
	} 
	&[data-align=right] {
		text-align: right;
	}
	> *:not(:last-child) {
		margin-right: var(--gap);
	}
`;

export default (props: { columns?: number, align?: ButtonBarAlignType, children: React.ReactNode }) => {
	const { columns = 3, align = ButtonBarAlignType.LEFT, children } = props;

	return <ButtonBar data-columns={columns} data-align={align}>
		{children}
	</ButtonBar>;
}