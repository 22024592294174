import pako from 'pako';
import { useEffect, useState } from 'react';
import {
	WebEventWithTime
} from '../catcher/rrweb/types';
import {
	OssStsToken
} from '../service/oss-service';
import {initTraceData,forceFixHeightForMobileReplay,sort,
	fixSimpleImageFrames,reCalcTimeStamp} from './trace-view-data';
import { getAccount } from '../service/storage';
import { TraceData } from '../service/trace-service';
import { findFilePathData,downloadRRwebFileContent,downloadMetaFileContent } from '../service/trace-filepath-service';
import {
	Details,
	FileContent,
	FileMeta,
	OffsetInMillisecond,
	UUID
} from './trace-view-types';
import Account from '../service/account-model';

const fetchData = async (filePaths: Array<String>,token?: String | null): Promise<Array<any>> => {
	return Promise.all(
		filePaths.map(async filePath => await downloadMetaFileContent(filePath))
	);
};
const fetchRRwebData = async (filePaths: Array<String>,token?: String | null): Promise<Array<any>> => {
	return Promise.all(
		filePaths.map(async filePath => await downloadRRwebFileContent(filePath))
	);
};

//增加 account/ossToken，下载功能传参
export const initDataForFastdfs = async (data: TraceData, account?: Account | null,ossToken?: OssStsToken | null): Promise<Details> => {
	if (!data || !data.traceNo) {
		// 没有数据
		return { loaded: true };
	}

	const fileModelData  = await findFilePathData({traceNo:data.traceNo});
	const metaFilePaths = fileModelData!.metaFilePath!.split(',');
	const rrwebFilePaths = fileModelData!.rrwebFilePath!.split(',');
	const metas = await fetchData(metaFilePaths);

	const metaMap = metas.reduce((map, meta) => {
		map.set(meta.uuid, meta);
		return map;
	}, new Map<String, FileMeta>());

	const deflateContents = await fetchRRwebData(rrwebFilePaths);
	const contents: Array<FileContent> = deflateContents.reduce((all,content)=>{
		all.push(JSON.parse(pako.inflate(content.toString('utf8'), { to: 'string' })));
		return all;
	},[] as Array<FileContent>);

	const stepTimeOffsets = new Map<UUID, OffsetInMillisecond>();
	let startTime = 0;
	const events = (contents || [])
		.filter(x => x != null)
		.reduce((all: Array<WebEventWithTime>, content: FileContent, index: number) => {
			if (index === 0) {
				// 记录起始时间
				startTime = content.content![0]!.timestamp;
			}
			// 每一个content都有一个uuid, 对照到meta里面的uuid, 然后找到记录的step
			// 如果step被记录, 则记录这个content的第一帧的时间作为偏移量
			const uuid = content.uuid;
			const meta = metaMap.get(uuid)?.content;
			const currentStep = meta?.currentStep;
			if (currentStep && !stepTimeOffsets.has(currentStep)) {
				stepTimeOffsets.set(currentStep, content.content![0].timestamp - startTime);
			}
			const events = (content.content || []);
			return [ ...all, ...events ];
		}, [] as Array<WebEventWithTime>);

	forceFixHeightForMobileReplay(events);
	sort(events);
	fixSimpleImageFrames(events);
	reCalcTimeStamp(events);

	return {
		stepTimeOffsets: stepTimeOffsets,
		events,
		metas,
		contents,
		loaded: true
	};
};

export const useDataForFastdfs = (traceId: string,createAt: string) => {
	const [ traceData, setTraceData ] = useState<TraceData | null>(null);
	const [ details, setDetails ] = useState<Details>({ loaded: false });
	const [ account, setAccount ] = useState<Account | null>(null);
	const [ ossToken, setOssToken ] = useState<OssStsToken | null>(null);

	useEffect(() => {
		(async () => {
			const traceData = await initTraceData(traceId,createAt);
			setTraceData(traceData);
			sessionStorage.setItem('maskRules',''+traceData?.maskRules);
			if (traceData && traceData.traceNo) {
				setAccount(getAccount());
				const details = await initDataForFastdfs(traceData,{accountName:traceData.createdByAccountName, isAuthorized:true},ossToken);
				setDetails(details);
			}
		})();
	}, [ traceId ]);

	return { trace: traceData, details, account, ossToken };
};