import { NodeType, SerializedNodeWithId } from '../../types';
import { CommonDataSet } from '../common/types';
import { buildDefaultAttributes, buildFontStyle } from './utils';

export type StandTextDataSet = {
	text: string;
} & CommonDataSet;
export const buildStandardText = (dataset: StandTextDataSet, nodeIdIncrementer: () => number): Array<SerializedNodeWithId> => {
	return [ {
		type: NodeType.Element,
		id: nodeIdIncrementer(),
		tagName: 'span',
		attributes: {
			...buildDefaultAttributes('text', dataset, buildFontStyle(dataset))
		},
		childNodes: dataset.text
			? [ {
				type: NodeType.Text,
				id: nodeIdIncrementer(),
				textContent: dataset.text
			} ]
			: []
	} as SerializedNodeWithId ];
};

export const TextStyles = `
html[data-css=glutton-wmp] span:not([data-rdcs])[data-css=text] {
	display: block;
	margin-top: 5px;
}
`;