import { BlockClass, HTMLEventListenerReleaser, INode, Mirror, ThrottleOptions } from './types';

export const polyfillNodeForEach = (): void => {
	if ('NodeList' in window && !NodeList.prototype.forEach) {
		NodeList.prototype.forEach = (Array.prototype.forEach as unknown) as NodeList['forEach'];
	}
};

export const on = (
	type: string,
	fn: EventListenerOrEventListenerObject,
	target: Document | Window = document
): HTMLEventListenerReleaser => {
	const options = { capture: true, passive: true };
	target.addEventListener(type, fn, options);
	return () => {
		target.removeEventListener(type, fn, options);
	};
};


export const getWindowHeight = (targetWindow: Window = window, targetDocument: Document = document): number => {
	return (
		targetWindow.innerHeight ||
		(targetDocument.documentElement && targetDocument.documentElement.clientHeight) ||
		(targetDocument.body && targetDocument.body.clientHeight)
	);
};

export const getWindowWidth = (targetWindow: Window = window, targetDocument: Document = document): number => {
	return (
		targetWindow.innerWidth ||
		(targetDocument.documentElement && targetDocument.documentElement.clientWidth) ||
		(targetDocument.body && targetDocument.body.clientWidth)
	);
};

export const mirror: Mirror = {
	map: {},
	getId(n) {
		// if n is not a serialized INode, use -1 as its id.
		if (!n.__sn) {
			return -1;
		}
		return n.__sn.id;
	},
	getNode(id) {
		return mirror.map[id] || null;
	},
	// TODO: use a weakmap to get rid of manually memory management
	removeNodeFromMap(n) {
		const id = n.__sn && n.__sn.id;
		delete mirror.map[id];
		if (n.childNodes) {
			n.childNodes.forEach((child) =>
				mirror.removeNodeFromMap((child as Node) as INode)
			);
		}
	},
	has(id) {
		return mirror.map.hasOwnProperty(id);
	}
};

export const isBlocked = (node: Node | null, blockClass: BlockClass): boolean => {
	if (!node) {
		return false;
	}
	if (node.nodeType === node.ELEMENT_NODE) {
		let needBlock = false;
		if (typeof blockClass === 'string') {
			needBlock = (node as HTMLElement).classList.contains(blockClass);
		} else {
			(node as HTMLElement).classList.forEach((className) => {
				if (blockClass.test(className)) {
					needBlock = true;
				}
			});
		}
		return needBlock || isBlocked(node.parentNode, blockClass);
	}
	if (node.nodeType === node.TEXT_NODE) {
		// check parent node since text node do not have class name
		return isBlocked(node.parentNode, blockClass);
	}
	return isBlocked(node.parentNode, blockClass);
};

export function throttle<T>(
	func: (arg: T) => void,
	wait: number,
	options: ThrottleOptions = {}
) {
	let timeout: number | null = null;
	let previous = 0;
	// tslint:disable-next-line: only-arrow-functions
	return function (arg: T) {
		let now = Date.now();
		if (!previous && options.leading === false) {
			previous = now;
		}
		let remaining = wait - (now - previous);
		// @ts-ignore
		let context = this;
		let args = arguments;
		if (remaining <= 0 || remaining > wait) {
			if (timeout) {
				window.clearTimeout(timeout);
				timeout = null;
			}
			previous = now;
			// @ts-ignore
			func.apply(context, args);
		} else if (!timeout && options.trailing !== false) {
			timeout = window.setTimeout(() => {
				previous = options.leading === false ? 0 : Date.now();
				timeout = null;
				// @ts-ignore
				func.apply(context, args);
			}, remaining);
		}
	};
}

export const isTouchEvent = (event: MouseEvent | TouchEvent): event is TouchEvent => {
	return Boolean((event as TouchEvent).changedTouches);
};