import { doExport, doGet, doPost } from './ajax';
import {Account, AccountList} from './record-service';

export interface AccountData {
	accountId?:number;
	accountName?: string;
	tenantId?: number | null;
	createAt?: string;
	name?: string;
	email?: string;
	roles?:string;
	role?:string;//添加cas用户时
	enabled?:string;
	authorityList?:Array<Account>
}

export interface AccountDataPage {
	rows?: Array<AccountData & { seq: number, checked?: number }>
}

export const addAccount = async (account: AccountData) => {
	return await doPost(`/api/account/add`,account);
};

export const getTenantList = async ():Promise<AccountList> => {
	const response = await doGet("/api/account/tenant");
	const data = response.body||{data:[]};
	return {
		accounts:data.map((account:any) =>{
			return account;
		})
	};
};

export const getCurrentUserTenantList = async ():Promise<AccountList> => {
	const response = await doGet("/api/account/currentUserTenants");
	const data = response.body||{data:[]};
	return {
		accounts:data.map((account:any) =>{
			return account;
		})
	};
};

export const getUserAuthority = async (accName:string | null):Promise<AccountList> => {
	if(!accName){
		accName = '';
	}
	const response = await doPost("/api/account/getAccountAuthority",{accountName:accName});
	const data = response.body||{data:[]};
	return {
		accounts:data.map((account:any) =>{
			return account;
		})
	};
};

export const findAccountData = async (criteria: AccountData): Promise<AccountDataPage> => {
	const response = await doPost(`/api/account/find`, criteria);
	const data = response.body||{data:[]};
	return {
		rows:data.map((row:any) =>{
			return row;
		})
	};
};
export const findGluttonUser = async (criteria: AccountData): Promise<AccountDataPage> => {
	const response = await doPost("/api/account/findGluttonUser", criteria);
	const data = response.body||{data:[]};
	return {
		rows:data.map((row:any) =>{
			return row;
		})
	};
};

export const addGluttonUser = async (accountName: string,accountPwd: string,) => {
	return await doPost('/api/account/addGluttonUser',{'accountName':accountName,'accountPwd':accountPwd});
};
