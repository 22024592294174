import { doGet, doPost } from './ajax';

export interface TraceFilePathData {
	traceNo: string;
	filePath?: string;
}

export interface TraceFilePathModelData {
	traceNo?: string;
	metaFilePath?: string;
	rrwebFilePath?:string;
}

export interface TraceBufferData {
	traceNo: string;
	buffer?: any;
}

export const findFilePathData = async (data: TraceFilePathData): Promise<TraceFilePathModelData | null> => {
	const response = await doGet(`/api/fdfs/findFilePath/${data.traceNo}`);
	return response.body;
};

export const downloadMetaFileContent = async (filePath: String): Promise<any> => {
	const response = await doPost(`/api/fdfs/download`,{fileName:filePath});
	const data : TraceBufferData = response.body;
	return JSON.parse(data.buffer);
};
export const downloadRRwebFileContent = async (filePath: String): Promise<any> => {
	const response = await doPost(`/api/fdfs/download`,{fileName:filePath});
	const data : TraceBufferData = response.body;
	return data.buffer;
};


