import React, { forwardRef } from 'react';
import styled from 'styled-components';
import FormField from './form-field';

const Input = styled.input`
	color: var(--primary-color);
	transition: all 300ms ease-in-out;
`;
const lableStyle: React.CSSProperties = {
	display: "contents"
};

type Props = {
	label?: string, columns?: number,
	placeholder?: string,
	value?: string;
	defaultValue?: string,
	disabled?:boolean,
	onChange?: (event: React.ChangeEvent) => void
};

export default forwardRef((props: Props, ref: any) => {
	const { label, columns = 1, placeholder, value, defaultValue, onChange,disabled} = props;

	return <FormField style={lableStyle} data-columns={columns}>
		<input type='checkbox' disabled={disabled} checked={value ? true : false} placeholder={placeholder} value={value} defaultValue={defaultValue} onChange={onChange}
		       ref={ref}/>
		<label style={lableStyle}>{label}</label>
	</FormField>;
});