import React, { useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
	Button,
	ButtonBar,
	ButtonBarAlignType,
	FormSectionGapVertical,
	PanelSeparator,
	QueryPanel,
	Text,
	WorkAreaTitle,
	QueryResultRows
} from '../component';
import {addGluttonUser} from '../service/account-service';
const UserEdit = styled.div`
	display: flex;
	flex-direction: column;
`;

export default () => {
	const history = useHistory();
	const [ , forceUpdate ] = useReducer(x => x + 1, 0);
	const [ accountName,setAccountName ] = useState<string>( '');
    const [ accountPwd,setAccountPwd ] = useState<string>( '');

	const onChanged = (propName: string) => (event: React.ChangeEvent) => {
		// @ts-ignore
        if(propName == 'accountName'){
            setAccountName((event.target as HTMLInputElement).value);
        }else{
            setAccountPwd((event.target as HTMLInputElement).value);
        }
		forceUpdate();
	};

	const onAddClicked = async ()  => {
	    if(accountName == '' || accountPwd == ''){
	        alert('用户名/密码不能为空');
	        return;
        }
		const result = await addGluttonUser(accountName,accountPwd);
		if(result.body == 'OK'){
		    alert("添加成功!");
			history.goBack();
		}else{
			alert(result.body);
		}
	};

	return <UserEdit>
		<WorkAreaTitle title="CAS用户信息"/>
		<QueryPanel>
			<Text label="系统帐号" placeholder="请输入" value={accountName} onChange={onChanged('accountName')}/>
            <Text label="帐号密码" placeholder="请输入" value={accountPwd} onChange={onChanged('accountPwd')}/>
		</QueryPanel>
		<PanelSeparator columns={3}/>
		<ButtonBar align={ButtonBarAlignType.CENTER}>
			<Button label="提交" onClick = {() => onAddClicked()} />
			<Button label="返回" onClick = {() => history.goBack() } />
		</ButtonBar>
		<FormSectionGapVertical/>
		
	</UserEdit>;
}