import { NodeType, SerializedNodeWithId } from '../../types';
import { CommonDataSet } from '../common/types';
import { buildDefaultAttributes } from './utils';

//icon
type IconType =
	'success'
	| 'success_no_circle'
	| 'info'
	| 'warn'
	| 'waiting'
	| 'cancel'
	| 'download'
	| 'search'
	| 'clear';
export type StandIconDataSet = {
	icon?: IconType;
	kind?: IconType;
} & CommonDataSet;
export const buildStandardIcon = (dataset: StandIconDataSet, nodeIdIncrementer: () => number): Array<SerializedNodeWithId> => {
	return [ {
		type: NodeType.Element,
		id: nodeIdIncrementer(),
		tagName: 'div',
		attributes: {
			...buildDefaultAttributes('icon', dataset),
			'data-icon': dataset.icon || dataset.kind || ''
		},
		childNodes: []
	} as SerializedNodeWithId ];
};

export const IconStyles = `
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=icon] {
	margin: 0;
	box-sizing: border-box;
	text-align: center;
}
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=icon]:before {
	font: normal normal normal 14px/1 "weui";
	box-sizing: border-box;
	font-size: 23px;
}
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=icon][data-icon=success] {
	color: #09BB07;
}
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=icon][data-icon=success]:before {
	content: "\\EA06";
}
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=icon][data-icon=success_no_circle] {
	color: #09BB07;
}
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=icon][data-icon=success_no_circle]:before {
	content: "\\EA08";
}
html[data-css=glutton-wmp] div[data-css=icon][data-icon=info] {
	color: #10AEFF;
}
html[data-css=glutton-wmp] div[data-css=icon][data-icon=info]:before {
	content: "\\EA03";
}
html[data-css=glutton-wmp] div[data-css=icon][data-icon=warn] {
	color: #FFBE00;
}
html[data-css=glutton-wmp] div[data-css=icon][data-icon=warn]:before {
	border-box;content: "\\EA0B";
}
html[data-css=glutton-wmp] div[data-css=icon][data-icon=waiting] {
	color: #10AEFF;
}
html[data-css=glutton-wmp] div[data-css=icon][data-icon=waiting]:before {
	content: "\\EA09";
}
html[data-css=glutton-wmp] div[data-css=icon][data-icon=cancel] {
	color: #F43530;
}
html[data-css=glutton-wmp] div[data-css=icon][data-icon=cancel]:before {
	content: "\\EA0D";
}
html[data-css=glutton-wmp] div[data-css=icon][data-icon=download] {
	color: #09BB07;
}
html[data-css=glutton-wmp] div[data-css=icon][data-icon=download]:before {
	content: "\\EA02";
}
html[data-css=glutton-wmp] div[data-css=icon][data-icon=search] {
	color: #B2B2B2;
}
html[data-css=glutton-wmp] div[data-css=icon][data-icon=search]:before {
	box-sizing: border-box;
	content: "\\EA0E";
}
html[data-css=glutton-wmp] div[data-css=icon][data-icon=clear] {
	color: #B2B2B2;
}
html[data-css=glutton-wmp] div[data-css=icon][data-icon=clear]:before {
	box-sizing: border-box;
	content: "\\EA0F";
}
`;