import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Path from '../path';
import QualityInspectionTaskQuery from './qualityInspectionTask-query';
import QualityInspectionTaskEdit from './qualityInspectionTask-edit';

export default () => {
	return <Switch>
		<Route path={Path.QUALITY_INSPECTION_TASK_ADD} exact render={({ match }) => {
			return <QualityInspectionTaskEdit taskName={''}/>;
		}}/>
		<Route path={Path.QUALITY_INSPECTION_TASK_MODIFY} exact render={({ match }) => {
			return <QualityInspectionTaskEdit taskName={match.params.taskName}/>;
		}}/>
		<Route path={Path.QUALITY_INSPECTION_TASK_VIEW} exact render={({ match }) => {
			return <QualityInspectionTaskEdit taskName={match.params.taskName}/>;
		}}/>
		<Route path={"/"}>
			<QualityInspectionTaskQuery/>
		</Route>
	</Switch>;
}