import Account from './account-model';
import { doPost, CorrectResponse } from './ajax';
import { getAccount } from './storage';

export interface SignInBean {
	id: string;
	credential: string;
}

export const signIn = async (bean: SignInBean): Promise<Account> => {
	await doPost('/api/account/login', bean, { ignoreAuth: true }).then((resp: CorrectResponse) => {
		sessionStorage.setItem("glutton-account-role", JSON.stringify(resp?.body?.roles ? resp.body.roles : []));
		sessionStorage.setItem("glutton-account-tenantId", JSON.stringify(resp?.body?.tenantId ? resp.body.tenantId : null));
	});
	return getAccount();
};