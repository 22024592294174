import React, { ReactNode } from 'react';

/**
 *  share something between components
 */
export interface SharesContext {
	share: (key: string, value: any) => void;
	discard: (key: string) => void;
	reachout: (key: string) => object | null;
	discardAll: () => void;
}

const Context = React.createContext<SharesContext>({
	share: (key: string, value: any) => {},
	discard: (key: string) => { },
	discardAll: () => { },
	reachout: (key: string): any => { }
});

export const SharesProvider = (props: { children?: ReactNode }) => {
	const { children } = props;

	const sharesContext = new Map<string, any>();

	const share = (key: string, value: any) => {
		sharesContext.set(key, value);
	};

	const discard = (key: string) => {
		sharesContext.delete(key);
	};

	const discardAll = () => {
		sharesContext.clear();
	};

	const reachout = (key: string): object | null => {
		return sharesContext.get(key);
	};


	return <Context.Provider value={{
		share: share,
		discard: discard,
		reachout: reachout,
		discardAll: discardAll
	}}>{children}</Context.Provider>;
};

export const useShares = () => {
	return React.useContext(Context);
};
