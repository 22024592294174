import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Path from '../path';
import GluttonUserQuery from './user-query';
import GluttonUserEdit from './user-edit';

export default () => {
	return <Switch>
		<Route path={Path.GLUTTON_USER_EDIT} exact render={({ match }) => {
			return <GluttonUserEdit/>;
		}}/>
		<Route path={"/"}>
			<GluttonUserQuery/>
		</Route>
	</Switch>;
}