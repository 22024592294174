import React, { useReducer, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
	Button,
	ButtonBar,
	ButtonBarAlignType,
	FormSectionGapVertical,
	PanelSeparator,
	QueryPanel,
	Text,
	WorkAreaTitle,
	QueryResultRows,
	QueryResultPanel, CheckBox
} from '../component';
import {Account, AccountList} from '../service/record-service';
import {getUserAuthority} from '../service/account-service';
import {AccountData, addAccount} from '../service/account-service';
import { DropdownOption } from 'src/component/dropdown';
import {QueryResultColumns, QueryResultRow} from "../component/query-result-panel";
import {isTenantAdminDisplay} from '../utils';
const AccountEdit = styled.div`
	display: flex;
	flex-direction: column;
`;
const roleDivStyle: React.CSSProperties = {
	display: "inline-flex"
};
export default (props: { casName: string | null,role: string | null,id:number | null,tenantId:number | null}) => {
	const { casName,role,id,tenantId } = props;
	const history = useHistory();
	const [ , forceUpdate ] = useReducer(x => x + 1, 0);
	const [ criteria,setCriteria ] = useState<AccountData>( {});
	const [roles,setRoles] = useState<Array<DropdownOption> | []>([
		{value:'TENANT_ADMIN',label:'系统管理员'},{value:'BASIC_USER',label:'普通用户'}
	]);
	const [authorityList,setAuthorityList] = useState<Array<Account & { seq: number, checked?: boolean }> | []>([]);




	type InputCriteria = Exclude<keyof AccountData, 'pageSize' | 'pageNumber'>

	useEffect(() => {
		(async () => {
			const userAuthority:AccountList = await getUserAuthority(casName);
			if(casName && id && tenantId){
                setCriteria({accountName:casName,accountId:id,tenantId:tenantId});
            }
			// @ts-ignore
			setAuthorityList(userAuthority.accounts);
		})();
	}, []);

	const onChanged = (propName: InputCriteria) => (event: React.ChangeEvent) => {
		// @ts-ignore
		criteria[propName] = (event.target as HTMLInputElement).value;
		forceUpdate();
	};

	const onAddClicked = async (account:AccountData)  => {
		if(!account.accountName){
			alert('请填写CAS账号');
			return;
		}
		const authList : Array<Account> = [];
		let hasTenantSelected = false;
		authorityList.forEach(auth => {
			authList.push({checked:auth.checked,tenantId:auth.tenantId,role:auth.role,accountName:''});
			if(auth.checked){
				hasTenantSelected = true;
			}
		});
		if(!hasTenantSelected){
			alert('请为该用户分配系统权限');
			return;
		}

		account.authorityList = authList;
		const result = await addAccount(account);
		if(result.body == 'OK'){
			if(casName){
				alert("配置成功!");
			}else{
				alert("添加成功!");
			}
			history.goBack();
		}else{
			alert(result.body);
		}
	};
	const tenants: QueryResultColumns = [
		{ label: '系统', width: '200px', name: 'tenantName' },
		{ label: '角色', width: '250px', name: 'rolesColumn' }
	];

	const rowData : QueryResultRows = authorityList;

	const rows = (rowData).map((row): QueryResultRow => {
		const data = row as QueryResultRow;
		data.role = data.role ? data.role : 'BASIC_USER';
		const onChanged = (propName?: string) => (event: React.ChangeEvent) => {
			// @ts-ignore
			if(propName == 'checkbox'){
				data.checked = (event.target as HTMLInputElement).checked;
			}else{
				data.role = propName;
			}
			forceUpdate();
		};
		const isTenantAdm = isTenantAdminDisplay();
		const checkboxDisable = data.role == 'TENANT_ADMIN' && isTenantAdm ? true : false;
		const tenantName = data.accountName;
		data.tenantName = (<CheckBox disabled={checkboxDisable} value={data.checked} label={tenantName} onChange={onChanged('checkbox')} />);

		data.rolesColumn = (<div style={roleDivStyle}>
						<input type="radio" disabled={isTenantAdm} name={tenantName} onChange={onChanged('TENANT_ADMIN')} checked={data.role == 'TENANT_ADMIN'} value='TENANT_ADMIN'/><label>{"系统管理员"}</label>
						&nbsp;&nbsp;&nbsp;&nbsp;
						<input type="radio" disabled={isTenantAdm} name={tenantName} onChange={onChanged('BASIC_USER')} checked={data.role == 'BASIC_USER'} value='BASIC_USER'/><label>{"普通用户"}</label>
					</div>);
		return data;
	});


	return <AccountEdit>
		<WorkAreaTitle title="CAS用户信息"/>
		<QueryPanel>
			<Text label="CAS账号" placeholder="请输入" disabled={casName ? true : false} value={criteria.accountName || ''} onChange={onChanged('accountName')}/>
			{/*<Dropdown label="角色" options={roles} value = {criteria.role} onChange ={onChanged("role")}/>
			<Dropdown label="所属系统" options={tenantList} value = {criteria.tenantId?.toString()} onChange ={onChanged("tenantId")}/>*/}
		</QueryPanel>
		<PanelSeparator columns={3}/>
		<WorkAreaTitle title="CAS用户权限配置"/>
		<QueryPanel>
			<QueryResultPanel columns={tenants} rows={rows} pagination={false}/>
		</QueryPanel>
		<PanelSeparator columns={3}/>
		<ButtonBar align={ButtonBarAlignType.LEFT}>
			<Button label="提交" onClick = {() => onAddClicked(criteria) } />
			<Button label="返回" onClick = {() => history.goBack() } />
		</ButtonBar>
		<FormSectionGapVertical/>

	</AccountEdit>;
}