import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Path from '../path';
import AccountQuery from './account-query';
import AccountEdit from './account-edit';

export default () => {
	return <Switch>
		<Route path={Path.ACCOUNT_EDIT} exact render={({ match }) => {
			return <AccountEdit casName = {null} role = {null} id={null} tenantId={null}/>;
		}}/>
		<Route path={Path.ACCOUNT_MODIFY} exact render={({ match }) => {
			return <AccountEdit casName={match.params.accountName} tenantId={match.params.tenantId} role = {match.params.role} id={match.params.id}/>;
		}}/>
		<Route path={"/"}>
			<AccountQuery/>
		</Route>
	</Switch>;
}