export const dateFormat = (date: Date, format: string) => {
	if (!date) {
		return null;
	}

	let opt = {
		"M+": date.getMonth() + 1, //月份
		"d+": date.getDate(), //日
		"h+": date.getHours(), //小时
		"m+": date.getMinutes(), //分
		"s+": date.getSeconds(), //秒
		"S": date.getMilliseconds() //毫秒
	};
	if (/(y+)/.test(format)) {
		format = format.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
	}
	for (let item in opt) {
		if (new RegExp(`(${item})`).test(format)) {
			// @ts-ignore
			format = format.replace(RegExp.$1, (RegExp.$1.length === 1) ? (opt[item]) : ((`00${opt[item]}`).substr((`${opt[item]}`).length)));
		}
	}
	return format;
};


// export const dataURLtoBlob = (dataUrl: string): Blob => {
// 	// @ts-ignore
// 	var arr = dataUrl.split(','), mime = arr[0].match(/:(.*?);/)[1], bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
// 	while (n--) {
// 		u8arr[n] = bstr.charCodeAt(n);
// 	}
// 	return new Blob([u8arr], { type: mime });
// }
//
// export const blobToDataURL = (blob: Blob): Promise<string> => {
// 	return new Promise((resolve, reject) => {
// 		const a = new FileReader();
// 		a.onload = function (e) {
// 			// @ts-ignore
// 			resolve(e.target.result);
// 		};
// 		a.onerror = function (e) {
// 			reject(e);
// 		}
// 		a.readAsDataURL(blob);
// 	})
// }

export const sleep = (time: number): Promise<number> => new Promise(resolve => setTimeout(resolve, time));

/* eslint-disable */
export const { atob, btoa } = (() => {
	// Do not implement polyfill if browser already support Base64 algorithms
	// @ts-ignore
	if (window && typeof window.atob === 'function') {
		return { atob: window.atob, btoa: window.btoa };
	}

	class Chars {
		private cache: { [key in string]: number } | null = null;

		ascii() {
			return 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
		}

		indices() {
			if (!this.cache) {
				this.cache = {};
				const ascii = this.ascii();

				for (let c = 0; c < ascii.length; c++) {
					this.cache[ascii[c]] = c;
				}
			}
			return this.cache;
		}
	}

	const chars = new Chars();

	/**
	 * Binary to ASCII (encode data to Base64)
	 * @param {String} data
	 * @returns {String}
	 */
	const btoa = (data: string): string => {
		let ascii = chars.ascii(),
			len = data.length - 1,
			i = -1,
			b64 = '';

		while (i < len) {
			let code = data.charCodeAt(++i) << 16 | data.charCodeAt(++i) << 8 | data.charCodeAt(++i);
			b64 += ascii[(code >>> 18) & 63] + ascii[(code >>> 12) & 63] + ascii[(code >>> 6) & 63] + ascii[code & 63];
		}

		let pads = data.length % 3;
		if (pads > 0) {
			b64 = b64.slice(0, pads - 3);

			while (b64.length % 4 !== 0) {
				b64 += '=';
			}
		}

		return b64;
	};

	/**
	 * ASCII to binary (decode Base64 to original data)
	 * @param {String} b64
	 * @returns {String}
	 */
	const atob = (b64: string): string => {
		let indices = chars.indices(),
			pos = b64.indexOf('='),
			padded = pos > -1,
			len = padded ? pos : b64.length,
			i = -1,
			data = '';

		while (i < len) {
			let code = indices[b64[++i]] << 18 | indices[b64[++i]] << 12 | indices[b64[++i]] << 6 | indices[b64[++i]];
			if (code !== 0) {
				data += String.fromCharCode((code >>> 16) & 255, (code >>> 8) & 255, code & 255);
			}
		}

		if (padded) {
			data = data.slice(0, pos - b64.length);
		}

		return data;
	};

	return { atob, btoa };
})();
