import { faCaretDown, faCrosshairs, faExchangeAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { formatAsYearMonth } from '../service/date';
import FormField from './form-field';

type ADate = {
	year: number;
	month: number;
	dateOfMonth: number;
	monthType: 'current' | 'previous' | 'next',
	dateType?: 'today',
	selected?: boolean
}

enum PickerSize { INPUT_WIDER = 'input-wider', CALENDAR_WIDER = 'calendar-wider', SAME = 'same'}

const Picker = styled.div`
	position: relative;
	height: var(--input-height);
	line-height: var(--input-line-height);
	border: var(--input-border);
	outline: none;
	appearance: none;
	padding: var(--input-padding-vertical) var(--input-padding-horizontal);
	border-radius: var(--radius);
	transition: all 300ms ease-in-out;
	cursor: pointer;
	display: grid;
	grid-template-columns: auto 48px 1fr;
	&:hover {
		border: var(--input-hover-border);
	}
	&:focus {
		border: var(--input-focus-border);
		box-shadow: 1px 1px 1px 1px var(--input-focus-box-shadow-color), \
			-1px -1px 1px 1px var(--input-focus-box-shadow-color), \
			1px -1px 1px 1px var(--input-focus-box-shadow-color), \
			-1px 1px 1px 1px var(--input-focus-box-shadow-color);
	}
	&[data-show=true] {
		border-bottom-left-radius: 0;
		box-shadow: -1px -1px 1px 1px var(--input-focus-box-shadow-color), \
			1px -1px 1px 1px var(--input-focus-box-shadow-color), \
			1px 0 1px 1px var(--input-focus-box-shadow-color), \
			-1px 0 1px 1px var(--input-focus-box-shadow-color);
		&[data-picker-size=calendar-wider],
		&[data-picker-size=same] {
			border-bottom-right-radius: 0;
		}
		&[data-picker-size=input-wider],
		&[data-picker-size=same]{
			> div[data-widget=calendar-picker] {
				border-top-right-radius: 0;				
			}
		}
	}
	> div:nth-child(2) {
		text-align: center;
	}
`;
const DateLabel = styled.div`
	line-height: var(--input-line-height);
`;
const StartDate = styled(DateLabel)``;
const EndDate = styled(DateLabel)``;
const DatePicker = styled.div.attrs({ 'data-widget': 'calendar-picker' })`
	opacity: 0;
	border-radius: var(--radius);
	border: var(--input-focus-border);
	border-top: 0;
	display: grid;
	grid-template-columns: auto calc(var(--gap) * 3) 1fr;
	position: fixed;
	z-index: 1;
	pointer-events: none;
	transition: all 300ms ease-in-out;
	margin-left: calc(var(--input-padding-horizontal) * -1 - 1px);
	background-color: var(--bg-color);
	cursor: default;
	&[data-show=true] {
		border-top-left-radius: 0;
		opacity: 1;
		pointer-events: unset;
		box-shadow: 1px 1px 1px 1px var(--input-focus-box-shadow-color), \
			-1px 1px 1px 1px var(--input-focus-box-shadow-color), \
			-1px 0 1px 1px var(--input-focus-box-shadow-color), \
			1px 0 1px 1px var(--input-focus-box-shadow-color);
	}
`;
const Calendar = styled.div`
	width: calc(var(--calendar-day-size) * 7);
`;
const CalendarHeader = styled.div`
	display: grid;
	position: relative;
	grid-template-columns: 1fr 1fr;
	> div,
	> select {
		line-height: var(--input-height);
		font-weight: 500;
	}
`;
const YearOrMonthSelect = styled.select`
	appearance: none;
	outline: none;
	border: 0;
	cursor: pointer;
	color: var(--font-color);
	font-size: var(--font-size);
	font-family: var(--font-family);
	padding-right: calc(var(--padding) * 1.5);
	& + svg {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		cursor: pointer;
		pointer-events: none;
	}
`;
const CalendarHeaderYearSelect = styled(YearOrMonthSelect)`
	direction: rtl;
	& + svg {
		left: calc(50% - var(--padding));
	}
`;
const CalendarHeaderMonthSelect = styled(YearOrMonthSelect)`
	padding-left: calc(var(--gap) * 1.5);
	& + svg {
		left: calc(50% + 60px);
	}
`;
const CalendarBody = styled.div`
	display: grid;
	grid-template-columns: repeat(7, var(--calendar-day-size));
	&[data-shortern=true] {
		> div:nth-last-child(-n + 7) {
			border-bottom: var(--input-focus-border);
		}
	}
	> div {
		border-top: var(--input-focus-border);
		line-height: calc(var(--input-height) - 1px);
		&:not(:nth-child(7n+1)) {
			border-left: var(--input-focus-border);
		}
		&:nth-child(-n + 7) {
			font-weight: 500;
		}
	}
`;
const CalendarWeekday = styled.div`
	text-align: center;
`;
const CalendarDay = styled.div`
	position: relative;
	text-align: center;
	cursor: pointer;
	&[data-month-type=previous],
	&[data-month-type=next] {
		color: var(--waive-color);
	}
	&[data-date-type=today] {
		&:before {
			content: '';
			width: var(--gap-thin);
			height: var(--gap-thin);
			background-color: var(--question-color);
			border-radius: 100%;
			display: block;
			position: absolute;
			bottom: var(--gap-thinner);
			right: var(--gap-thinner);
		}
	}
	&[data-selected=true] {
		color: var(--invert-color);
		background-color: var(--primary-color);
	}
	&:hover {
		color: var(--invert-color);
		background-color: var(--primary-color-hover);
	}
`;
const Separator = styled.div`
	border-left: var(--input-focus-border);
	border-right: var(--input-focus-border);
	justify-content: stretch;
	position: relative;
	> svg {
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: var(--input-focus-border-color);
	}
`;
const PickerButtons = styled.div`
	grid-column: span 3;
	display: grid;
	grid-template-columns: 1fr auto auto;
	grid-column-gap: var(--gap);
	padding: var(--gap-thinner) var(--gap-thinner);
	border-top: var(--input-focus-border);
	> div:not(:first-child) {
    	display: grid;
    	grid-template-columns: var(--input-height) 1fr;
		height: var(--input-height);
		//width: var(--input-height);
		padding: 0 calc(var(--padding) / 2) 0 0;
		border-radius: var(--radius);
		background-color: var(--primary-color);
		color: var(--invert-color);
		transition: all 300ms ease-in-out;
		cursor: pointer;
		align-items: center;
    	justify-items: center;
		& > svg {
			color: var(--invert-color);
			transition: all 300ms ease-in-out;
			pointer-events: none;
		}
		&:hover {
			background-color: var(--primary-color-hover);
		}
		&:active {
			background-color: var(--primary-color-active);
		}
	}
`;

const getDaysOfMonths = (year: number): Array<number> => {
	const days = [ 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];
	if ((year % 4 === 0 && year % 100 !== 0) || (year % 400) === 0) {
		days[1] = 29;
	} else {
		days[1] = 28;
	}
	return days;
};

const getDaysOfPanel = (year: number, month: number, dateOfMonth: number): Array<ADate> => {
	const daysOfMonths = getDaysOfMonths(year);
	const daysOfCurrentMonth = daysOfMonths[month - 1];
	const daysOfPreviousMonth = month === 1 ? 31 : daysOfMonths[month - 2];
	const firstDayOfMonth = new Date(year, month - 1, 1);
	const now = new Date();
	const currentMonth: Array<ADate> = new Array(daysOfCurrentMonth).fill(1).map((v, index) => {
		return {
			year: year,
			month: month,
			dateOfMonth: index + 1,
			monthType: 'current',
			dateType: (now.getFullYear() === year && (now.getMonth() + 1 === month) && now.getDate() === index + 1) ? 'today' : undefined,
			selected: index + 1 === dateOfMonth
		};
	});
	const weekday = firstDayOfMonth.getDay();
	const previousDays = (weekday + 6) % 7;
	const previousYear = month === 1 ? year - 1 : year;
	const previousMonth = month === 1 ? 12 : (month - 1);
	new Array(previousDays).fill(1).forEach((v, index) => {
		currentMonth.splice(0, 0, {
			year: previousYear,
			month: previousMonth,
			dateOfMonth: daysOfPreviousMonth - index,
			dateType: (now.getFullYear() === previousYear && (now.getMonth() + 1 === previousMonth) && now.getDate() === daysOfPreviousMonth - index) ? 'today' : undefined,
			monthType: 'previous'
		});
	});
	const nextDays = (7 - (currentMonth.length % 7)) % 7;
	const nextYear = month === 12 ? year + 1 : year;
	const nextMonth = month === 12 ? 1 : (month + 1);
	new Array(nextDays).fill(1).forEach((v, index) => {
		currentMonth.push({
			year: nextYear,
			month: nextMonth,
			dateOfMonth: index + 1,
			dateType: (now.getFullYear() === nextYear && (now.getMonth() + 1 === nextMonth) && now.getDate() === index + 1) ? 'today' : undefined,
			monthType: 'next'
		});
	});
	return currentMonth;
};

const asDate = (date?: string, defaultValue?: Date): Date | null => {
	if (!date) {
		return defaultValue || null;
	}
	const [ datePart, timePart ] = date.split(' ');
	const [ year, month, dateOnMonth ] = datePart.split('-').map(v => v as any * 1);
	const [ hour, minute, second ] = timePart.split(':').map(v => v as any * 1);
	return new Date(year, month - 1, dateOnMonth, hour, minute, second);
};

const CalendarHeaderSelect = (props: {
	value: number,
	onChange: (newValue: number) => void,
	InternalSelect: typeof CalendarHeaderYearSelect | typeof CalendarHeaderMonthSelect,
	options: Array<JSX.Element>
}) => {
	const { value, onChange, InternalSelect, options } = props;
	const onSelectionChange = (evt: React.ChangeEvent) => {
		const newValue = parseInt((evt.target as HTMLSelectElement).value);
		if (newValue !== value) {
			onChange(newValue);
		}
	};
	return <React.Fragment>
		<InternalSelect onChange={onSelectionChange} value={value}>
			{options}
		</InternalSelect>
		<FontAwesomeIcon icon={faCaretDown}/>
	</React.Fragment>;
};
const CalendarHeaderYear = (props: { value: number, onChange: (year: number) => void }) => {
	const { value, onChange } = props;
	return <CalendarHeaderSelect value={value} onChange={onChange}
								 InternalSelect={CalendarHeaderYearSelect}
								 options={new Array(50).fill(1).map((v, index) => {
									 return <option value={index + 2020} key={index}>{index + 2020}年</option>;
								 })}/>;
};
const CalendarHeaderMonth = (props: { value: number, onChange: (month: number) => void }) => {
	const { value, onChange } = props;
	return <CalendarHeaderSelect value={value} onChange={onChange}
								 InternalSelect={CalendarHeaderMonthSelect}
								 options={new Array(12).fill(1).map((v, index) => {
									 return <option value={index} key={index}>{index + 1}月</option>;
								 })}/>;
};

export default (props: {
	label?: string, columns?: number, placeholder?: string,
	startDate: string, endDate: string, isReset?: boolean,
	onChange?: (startDate?: Date, endDate?: Date) => void,
	update?: () => void
}) => {

	const {
		label, columns = 1, placeholder, startDate, endDate, isReset,
		onChange = () => {
		},
		update = () => {
		}
	} = props;

	const containerRef = useRef<HTMLDivElement>(null);
	const calendarRef = useRef<HTMLDivElement>(null);
	const [ pickerSize, setPickerSize ] = useState(PickerSize.SAME);

	const [ showPicker, setShowPicker ] = useState(false);
	const [ currentStartDate, setCurrentStartDate ] = useState<Date>(asDate(startDate) as Date);
	const [ currentEndDate, setCurrentEndDate ] = useState<Date>(asDate(endDate) as Date);

	const doShowPicker = () => {
		if (showPicker) {
			return true;
		}
		const containerWidth = containerRef.current?.offsetWidth || 0;
		const calendarWidth = calendarRef.current?.offsetWidth || 0;
		if (containerWidth > calendarWidth) {
			setPickerSize(PickerSize.INPUT_WIDER);
		} else if (containerWidth < calendarWidth) {
			setPickerSize(PickerSize.CALENDAR_WIDER);
		} else {
			setPickerSize(PickerSize.SAME);
		}
		const top = containerRef.current!.getBoundingClientRect().top;
		const height = containerRef.current?.offsetHeight || 0;
		calendarRef.current!.style.top = `${top + height}px`;
		setShowPicker(true);
	};
	useEffect(() => {
		if (isReset && (formatAsYearMonth(asDate(startDate) as Date)!==formatAsYearMonth(currentStartDate)
			|| formatAsYearMonth(asDate(endDate) as Date)!==formatAsYearMonth(currentEndDate))) {
			setCurrentStartDate(asDate(startDate) as Date);
			setCurrentEndDate(asDate(endDate) as Date);
		}
		const onFocus = (event: FocusEvent) => {
			let notMe = true;
			const body = document.body;
			const target = event.target;
			if (target === window) {
				return;
			}
			let parent: HTMLElement | null | undefined = target as HTMLElement;
			while (true) {
				if (parent === containerRef.current) {
					notMe = false;
					break;
				}
				if (parent === body || parent == null) {
					notMe = true;
					break;
				}
				parent = parent?.parentElement;
			}
			if (notMe) {
				setShowPicker(false);
				setTimeout(() => {
					if (calendarRef.current) {
						calendarRef.current.style.top = '';
						calendarRef.current.style.transition = '';
					}
				}, 300);
			}
		};
		const onScroll = () => {
			if (!showPicker) {
				return true;
			}
			const top = containerRef.current!.getBoundingClientRect().top;
			const height = containerRef.current?.offsetHeight || 0;
			calendarRef.current!.style.top = `${top + height}px`;
			calendarRef.current!.style.transition = 'none';
		};
		window.addEventListener('click', onFocus, true);
		window.addEventListener('focus', onFocus, true);
		window.addEventListener('scroll', onScroll, true);
		return () => {
			window.removeEventListener('click', onFocus, true);
			window.removeEventListener('focus', onFocus, true);
			window.removeEventListener('scroll', onScroll, true);
		};
	});

	const onDateChange = (type: 'start' | 'end', date: Date) => {
		if (type === 'start') {
			setCurrentStartDate(date);
			onChange(date, currentEndDate);
		} else {
			setCurrentEndDate(date);
			onChange(currentStartDate, date);
		}
		update();
	};
	const onYearChange = (type: 'start' | 'end') => (year: number) => {
		const changeDate = type === 'start' ? currentStartDate : currentEndDate;
		const daysOfMonths = getDaysOfMonths(year);
		const daysOfMonth = daysOfMonths[changeDate.getMonth()];
		if (changeDate.getDate() > daysOfMonth) {
			onDateChange(type, new Date(year, changeDate.getMonth(), daysOfMonth));
		} else {
			onDateChange(type, new Date(year, changeDate.getMonth(), changeDate.getDate()));
		}
	};
	const onMonthChange = (type: 'start' | 'end') => (month: number) => {
		const changeDate = type === 'start' ? currentStartDate : currentEndDate;
		const year = changeDate.getFullYear();
		const daysOfMonths = getDaysOfMonths(year);
		const daysOfMonth = daysOfMonths[month];
		if (changeDate.getDate() > daysOfMonth) {
			onDateChange(type, new Date(year, month, daysOfMonth));
		} else {
			onDateChange(type, new Date(year, month, changeDate.getDate()));
		}
	};

	// console.log(currentStartDate);
	const startStr = formatAsYearMonth(currentStartDate);
	// console.log(startStr);
	const endStr = formatAsYearMonth(currentEndDate);

	return <FormField data-columns={columns}>
		<label>{label}</label>
		<Picker placeholder={placeholder} tabIndex={0} onClick={doShowPicker}
				onFocus={() => doShowPicker}
				ref={containerRef}
				data-show={showPicker} data-picker-size={pickerSize}>
			<StartDate>{startStr}</StartDate>
			<div>~</div>
			<EndDate>{endStr}</EndDate>
			<DatePicker data-show={showPicker} ref={calendarRef}>
				<Calendar>
					<CalendarHeader>
						<CalendarHeaderYear value={currentStartDate.getFullYear()} onChange={onYearChange('start')}/>
						<CalendarHeaderMonth value={currentStartDate.getMonth()} onChange={onMonthChange('start')}/>
					</CalendarHeader>
				</Calendar>
				<Separator>
					<FontAwesomeIcon icon={faExchangeAlt}/>
				</Separator>
				<Calendar>
					<CalendarHeader>
						<CalendarHeaderYear value={currentEndDate.getFullYear()} onChange={onYearChange('end')}/>
						<CalendarHeaderMonth value={currentEndDate.getMonth()} onChange={onMonthChange('end')}/>
					</CalendarHeader>
				</Calendar>
			</DatePicker>
		</Picker>
	</FormField>;
};