import React from 'react';
import styled from 'styled-components';
import { TraceData } from '../service/trace-service';
import RRWebPlayer from './rrweb-player';
import { Details } from './trace-view-types';

const RRWeb = styled.div`
	width: 100%;
	background-color: var(--video-bg-color);
	border-radius: var(--radius);
	outline: none;
	> .rr-player {
		background-color: var(--video-bg-color);
	}
`;
const VideoContainer = styled.div`
	width: 100%;
	video {
		background-color: var(--video-bg-color);
		border-radius: var(--radius);
		outline: none;
	}
`;

export default (props: { trace: TraceData | null, details: Details }) => {
	const { trace, details } = props;

	let player;
	if (trace) {
		if (details.loaded) {
			if (details.events?.length !== 0) {
				player = <RRWeb>
					<RRWebPlayer autoPlay={true} events={details.events}/>
				</RRWeb>;
			} else {
				player = <div>没有视频被录制, 请联系系统管理员查询. 联系时请提供TraceNo.</div>;
			}
		} else {
			player = <div className="tips">视频加载中...</div>;
		}
	} else {
		player = <div className="tips">视频加载中...</div>;
	}

	return <VideoContainer>
		{player}
	</VideoContainer>;
}
