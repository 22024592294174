import React, { useReducer, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { doPost } from '../service/ajax';
import styled from 'styled-components';
import {
	Button,
	ButtonBar,
	ButtonBarAlignType,
	FormSectionGapVertical,
	PanelSeparator,
	QueryPanel,
	Text,
	WorkAreaTitle,
	Dropdown
} from '../component';
import { DropdownOption } from 'src/component/dropdown';
import {AccountList} from "../service/record-service";
import {getCurrentUserTenantList, getTenantList} from "../service/account-service";
const MaskEdit = styled.div`
	display: flex;
	flex-direction: column;
`;

export default () => {
	const history = useHistory();
	const [ , forceUpdate ] = useReducer(x => x + 1, 0);
    const [dropdownOpts,setDropdownOpts] = useState<Array<DropdownOption> | []>([]);
    const [ type,setType ] = useState<string>( '1');
    const [ className,setClassName ] = useState<string>( '');
    const [ attributeName,setAttributeName ] = useState<string>( '');
    const [ attributeValue,setAttributeValue ] = useState<string>( '');
	const [tenantList,setTenantList] = useState<Array<DropdownOption> | []>([]);
	const [currentTenant,setCurrentTenant] = useState<string>('');


	useEffect(() => {
		(async () => {
			const tenantlist : AccountList =await getCurrentUserTenantList();
			const accounts = tenantlist?.accounts || [];
			const tenant = new Array<DropdownOption>();
			accounts.forEach((account)=>{
				tenant.push({value:account.tenantId,label:account.accountName});
			});
			setTenantList(tenant);
			// @ts-ignore
			setCurrentTenant(tenant[0].value);

            const dropDownData = new Array<DropdownOption>();
            dropDownData.push({value:1,label:'className'});
            dropDownData.push({value:2,label:'attribute'});
            setDropdownOpts(dropDownData);
		})();
	}, []);

	const onChanged = (propName: string) => (event: React.ChangeEvent) => {
		// @ts-ignore
        if(propName == 'type'){
            setType((event.target as HTMLInputElement).value);
        }else if(propName == 'attributeName'){
            setAttributeName((event.target as HTMLInputElement).value);
        }else if(propName == 'attributeValue'){
            setAttributeValue((event.target as HTMLInputElement).value);
        }else if(propName == 'className'){
            setClassName((event.target as HTMLInputElement).value);
        }else if(propName == 'tenant'){
			setCurrentTenant((event.target as HTMLInputElement).value);
		}
		forceUpdate();
	};

	const onAddClicked = async ()=> {
		if(type == '1' && !className){
			alert("请填写className");
			return;
		}
		if(type == '2'){
			if(!attributeName){
				alert("请填写attributeName");
				return;
			}
			if(!attributeValue){
				alert("请填写attributeValue");
				return;
			}
		}

	    let rule = '.'+className;
	    if(type == '2'){
	        rule = '['+attributeName+'="'+attributeValue+'"]';
        }
	    const result = await doPost('/api/mask/add',{'rule':rule,'tenantId':currentTenant});
	    if(result.body == 'OK'){
			alert("添加成功!");
			history.goBack();
		}else{
			alert(result.body);
		}
	};

	return <MaskEdit>
		<WorkAreaTitle title="新增CAS用户"/>
		<QueryPanel>
			<Dropdown label="脱敏系统" options={tenantList} value={currentTenant} onChange ={onChanged('tenant')}/>
			<Dropdown label="脱敏规则类型" options={dropdownOpts} value={type} onChange ={onChanged('type')}/>
            {type == '1' ?
                <Text label="ClassName" placeholder="请输入" value={className} onChange={onChanged('className')}/>
                :
                <span>
                    <Text label="AttributeName" placeholder="请输入" value={attributeName} onChange={onChanged('attributeName')}/>
                    <Text label="AttributeValue" placeholder="请输入" value={attributeValue} onChange={onChanged('attributeValue')}/>
                </span>
            }
            <PanelSeparator columns={3}/>
			<ButtonBar align={ButtonBarAlignType.CENTER}>
				<Button label="提交" onClick = {() => onAddClicked() } />
				<Button label="返回" onClick = {() => history.goBack() } />
			</ButtonBar>
			<PanelSeparator columns={3}/>
		</QueryPanel>
		<FormSectionGapVertical/>
		
	</MaskEdit>;
}