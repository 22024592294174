import React, { useReducer, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { doExport,doPost } from '../service/ajax';
import styled from 'styled-components';
import {
    Button,
    ButtonBar,
    ButtonBarAlignType, ButtonType,
    Dropdown,
    FormSectionGapVertical,
    PanelSeparator,
    QueryPanel,
    QueryResultColumns,
    QueryResultPanel,
    QueryResultRow,
    Text,
    WorkAreaTitle
} from '../component';
import Path from '../path';
import {AccountList} from "../service/record-service";
import {getCurrentUserTenantList} from "../service/account-service";
import {DropdownOption} from "../component/dropdown";
import {endOfDate, formatFull, startOfDate} from "../service/date";
const QualityInspectionQuery = styled.div`
	display: flex;
	flex-direction: column;
`;

const columns: QueryResultColumns = [
	{ label: '系统', width: '100px', name: 'tenantName'},
	{ label: '回溯号', width: '300px', name: 'traceNoLink'},
	/*{ label: '保险产品计划', width: '1fr', name: 'productName' },
	{
		label: <React.Fragment>
			<div>报价单号</div>
			<div>保单号</div>
			<div>订单号</div>
		</React.Fragment>, width: '200px', name: [ 'quoteNo', 'policyNo', 'orderNo' ]
	},
	{
		label: <React.Fragment>
			<div>付款号</div>
			<div>支付订单号</div>
		</React.Fragment>, width: '200px', name: [ 'payNo', 'payOrderNo' ]
	},
	{
		label: <React.Fragment>
			<div>投保人</div>
			<div>投保人证件号码</div>
			<div>投保人手机号码</div>
		</React.Fragment>,
		width: '160px',
		name: [ 'policyHolderName', 'policyHolderIdNo', 'policyHolderMobile' ]
	},*/
	{ label: '来源', width: '100px', name: 'source'},
	{ label: '质检结果', width: '100px', name: 'resultName'},
	{ label: '质检意见', width: '250px', name: 'comment'},
	{ label: '质检人', width: '100px', name: 'createUserName'},
	{ label: '质检时间', width: '120px', name: 'createAt'}
];

export interface QualityInspectionData {
	tenantId?:string,
	source?:string;
	result?:string;
	comment?:string;
	createBy?:string;
	createAt?: string;
	taskName?:string;
	traceNo?:string;
	traceCreateAt?:string;
    traceCreateTime?:string;
    stringTraceId?:string;
}

export interface QueryResultData {
    pageSize: number;
    pageNumber: number;
    totalPages: number;
    totalItemCount: number;
	rows?: Array<QualityInspectionData>
}

export interface QualityInspectionCriteria {
	createFrom?: string;
	createTo?: string;
	tenantId?:string;
	source?:string;
	result?:string;
	taskName?:string;
	createBy?:string;
    createUserName?:string;
	pageNumber?: number;
	pageSize?: number;
}

export default (props: {taskName?:string}) => {
	const {taskName} = props;
	const history = useHistory();
	const [ , forceUpdate ] = useReducer(x => x + 1, 0);
	const [ data, setData ] = useState<QueryResultData | null>(null);
	const [ searched, setSearched ] = useState(false);
	const [tenantList,setTenantList] = useState<Array<DropdownOption> | []>([]);
	const [resultList] = useState<Array<DropdownOption>>([
		{value:'',label:'All'},{value:'0',label:'不通过'},{value:'1',label:'通过'}]);
	const now = new Date();
	const [ criteria ] = useState<QualityInspectionCriteria>({
		/*createFrom: formatFull(startOfDate(now)),
		createTo: formatFull(endOfDate(now))*/
		taskName:taskName
	});

	useEffect(() => {
		(async () => {
			const tenantlist : AccountList =await getCurrentUserTenantList();
			const accounts = tenantlist?.accounts || [];
			const tenant = new Array<DropdownOption>({value:'null',label:'All'});
			accounts.forEach((account)=>{
				tenant.push({value:account.tenantId,label:account.accountName});
			});
			setTenantList(tenant);
			if(taskName){
				await onSearchClicked(1);
			}
		})();
	}, []);

	const onDateChanged = (startDate?: Date, endDate?: Date) => {
		criteria.createFrom = formatFull(startOfDate(startDate));
		criteria.createTo = formatFull(endOfDate(endDate));
		forceUpdate();
	};

	const onChanged = (propName:string) => (event: React.ChangeEvent) => {
		// @ts-ignore
		criteria[propName] = (event.target as HTMLInputElement).value;
		forceUpdate();
	};

	const onSearchClicked = async (pageNum: number) => {
		if(!criteria.taskName){
			alert('请录入质检任务名称');
			return;
		}
		const { pageNumber = pageNum, pageSize = 10, ...rest } = criteria;
		const response = await doPost(`/api/qualityInspection/list?pageNumber=${pageNumber}&pageSize=${pageSize}`, rest);

		const responseData = response.body || {
			pageNumber: 1,
			pageSize: 10,
			totalPages: 0,
			totalItemCount: 0,
			data: []
		};
		const { data, ...pages } = responseData;
		const result = {
			...pages,
			rows: data.map((row: any, index: number) => {
				row.seq = index + 1;
				return row;
			})
		};
		setData(result);
		setSearched(true);
	};

    const onExportClicked = async () => {
        await doExport('/api/qualityInspection/export',criteria);
    };

    const onPageChanged = async (pageNumber: number) => {
        await onSearchClicked(pageNumber);
    };

	const onAddClicked = () => {
		history.push(Path.QUALITY_INSPECTION_ADD);
	};

	const dataFound = data || { pageNumber: 1, pageSize: 10, totalPages: 1, totalItemCount: 0, rows: [] };

	const rows = (dataFound.rows || []).map((row): QueryResultRow => {
		const data = row as QueryResultRow;
        data.tenantName = `${tenantList.filter(tenant => tenant.value == `${row.tenantId}`)[0].label}`;
        data.resultName = `${resultList.filter(result => result.value == `${row.result}`)[0].label}`;
        data.traceNoLink = <Button label={`${row.traceNo}`} type={ButtonType.LINK}
                                   onClick={() => {
                                       history.push(Path.TRACE_VIEW
										   .replace(':traceId', `${row.stringTraceId}`)
										   .replace(':createAt',`${row.traceCreateTime}`));}
                                   }/>;
		return data;
	});

	return <QualityInspectionQuery>
		<WorkAreaTitle title="质检报表"/>
		<QueryPanel>
			{/*<DateTimeRange label="质检时间" columns={2} startDate={criteria.createFrom!} endDate={criteria.createTo!}
						   onChange={onDateChanged}/>
			<Dropdown label="质检系统" options={tenantList} value = {criteria.tenantId} onChange ={onChanged("tenantId")}/>
			<Text label="来源" placeholder="请输入" value={criteria.source} onChange={onChanged('source')}/>
			<Text label="质检人" placeholder="请输入" value={criteria.createUserName} onChange={onChanged('createUserName')}/>*/}
			<Text label="质检任务名称" placeholder="请输入" value={criteria.taskName} onChange={onChanged('taskName')}/>
			<Dropdown label="质检结果" options={resultList} value={criteria.result} onChange={onChanged('result')}/>

			<PanelSeparator columns={3}/>
			<ButtonBar align={ButtonBarAlignType.CENTER}>
				<Button label="查询" onClick={() => onSearchClicked(1)}/>
				<Button label="导出质检报表" onClick={onExportClicked}/>
			</ButtonBar>
			<PanelSeparator columns={3}/>
		</QueryPanel>
		<FormSectionGapVertical/>
		<QueryResultPanel columns={columns} rows={rows} pagination={true} searched={searched}
                          pageCount={dataFound.totalPages} pageNumber={dataFound.pageNumber}
                          totalItemCount={dataFound.totalItemCount}
                          pageChange={onPageChanged}/>
	</QualityInspectionQuery>;
}