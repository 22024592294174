import React, { ReactNode } from 'react';
import Account from '../service/account-model';
import { getAccount } from '../service/storage';
import SignIn from '../sign-in';
import { useSecurity } from './security';

const Context = React.createContext<Account>({ accountName: '', token: '', isAuthorized: false });

export const AccountProvider = (props: { children?: ReactNode }) => {
	const { children } = props;

	const security = useSecurity();

	const onSigned = () => {
		security.accountChange();
	};

	const account = getAccount();
	if (!account.isAuthorized) {
		return <SignIn onSigned={onSigned}/>;
	} else {
		return <Context.Provider value={account}>{children}</Context.Provider>;
	}
};

export const useAccount = () => {
	return React.useContext(Context);
};
