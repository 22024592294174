import { NodeType, SerializedNodeWithId } from '../../types';
import { CommonDataSet } from '../common/types';
import { buildDefaultAttributes } from './utils';

const base64ImageData: { [key in string]: string } = {};

// 注释：小程序原内容-mode用法：mode ：top left，此处需要变为：topLeft,以此类推；
export type StandImageDataSet = {
	url: string;
	mode?: 'scaleToFill' | 'aspectFit' | 'aspectFill' | 'widthFix' | 'heightFix' | 'top' | 'bottom' | 'center' | 'left' | 'right' | 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
} & CommonDataSet;
export const buildStandardImage = (dataset: StandImageDataSet, nodeIdIncrementer: () => number): Array<SerializedNodeWithId> => {
	let style;
	if (dataset.url && (dataset.url.startsWith('https://') || dataset.url.startsWith('http://'))) {
		style = `background-image:url(${dataset.url});`;
	} else {
		try {
			// @ts-ignore
			const base64 = base64ImageData[dataset.url] || wx.getFileSystemManager().readFileSync(dataset.url, 'base64');
			base64ImageData[dataset.url] = base64;
			style = `background-image:url(data:image/png;base64,${base64})`;
		} catch {
			style = `background-image:url(${dataset.url});`;
		}
	}

	return [ {
		type: NodeType.Element,
		id: nodeIdIncrementer(),
		tagName: 'div',
		attributes: {
			...buildDefaultAttributes('image', dataset, style),
			'data-mode': dataset.mode || 'scaleToFill'
		},
		// 如果选中, 需要一个图片渲染. 如果不选中就不需要, 那么推入一个空数组
		childNodes: []
	} as SerializedNodeWithId ];
};

export const ImageStyles = `
html[data-css='glutton-wmp'] div:not([data-rdcs])[data-css=image] {
    width: 100%;
	height: 100%;
	background-repeat: no-repeat;
}
html[data-css='glutton-wmp'] div:not([data-rdcs])[data-css=image][data-mode=scaleToFill] {
	background-size: 100% 100%;
	background-position: 0% 0%;
}
html[data-css='glutton-wmp'] div:not([data-rdcs])[data-css=image][data-mode=aspectFit] {
	background-size: contain;
    background-position: center center;
}
html[data-css='glutton-wmp'] div:not([data-rdcs])[data-css=image][data-mode=aspectFill] {
	background-size: cover;
    background-position: center center;
 
}
html[data-css='glutton-wmp'] div:not([data-rdcs])[data-css=image][data-mode=widthFix] {
	background-size: 100% auto;
	background-position: 0% 0%;
}
html[data-css='glutton-wmp'] div:not([data-rdcs])[data-css=image][data-mode=heightFix] {
	background-size: auto 100%;
	background-position: 0% 0%;
}
html[data-css='glutton-wmp'] div:not([data-rdcs])[data-css=image][data-mode=top] {
	background-size: auto;
    background-position: center top;
}
html[data-css='glutton-wmp'] div:not([data-rdcs])[data-css=image][data-mode=bottom] {
	background-size: auto;
    background-position: center bottom;
}
html[data-css='glutton-wmp'] div:not([data-rdcs])[data-css=image][data-mode=center] {
	background-size: auto;
    background-position: center center;
}
html[data-css='glutton-wmp'] div:not([data-rdcs])[data-css=image][data-mode=left] {
	background-size: auto;
    background-position: left center;
}
html[data-css='glutton-wmp'] div:not([data-rdcs])[data-css=image][data-mode=right] {
	background-size: auto;
    background-position: right center;
}
html[data-css='glutton-wmp'] div:not([data-rdcs])[data-css=image][data-mode=topLeft] {
	background-size: auto;
    background-position: left top;
}
html[data-css='glutton-wmp'] div:not([data-rdcs])[data-css=image][data-mode=topRight] {
	background-size: auto;
    background-position: right top;
}
html[data-css='glutton-wmp'] div:not([data-rdcs])[data-css=image][data-mode=bottomLeft] {
	background-size: auto;
    background-position: left bottom;
}
html[data-css='glutton-wmp'] div:not([data-rdcs])[data-css=image][data-mode=bottomRight] {
	background-size: auto;
    background-position: right bottom;
}
`;