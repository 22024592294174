import React, { useReducer, useState } from 'react';
import styled from 'styled-components';
import {
	Button,
	ButtonBar,
	ButtonBarAlignType,
	ButtonType,
	FormSectionGapVertical,
	PanelSeparator,
	QueryPanel,
	Text,
	WorkAreaTitle
} from '../component';
import { AuditCriteria, AuditData, findAuditData, blockChainVerify } from '../service/audit-service';
import { useShares } from '../hooks/shares';
// import { findTenantDynamicQueryCriteria, DynamicCriteria } from '../service/trace-service';

const AuditView = styled.div`
	display: grid;
	grid-template-columns: 1fr auto;
	grid-column-gap: var(--side-margin);
	> div:first-child {
		grid-column: span 2;
	}
`;
const Searched = styled.div`
	opacity: 0;
	height: 0;
	transition: all 300ms ease-in-out;
	&[data-searched=true] {
		opacity: 1;
		height: auto;
	}
`;
const NotFound = styled.div`
	line-height: var(--input-height);
`;
const Info = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
`;
const InfoProp = styled.div`
	display: flex;
	flex-direction: column;
	padding: var(--gap-thinner) var(--gap);
	> div:first-child {
		font-weight: 500;
		line-height: var(--input-height);
		color: var(--primary-color-hover);
	}
	> div:last-child {
		font-size: var(--font-size-small);
		line-height: 1.2;
		word-break: break-all;
	}
	&:last-child {
		grid-column: span 3;
		> div:last-child {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			margin-left: calc(var(--gap) * -1);
			margin-right: calc(var(--gap) * -1);
			> div {
				padding: var(--gap-thinner) var(--gap);
			}
		}
	}
`;

export default () => {
	const shares = useShares();
	const [, forceUpdate] = useReducer(x => x + 1, 0);
	const [data, setData] = useState<AuditData | null>(null);
	const [searched, setSearched] = useState(false);
	const [criteria] = useState<AuditCriteria>(shares.reachout('__trace_audit_criteria_keeped') || { traceNo: '', orderNo: '', policyNo: '' });
	// const [dynamicCriterias, setDynamicCriterias] = useState<Array<DynamicCriteria> | null>(null);

	// useEffect(() => {
	// 	(async () => {
	// 		const dynamicCriterias = await findTenantDynamicQueryCriteria();
	// 		setDynamicCriterias(dynamicCriterias);
	// 	})();
	// }, []);

	const onChanged = (propName: keyof AuditCriteria) => (event: React.ChangeEvent) => {
		criteria[propName] = (event.target as HTMLInputElement).value;
		forceUpdate();
	};
	const onSearchClicked = async () => {
		const hasCriteria = Object.keys(criteria).find(key => criteria[key as keyof AuditCriteria]) != null;
		if (!hasCriteria) {
			setData(null);
		} else {
			shares.share('__trace_audit_criteria_keeped', criteria);
			const data = await findAuditData(criteria);
			setData(data);
		}
		setSearched(true);
	};
	const onResetClicked = () => {
		shares.discard('__trace_audit_criteria_keeped');
		Object.keys(criteria).forEach((key) => criteria[key as keyof AuditCriteria] = '');
		forceUpdate();
	};
	const onBlockChainVerifyClicked = async (traceId: number) => {
		const redirectUrl = await blockChainVerify(traceId);
		window.open(redirectUrl);
	}

	return <AuditView>
		<WorkAreaTitle title="区块链验真" />
		<QueryPanel>
			<Text label="业务回溯码" placeholder="请输入" value={criteria.traceNo} onChange={onChanged('traceNo')} />
			<Text label="报价单号" placeholder="请输入" value={criteria.quoteNo} onChange={onChanged('quoteNo')} />
			<Text label="保单号" placeholder="请输入" value={criteria.policyNo} onChange={onChanged('policyNo')} />
			<Text label="订单号" placeholder="请输入" value={criteria.orderNo} onChange={onChanged('orderNo')} />
			<Text label="付款号" placeholder="请输入" value={criteria.payNo} onChange={onChanged('payNo')} />
			<Text label="支付订单号" placeholder="请输入" value={criteria.payOrderNo} onChange={onChanged('payOrderNo')} />
			<Text label="来源" placeholder="请输入" value={criteria.source} onChange={onChanged('source')} />
			{/* {dynamicCriterias?.map((dynamicCriteria, idx) => {
				// @ts-ignore
				return <Text key={`dynamicCriteria_${idx}`} label={`${dynamicCriteria.displayName}`} placeholder="请输入" value={criteria[`uniqueNo${dynamicCriteria.columnSeq}`]} onChange={onChanged(`uniqueNo${dynamicCriteria.columnSeq}`)} />
			})} */}
			<PanelSeparator columns={3} />
			<ButtonBar align={ButtonBarAlignType.CENTER}>
				<Button label="查询" onClick={onSearchClicked} />
				<Button label="重置" type={ButtonType.WAIVE} onClick={onResetClicked} />
			</ButtonBar>
			<PanelSeparator columns={3} />
		</QueryPanel>
		<FormSectionGapVertical />
		<Searched data-searched={searched}>
			{data == null
				? <NotFound>没有找到对应的数据.</NotFound>
				: <React.Fragment>
					<WorkAreaTitle title="验真数据" />
					<Info>
						<InfoProp>
							<div>保险产品计划</div>
							<div>{data.productName}</div>
						</InfoProp>
						<InfoProp>
							<div>报价单号</div>
							<div>{data.quoteNo}</div>
						</InfoProp>
						<InfoProp>
							<div>保单号</div>
							<div>{data.policyNo}</div>
						</InfoProp>
						<InfoProp>
							<div>订单号</div>
							<div>{data.orderNo}</div>
						</InfoProp>
						<InfoProp>
							<div>付款号</div>
							<div>{data.payNo}</div>
						</InfoProp>
						<InfoProp>
							<div>支付单号</div>
							<div>{data.payOrderNo}</div>
						</InfoProp>
						<InfoProp>
							<div>来源</div>
							<div>{data.source}</div>
						</InfoProp>
						<InfoProp>
							<div>投保人</div>
							<div>{data.policyHolderName}</div>
						</InfoProp>
						<InfoProp>
							<div>投保人证件号码</div>
							<div>{data.policyHolderIdNo}</div>
						</InfoProp>
						<InfoProp>
							<div>投保人手机号码</div>
							<div>{data.policyHolderMobile}</div>
						</InfoProp>
						<InfoProp>
							<div>资料生成时间</div>
							<div>{data.createTime}</div>
						</InfoProp>
						<InfoProp>
							<div>资料回溯代码</div>
							<div>{data.traceNo}</div>
						</InfoProp>
						{/* {dynamicCriterias?.map((dynamicCriteria, idx) => {
							// @ts-ignore
							return <InfoProp key={`dynamicCriteria_${idx}`} >
								<div>`${dynamicCriteria.displayName}`</div>
								<div>{data[`uniqueNo${dynamicCriteria.columnSeq}`]}</div>
							</InfoProp>
						})} */}
						{/* <InfoProp>
							<div>回溯资料件数</div>
							<div>{data.fileCount}</div>
						</InfoProp> */}
						<InfoProp>
							<div>验真码</div>
							<div>{data.chainContractNo}</div>
							<ButtonBar align={ButtonBarAlignType.RIGHT}>
								<Button label="验真" onClick={() => { onBlockChainVerifyClicked(data.traceId) }} />
							</ButtonBar>
						</InfoProp>
					</Info>
				</React.Fragment>}
		</Searched>
	</AuditView >;
}