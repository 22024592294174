import { NodeType, SerializedNodeWithId } from '../../types';
import { CommonDataSet } from '../common/types';
import { buildDefaultAttributes } from './utils';

export type StandCameraDataSet = {
	url: string;
} & CommonDataSet;
export const buildStandardCamera = (dataset: StandCameraDataSet, nodeIdIncrementer: () => number): Array<SerializedNodeWithId> => {
	const style = `background-image:url(${dataset.url});`;
	return [ {
		type: NodeType.Element,
		id: nodeIdIncrementer(),
		tagName: 'div',
		attributes: {
			...buildDefaultAttributes('camera', dataset, style)
		},
		childNodes: []
	} as SerializedNodeWithId ];
};

export const CameraStyles = `
html[data-css='glutton-wmp'] div:not([data-rdcs])[data-css=camera] {
    width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: 0% 0%;
}
`;