import React, { useReducer, useState } from 'react';
import styled from 'styled-components';
import {
	Button,
	ButtonBar,
	ButtonBarAlignType,
	ButtonType,
	DateTimeRange,
	FormSectionGapVertical,
	PanelSeparator,
	QueryPanel,
	QueryResultColumns,
	QueryResultPanel,
	QueryResultRow,
	Text,
	WorkAreaTitle,
	CheckBox
} from '../component';
import {downloadFile,batchDownloadFile } from '../service/oss-service';
import {endOfDate, formatFull, startOfDate} from "../service/date";
import { postExcelFile, doPost } from '../service/ajax';
const TraceQuery = styled.div`
	display: flex;
	flex-direction: column;
`;
const Container = styled.div`
	height:30px;
	line-height:30px;
`;
const ButtonWrapper = styled.div`
	width: max-content;
	text-align: center;
	> *:last-child {
		display: inline-block;
		margin-left: var(--gap-thin);
	}
`;
 interface TraceDownloadCriteria {
	createFrom?:string;
	createTo?:string;
	traceNo?: string;
	insuranceCompany?:string;
	productName?:string;
	policyNo?:string;
	allChecked?: boolean;
	traceVideoList?: Array<TraceVideoData>;
	pageNumber?: number;
	pageSize?: number;
}

 interface TraceDownloadDataPage {
	pageSize: number;
	pageNumber: number;
	totalPages: number;
	totalItemCount: number;
	errMsg?: string;
	rows?: Array<TraceVideoData & { seq: number, checked?: boolean}>
}

interface TraceVideoData {
	traceNo: string;
	downloadUrl?: string ;
	createAt: Date ;
	videoSize?: string ;
	downloadStatus?: string ;
	insuranceCompany?: string ;
	productName?: string ;
	policyNo?: string ;
}

const findTraceDownloadDataPage = async (criteria: TraceDownloadCriteria): Promise<TraceDownloadDataPage> => {
	const { pageNumber = 1, pageSize = 10, ...rest } = criteria;
	const response = await doPost(`/api/trace/listVideo?pageNumber=${pageNumber}&pageSize=${pageSize}`, rest);
	const responseData = response.body || {
		pageNumber: 1,
		pageSize: 10,
		totalPages: 0,
		totalItemCount: 0,
		data: []
	};
	const { data, ...pages } = responseData;

	return {
		...pages,
		rows: data.map((row: any, index: number) => {
			row.seq = index + 1;
			row.checked=false;
			return row;
		})
	};
};

const findTraceDownloadData = async (criteria: TraceDownloadCriteria): Promise<TraceDownloadDataPage> => {
	const { ...rest } = criteria;
	const response = await doPost(`/api/trace/loadAllVideo`, rest);
	const responseData = response.body || {
		pageNumber: 1,
		pageSize: 10,
		totalPages: 0,
		totalItemCount: 0,
		data: []
	};
	const { data, ...pages } = responseData;
	return {
		...pages,
		rows: data.map((row: any, index: number) => {
			row.seq = index + 1;
			// row.checked=false;
			return row;
		})
	};
};

type InputCriteria = Exclude<keyof TraceDownloadCriteria, 'pageSize' | 'pageNumber'>

export default () => {
	const now = new Date();
	const [ isDateReset, setisDateReset ] = useState(false);
	const [ allchecked,  setAllchecked  ]:any = useState(false);
	const [ , forceUpdate ] = useReducer(x => x + 1, 0);
	const [ data, setData ] = useState<TraceDownloadDataPage | null>(null);
	const [ searched, setSearched ] = useState(false);
	const [ criteria ] = useState<TraceDownloadCriteria>({
		createFrom: formatFull(startOfDate(now)),
		createTo: formatFull(endOfDate(now)),
		traceNo: '',
		insuranceCompany:'',
		productName:'',
		allChecked:false,
		traceVideoList:[]
	});
	const [ currentCriteria, setCurrentCriteria ] = useState<TraceDownloadCriteria | null>(null);
	const columns: QueryResultColumns = [
		/*{
			label:<React.Fragment>
					<input type='checkbox' checked={allchecked} onChange={($event)=>{
					$event.persist();
					setAllchecked($event.target.checked);
					criteria.allChecked=$event.target.checked;
					localStorage.setItem('allchecked',JSON.stringify($event.target.checked));//存储全选状态
					// @ts-ignore
					criteria.traceVideoList=[];
					if(localStorage.getItem('allchecked')==='true'){
						rows.forEach(item=>{
							if(item.downloadStatus==='2'){
								item.checked=true;//将每一项数据checkbox全选时为true
							}else{
								item.checked=false;
							}
						})
					}else{
						rows.forEach(item=>{
							item.checked=false;
						})
					}
					// @ts-ignore
					document.getElementById("btn").disabled=false;
					console.log(criteria.traceVideoList);

				}} />
			</React.Fragment>,
			width: '40px', name: 'checkbox'},*/
		{ label: '#', width: '40px', name: 'seq' },
		{ label: '保险产品计划', width: '200px', name: 'productName' },
		{ label: '保单号', width: '200px', name: 'policyNo' },
		{
			label: <React.Fragment>
				<div>回溯号</div>
			</React.Fragment>, width: '250px', name: [ 'traceNo' ]
		},
		{
			label: <React.Fragment>
				<div>视频合成状态</div>
			</React.Fragment>, width: '120px', name: [ 'downloadStatusValue' ]
		},
		{
			label: <React.Fragment>
				<div>视频生成时间</div>
			</React.Fragment>, width: '200px', name: [ 'createAt' ]
		},
		{ label: '', width: '60px', name: 'actions' }
	];
	const onChanged = (propName: InputCriteria) => (event: React.ChangeEvent) => {
		// @ts-ignore
		criteria[propName] = (event.target as HTMLInputElement).value;
		forceUpdate();
	};
	const doSearch = async (pagination: TraceDownloadCriteria) => {
		// 设置当前查询
		setCurrentCriteria(pagination);

		const data = await findTraceDownloadDataPage(pagination);
		/*if(localStorage.getItem('allchecked')==='true'){
			data.rows?.forEach(item=>{
				// @ts-ignore
				if(item.downloadStatus == '2'){
					// @ts-ignore
					item.checked=true;
					if(criteria.allChecked){
						// @ts-ignore
						criteria.traceVideoList.forEach(i=>{
							if(i.traceNo===item.traceNo){
								item.checked=false;
							}
						})
					}
				}else{
					// @ts-ignore
					item.checked=false;
				}
			})
		}else{
			data.rows?.forEach(item=>{
				// @ts-ignore
				item.checked=false;
				if(!criteria.allChecked){
					// @ts-ignore
					criteria.traceVideoList.forEach(i=>{
						if(i.traceNo===item.traceNo){
							item.checked=true;
						}
					})
				}
			})
		}*/

		// @ts-ignore
		//console.log(criteria.traceVideoList);
		setData(data);
		setSearched(true);

	};
	const onSearchClicked = async (criteria: TraceDownloadCriteria) => {
		const pagination = {
			...criteria,
			pageNumber: 1,
			pageSize: 10
		};
		//localStorage.removeItem('allchecked');
		setAllchecked(false);
		// @ts-ignore
		criteria.traceVideoList=[];
		// @ts-ignore
		//document.getElementById("btn").disabled=false;
		await doSearch(pagination);
	};
	const onResetClicked = () => {
		criteria.createFrom = formatFull(startOfDate(now));
		criteria.createTo = formatFull(endOfDate(now));
		criteria.traceNo = '';
		criteria.insuranceCompany='';
		criteria.productName='';
		// @ts-ignore
		//document.getElementById("btn").disabled=false;
		setisDateReset(true);
		forceUpdate();
	};
	// 批量下载
	const onbatchdownloads = async (rows: Array<any>,criteria: TraceDownloadCriteria)=>{
		//dataFound.totalItemCount
		const pagination={ ...criteria };
		const data = await findTraceDownloadData(pagination);
		let downloadList :string[]=[];
		// @ts-ignore
		if(data.rows.length === 0){
			alert('请先勾选需要下载的记录');
			return;
			// @ts-ignore
		}else if(data.rows.length >10){
			alert('不能超过10条记录，请修改');
			return;
			// @ts-ignore
		}else if(data.rows.length<=10){
			// @ts-ignore
			data.rows.map(item=>{
				// @ts-ignore
				downloadList.push(item.downloadUrl);
				return downloadList;
			})
			batchDownloadFile(downloadList);
			// @ts-ignore
			document.getElementById("btn").disabled=true;//按钮禁用
		}
	}

	const onPageChanged = async (pageNumber: number) => {
		const pagination = {
			...currentCriteria,
			pageNumber
		};
		await doSearch(pagination);
	};

	const dataFound = data || { pageNumber: 1, pageSize: 10, totalPages: 1, totalItemCount: 0, rows: [] };
	const rows = (dataFound.rows || []).map((row): QueryResultRow => {
		const data = row as QueryResultRow;
		const onChangeds = () => (event: React.ChangeEvent) => {
			// @ts-ignore
			data.checked = (event.target as HTMLInputElement).checked;
			if(criteria.allChecked){
				if(data.checked===false){
					// @ts-ignore
					criteria!.traceVideoList!.push({traceNo:data.traceNo,
													videoSize:data.videoSize,
													createAt: data.createAt,
													downloadUrl: data.downloadUrl});
				}else{
					// @ts-ignore
					criteria.traceVideoList=criteria.traceVideoList.filter(item=>item.traceNo!==data.traceNo);
				}
			}else{
				if(data.checked){
					// @ts-ignore
					criteria!.traceVideoList!.push({traceNo:data.traceNo,
													videoSize:data.videoSize,
													createAt: data.createAt,
													downloadUrl: data.downloadUrl});
				}else{
					// @ts-ignore
					criteria.traceVideoList=criteria.traceVideoList.filter(item=>item.traceNo!==data.traceNo);
				}
			}
			// @ts-ignore
			document.getElementById("btn").disabled=false;

			// setAllchecked(rows.every(item=>item.checked));//反选
			forceUpdate();
		};
		data.downloadStatusValue = `${data.downloadStatus == 1 ? '合成中' : data.downloadStatus == 3 ? '合成异常' : '合成完成' }`;
		if(process.env.REACT_APP_CUSTOM_STORAGE && process.env.REACT_APP_CUSTOM_STORAGE == 'fastdfs'){
			data.actions = data.downloadStatus == 2 ? <ButtonWrapper>
				<Button label="下载" type={ButtonType.LINK}
						onClick={() => {postExcelFile('/api/fdfs/downloadVideo?filePath='+`${row.downloadUrl}`,[])
						}} />
			</ButtonWrapper> : null;
			// 单选
			data.checkbox = data.downloadStatus == 2 ?(<CheckBox value={data.checked} onChange={onChangeds()} />):null;
		}else{
			data.actions = data.downloadStatus == 1 || data.downloadStatus == 3 ? null : <ButtonWrapper>
				<Button label="下载" type={ButtonType.LINK}
						onClick={() => {downloadFile(`${row.downloadUrl}`);
						}} />
			</ButtonWrapper>;
			// 单选
			data.checkbox = data.downloadStatus == 1 || data.downloadStatus == 3 ?null:(<CheckBox value={data.checked} onChange={onChangeds()} />);
		}
		return data;
	});
	const onDateChanged = (startDate?: Date, endDate?: Date) => {
		criteria.createFrom = formatFull(startOfDate(startDate));
		criteria.createTo = formatFull(endOfDate(endDate));
		forceUpdate();
	};
	const traceUpdate = () => {
		forceUpdate();
	};

	// noinspection DuplicatedCode
	return <TraceQuery>
		<WorkAreaTitle title="视频下载管理"/>
		<QueryPanel>
			<DateTimeRange label="资料生成时间" columns={2} startDate={criteria.createFrom!} endDate={criteria.createTo!} isReset={isDateReset}
						   onChange={onDateChanged} update={traceUpdate} />
			<Text label="保险产品计划" placeholder="请输入" value={criteria.productName} onChange={onChanged('productName')}/>
			<Text label="业务回溯码" placeholder="请输入" value={criteria.traceNo} onChange={onChanged('traceNo')}/>
			<PanelSeparator columns={3}/>
			<ButtonBar align={ButtonBarAlignType.CENTER}>
				<Button label="查询" onClick={() => onSearchClicked(criteria)}/>
				<Button label="重置" type={ButtonType.WAIVE} onClick={onResetClicked}/>
				{/*<Button label="批量下载" type={ButtonType.WAIVE} onClick={()=>{onbatchdownloads(rows,criteria)}} id="btn"/>*/}
			</ButtonBar>
			<PanelSeparator columns={3}/>
		</QueryPanel>
		<FormSectionGapVertical/>
		<QueryResultPanel columns={columns} rows={rows} pagination={true} searched={searched}
						  pageCount={dataFound.totalPages} pageNumber={dataFound.pageNumber}
						  totalItemCount={dataFound.totalItemCount}
						  pageChange={onPageChanged}/>
	</TraceQuery>;
}