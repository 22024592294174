// @ts-ignore
import formatDate from 'dateformat';
// @ts-ignore
import HmacSHA1 from 'hmacsha1';
import md5, { fromByteArray } from './md5';
import { doGet } from './ajax';

// import OSS from 'ali-oss';

export interface OssStsToken {
	ossEndpoint: string;
	ossRegion: string;
	ossBucket: string;
	accessKeyId: string;
	accessKeySecret: string;
	securityToken: string;
}

export const askOssStsToken = async (host: string, dataToken: string): Promise<OssStsToken> => {
	const { body } = await doGet(host, '/api/oss/ask/writeonly', dataToken);
	return body;
};

const hasWX = () => {
	try {
		// @ts-ignore
		return wx && wx.request && (typeof wx.request === 'function');
	} catch {
		return false;
	}
};

export const uploadFile = async (file: Buffer, fileName: string, stsToken: OssStsToken): Promise<void> => {
	const date = formatDate(new Date(), 'UTC:ddd, dd mmm yyyy HH:MM:ss \'GMT\'');
	let host = `${stsToken.ossBucket}.${stsToken.ossRegion}.aliyuncs.com`;
	if(stsToken.ossEndpoint.endsWith('.jdcloud-oss.com')){
		host = `${stsToken.ossBucket}.s3.${stsToken.ossRegion}.jdcloud-oss.com`;
	}else if(stsToken.ossEndpoint.endsWith('.myhuaweicloud.com')){
		host = `${stsToken.ossBucket}.obs.${stsToken.ossRegion}.myhuaweicloud.com`;
	}
	const filepath = fileName.split('/').map(segment => encodeURIComponent(segment)).join('/');
	const url = `https://${host}/${filepath}`;

	if (hasWX()) {
		const bodyString = file.toString("utf8");
		const bodyMd5 = md5(bodyString);
		const bodyMd5Base64 = fromByteArray(bodyMd5);
		let ossHeaders = [ `x-amz-date:${date}`, `x-amz-security-token:${stsToken.securityToken}` ].join('\n');
		if(stsToken.ossEndpoint.endsWith('.aliyuncs.com')){
			ossHeaders = [ `x-oss-date:${date}`, `x-oss-security-token:${stsToken.securityToken}` ].join('\n');
		}
		const signatureString = `PUT\n${bodyMd5Base64}\napplication/json\n${date}\n${ossHeaders}\n/${stsToken.ossBucket}/${fileName}`;
		const signature = HmacSHA1(stsToken.accessKeySecret, signatureString);
		let authorization = `AWS ${stsToken.accessKeyId}:${signature}`;
		if(stsToken.ossEndpoint.endsWith('.aliyuncs.com')){
			authorization = `OSS ${stsToken.accessKeyId}:${signature}`;
		}
		let headers = {
			'content-length': `${file.length}`,
			'content-type': 'application/json',
			'content-md5': bodyMd5Base64,
			host: host,
			// GMT time
			'x-amz-date': date,
			'x-amz-security-token': stsToken.securityToken,
			authorization: authorization
		};
		if(stsToken.ossEndpoint.endsWith('.aliyuncs.com')){
			headers = {
				'content-length': `${file.length}`,
				'content-type': 'application/json',
				'content-md5': bodyMd5Base64,
				host: host,
				// GMT time
				// @ts-ignore
				'x-oss-date': date,
				'x-oss-security-token': stsToken.securityToken,
				authorization: authorization
			}
		}
		// 微信小程序环境
		await new Promise((resolve, reject) => {
			// @ts-ignore
			wx.request({
				url,
				method: 'PUT',
				header: headers,
				data: bodyString,
				success: (res: { statusCode: number }) => {
					const { statusCode } = res;
					if (statusCode === 200) {
						resolve();
					} else {
						reject();
					}
				},
				fail: () => {
					// TODO 失败的参数格式是什么?
					reject();
				}
			});
		});
	} else {
		// 一般浏览器环境
		const bodyMd5 = md5(file);
		const bodyMd5Base64 = fromByteArray(bodyMd5);

		let ossHeaders = [ `x-amz-date:${date}`, `x-amz-security-token:${stsToken.securityToken}` ].join('\n');
		if(stsToken.ossEndpoint.endsWith('.aliyuncs.com')){
			ossHeaders = [ `x-oss-date:${date}`, `x-oss-security-token:${stsToken.securityToken}` ].join('\n');
		}else if(stsToken.ossEndpoint.endsWith('.myhuaweicloud.com')){
			ossHeaders = [ `x-obs-date:${date}`, `x-obs-security-token:${stsToken.securityToken}` ].join('\n');
		}

		let signatureString = `PUT\n${bodyMd5Base64}\n\n\n${ossHeaders}\n/${stsToken.ossBucket}/${fileName}`;
		if(stsToken.ossEndpoint.endsWith('.aliyuncs.com')){
			signatureString = `PUT\n${bodyMd5Base64}\n\n${date}\n${ossHeaders}\n/${stsToken.ossBucket}/${fileName}`;
		}else if(stsToken.ossEndpoint.endsWith('.myhuaweicloud.com')){
			signatureString = `PUT\n${bodyMd5Base64}\n\n\n${ossHeaders}\n/${stsToken.ossBucket}/${fileName}`;
		}
		const signature = HmacSHA1(stsToken.accessKeySecret, signatureString);

		let authorization = `AWS ${stsToken.accessKeyId}:${signature}`;
		if(stsToken.ossEndpoint.endsWith('.aliyuncs.com')){
			authorization = `OSS ${stsToken.accessKeyId}:${signature}`;
		}else if(stsToken.ossEndpoint.endsWith('.myhuaweicloud.com')){
			authorization = `OBS ${stsToken.accessKeyId}:${signature}`;
		}

		let headers = {
			'content-length': `${file.length}`,
			'content-md5': bodyMd5Base64,
			host: host,
			// GMT time
			'x-amz-date': date,
			'x-amz-security-token': stsToken.securityToken,
			authorization: authorization
		};
		if(stsToken.ossEndpoint.endsWith('.aliyuncs.com')){
			headers = {
				'content-length': `${file.length}`,
				'content-md5': bodyMd5Base64,
				host: host,
				// GMT time
				// @ts-ignore
				'x-oss-date': date,
				'x-oss-security-token': stsToken.securityToken,
				authorization: authorization
			}
		}else if(stsToken.ossEndpoint.endsWith('.myhuaweicloud.com')){
			headers = {
				'content-length': `${file.length}`,
				'content-md5': bodyMd5Base64,
				host: host,
				// GMT time
				// @ts-ignore
				'x-obs-date': date,
				'x-obs-security-token': stsToken.securityToken,
				authorization: authorization
			}
		}
		await fetch(url, {
			method: 'PUT',
			// @ts-ignore
			headers,
			body: file
		});
	}
};