/** 增量事件类型 */
export enum IncrementalEventType {
	Mutation,
	MouseMove,
	MouseInteraction,
	Scroll,
	ViewportResize,
	Input,
	TouchMove,
	MediaInteraction,
	StyleSheetRule,
	Canvas,
}
