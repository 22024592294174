import {
	AddedNodeMutation,
	AttributeMutation,
	IncrementalEventType,
	INode,
	InputEventData,
	MutationEventData,
	RemovedNodeMutation,
	ScrollEventData,
	TextMutation,
	TreeNode
} from '../rrweb/types';
import { mirror } from '../rrweb/utils';

export class TreeIndex {
	public tree!: Record<number, TreeNode>;

	private removeNodeMutations!: RemovedNodeMutation[];
	private textMutations!: TextMutation[];
	private attributeMutations!: AttributeMutation[];
	private indexes!: Map<number, TreeNode>;
	private removeIdSet!: Set<number>;
	private scrollMap!: Map<number, ScrollEventData>;
	private inputMap!: Map<number, InputEventData>;

	constructor() {
		this.reset();
	}

	public add(mutation: AddedNodeMutation) {
		const parentTreeNode = this.indexes.get(mutation.parentId);
		const treeNode: TreeNode = {
			id: mutation.node.id,
			mutation,
			children: [],
			texts: [],
			attributes: []
		};
		if (!parentTreeNode) {
			this.tree[treeNode.id] = treeNode;
		} else {
			treeNode.parent = parentTreeNode;
			parentTreeNode.children[treeNode.id] = treeNode;
		}
		this.indexes.set(treeNode.id, treeNode);
	}

	public remove(mutation: RemovedNodeMutation) {
		const parentTreeNode = this.indexes.get(mutation.parentId);
		const treeNode = this.indexes.get(mutation.id);

		const deepRemoveFromMirror = (id: number) => {
			this.removeIdSet.add(id);
			const node = mirror.getNode(id);
			node?.childNodes.forEach((childNode) =>{
				if(((childNode as unknown) as INode).__sn){
					deepRemoveFromMirror(((childNode as unknown) as INode).__sn.id)
				}
			});
		};
		const deepRemoveFromTreeIndex = (node: TreeNode) => {
			this.removeIdSet.add(node.id);
			Object.values(node.children).forEach((n) => deepRemoveFromTreeIndex(n));
			const _treeNode = this.indexes.get(node.id);
			if (_treeNode) {
				const _parentTreeNode = _treeNode.parent;
				if (_parentTreeNode) {
					delete _treeNode.parent;
					delete _parentTreeNode.children[_treeNode.id];
					this.indexes.delete(mutation.id);
				}
			}
		};

		if (!treeNode) {
			this.removeNodeMutations.push(mutation);
			deepRemoveFromMirror(mutation.id);
		} else if (!parentTreeNode) {
			delete this.tree[treeNode.id];
			this.indexes.delete(treeNode.id);
			deepRemoveFromTreeIndex(treeNode);
		} else {
			delete treeNode.parent;
			delete parentTreeNode.children[treeNode.id];
			this.indexes.delete(mutation.id);
			deepRemoveFromTreeIndex(treeNode);
		}
	}

	public text(mutation: TextMutation) {
		const treeNode = this.indexes.get(mutation.id);
		if (treeNode) {
			treeNode.texts.push(mutation);
		} else {
			this.textMutations.push(mutation);
		}
	}

	public attribute(mutation: AttributeMutation) {
		const treeNode = this.indexes.get(mutation.id);
		if (treeNode) {
			treeNode.attributes.push(mutation);
		} else {
			this.attributeMutations.push(mutation);
		}
	}

	public scroll(d: ScrollEventData) {
		this.scrollMap.set(d.id, d);
	}

	public input(d: InputEventData) {
		this.inputMap.set(d.id, d);
	}

	public flush(): {
		mutationData: MutationEventData;
		scrollMap: TreeIndex['scrollMap'];
		inputMap: TreeIndex['inputMap'];
	} {
		const {
			tree,
			removeNodeMutations,
			textMutations,
			attributeMutations
		} = this;

		const batchMutationData: MutationEventData = {
			source: IncrementalEventType.Mutation,
			removes: removeNodeMutations,
			texts: textMutations,
			attributes: attributeMutations,
			adds: []
		};

		const walk = (treeNode: TreeNode, removed: boolean) => {
			if (removed) {
				this.removeIdSet.add(treeNode.id);
			}
			batchMutationData.texts = batchMutationData.texts
				.concat(removed ? [] : treeNode.texts)
				.filter((m) => !this.removeIdSet.has(m.id));
			batchMutationData.attributes = batchMutationData.attributes
				.concat(removed ? [] : treeNode.attributes)
				.filter((m) => !this.removeIdSet.has(m.id));
			if (
				!this.removeIdSet.has(treeNode.id) &&
				!this.removeIdSet.has(treeNode.mutation.parentId) &&
				!removed
			) {
				batchMutationData.adds.push(treeNode.mutation);
				if (treeNode.children) {
					Object.values(treeNode.children).forEach((n) => walk(n, false));
				}
			} else {
				Object.values(treeNode.children).forEach((n) => walk(n, true));
			}
		};

		Object.values(tree).forEach((n) => walk(n, false));

		this.scrollMap.forEach((data, id) => {
			if (this.removeIdSet.has(id)) {
				this.scrollMap.delete(id);
			}
		});
		this.inputMap.forEach((data, id) => {
			if (this.removeIdSet.has(id)) {
				this.inputMap.delete(id);
			}
		});

		const scrollMap = new Map(this.scrollMap);
		const inputMap = new Map(this.inputMap);

		this.reset();

		return {
			mutationData: batchMutationData,
			scrollMap,
			inputMap
		};
	}

	private reset() {
		this.tree = [];
		this.indexes = new Map();
		this.removeNodeMutations = [];
		this.textMutations = [];
		this.attributeMutations = [];
		this.removeIdSet = new Set();
		this.scrollMap = new Map();
		this.inputMap = new Map();
	}
}
