import React, { useReducer, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { doPost } from '../service/ajax';
import styled from 'styled-components';
import {QualityInspectionData} from './qualityInspection-query';
import {
	Button,
	ButtonBar,
	ButtonBarAlignType,
	FormSectionGapVertical,
	PanelSeparator,
	QueryPanel,
	Text,
	WorkAreaTitle,
	Dropdown
} from '../component';
import { DropdownOption } from 'src/component/dropdown';
const QualityInspectionEdit = styled.div`
	display: flex;
	flex-direction: column;
`;

export default (props: { traceNo: string,taskName:string,createAt:string}) => {
    const history = useHistory();
	const { traceNo, taskName,createAt} = props;
	const [ , forceUpdate ] = useReducer(x => x + 1, 0);
    const [ criteria ] = useState<QualityInspectionData>({taskName:taskName,traceNo:traceNo,traceCreateAt:createAt});
    const [resultList] = useState<Array<DropdownOption>>([
        {value:'0',label:'不通过'},{value:'1',label:'通过'}]);

	const onChanged = (propName: string) => (event: React.ChangeEvent) => {
		// @ts-ignore
        criteria[propName] = (event.target as HTMLInputElement).value;
		forceUpdate();
	};

	useEffect(() => {
		(async () => {
			criteria.result = '1';
            forceUpdate();
		})();
	}, []);

	const onAddClicked = async ()=> {
	    const result = await doPost('/api/qualityInspection/add',criteria);
	    if(result.body == 'OK'){
			alert("添加成功!");
			window.close();
		}else{
			alert(result.body);
		}
	};

	return <QualityInspectionEdit>
		<WorkAreaTitle title="质检结果录入"/>
		<QueryPanel>
			<Text label="质检任务名称" placeholder="请输入" value={criteria.taskName} disabled={true}/>
			<Text label="回溯号" placeholder="请输入" value={criteria.traceNo} disabled={true}/>
            <Dropdown label="质检结果" options={resultList} value={criteria.result} onChange={onChanged('result')}/>
            <Text label="质检意见" columns={3} placeholder="请输入" value={criteria.comment} onChange={onChanged('comment')}/>

            <PanelSeparator columns={3}/>
			<ButtonBar align={ButtonBarAlignType.CENTER}>
				<Button label="提交" onClick = {() => onAddClicked() } />
				<Button label="返回" onClick = {() => history.goBack() } />
			</ButtonBar>
			<PanelSeparator columns={3}/>
		</QueryPanel>
		<FormSectionGapVertical/>
		
	</QualityInspectionEdit>;
}