import { serializeNodeWithId, transformAttribute } from './capture-snapshot';
import {
	AddedNodeMutation,
	AttributeCursor,
	BlockClass,
	INode,
	MaskInputOptions,
	MutationData,
	RemovedNodeMutation,
	TextCursor
} from './types';
import { isBlocked, mirror } from './utils';

export type MutationListener = (data: MutationData) => void;

const moveKey = (id: number, parentId: number) => `${id}@${parentId}`;

const isINode = (n: Node | INode): n is INode => '__sn' in n;

const isAncestorInSet = (nodeSet: Set<Node>, node: Node): boolean => {
	const { parentNode } = node;
	if (!parentNode) {
		return false;
	}
	if (nodeSet.has(parentNode)) {
		return true;
	}
	return isAncestorInSet(nodeSet, parentNode);
};

const isParentRemoved = (removes: RemovedNodeMutation[], node: Node): boolean => {
	const { parentNode } = node;
	if (!parentNode) {
		return false;
	}
	const parentId = mirror.getId((parentNode as Node) as INode);
	if (removes.some((r) => r.id === parentId)) {
		return true;
	}
	return isParentRemoved(removes, parentNode);
};

const isAncestorRemoved = (target: INode): boolean => {
	const id = mirror.getId(target);
	if (!mirror.has(id)) {
		return true;
	}
	if (target.parentNode && target.parentNode.nodeType === target.DOCUMENT_NODE) {
		return false;
	}
	// if the root is not document, it means the node is not in the DOM tree anymore
	if (!target.parentNode) {
		return true;
	}
	return isAncestorRemoved((target.parentNode as unknown) as INode);
};

const deepDelete = (addsSet: Set<Node>, n: Node) => {
	addsSet.delete(n);
	n.childNodes.forEach((childN) => deepDelete(addsSet, childN));
};

export default class MutationBuffer {
	private texts: TextCursor[] = [];
	private attributes: AttributeCursor[] = [];
	private removes: RemovedNodeMutation[] = [];
	private adds: AddedNodeMutation[] = [];

	private movedMap: Record<string, true> = {};

	/**
	 * the browser MutationObserver emits multiple mutations after
	 * a delay for performance reasons, making tracing added nodes hard
	 * in our `processMutations` callback function.
	 * For example, if we append an element el_1 into body, and then append
	 * another element el_2 into el_1, these two mutations may be passed to the
	 * callback function together when the two operations were done.
	 * Generally we need to trace child nodes of newly added nodes, but in this
	 * case if we count el_2 as el_1's child node in the first mutation record,
	 * then we will count el_2 again in the second mutation record which was
	 * duplicated.
	 * To avoid of duplicate counting added nodes, we use a Set to store
	 * added nodes and its child nodes during iterate mutation records. Then
	 * collect added nodes from the Set which have no duplicate copy. But
	 * this also causes newly added nodes will not be serialized with id ASAP,
	 * which means all the id related calculation should be lazy too.
	 */
	private addedSet = new Set<Node>();
	private movedSet = new Set<Node>();
	private droppedSet = new Set<Node>();

	private readonly onMutation: MutationListener;
	private readonly targetWindow: Window;
	private readonly targetDocument: Document;
	private readonly blockClass: BlockClass;
	private readonly inlineStylesheet: boolean;
	private readonly maskInputOptions: MaskInputOptions;
	private readonly watch: (targetWindow: Window, targetDocument: Document) => void;

	constructor(
		targetWindow: Window,
		targetDocument: Document,
		listener: MutationListener,
		blockClass: BlockClass,
		inlineStylesheet: boolean,
		maskInputOptions: MaskInputOptions,
		watch: (targetWindow: Window, targetDocument: Document) => void
	) {
		this.targetWindow = targetWindow;
		this.targetDocument = targetDocument;
		this.blockClass = blockClass;
		this.inlineStylesheet = inlineStylesheet;
		this.maskInputOptions = maskInputOptions;
		this.onMutation = listener;
		this.watch = watch;
	}

	public processMutations = (mutations: Array<MutationRecord>) => {
		mutations.forEach(this.processMutation);

		/**
		 * Sometimes child node may be pushed before its newly added
		 * parent, so we init a queue to store these nodes.
		 */
		const addQueue: Array<Node> = [];
		const pushAdd = (node: Node) => {
			if (!node.parentNode) {
				return;
			}
			const parentId = mirror.getId((node.parentNode as Node) as INode);
			const nextId = node.nextSibling && mirror.getId((node.nextSibling as unknown) as INode);
			if (parentId === -1 || nextId === -1) {
				addQueue.push(node);
				return;
			}
			this.adds.push({
				parentId,
				nextId,
				node: serializeNodeWithId(
					node,
					this.targetWindow,
					this.targetDocument,
					mirror.map,
					this.blockClass,
					true,
					this.inlineStylesheet,
					this.maskInputOptions
				)!
			});
		};

		this.movedSet.forEach((n) => {
			pushAdd(n);
		});

		this.addedSet.forEach((n) => {
			if (!isAncestorInSet(this.droppedSet, n) && !isParentRemoved(this.removes, n)) {
				pushAdd(n);
			} else if (isAncestorInSet(this.movedSet, n)) {
				pushAdd(n);
			} else {
				this.droppedSet.add(n);
			}
		});

		while (addQueue.length) {
			if (
				addQueue.every(
					(n) => mirror.getId((n.parentNode as Node) as INode) === -1
				)
			) {
				/**
				 * If all nodes in queue could not find a serialized parent,
				 * it may be a bug or corner case. We need to escape the
				 * dead while loop at once.
				 */
				break;
			}
			pushAdd(addQueue.shift()!);
		}

		this.emit();
	};
	public emit = () => {
		const payload = {
			texts: this.texts
				.map((text) => ({
					id: mirror.getId(text.node as INode),
					value: text.value
				}))
				// text mutation's id was not in the mirror map means the target node has been removed
				.filter((text) => mirror.has(text.id)),
			attributes: this.attributes
				.map((attribute) => ({
					id: mirror.getId(attribute.node as INode),
					attributes: attribute.attributes
				}))
				// attribute mutation's id was not in the mirror map means the target node has been removed
				.filter((attribute) => mirror.has(attribute.id)),
			removes: this.removes,
			adds: this.adds
		};
		// payload may be empty if the mutations happened in some blocked elements
		if (
			!payload.texts.length &&
			!payload.attributes.length &&
			!payload.removes.length &&
			!payload.adds.length
		) {
			return;
		}
		this.onMutation(payload);

		// reset
		this.texts = [];
		this.attributes = [];
		this.removes = [];
		this.adds = [];
		this.addedSet = new Set<Node>();
		this.movedSet = new Set<Node>();
		this.droppedSet = new Set<Node>();
		this.movedMap = {};
	};
	private watchIFrame = (iframe: HTMLIFrameElement): void => {
		if (iframe.contentDocument
			&& (iframe.contentDocument.readyState === 'interactive' || iframe.contentDocument.readyState === 'complete')) {
			// 已经加载完毕
			this.watch(iframe.contentWindow!, iframe.contentDocument);
		}
		// 可能还没有加载完毕
		const onIframeLoad = (event: Event) => {
			const target = event.target as HTMLIFrameElement;
			target.removeEventListener('load', onIframeLoad);
			// 因为src变化重新载入之后, 需要全部重新添加监听
			this.watch(target.contentWindow!, target.contentDocument!);
		};
		iframe.addEventListener('load', onIframeLoad);
	};
	private processMutation = (m: MutationRecord) => {
		switch (m.type) {
			case 'characterData': {
				const value = m.target.textContent;
				if (!isBlocked(m.target, this.blockClass) && value !== m.oldValue) {
					this.texts.push({
						value,
						node: m.target
					});
				}
				break;
			}
			case 'attributes': {
				const value = (m.target as HTMLElement).getAttribute(m.attributeName!);
				if (isBlocked(m.target, this.blockClass) || value === m.oldValue) {
					return;
				}

				if ((m.target as HTMLElement).tagName.toLowerCase() === 'iframe' && m.attributeName === 'src') {
					// iframe src变化
					this.watchIFrame(m.target as HTMLIFrameElement);
				}

				let item: AttributeCursor | undefined = this.attributes.find(
					(a) => a.node === m.target
				);
				if (!item) {
					item = {
						node: m.target,
						attributes: {}
					};
					this.attributes.push(item);
				}
				// overwrite attribute if the mutations was triggered in same time
				item.attributes[m.attributeName!] = transformAttribute(
					this.targetWindow,
					this.targetDocument,
					m.attributeName!,
					value!
				);
				break;
			}
			case 'childList': {
				m.addedNodes.forEach((n: Node) => this.genAdds(n, m.target));
				m.removedNodes.forEach((n: Node) => {
					const nodeId = mirror.getId(n as INode);
					const parentId = mirror.getId(m.target as INode);
					if (isBlocked(n, this.blockClass) || isBlocked(m.target, this.blockClass)) {
						return;
					}
					// removed node has not been serialized yet, just remove it from the Set
					if (this.addedSet.has(n)) {
						deepDelete(this.addedSet, n);
						this.droppedSet.add(n);
					} else if (this.addedSet.has(m.target) && nodeId === -1) {
						/**
						 * If target was newly added and removed child node was
						 * not serialized, it means the child node has been removed
						 * before callback fired, so we can ignore it because
						 * newly added node will be serialized without child nodes.
						 * TODO: verify this
						 */
					} else if (isAncestorRemoved(m.target as INode)) {
						/**
						 * If parent id was not in the mirror map any more, it
						 * means the parent node has already been removed. So
						 * the node is also removed which we do not need to track
						 * and replay.
						 */
					} else if (this.movedSet.has(n) && this.movedMap[moveKey(nodeId, parentId)]) {
						deepDelete(this.movedSet, n);
					} else {
						this.removes.push({
							parentId,
							id: nodeId
						});
					}
					mirror.removeNodeFromMap(n as INode);
				});
				break;
			}
			default:
				break;
		}
	};

	private genAdds = (n: Node | INode, target?: Node | INode) => {
		if (isBlocked(n, this.blockClass)) {
			return;
		}
		if (isINode(n)) {
			this.movedSet.add(n);
			let targetId: number | null = null;
			if (target && isINode(target)) {
				targetId = target.__sn.id;
			}
			if (targetId) {
				this.movedMap[moveKey(n.__sn.id, targetId)] = true;
			}
		} else {
			this.addedSet.add(n);
			this.droppedSet.delete(n);
			if ((n as HTMLElement).tagName) {
				if ((n as HTMLElement).tagName.toLowerCase() === 'iframe') {
					this.watchIFrame(n as HTMLIFrameElement);
				}
			}
		}
		n.childNodes.forEach((childN) => this.genAdds(childN));
	};
}
