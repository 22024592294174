import { SerializedNodeWithId } from '../types';
import standardBuilders, { StandardStyles } from './standard';

export const buildSuppliedNodes = (
	nodeType: string,
	dataset: any,
	nodeIdIncrementer: () => number
): Array<SerializedNodeWithId> => {
	const nodeBuild = standardBuilders.get(nodeType);
	if (!nodeBuild) {
		console.log(`No node supplement builder found for node type[${nodeType}].`);
		return [];
	} else {
		return nodeBuild(dataset, nodeIdIncrementer);
	}
};

export const SuppliedStyles = [ StandardStyles ].join('');