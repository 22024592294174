import React, { forwardRef } from 'react';
import styled from 'styled-components';
import FormField from './form-field';

export interface DropdownOption {
	value: string | number;
	label: string;
}

export type DropdownOptions = Array<DropdownOption>;

const Select = styled.select`
	color: var(--font-color);
	height: var(--input-height);
	line-height: calc(var(--input-line-height) + 2px);
	border: var(--input-border);
	outline: none;
	appearance: none;
	padding: var(--input-padding-vertical) var(--input-padding-horizontal);
	border-radius: var(--radius);
	transition: all 300ms ease-in-out;
	&:hover {
		border: var(--input-hover-border);
	}
	&:focus {
		border: var(--input-focus-border);
		box-shadow: 1px 1px 1px 1px var(--input-focus-box-shadow-color), \
			-1px -1px 1px 1px var(--input-focus-box-shadow-color), \
			1px -1px 1px 1px var(--input-focus-box-shadow-color), \
			-1px 1px 1px 1px var(--input-focus-box-shadow-color);
	}
`;

type Props = {
	label?: string, columns?: number,
	placeholder?: string,
	options: DropdownOptions,
	value?: string;
	defaultValue?: string,
	onChange?: (event: React.ChangeEvent) => void,
	disabled?:boolean
};

export default forwardRef((props: Props, ref: any) => {
	const { label, columns = 1, placeholder, options, value, defaultValue, onChange,disabled } = props;
	return <FormField data-columns={columns}>
		<label>{label}</label>
		<Select placeholder={placeholder} value={value} disabled={disabled} defaultValue={defaultValue} onChange={onChange}
		        ref={ref}>
			{options.map((option) => {
				return <option data-value={option.value} key={option.label} value = {option.value}>
					{option.label}
				</option>;
			})}
		</Select>
	</FormField>;
});