import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlug } from '@fortawesome/free-solid-svg-icons';

const Footer = styled.div`
	display: flex;
	width: 100%;
	height: var(--footer-height);
	padding: 0 var(--side-margin);
	background-color: var(--footer-bg-color);
	color: var(--footer-color);
	z-index: var(--footer-z-index);
`;
const CopyRight = styled.div`
	line-height: var(--footer-height);
	opacity: var(--blurred-color-opacity);
`;
const Placeholder = styled.div`
	flex-grow: 1;
`;
const Builder = styled.div`
	display: flex;
	line-height: var(--footer-height);
	> svg {
		margin-right: var(--gap-thin);
		align-self: center;
	}
	> span {
		align-self: center;
	}
`;

export default () => {
	return <Footer>
		<CopyRight>Copyright © 2020</CopyRight>
		<Placeholder/>
		<Builder>
			<FontAwesomeIcon icon={faPlug}/>
			<span>Powered by eBaoCloud</span>
		</Builder>
	</Footer>;
}