import SaasGluttonRecorder from './rrweb-saas';
import '../polyfill/array-find';
import '../polyfill/object-assign';
import '../polyfill/array-from';
import '../polyfill/array-includes';
import '../polyfill/number-isnan';
import '../polyfill/string-endsWith';
import '../polyfill/string-startsWith';
// @ts-ignore
import { fetch } from 'whatwg-fetch';
import Promise from 'promise';

// console.warn('original promise', window.Promise);
// console.warn('polyfill promise', Promise);
// console.warn('original fetch', window.fetch)
// console.warn('polyfill fetch', fetch);
window.Promise = window.Promise || Promise;
window.fetch = window.fetch || fetch;

export default SaasGluttonRecorder;