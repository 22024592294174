import { faCheckCircle, faPaste, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import { TraceData } from '../service/trace-service';
import { useTimeOffset, TIME_OFFSET_CHANGE } from './trace-view-context';
import { Details } from './trace-view-types';

const Info = styled.div`
	display: flex;
	flex-direction: column;
	//width: 300px;
	background-color: var(--trace-info-bg-color);
	border-radius: var(--radius);
	margin-bottom: var(--gap);
	padding-bottom: var(--gap-thinner);
`;
const InfoTitle = styled.div`
	grid-column: span 3;
	display: flex;
	position: relative;
	line-height: var(--trace-info-title-height);
	padding: var(--gap-thinner) var(--gap) 0;
	align-items: center;
	color: var(--primary-color);
	> svg {
		font-size: 1.2em;
		margin-right: var(--gap-thin);
	}
	> div {
		font-weight: 600;
	}
	&:after {
		content: '';
		position: absolute;
		top: 100%;
		left: var(--gap);
		width: calc(100% - var(--gap) * 2);
		height: 1px;
		background-color: var(--separator-color);
	}
`;
const NotFound = styled.div`
	line-height: var(--input-height);
	padding: var(--gap-thinner) var(--gap);
`;
const InfoProp = styled.div`
	display: flex;
	flex-direction: column;
	padding: var(--gap-thinner) var(--gap);
	> div:first-child {
		font-weight: 500;
		line-height: var(--input-height);
		color: var(--primary-color-hover);
	}
	> div:last-child {
		line-height: 1.2;
		word-break: break-all;
	}
	&:last-child > div:last-child {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		border-radius: var(--radius);
		//&[data-totel="1"],
		//&[data-totel="2"] {
		//	> div:last-child {
		//		border-top-right-radius: var(--radius);
		//	}
		//}
		//&[data-remainder="1"] {
		//	> div:nth-last-child(2) {
		//		border-bottom-right-radius: var(--radius);
		//	}
		//	> div:last-child {
		//		border-bottom-left-radius: var(--radius);
		//	}
		//}
		//&[data-remainder="2"] {
		//	> div:nth-last-child(3) {
		//		border-bottom-right-radius: var(--radius);
		//	}
		//	> div:nth-last-child(2) {
		//		border-bottom-left-radius: var(--radius);
		//	}
		//}
		//&[data-remainder="0"] {
		//	> div:nth-last-child(3) {
		//		border-bottom-left-radius: var(--radius);
		//	}
		//}
	}
`;
const Step = styled.div`
	display: flex;
	align-items: center;
	line-height: var(--input-line-height);
	padding: var(--gap-thinner) calc((var(--gap-thinner) + var(--gap)) / 2);
	text-align: center;
	border: 1px solid var(--trace-step-bg-color);
	border-top: 0;
	font-size: var(--font-size-small);
	//&:nth-child(-n + 3) {
	//	border-top: 1px solid var(--trace-step-bg-color);
	//}
	//&:nth-child(3n),
	// &:nth-child(3n + 2) {
	//	border-left: 0;
	//}
	&:first-child {
		border-top: 1px solid var(--trace-step-bg-color);
		border-top-left-radius: var(--radius);
		border-top-right-radius: var(--radius);
	}
	//&:nth-child(3) {
	//	border-top-right-radius: var(--radius);
	//}
	&:last-child {
		border-bottom-left-radius: var(--radius);
		border-bottom-right-radius: var(--radius);
		//border-right: 1px solid var(--trace-step-bg-color);
	}
	&[data-active=true] {
		> svg {
			color: var(--success-color);
		};
		cursor: pointer
	}
	> svg {
		margin-right: var(--gap-thinner);
		color: var(--question-color);
	}
	> div {
		white-space: nowrap;
	}
`;

const NoData = () => {
	return <NotFound>没有找到对应的数据.</NotFound>;
};

const HasData = (props: { trace: TraceData, details: Details }) => {
	const { trace, details } = props;
	const { stepTimeOffsets } = details;

	const { emitter } = useTimeOffset();

	return <React.Fragment>
		<InfoProp>
			<div>保险产品计划</div>
			<div>{trace.productName}</div>
		</InfoProp>
		<InfoProp>
			<div>报价单号</div>
			<div>{trace.quoteNo}</div>
		</InfoProp>
		<InfoProp>
			<div>保单号</div>
			<div>{trace.policyNo}</div>
		</InfoProp>
		<InfoProp>
			<div>订单号</div>
			<div>{trace.orderNo}</div>
		</InfoProp>
		<InfoProp>
			<div>付款号</div>
			<div>{trace.payNo}</div>
		</InfoProp>
		<InfoProp>
			<div>支付单号</div>
			<div>{trace.payOrderNo}</div>
		</InfoProp>
		<InfoProp>
			<div>来源</div>
			<div>{trace.source}</div>
		</InfoProp>
		<InfoProp>
			<div>投保人</div>
			<div>{trace.policyHolderName}</div>
		</InfoProp>
		<InfoProp>
			<div>投保人证件号码</div>
			<div>{trace.policyHolderIdNo}</div>
		</InfoProp>
		<InfoProp>
			<div>投保人手机号码</div>
			<div>{trace.policyHolderMobile}</div>
		</InfoProp>
		<InfoProp>
			<div>资料生成时间</div>
			<div>{trace.createTime}</div>
		</InfoProp>
		<InfoProp>
			<div>资料回溯代码</div>
			<div>{trace.traceNo}</div>
		</InfoProp>
		<InfoProp>
			<div>投保步骤</div>
			<div data-remainder={(trace.allSteps || []).length % 3}
				data-totel={(trace.allSteps || []).length}>{
					(trace.allSteps || []).map((step, index) => {
						if (stepTimeOffsets && stepTimeOffsets.has(step)) {
							// 步骤有对应的数据的时候才会显示为可点击
							return <Step key={`${step}-${index}`}
								data-active={true}
								onClick={() => emitter.emit(TIME_OFFSET_CHANGE, stepTimeOffsets.get(step)!)}>
								<FontAwesomeIcon icon={faCheckCircle} />
								<div>{step}</div>
							</Step>;
						} else {
							return <Step key={`${step}-${index}`}
								data-active={false}
								title={"目前没有对应的视频数据."}>
								<FontAwesomeIcon icon={faQuestionCircle} />
								<div>{step}</div>
							</Step>;
						}
					})
				}
			</div>
		</InfoProp>
	</React.Fragment>;
};

const InfoData = (props: { trace: TraceData | null, details: Details }) => {
	const { trace, details } = props;

	return trace == null
		? <NoData />
		: <HasData trace={trace} details={details} />;
};

export default (props: { trace: TraceData | null, details: Details }) => {
	const { trace, details } = props;

	return <Info>
		<InfoTitle>
			<FontAwesomeIcon icon={faPaste} />
			<div>保单/订单信息</div>
		</InfoTitle>
		<InfoData trace={trace} details={details} />
	</Info>;
}