import React, { useReducer, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { doPost,doGet } from '../service/ajax';
import styled from 'styled-components';
import {QualityInspectionTask} from './qualityInspectionTask-query';
import {
	Button,
	ButtonBar,
	ButtonBarAlignType,
	FormSectionGapVertical,
	PanelSeparator,
	QueryPanel,
	Text,
	WorkAreaTitle
} from '../component';
const QualityInspectionTaskEdit = styled.div`
	display: flex;
	flex-direction: column;
`;

export default (props: { taskName: string }) => {
	const { taskName } = props;
	const isView = window.location.href.indexOf('/view') > 0;
	const history = useHistory();
	const [ , forceUpdate ] = useReducer(x => x + 1, 0);
    const [ criteria,setCriteria ] = useState<QualityInspectionTask>({});

	const onChanged = (propName: string) => (event: React.ChangeEvent) => {
		// @ts-ignore
        criteria[propName] = (event.target as HTMLInputElement).value;
		forceUpdate();
	};

	useEffect(() => {
		(async () => {
			if(taskName !== ''){
				const result = await doGet(`/api/qualityInspection/findTaskByTaskName/${taskName}`);
				const task : QualityInspectionTask = result.body.data[0];
				setCriteria(task);
			}
		})();
	}, []);

	const onAddClicked = async ()=> {
	    console.log(criteria);
	    if(!criteria.taskName){
	        alert('请录入质检任务名称');
	        return;
        }
	    const result = await doPost('/api/qualityInspection/addTask',criteria);
	    if(result.body == 'OK'){
			alert("添加成功!");
			history.goBack();
		}else{
			alert(result.body);
		}
	};

	return <QualityInspectionTaskEdit>
		<WorkAreaTitle title={isView ? "查看质检任务" : "质检任务录入"}/>
		<QueryPanel>
            <Text label="质检任务名称" disabled={taskName !== ''} columns={3} placeholder="请输入" value={criteria.taskName} onChange={onChanged('taskName')}/>
            <Text label="质检任务说明" disabled={isView} columns={3} placeholder="请输入" value={criteria.taskDesc} onChange={onChanged('taskDesc')}/>
			{taskName !== '' ? <Text label="质检任务结果说明" disabled={isView} columns={3} placeholder="请输入" value={criteria.taskResultComment} onChange={onChanged('taskResultComment')}/> : null}
			<PanelSeparator columns={3}/>
			<ButtonBar align={ButtonBarAlignType.CENTER}>
				{isView ? null : <Button label="提交" onClick = {() => onAddClicked() } />}
				<Button label="返回" onClick = {() => history.goBack() } />
			</ButtonBar>
			<PanelSeparator columns={3}/>
		</QueryPanel>
		<FormSectionGapVertical/>
		
	</QualityInspectionTaskEdit>;
}