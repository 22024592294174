import React from 'react';
import styled from 'styled-components';

export enum ButtonType {
	PRIMARY = "primary",
	DANGER = "danger",
	INFO = "info",
	WARN = "warn",
	WAIVE = "waive",
	LINK = "link"
}

const Button = styled.button`
	height: var(--button-height);
	line-height: var(--button-line-height);
	padding: var(--button-padding-vertical) var(--button-padding-horizontal);
	border-radius: var(--radius);
	outline: none;
	appearance: none;
	cursor: pointer;
	transition: all 300ms ease-in-out;
	border: 0;
	min-width: 100px;
	user-select: none;
	&[data-type=primary] {
		background-color: var(--primary-color);
		color: var(--invert-color);
		&:hover {
			background-color: var(--primary-color-hover);
		}
		&:active {
			background-color: var(--primary-color-active);
		}
	}
	&[data-type=waive] {
		background-color: var(--waive-color);
		&:hover {
			background-color: var(--waive-color-hover);
		}
		&:active {
			background-color: var(--waive-color-active);
		}
	}
	&[data-type=link] {
		padding-left: 0;
		padding-right: 0;
		background-color: transparent;
		min-width: unset;
		text-decoration: underline;
		color: var(--primary-color);
		&:hover {
			color: var(--primary-color-hover);
		}
		&:active {
			color: var(--primary-color-active);
		}
	}
`;

export default (props: { label?: string, type?: ButtonType, id?: string, onClick?: () => void }) => {
	const { label, type = ButtonType.PRIMARY,id, onClick } = props;

	return <Button id={id} data-type={type} onClick={onClick}>
		{label}
	</Button>;
}