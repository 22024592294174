import { doGet, doPost,doExport } from './ajax';

export interface TraceCriteria {
	createFrom?: string;
	createTo?: string;
	traceNo?: string;
	productName?: string;
	policyNo?: string;
	orderNo?: string;
	quoteNo?: string;
	payNo?: string;
	payOrderNo?: string;
	policyHolderName?: string;
	policyHolderIdNo?: string;
	policyHolderMobile?: string;
	pageNumber?: number;
	pageSize?: number;
	archiveStatuses?: Array<number>;
	source?: string;
	tenantId?: string;

	uniqueNo1?: string;
	uniqueNo2?: string;
	uniqueNo3?: string;
	uniqueNo4?: string;
	uniqueNo5?: string;
	uniqueNo6?: string;
	uniqueNo7?: string;
	uniqueNo8?: string;
	uniqueNo9?: string;
	uniqueNo10?: string;

	allChecked?: boolean;
	traceDataList?: Array<TraceData>;
	 
}

export interface TraceDataPage {
	pageSize: number;
	pageNumber: number;
	totalPages: number;
	totalItemCount: number;
	errMsg?: string;
	rows?: Array<TraceData & { seq: number, checked?: boolean, createdBy?: string }>
}

export interface OperationLogPage {
	pageSize: number;
	pageNumber: number;
	totalPages: number;
	totalItemCount: number;
	rows?: Array<OperationLogData & { seq: number, checked?: number, createdBy?: string }>
}

export interface OperationLogData{
	traceNo: string;
	createAt: string;
	operationType: string;
	tenantId: number;
	createUserName: string;
	quoteNo?: string;
	orderNo?: string;
	policyNo?: string;
	payNo?: string;
	payOrderNo?: string;
}
export interface TraceData {
	traceId: string;
	traceNo: string;
	createTime: string;
	productName?: string;
	policyNo?: string;
	orderNo?: string;
	quoteNo?: string;
	payNo?: string;
	payOrderNo?: string;
	source?: string;
	policyHolderName?: string;
	policyHolderIdNo?: string;
	policyHolderMobile?: string;
	createdByAccountName?: string;
	maskRules?: string

	// uniqueNo1?: string;
	// uniqueNo2?: string;
	// uniqueNo3?: string;
	// uniqueNo4?: string;
	// uniqueNo5?: string;
	// uniqueNo6?: string;
	// uniqueNo7?: string;
	// uniqueNo8?: string;
	// uniqueNo9?: string;
	// uniqueNo10?: string;
	downloadUrl?: string;

	allSteps?: Array<string>;
	steps?: Array<string>;
	files?: Array<string>;
}

export interface RecordTraceData {
	traceNo: string;
	productName?: string;
	policyNo?: string;
	orderNo?: string;
	policyHolderName?: string;
	policyHolderIdNo?: string;
	policyHolderMobile?: string;
	allSteps?: Array<string>;
	currentStep?: string;
	fileHash?: string;
	fileType?: string;
	fileLocation?: string;
	fileSize?: number;
	ip?: string;
	userAgent: string;
	quoteNo?: string;
	payNo?: string;
	payOrderNo?: string;
	uniqueNo1?: string;
	uniqueNo2?: string;
	uniqueNo3?: string;
	uniqueNo4?: string;
	uniqueNo5?: string;
	uniqueNo6?: string;
	uniqueNo7?: string;
	uniqueNo8?: string;
	uniqueNo9?: string;
	uniqueNo10?: string;
}

export interface DynamicCriteria {
	// 动态字段的序列号,1-10
	columnSeq: number;
	displayName: string
}

export interface WebSource {
	source: string,
	version?: string,
	should: boolean
};

export const findTraceDataPage = async (criteria: TraceCriteria): Promise<TraceDataPage> => {
	const { pageNumber = 1, pageSize = 10, ...rest } = criteria;
	const response = await doPost(`/api/trace/list?pageNumber=${pageNumber}&pageSize=${pageSize}`, rest);
	
	const responseData = response.body || {
		pageNumber: 1,
		pageSize: 10,
		totalPages: 0,
		totalItemCount: 0,
		data: []
	};
	const { data, ...pages } = responseData;
	if(pages.errMsg){
		alert(pages.errMsg);
	}
	return {
		...pages,
		rows: data.map((row: any, index: number) => {
			row.seq = index + 1;
			row.checked=false;
			return row;
		})
	};
};

export const findTraceDownloadDataPage = async (criteria: TraceCriteria): Promise<TraceDataPage> => {
	const { pageNumber = 1, pageSize = 10, ...rest } = criteria;
	const response = await doPost(`/api/trace/listVideo?pageNumber=${pageNumber}&pageSize=${pageSize}`, rest);

	const responseData = response.body || {
		pageNumber: 1,
		pageSize: 10,
		totalPages: 0,
		totalItemCount: 0,
		data: []
	};
	const { data, ...pages } = responseData;
	return {
		...pages,
		rows: data.map((row: any, index: number) => {
			row.seq = index + 1;
			row.checked=false;
			return row;
		})
	};
};

export const findOperationRecordDataPage = async (criteria: TraceCriteria): Promise<OperationLogPage> => {
	const { pageNumber = 1, pageSize = 10, ...rest } = criteria;
	const response = await doPost(`/api/trace/listOperationLog?pageNumber=${pageNumber}&pageSize=${pageSize}`, rest);

	const responseData = response.body || {
		pageNumber: 1,
		pageSize: 10,
		totalPages: 0,
		totalItemCount: 0,
		data: []
	};
	const { data, ...pages } = responseData;
	return {
		...pages,
		rows: data.map((row: any, index: number) => {
			row.seq = index + 1;
			row.checked=false;
			return row;
		})
	};
};

export const exportOperationRecordData = async (criteria: TraceCriteria) => {
	const { pageNumber = 1, pageSize = 10, ...rest } = criteria;
	doExport(`/api/trace/export/operation`,rest);
}

export const findTraceData = async (traceId: string,createAt: string): Promise<TraceData | null> => {
	const response = await doGet(`/api/trace/find/${traceId}/${createAt}`);
	return response.body;
};

export const findCollectedSources = async (sources: Array<WebSource>): Promise<Array<WebSource>> => {
	const response = await doPost('/api/trace/source/collected', sources);
	return response.body;
};

export const archiveTraceData = async (traceId: string): Promise<void> => {
	await doGet(`/api/trace/archive/${traceId}`);
};

export const createTraceData = async (data: RecordTraceData): Promise<void> => {
	await doPost('/api/trace/create', data);
};

export const findTenantDynamicQueryCriteria = async (): Promise<Array<DynamicCriteria>> => {
	const response = await doGet('/api/trace/customization/criteria');
	return response.body;
};

export const findFullTraceData = async (traceId: string,createAt: string): Promise<TraceDataPage | null> => {
	const response = await doGet(`/api/ccic/full/trace/find/relations/${traceId}/${createAt}`);

	const responseData = response.body || {
		pageNumber: 1,
		pageSize: 10,
		totalPages: 0,
		totalItemCount: 0,
		data: []
	};
	const { data, ...pages } = responseData;
	return {
		...pages,
		rows: (data||[]).map((row: any, index: number) => {
			//row.seq = index + 1;
			return row;
		})
	};
};