import React, { useReducer, useState, useEffect } from 'react';
import { doExport,doPost } from '../service/ajax';
import styled from 'styled-components';
import {
	Button,
	ButtonBar,
	ButtonBarAlignType,
	ButtonType, DateTimeRange, Dropdown,
	FormSectionGapVertical,
	PanelSeparator,
	QueryPanel,
	QueryResultColumns,
	QueryResultPanel,
	QueryResultRow,
	Text,
	WorkAreaTitle
} from '../component';
import {AccountList} from "../service/record-service";
import {getCurrentUserTenantList} from "../service/account-service";
import {DropdownOption} from "../component/dropdown";
import {TraceCriteria } from "../service/trace-service";
import {endOfDate, formatFull, startOfDate} from "../service/date";
import {isCCICDisplay} from "../utils";
const MaskQuery = styled.div`
	display: flex;
	flex-direction: column;
`;

const columns: QueryResultColumns = [
	{ label: '日期', width: '180px', name: 'reportDate'},
	{ label: '系统', width: '180px', name: 'tenantName'},
	{ label: '来源', width: '300px', name: 'source'},
	{ label: '产品计划名称', width: '300px', name: 'productName'},
	{ label: '可回溯记录数', width: '100px', name: 'count'}
];

export interface TraceReportData {
	reportDate?:string;
	tenantId?:string,
	source?:string;
	productName?: string;
	count?:string;
	pageSize?: number;
	pageNumber?: number;
}

export interface TraceReportDataPage {
	pageSize: number;
	pageNumber: number;
	totalPages: number;
	totalItemCount: number;
	rows?: Array<TraceReportData & { seq: number, checked?: number, createdBy?: string }>
}

export default () => {
	const now = new Date();
	const [ , forceUpdate ] = useReducer(x => x + 1, 0);
	const [ data, setData ] = useState<TraceReportDataPage | null>(null);
	const [ searched, setSearched ] = useState(false);
	const [tenantList,setTenantList] = useState<Array<DropdownOption> | []>([]);
	const [ criteria ] = useState<TraceCriteria>({
		createFrom: formatFull(startOfDate(now)),
		createTo: formatFull(endOfDate(now))
	});
	useEffect(() => {
		(async () => {
			const tenantlist : AccountList =await getCurrentUserTenantList();
			const accounts = tenantlist?.accounts || [];
			const tenant = new Array<DropdownOption>();
			accounts.forEach((account)=>{
				tenant.push({value:account.tenantId,label:account.accountName});
			});
			setTenantList(tenant);
			criteria.tenantId = accounts[0]?.tenantId.toString();
		})();
	}, []);

	const onChanged = (propName:string) => (event: React.ChangeEvent) => {
		// @ts-ignore
		criteria[propName] = (event.target as HTMLInputElement).value;
		forceUpdate();
	};

	const onSearchClicked = async (pageNum: number) => {
        if(criteria.createFrom! > criteria.createTo!){
            alert('开始日期不得大于结束日期，请重新选择');
            return;
        }
        const from = criteria.createFrom!.split("-"),
            to = criteria.createTo!.split("-"),
            year1 = parseInt(from[0]),
            month1 = parseInt(from[1]),
            year2 = parseInt(to[0]),
            month2 = parseInt(to[1]),
            //通过年,月差计算月份差
            months = (year2 - year1) * 12 + (month2 - month1) + 1;
        if(months > 3){
            alert('不支持超过三个月范围的查询，请重新选择日期');
            return;
        }
		const { pageNumber = pageNum, pageSize = 10, ...rest } = criteria;
		const response = await doPost(`/api/trace/dailyReportQuery?pageNumber=${pageNumber}&pageSize=${pageSize}`, rest);
		const responseData = response.body || {
			pageNumber: 1,
			pageSize: 10,
			totalPages: 0,
			totalItemCount: 0,
			data: []
		};
		const { data, ...pages } = responseData;
		const result = {
			...pages,
			rows: data.map((row: any, index: number) => {
				row.seq = index + 1;
				return row;
			})
		};
		setData(result);
		setSearched(true);
	};

	const dataFound = data || { pageNumber: 1, pageSize: 10, totalPages: 1, totalItemCount: 0, rows: [] };

	const rows = (dataFound.rows || []).map((row): QueryResultRow => {
		const data = row as QueryResultRow;
        data.tenantName = `${tenantList.filter(tenant => tenant.value == `${row.tenantId}`)[0].label}`;
		return data;
	});

	const onDateChanged = (startDate?: Date, endDate?: Date) => {
		criteria.createFrom = formatFull(startOfDate(startDate));
		criteria.createTo = formatFull(endOfDate(endDate));
		forceUpdate();
	};

	const onPageChanged = async (pageNumber: number) => {
		await onSearchClicked(pageNumber);
	};
    const onExportClicked = async () => {
        await doExport('/api/trace/dailyReportExport',criteria);
    };
	return <MaskQuery>
		<WorkAreaTitle title="回溯信日统计报表"/>
		<QueryPanel>
			<DateTimeRange label="时间区间" columns={2} startDate={criteria.createFrom!} endDate={criteria.createTo!}
						   onChange={onDateChanged}/>
			<Dropdown label="所属系统" options={tenantList} value = {criteria.tenantId} onChange ={onChanged("tenantId")}/>
			<Text label="来源" placeholder="请输入" value={criteria.source} onChange={onChanged('source')}/>
			<Text label="产品计划" placeholder="请输入" value={criteria.productName} onChange={onChanged('productName')}/>

			<PanelSeparator columns={3}/>
			<ButtonBar align={ButtonBarAlignType.CENTER}>
				<Button label="查询" onClick={() => onSearchClicked(1)}/>
				<Button label="导出" type={ButtonType.WAIVE} onClick={onExportClicked}/>
			</ButtonBar>
			<PanelSeparator columns={3}/>
		</QueryPanel>
		<FormSectionGapVertical/>
		<QueryResultPanel columns={columns} rows={rows} pagination={true} searched={searched}
						  pageCount={dataFound.totalPages} pageNumber={dataFound.pageNumber}
						  totalItemCount={dataFound.totalItemCount}
						  pageChange={onPageChanged}/>
	</MaskQuery>;
}