import React, { ReactNode, useState } from 'react';
import '../catcher/rrweb/rrweb-saas-polyfill';
import { getAccount } from '../service/storage';

export interface ScreenRecorderContext {
	startRecord: () => void;
	stopRecord: (options: {
		productName: string;
		policyHolderName?: string;
		policyHolderIdNo?: string;
		policyHolderMobile?: string;
		source?: string;
		version?: string;
	}) => void;
	recording: boolean;
}

const Context = React.createContext<ScreenRecorderContext>({
	startRecord: () => {
	},
	stopRecord: () => {
	},
	recording: false
});

let isRecordingStopped: boolean = true;

export const ScreenRecorderProvider = (props: { children?: ReactNode }) => {
	const { children } = props;

	// @ts-ignore
	const [ recorder, setRecorder ] = useState<GluttonRecorder | null>(null);

	const onStartRecordClicked = async () => {
		// @ts-ignore
		const recorder = new GluttonRecorder({autoPlay: false, firstToken: getAccount().token, disableLogs: false });
		console.log(recorder);
		recorder.startRecord();
		isRecordingStopped = false;
		setRecorder(recorder);
	};
	const onStopRecordClicked = async (options: {
		productName: string;
		policyHolderName?: string;
		policyHolderIdNo?: string;
		policyHolderMobile?: string;
		source?: string;
		version?: string;
	}) => {
		const { productName, policyHolderName, policyHolderIdNo, policyHolderMobile, source, version } = options;
		if (isRecordingStopped && !recorder) {
			return;
		}
		// @ts-ignore
		window.GluttonContext = {
			productName,
			policyHolderName,
			policyHolderIdNo,
			policyHolderMobile,
			source,
			version
		};
		isRecordingStopped = true;
		recorder.stopRecord();
		setRecorder(null);
	};


	return <Context.Provider value={{
		startRecord: onStartRecordClicked,
		stopRecord: onStopRecordClicked,
		recording: !isRecordingStopped
	}}>
		{children}
		{/*<Canvas ref={canvasRef}/>*/}
	</Context.Provider>;
};

export const useScreenRecorder = () => {
	return React.useContext(Context);
};
