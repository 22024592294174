import { IncrementalEventType } from './incremental-event-data-type';

/** 鼠标交互类型 */
export enum MouseInteraction {
	MouseUp,
	MouseDown,
	Click,
	ContextMenu,
	DblClick,
	Focus,
	Blur,
	TouchStart,
	// see MouseMoveData, leave this enumeration here, to keep to ordinary value
	TouchMove_Departed, // we will start a separate observer for touch move event
	TouchEnd,
}

/** 鼠标交互位置 */
export type MouseInteractionData = {
	type: MouseInteraction;
	id: number;
	x: number;
	y: number;
};
/** 鼠标交互专区数据 */
export type MouseInteractionEventData = {
	source: IncrementalEventType.MouseInteraction;
} & MouseInteractionData;
