import React from 'react';
import styled from 'styled-components';

const Panel = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: var(--form-column-gap);
	grid-row-gap: var(--form-row-gap);
`;

export default (props: { children: React.ReactNode }) => {
	const { children } = props;

	return <Panel>
		{children}
	</Panel>;
}