import { NodeType, SerializedNodeWithId } from '../../types';
import { CommonDataSet } from '../common/types';
import { buildDefaultAttributes, buildFontStyle } from './utils';

export type StandTextareaDataSet = {
	text?: string;
	value?: string;
	placeholder?: string;
	placeholderStyle?: string;
	placeholderClass?: string;
} & CommonDataSet;
export const buildStandardTextArea = (dataset: StandTextareaDataSet, nodeIdIncrementer: () => number): Array<SerializedNodeWithId> => {
	const text = dataset.text || dataset.value;
	let hasValue = text ? true : false;

	const fontStyle = buildFontStyle(dataset);
	const givenStyle = hasValue ? (dataset.style || '') : (dataset.style || '' + dataset.placeholderStyle || '');
	return [ {
		type: NodeType.Element,
		id: nodeIdIncrementer(),
		tagName: 'div',
		attributes: {
			...buildDefaultAttributes('textarea', dataset, `${fontStyle}${givenStyle}`),
			class: hasValue ? dataset.class || '' : dataset.placeholderClass,
			'data-text': hasValue ? 'true' : 'false'
		},
		// 如果选中, 需要一个图片渲染. 如果不选中就不需要, 那么推入一个空数组
		childNodes: [ {
			type: NodeType.Text,
			id: nodeIdIncrementer(),
			textContent: text ? text : (dataset.placeholder || '')
		} ]
	} as SerializedNodeWithId ];
};

export const TextAreaStyles = `
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=textarea] {
	width: 100%;
	height: 100%;
	cursor: auto;
	display: block;
	position: relative;
	outline: none;
	border: none;
	resize: none;
	background-color: transparent;
	line-height: 1.2;
	z-index: 2;
	padding: 0;
	font-family: inherit;
	background: transparent;
	word-wrap: break-word;
	word-break: break-all;
}
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=textarea][data-text=false] {
	color: gray;
}
`;