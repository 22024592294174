import { NodeType, SerializedNodeWithId } from '../../types';
import { CommonDataSet } from '../common/types';
import { buildDefaultAttributes, buildFontStyle } from './utils';

export type StandardViewDataSet = {
	text?: string;
} & CommonDataSet;
export const buildStandardView = (dataset: StandardViewDataSet, nodeIdIncrementer: () => number): Array<SerializedNodeWithId> => {
	return [ {
		type: NodeType.Element,
		id: nodeIdIncrementer(),
		tagName: 'div',
		attributes: {
			...buildDefaultAttributes('view', dataset, buildFontStyle(dataset))
		},
		childNodes: dataset.text
			? [ {
				type: NodeType.Text,
				id: nodeIdIncrementer(),
				textContent: dataset.text
			} ]
			: []
	} as SerializedNodeWithId ];
};

export const ViewStyles = `
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=view] {
	display: block;
	height: 100%;
}
`;