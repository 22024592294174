import React, {useEffect,useState,Fragment} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import Content from './content';
import Footer from './footer';
import Header from './header';
import { ResponsiveProvider } from './hooks/responsive';
import { ScreenRecorderProvider } from './hooks/screen-recorder';
import { SecurityProvider } from './hooks/security';
import StandardTheme from './theme/standard';
import Styles from './theme/styles';
import { SharesProvider } from './hooks/shares';
import RRWebPlayer from './trace/rrweb-player';
import {WebEventWithTime} from "./catcher/rrweb/types";
import { initData } from './trace/trace-view-data';
import { initDataForFastdfs } from './trace/trace-view-data-fastdfs';
import {Details} from "./trace/trace-view-types";
import {TraceData} from "./service/trace-service";
import {OssStsToken} from "./service/oss-service";
const themes = {
	standard: StandardTheme
};
const reCalcTimeStamp = (events: Array<WebEventWithTime>, previousDetailLastEvent: WebEventWithTime) => {
	if (events && events.length>0 && previousDetailLastEvent) {
		let timestampChanged = 0;
		const timestampDiff = (events[0].timestamp || 0) - (previousDetailLastEvent.timestamp || 0);
		if (timestampDiff > 5*1000) {
			timestampChanged = 5*1000 - timestampDiff;
		} else if (timestampDiff < 0) {
			timestampChanged = 0-timestampDiff + 2*1000;
		}

		if (timestampChanged !== 0) {
			for(let i = 0;i < events.length; i++){
				events[i].timestamp += timestampChanged;
			}
		}
	}
};
const Player = () => {
	const [events, setEvents] = useState<Array<WebEventWithTime> | undefined>([]);
	const [ details, setDetails ] = useState<Details>({loaded: false});
	useEffect(() => {
		(async () => {
			const receiveData = await (window as any).getNeedData();
			const traceList : Array<TraceData> = receiveData.traceList;
			traceList.sort((a,b)=>{
				return a.createTime<b.createTime ? -1:1;
			});
			let maskRules : string = '';
			const ossToken : OssStsToken = receiveData.ossToken;
			const detailList : Array<Details> = [];
			for(let i = 0;i<traceList.length;i++){
				const trace = traceList[i];
				if(trace.maskRules && trace.maskRules != ''){
					maskRules = maskRules.concat(trace.maskRules+',');
				}
				const detail : Details | null = ossToken
					? await initData(trace,{accountName:trace.createdByAccountName,isAuthorized:true},ossToken)
					: await initDataForFastdfs(trace,{accountName:trace.createdByAccountName,isAuthorized:true},ossToken);
				if (detail.events && detail.events.length >0) {
					detailList.push(detail);
					detailList.sort((a,b)=>{
						return (a.events||[])[0].timestamp - (b.events||[])[0].timestamp;
					}).forEach(detailData => {
						if (detailList.length > 1 && details.events) {
							reCalcTimeStamp(detailData.events || [], details.events[details.events.length-1]);
						}
						details.events = (details.events || []).concat(detailData.events || []);
					});
				}
			}
			sessionStorage.setItem('maskRules',maskRules.length > 1 ? maskRules.substring(0,maskRules.length-1) : '');
			setEvents(details.events);
		})()
	}, []);

	if (events && events.length === 0) {
		return <Fragment/>
	} else {
		return <RRWebPlayer autoPlay={true} events={events}/>
	}
};

export default () => {
	const theme = themes.standard;
	if(window.location.href.endsWith('/headlessPlayer')){
		return <ThemeProvider theme={theme}>
			<Styles theme={theme} />
			<Router>
				<Switch>
					<Route path='/headlessPlayer'>
						<Player/>
					</Route>
				</Switch>
			</Router>
		</ThemeProvider>
	}

	return <ThemeProvider theme={theme}>
		<Styles theme={theme} />
		<React.StrictMode>
			<ResponsiveProvider>
				<ScreenRecorderProvider>
					<SecurityProvider>
						<SharesProvider>
							<Router>
								<Header />
								<Content />
								<Footer />
							</Router>
						</SharesProvider>
					</SecurityProvider>
				</ScreenRecorderProvider>
			</ResponsiveProvider>
		</React.StrictMode>
	</ThemeProvider>;
}