import React, { ReactNode, useState } from 'react';
import { EventEmitter } from 'events';

export interface TraceViewContext {
	emitter: EventEmitter;
}

const Context = React.createContext<TraceViewContext>({
	emitter: new EventEmitter()
});

export const TraceViewContextProvider = (props: { children?: ReactNode }) => {
	const { children } = props;

	const [ emitter ] = useState(new EventEmitter());

	return <Context.Provider value={{ emitter }}>{children}</Context.Provider>;
};

export const useTimeOffset = () => {
	return React.useContext(Context);
};

export const TIME_OFFSET_CHANGE = 'time-offset-change';
