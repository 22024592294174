import React from 'react';
import { Theme } from './standard';
import { createGlobalStyle } from 'styled-components';

const createCSSVariables = (theme: Theme) => {
	const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
	return Object.keys(theme).map(key => {
		let newKey = '--';
		newKey += key.split('').map(ch => {
			if (uppercase.includes(ch)) {
				return '-' + ch.toLowerCase();
			} else {
				return ch;
			}
		}).join('');

		const value = (theme as any)[key];
		if (typeof value == 'number') {
			if (!key.toLowerCase().includes('opacity')
				&& !key.includes("ZIndex")) {
				return newKey + ': ' + value + 'px;';
			}
		}
		return newKey + ': ' + value + ';';
	}).join('');
};

export default (props: { theme: Theme }) => {
	const { theme } = props;

	const GlobalStyle = createGlobalStyle`
		html {
			${createCSSVariables(theme)};
				
			box-sizing: border-box;

			font-size: var(--font-size);
			font-family: var(--font-family);
			font-weight: 400;
			font-style: normal;
			color: var(--font-color);
			background-color: var(--bg-color);

			line-height: 1.15;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			-webkit-text-size-adjust: 100%;
		}
		body {
			overflow-x: hidden;
			overflow-y: scroll;
		    position: relative;
		    margin: 0;
		}
		*, :after, :before {
		    box-sizing: inherit;
		}
		* {
		    margin: 0;
		    padding: 0;
		}
	`;

	return <GlobalStyle/>;
}