import React, { useReducer, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
	Button,
	ButtonBar,
	ButtonBarAlignType,
	ButtonType, Dropdown,
	FormSectionGapVertical,
	PanelSeparator,
	QueryPanel,
	QueryResultColumns,
	QueryResultPanel,
	QueryResultRow,
	Text,
	WorkAreaTitle
} from '../component';
import Path from '../path';
import {AccountData, AccountDataPage, findAccountData, getCurrentUserTenantList} from '../service/account-service';
import {DropdownOption} from "../component/dropdown";
import {AccountList} from "../service/record-service";
import {doPost} from "../service/ajax";
const AccountQuery = styled.div`
	display: flex;
	flex-direction: column;
`;

const ButtonWrapper = styled.div`
	text-align: center;
	> *:last-child {
		display: inline-block;
		margin-left: var(--gap-thin);
	}
`;

const columns: QueryResultColumns = [
	{ label: '账户名称', width: '180px', name: 'accountName'},
    { label: '角色', width: '180px', name: 'currentRole'},
	{ label: '所属系统', width: '180px', name: 'tenantName'},
	{ label: '创建时间', width: '180px', name: 'createAt'},
	{ label: '操作', width: '100px', name: 'actions'}
];

type InputCriteria = Exclude<keyof AccountData, 'pageSize' | 'pageNumber'>

export default () => {
	const history = useHistory();
	const [ , forceUpdate ] = useReducer(x => x + 1, 0);
	const [ data, setData ] = useState<AccountDataPage | null>(null);
	const [ searched, setSearched ] = useState(false);
	const [tenantList,setTenantList] = useState<Array<DropdownOption> | []>([]);
	const [ criteria,setCriteria ] = useState<AccountData>( {});

	useEffect(() => {
		(async () => {
			const tenantlist : AccountList =await getCurrentUserTenantList();
			const accounts = tenantlist?.accounts || [];
			const tenant = new Array<DropdownOption>({value:'null',label:'All'});
			accounts.forEach((account)=>{
				tenant.push({value:account.tenantId,label:account.accountName});
			});
			setTenantList(tenant);
		})();
	}, []);

	const [ currentCriteria, setCurrentCriteria ] = useState<AccountData | null>(null);

	const onChanged = (propName: InputCriteria) => (event: React.ChangeEvent) => {
		// @ts-ignore
		criteria[propName] = (event.target as HTMLInputElement).value;
		forceUpdate();
	};

	const doSearch = async (account: AccountData) => {
		// 设置当前查询
		setCurrentCriteria(account);
		const data = await findAccountData(account);
		setData(data);
		setSearched(true);
	};
	const onSearchClicked = async (account: AccountData) => {
		await doSearch(account);
	};

	const onAddClicked = () => {
		history.push(Path.ACCOUNT_EDIT);
	};

	const dataFound = data || { pageNumber: 1, pageSize: 10, totalPages: 1, totalItemCount: 0, rows: [] };

	const rows = (dataFound.rows || []).map((row): QueryResultRow => {
		const data = row as QueryResultRow;
		data.tenantName = `${tenantList.filter(tenant => tenant.value == `${row.tenantId}`)[0].label}`;
		data.currentRole = `${data.role.toString().endsWith('ADMIN') ? '系统管理员' : '普通用户'}`;
		data.actions = <ButtonWrapper>
			<Button label="删除" type={ButtonType.LINK}
					onClick={async () => {
						const result = await doPost('/api/account/delete',{'accountName':row.accountName,});
						if(result.body == 'OK'){
							alert('删除成功');
							onSearchClicked(criteria);
						}else{
							alert(result.body);
						}
					}}/>
			<Button label="修改" type={ButtonType.LINK}
					onClick={() => history.push(Path.ACCOUNT_MODIFY
						.replace(':accountName', `${row.accountName}`)
                        .replace(':role', `${row.role}`)
                        .replace(':id', `${row.accountId}`)
						.replace(':tenantId', `${row.tenantId}`)
                    )
					}/>
		</ButtonWrapper>;
		return data;
	});

	return <AccountQuery>
		<WorkAreaTitle title="账户配置信息"/>
		<QueryPanel>
			<Text label="账户名称" placeholder="请输入" value={criteria.accountName} onChange={onChanged('accountName')}/>
			<Dropdown label="所属系统" options={tenantList} value = {criteria.tenantId?.toString()} onChange ={onChanged("tenantId")}/>
			<PanelSeparator columns={3}/>
			<ButtonBar align={ButtonBarAlignType.CENTER}>
				<Button label="查询" onClick={() => onSearchClicked(criteria)}/>
				<Button label="添加" type={ButtonType.WAIVE} onClick={onAddClicked}/>
			</ButtonBar>
			<PanelSeparator columns={3}/>
		</QueryPanel>
		<FormSectionGapVertical/>
		<QueryResultPanel columns={columns} rows={rows} pagination={false} searched={searched}/>
	</AccountQuery>;
}