import { NodeType, SerializedNodeWithId } from '../../types';
import { CommonDataSet } from '../common/types';
import { buildDefaultAttributes, buildFontStyle } from './utils';

export type StandLabelDataSet = {
	text: string;
} & CommonDataSet;
export const buildStandardLabel = (dataset: StandLabelDataSet, nodeIdIncrementer: () => number): Array<SerializedNodeWithId> => {
	return [ {
		type: NodeType.Element,
		id: nodeIdIncrementer(),
		tagName: 'span',
		attributes: {
			...buildDefaultAttributes('label', dataset, buildFontStyle(dataset))
		},
		childNodes: dataset.text
			? [ {
				type: NodeType.Text,
				id: nodeIdIncrementer(),
				textContent: dataset.text
			} ]
			: []
	} as SerializedNodeWithId ];
};

export const LabelStyles = `
html[data-css=glutton-wmp] span:not([data-rdcs])[data-css=label] {
	display: block;
	margin-top: 5px;
}
`;