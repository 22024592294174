/* eslint-disable */
const stringToBytes = (str: string): Array<number> => {
	let bytes = [];
	for (let i = 0; i < str.length; i++)
		bytes.push(str.charCodeAt(i) & 0xFF);
	return bytes;
};

const wordsToBytes = (words: Array<number>): Array<number> => {
	const bytes = [];
	for (let b = 0; b < words.length * 32; b += 8)
		bytes.push((words[b >>> 5] >>> (24 - b % 32)) & 0xFF);
	return bytes;
};

const bytesToWords = (bytes: Array<number>): Array<number> => {
	const words: Array<number> = [];
	for (let i = 0, b = 0; i < bytes.length; i++, b += 8)
		words[b >>> 5] |= bytes[i] << (24 - b % 32);
	return words;
};

const rotl = (n: number, b: number): number => {
	return (n << b) | (n >>> (32 - b));
};
const arrayEndian = (n: Array<number>): Array<number> => {
	return n.map(x => endian(x));
};
const endian = (n: number): number => {
	return rotl(n, 8) & 0x00FF00FF | rotl(n, 24) & 0xFF00FF00;
};

// Auxiliary functions
const _ff = (a: number, b: number, c: number, d: number, x: number, s: number, t: number): number => {
	var n = a + (b & c | ~b & d) + (x >>> 0) + t;
	return ((n << s) | (n >>> (32 - s))) + b;
};
const _gg = (a: number, b: number, c: number, d: number, x: number, s: number, t: number): number => {
	var n = a + (b & d | c & ~d) + (x >>> 0) + t;
	return ((n << s) | (n >>> (32 - s))) + b;
};
const _hh = (a: number, b: number, c: number, d: number, x: number, s: number, t: number): number => {
	var n = a + (b ^ c ^ d) + (x >>> 0) + t;
	return ((n << s) | (n >>> (32 - s))) + b;
};
const _ii = (a: number, b: number, c: number, d: number, x: number, s: number, t: number): number => {
	var n = a + (c ^ (b | ~d)) + (x >>> 0) + t;
	return ((n << s) | (n >>> (32 - s))) + b;
};

const md5 = (message: Buffer | string) => {
	let messageBytes;
	if (typeof message === 'string') {
		messageBytes = stringToBytes(unescape(encodeURIComponent(message)));
	} else {
		// Convert to byte array
		messageBytes = Array.prototype.slice.call(message, 0);
	}
	// else, assume byte array already

	var m = bytesToWords(messageBytes),
		l = messageBytes.length * 8,
		a = 1732584193,
		b = -271733879,
		c = -1732584194,
		d = 271733878;

	// Swap endian
	for (let i = 0; i < m.length; i++) {
		m[i] = ((m[i] << 8) | (m[i] >>> 24)) & 0x00FF00FF | ((m[i] << 24) | (m[i] >>> 8)) & 0xFF00FF00;
	}

	// Padding
	m[l >>> 5] |= 0x80 << (l % 32);
	m[(((l + 64) >>> 9) << 4) + 14] = l;

	// Method shortcuts
	const FF = _ff, GG = _gg, HH = _hh, II = _ii;

	for (let i = 0; i < m.length; i += 16) {
		const aa = a, bb = b, cc = c, dd = d;

		a = FF(a, b, c, d, m[i + 0], 7, -680876936);
		d = FF(d, a, b, c, m[i + 1], 12, -389564586);
		c = FF(c, d, a, b, m[i + 2], 17, 606105819);
		b = FF(b, c, d, a, m[i + 3], 22, -1044525330);
		a = FF(a, b, c, d, m[i + 4], 7, -176418897);
		d = FF(d, a, b, c, m[i + 5], 12, 1200080426);
		c = FF(c, d, a, b, m[i + 6], 17, -1473231341);
		b = FF(b, c, d, a, m[i + 7], 22, -45705983);
		a = FF(a, b, c, d, m[i + 8], 7, 1770035416);
		d = FF(d, a, b, c, m[i + 9], 12, -1958414417);
		c = FF(c, d, a, b, m[i + 10], 17, -42063);
		b = FF(b, c, d, a, m[i + 11], 22, -1990404162);
		a = FF(a, b, c, d, m[i + 12], 7, 1804603682);
		d = FF(d, a, b, c, m[i + 13], 12, -40341101);
		c = FF(c, d, a, b, m[i + 14], 17, -1502002290);
		b = FF(b, c, d, a, m[i + 15], 22, 1236535329);

		a = GG(a, b, c, d, m[i + 1], 5, -165796510);
		d = GG(d, a, b, c, m[i + 6], 9, -1069501632);
		c = GG(c, d, a, b, m[i + 11], 14, 643717713);
		b = GG(b, c, d, a, m[i + 0], 20, -373897302);
		a = GG(a, b, c, d, m[i + 5], 5, -701558691);
		d = GG(d, a, b, c, m[i + 10], 9, 38016083);
		c = GG(c, d, a, b, m[i + 15], 14, -660478335);
		b = GG(b, c, d, a, m[i + 4], 20, -405537848);
		a = GG(a, b, c, d, m[i + 9], 5, 568446438);
		d = GG(d, a, b, c, m[i + 14], 9, -1019803690);
		c = GG(c, d, a, b, m[i + 3], 14, -187363961);
		b = GG(b, c, d, a, m[i + 8], 20, 1163531501);
		a = GG(a, b, c, d, m[i + 13], 5, -1444681467);
		d = GG(d, a, b, c, m[i + 2], 9, -51403784);
		c = GG(c, d, a, b, m[i + 7], 14, 1735328473);
		b = GG(b, c, d, a, m[i + 12], 20, -1926607734);

		a = HH(a, b, c, d, m[i + 5], 4, -378558);
		d = HH(d, a, b, c, m[i + 8], 11, -2022574463);
		c = HH(c, d, a, b, m[i + 11], 16, 1839030562);
		b = HH(b, c, d, a, m[i + 14], 23, -35309556);
		a = HH(a, b, c, d, m[i + 1], 4, -1530992060);
		d = HH(d, a, b, c, m[i + 4], 11, 1272893353);
		c = HH(c, d, a, b, m[i + 7], 16, -155497632);
		b = HH(b, c, d, a, m[i + 10], 23, -1094730640);
		a = HH(a, b, c, d, m[i + 13], 4, 681279174);
		d = HH(d, a, b, c, m[i + 0], 11, -358537222);
		c = HH(c, d, a, b, m[i + 3], 16, -722521979);
		b = HH(b, c, d, a, m[i + 6], 23, 76029189);
		a = HH(a, b, c, d, m[i + 9], 4, -640364487);
		d = HH(d, a, b, c, m[i + 12], 11, -421815835);
		c = HH(c, d, a, b, m[i + 15], 16, 530742520);
		b = HH(b, c, d, a, m[i + 2], 23, -995338651);

		a = II(a, b, c, d, m[i + 0], 6, -198630844);
		d = II(d, a, b, c, m[i + 7], 10, 1126891415);
		c = II(c, d, a, b, m[i + 14], 15, -1416354905);
		b = II(b, c, d, a, m[i + 5], 21, -57434055);
		a = II(a, b, c, d, m[i + 12], 6, 1700485571);
		d = II(d, a, b, c, m[i + 3], 10, -1894986606);
		c = II(c, d, a, b, m[i + 10], 15, -1051523);
		b = II(b, c, d, a, m[i + 1], 21, -2054922799);
		a = II(a, b, c, d, m[i + 8], 6, 1873313359);
		d = II(d, a, b, c, m[i + 15], 10, -30611744);
		c = II(c, d, a, b, m[i + 6], 15, -1560198380);
		b = II(b, c, d, a, m[i + 13], 21, 1309151649);
		a = II(a, b, c, d, m[i + 4], 6, -145523070);
		d = II(d, a, b, c, m[i + 11], 10, -1120210379);
		c = II(c, d, a, b, m[i + 2], 15, 718787259);
		b = II(b, c, d, a, m[i + 9], 21, -343485551);

		a = (a + aa) >>> 0;
		b = (b + bb) >>> 0;
		c = (c + cc) >>> 0;
		d = (d + dd) >>> 0;
	}

	return arrayEndian([ a, b, c, d ]);
};

export default (message: Buffer | string) => {
	if (message === undefined || message === null) {
		throw new Error('Illegal argument, message cannot be null or undefined.');
	}

	return wordsToBytes(md5(message));
};

const lookup: Array<string> = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'.split('');

const tripletToBase64 = (num: number): string => {
	return lookup[num >> 18 & 0x3F] +
		lookup[num >> 12 & 0x3F] +
		lookup[num >> 6 & 0x3F] +
		lookup[num & 0x3F];
};
const encodeChunk = (uint8: Array<number>, start: number, end: number): string => {
	let tmp;
	const output = [];
	for (let i = start; i < end; i += 3) {
		tmp = ((uint8[i] << 16) & 0xFF0000) + ((uint8[i + 1] << 8) & 0xFF00) + (uint8[i + 2] & 0xFF);
		output.push(tripletToBase64(tmp));
	}
	return output.join('');
};

export const fromByteArray = (uint8: Array<number>): string => {
	let tmp;
	const len = uint8.length;
	const extraBytes = len % 3; // if we have 1 byte left, pad 2 bytes
	const parts = [];
	const maxChunkLength = 16383; // must be multiple of 3

	// go through the array every three bytes, we'll deal with trailing stuff later
	for (let i = 0, len2 = len - extraBytes; i < len2; i += maxChunkLength) {
		parts.push(encodeChunk(
			uint8, i, (i + maxChunkLength) > len2 ? len2 : (i + maxChunkLength)
		));
	}

	// pad the end with zeros, but make sure to not forget the extra bytes
	if (extraBytes === 1) {
		tmp = uint8[len - 1];
		parts.push(lookup[tmp >> 2] + lookup[(tmp << 4) & 0x3F] + '==');
	} else if (extraBytes === 2) {
		tmp = (uint8[len - 2] << 8) + uint8[len - 1];
		parts.push(lookup[tmp >> 10] + lookup[(tmp >> 4) & 0x3F] + lookup[(tmp << 2) & 0x3F] + '=');
	}

	return parts.join('');
};