import React, { useReducer, useState, useEffect } from 'react';
import styled from 'styled-components';
import {
	Button,
	ButtonBar,
	ButtonBarAlignType,
	DateTimeRange,
	FormSectionGapVertical,
	PanelSeparator,
	QueryPanel,
	QueryResultColumns,
	QueryResultPanel,
	QueryResultRow,
	Text,
	Dropdown,
	WorkAreaTitle
} from '../../component';
import { RecordCriteria, RecordDataPage, findRecordDataPage, exportTraceRecordData, AccountList,getTenantList} from '../../service/record-service';
import { endOfDate, formatFull, startOfDate } from '../../service/date';
import { DropdownOption } from 'src/component/dropdown';


const RecordQuery = styled.div`
	display: flex;
	flex-direction: column;
`;

const columns: QueryResultColumns = [
	{ label: '序号', width: '50px', name: 'seq' },
	{ label: '业务回溯码', width: '240px', name: 'traceNo' },
	{ label: '投保单号', width: '200px', name: 'quoteNo' },
	{ label: '保单号', width: '200px', name: 'policyNo' },
	{ label: '产品名称', width: '200px', name: 'productName' },
	{ label: '投保人', width: '70px', name: 'policyHolderName' },
	{ label: '资料生成时间', width: '120px', name: 'createTime' }
];

type InputCriteria = Exclude<keyof RecordCriteria, 'pageSize' | 'pageNumber'>

export default () => {
	const now = new Date();
	const [, forceUpdate] = useReducer(x => x + 1, 0);
	const [data, setData] = useState<RecordDataPage | null>(null);
	const [searched, setSearched] = useState(false);
	const [criteria,setCriteria] = useState<RecordCriteria>({
		createFrom: formatFull(startOfDate(now)),
		createTo: formatFull(endOfDate(now)),
		productName:'',
		policyHolderName:'',
		blockFlag:'0'
	});
	const [tenantList,setTenantList] = useState<Array<DropdownOption> | []>([]);

	useEffect(() => {
		(async () => {
			const tenantlist : AccountList =await getTenantList();
			const accounts = tenantlist?.accounts || [];
			const tenant = new Array<DropdownOption>();
			accounts.forEach((account)=>{
				tenant.push({value:account.tenantId,label:account.accountName});
			});
			setTenantList(tenant);
			setCriteria({tenantId:accounts[0]?.tenantId.toString(),blockFlag:'0',createFrom: formatFull(startOfDate(now)),createTo: formatFull(endOfDate(now))});
		})();
	}, []);

	const [currentCriteria, setCurrentCriteria] = useState<RecordCriteria | null>(null);
	const onChanged = (propName: InputCriteria) => (event: React.ChangeEvent) => {
		// @ts-ignore
		criteria[propName] = (event.target as HTMLInputElement).value;
		forceUpdate();
	};

	const onDateChanged = (startDate?: Date, endDate?: Date) => {
		criteria.createFrom = formatFull(startOfDate(startDate));
		criteria.createTo = formatFull(endOfDate(endDate));
		forceUpdate();
	};

	const dataFound = data || { pageNumber: 1, pageSize: 10, totalPages: 1, totalItemCount: 0, rows: [] };
	const rows = (dataFound.rows || []).map((row): QueryResultRow => {
		return  row as QueryResultRow;
	});

	const doSearch = async (pagination: RecordCriteria) => {
		// 设置当前查询
		setCurrentCriteria(pagination);
		const data = await findRecordDataPage(pagination);
		setData(data);
		setSearched(true);
	};

	const doExport =  (pagenation:RecordCriteria)=>{
		  setCurrentCriteria(pagenation);
		  exportTraceRecordData(pagenation);
	};


	const onSearchClicked = async (criteria: RecordCriteria) => {
		const pagination = {
			...criteria,
			pageNumber: 1,
			pageSize: 10
		};
		await doSearch(pagination);
	};

	const onExportClicked = async (criteria: RecordCriteria) => {
		const pagination = {
			...criteria,
			pageNumber: 1,
			pageSize: 10
		};
		await doExport(pagination);
	};


	const onPageChanged = async (pageNumber: number) => {
		const pagination = {
			...currentCriteria,
			pageNumber
		};
		await doSearch(pagination);
	};


	const traceUpdate = () => {
		forceUpdate();
	};

	return <RecordQuery>
		<WorkAreaTitle title="可回溯服务记录" />
		<QueryPanel>
			<DateTimeRange label="资料生成时间" columns={2} startDate={criteria.createFrom!} endDate={criteria.createTo!}
				onChange={onDateChanged}  update={traceUpdate}/>
			<Dropdown label="租户" placeholder="-请选择-" options={tenantList} value = {criteria.tenantId} onChange ={onChanged("tenantId")}/>
			<Text label="产品名称" placeholder="请输入" value={criteria.productName} onChange={onChanged('productName')}/>
			<Text label="投保人"   placeholder="请输入" value={criteria.policyHolderName} onChange={onChanged('policyHolderName')}/>

			<PanelSeparator columns={3} />
			<ButtonBar align={ButtonBarAlignType.CENTER}>
				<Button label="查询" onClick={() => onSearchClicked(criteria)} />
				<Button label="导出" onClick={() => onExportClicked(criteria)} />
			</ButtonBar>
			<PanelSeparator columns={3} />
		</QueryPanel>
		<FormSectionGapVertical />
		<QueryResultPanel columns={columns} rows={rows}  pagination={true} searched={searched}
			pageCount={dataFound.totalPages} pageNumber={dataFound.pageNumber}
			totalItemCount={dataFound.totalItemCount}
			pageChange={onPageChanged} />
	</RecordQuery>;
}



