import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Path from '../path';
import TraceQuery from './trace-query';
import Trace from './trace-view';
import TraceMonthReport from './trace-month-report';
import TraceDayReport from './trace-day-report';

export default () => {
	return <Switch>
		<Route path={Path.TRACE_VIEW} exact render={({ match }) => {
			return <Trace traceId={match.params.traceId} createAt={match.params.createAt} />;
		}}/>
		<Route path={Path.TRACE_QUALITY_INSPECTION_VIEW} exact render={({ match }) => {
			return <Trace traceId={match.params.traceId} createAt={match.params.createAt} taskName={match.params.taskName}/>;
		}}/>
		<Route path={Path.TRACE_QUALITY_INSPECTION_QUERY} exact render={({ match }) => {
			return <TraceQuery taskName={match.params.taskName}/>;
		}}/>
		<Route path={Path.TRACE_REPORT_MONTH} exact render={({ match }) => {
			return <TraceMonthReport/>;
		}}/>
		<Route path={Path.TRACE_REPORT_DAY} exact render={({ match }) => {
			return <TraceDayReport/>;
		}}/>
		<Route path={"/"}>
			<TraceQuery/>
		</Route>
	</Switch>;
}