import React, { useReducer, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { doPost } from '../service/ajax';
import styled from 'styled-components';
import {
	Button,
	ButtonBar,
	ButtonBarAlignType,
	FormSectionGapVertical,
	PanelSeparator,
	QueryPanel,
	Text,
	WorkAreaTitle,
	Dropdown
} from '../component';
import { DropdownOption } from 'src/component/dropdown';
import {AccountList} from "../service/record-service";
import {getCurrentUserTenantList, getTenantList} from "../service/account-service";
const SourceConfigEdit = styled.div`
	display: flex;
	flex-direction: column;
`;

export default () => {
	const history = useHistory();
	const [ , forceUpdate ] = useReducer(x => x + 1, 0);
	const [tenantList,setTenantList] = useState<Array<DropdownOption> | []>([]);
	const [currentTenant,setCurrentTenant] = useState<string>('');
	const [ source,setSource ] = useState<string>( '');
	const [ url,setUrl ] = useState<string>( '');

	useEffect(() => {
		(async () => {
			const tenantlist : AccountList =await getCurrentUserTenantList();
			const accounts = tenantlist?.accounts || [];
			const tenant = new Array<DropdownOption>();
			accounts.forEach((account)=>{
				tenant.push({value:account.tenantId,label:account.accountName});
			});
			setTenantList(tenant);
			// @ts-ignore
			setCurrentTenant(tenant[0].value);
		})();
	}, []);

	const onChanged = (propName: string) => (event: React.ChangeEvent) => {
		// @ts-ignore
        if(propName == 'tenant'){
			setCurrentTenant((event.target as HTMLInputElement).value);
		}else if(propName == 'url'){
			setUrl((event.target as HTMLInputElement).value);
		}else{
			setSource((event.target as HTMLInputElement).value);
		}
		forceUpdate();
	};

    const urlReg = RegExp('(https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/manifest.json');

	const onAddClicked = async ()=> {
		if(url == '' || !url){
			alert('请录入manifest url');
			return;
		}
		if(!urlReg.test(url)){
            alert('manifest url 格式错误');
            return;
        }
	    const result = await doPost('/api/sourceConfig/add',{'source':source,'tenantId':currentTenant,'url':url});
	    if(result.body == 'OK'){
			alert("添加成功!");
			history.goBack();
		}else{
			alert(result.body);
		}
	};

	return <SourceConfigEdit>
		<WorkAreaTitle title="新增版本管理配置"/>
		<QueryPanel>
			<Dropdown label="系统" options={tenantList} value={currentTenant} onChange ={onChanged('tenant')}/>
			<Text label="来源" placeholder="请输入" value={source} onChange={onChanged('source')}/>
			<Text label="manifest url" placeholder="请输入" value={url} onChange={onChanged('url')}/>
			<PanelSeparator columns={3}/>
			<ButtonBar align={ButtonBarAlignType.CENTER}>
				<Button label="提交" onClick = {() => onAddClicked() } />
				<Button label="返回" onClick = {() => history.goBack() } />
			</ButtonBar>
			<PanelSeparator columns={3}/>
		</QueryPanel>
		<FormSectionGapVertical/>
		
	</SourceConfigEdit>;
}