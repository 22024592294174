import * as mittProxy from 'mitt';
import * as SmoothScroll from 'smoothscroll-polyfill';
import { buildNodeWithSN, rebuild } from './rebuild-snapshot';
import { createPlayerService, createSpeedService } from './replay-machine';
import { Timer } from './replay-timer';
import { TreeIndex } from './replay-tree-index';
import {
	AddedNodeMutation,
	CanvasEventData,
	Emitter,
	FullSnapshotEvent,
	Handler,
	IncrementalEventData,
	IncrementalEventType,
	IncrementalSnapshotEvent,
	INode,
	InputEventData,
	MediaInteraction,
	MetaEvent,
	MissingNode,
	MissingNodeMap,
	MouseInteraction,
	MouseInteractionData,
	MutationEventData,
	NodeType,
	PlayerConfig,
	PlayerMetaData,
	RemovedNodeMutation,
	ReplayerEvents,
	ScrollEventData,
	ViewportResizeData,
	WebEventType,
	WebEventWithTime
} from '../rrweb/types';
import { mirror, polyfillNodeForEach } from '../rrweb/utils';
import WeixinMiniProgramStyles from './wmp-styles';

// https://github.com/rollup/rollup/issues/1267#issuecomment-296395734
// tslint:disable-next-line
const mitt = (mittProxy as any).default || mittProxy;
const REPLAY_CONSOLE_PREFIX = '[replayer]';
const SKIP_TIME_THRESHOLD = 10 * 1000;
const SKIP_TIME_INTERVAL = 5 * 1000;

const DEFAULT_CONFIG: PlayerConfig = {
	speed: 1,
	root: document.body,
	loadTimeout: 0,
	skipInactive: false,
	showWarning: true,
	showDebug: false,
	blockClass: 'rr-block',
	liveMode: false,
	insertStyleRules: [],
	triggerFocus: true
};

const ICON_FONTS: { [key in string]: string | Array<string> } = {
	"Font-Awesome4.5": `@font-face {font-family: 'FontAwesome';src: url('/font-awesome-4.5/fontawesome-webfont.eot?v=4.5.0');src: url('/font-awesome-4.5/fontawesome-webfont.eot?#iefix&v=4.5.0') format('embedded-opentype'), url('/font-awesome-4.5/fontawesome-webfont.woff2?v=4.5.0') format('woff2'), url('/font-awesome-4.5/fontawesome-webfont.woff?v=4.5.0') format('woff'), url('/font-awesome-4.5/fontawesome-webfont.ttf?v=4.5.0') format('truetype'), url('/font-awesome-4.5/fontawesome-webfont.svg?v=4.5.0#fontawesomeregular') format('svg');font-weight: normal;font-style: normal;}`,
	"Aquila": [
		`@font-face {font-family: 'IconFont';src: url('/aquila-iconfont/iconfont.eot');src: url('/aquila-iconfont/iconfont.woff') format('woff'), url('/aquila-iconfont/iconfont.ttf') format('truetype'), url('/aquila-iconfont/iconfont.svg') format('svg');}`,
		`.iconfont {font-family: "iconfont" !important;  font-size: inherit;  font-style: normal;  -webkit-font-smoothing: antialiased;  /*-webkit-text-stroke-width: 0.2px;*/  -moz-osx-font-smoothing: grayscale;}`,
		`.icon-zhifubao:before { content: "\\e601"; }`
	],
	"Font-Awesome5.14": [
		`@font-face {
			font-family: 'Font Awesome 5 Brands';
			font-style: normal;
			font-weight: 400;
			font-display: block;
			src: url("/fontawesome-free-5.14.0-web/webfonts/fa-brands-400.eot");
			src: url("/fontawesome-free-5.14.0-web/webfonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("/fontawesome-free-5.14.0-web/webfonts/fa-brands-400.woff2") format("woff2"), url("/fontawesome-free-5.14.0-web/webfonts/fa-brands-400.woff") format("woff"), url("/fontawesome-free-5.14.0-web/webfonts/fa-brands-400.ttf") format("truetype"), url("/fontawesome-free-5.14.0-web/webfonts/fa-brands-400.svg#fontawesome") format("svg");
		}`,
		`.fab {
			font-family: 'Font Awesome 5 Brands';
			font-weight: 400; 
		}`,
		`@font-face {
			font-family: 'Font Awesome 5 Free';
			font-style: normal;
			font-weight: 400;
			font-display: block;
			src: url("/fontawesome-free-5.14.0-web/webfonts/fa-regular-400.eot");
			src: url("/fontawesome-free-5.14.0-web/webfonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("/fontawesome-free-5.14.0-web/webfonts/fa-regular-400.woff2") format("woff2"), url("/fontawesome-free-5.14.0-web/webfonts/fa-regular-400.woff") format("woff"), url("/fontawesome-free-5.14.0-web/webfonts/fa-regular-400.ttf") format("truetype"), url("/fontawesome-free-5.14.0-web/webfonts/fa-regular-400.svg#fontawesome") format("svg");
		}`,
		`.far {
			font-family: 'Font Awesome 5 Free';
			font-weight: 400;
		}`,
		`@font-face {
			font-family: 'Font Awesome 5 Free';
			font-style: normal;
			font-weight: 900;
			src: url("/fontawesome-free-5.14.0-web/webfonts/fa-solid-900.eot");
			src: url("/fontawesome-free-5.14.0-web/webfonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("/fontawesome-free-5.14.0-web/webfonts/fa-solid-900.woff2") format("woff2"), url("/fontawesome-free-5.14.0-web/webfonts/fa-solid-900.woff") format("woff"), url("/fontawesome-free-5.14.0-web/webfonts/fa-solid-900.ttf") format("truetype"), url("/fontawesome-free-5.14.0-web/webfonts/fa-solid-900.svg#fontawesome") format("svg"); 
		}`,
		`.fa,
		.fas {
			font-family: 'Font Awesome 5 Free';
			font-weight: 900; 
		}`
	],
	'Vant1.5': [
		'@font-face{font-weight:400;font-family:vant-icon;font-style:normal;font-display:auto;src:url(https://img.yzcdn.cn/vant/vant-icon-eeb192.woff2) format("woff2"),url(https://img.yzcdn.cn/vant/vant-icon-eeb192.woff) format("woff"),url(https://img.yzcdn.cn/vant/vant-icon-eeb192.ttf) format("truetype")}',
		'.van-icon{position:relative;font:normal normal normal 14px/1 vant-icon;font-size:inherit;text-rendering:auto;-webkit-font-smoothing:antialiased}',
		'.van-icon,.van-icon:before{display:inline-block}',
		'.van-icon-underway-o:before{content:"\\F0D0"}',
		'.van-icon-warning-o:before{content:"\\F0DF"}',
		'.van-icon-passed:before{content:"\\F092"}'
	],
	'ionicons3.0.0':[
		`@font-face {
			font-family: "Roboto";
			font-style: normal;
			font-weight: 300;
			src: local("Roboto Light"), local("Roboto-Light"), url("/ionicons-3.0.0/roboto-light.woff2") format("woff2"), url("/ionicons-3.0.0/roboto-light.woff") format("woff"), url("/ionicons-3.0.0/roboto-light.ttf") format("truetype");
		}`,
		`@font-face {
			font-family: "Roboto";
			font-style: normal;
			font-weight: 400;
			src: local("Roboto"), local("Roboto-Regular"), url("/ionicons-3.0.0/roboto-regular.woff2") format("woff2"), url("/ionicons-3.0.0/roboto-regular.woff") format("woff"), url("/ionicons-3.0.0/roboto-regular.ttf") format("truetype");
			}`,
		`@font-face {
			font-family: "Roboto";
			font-style: normal;
			font-weight: 500;
			src: local("Roboto Medium"), local("Roboto-Medium"), url("/ionicons-3.0.0/roboto-medium.woff2") format("woff2"), url("/ionicons-3.0.0/roboto-medium.woff") format("woff"), url("/ionicons-3.0.0/roboto-medium.ttf") format("truetype");
		}`,
		`@font-face {
			font-family: "Roboto";
			font-style: normal;
			font-weight: 700;
			src: local("Roboto Bold"), local("Roboto-Bold"), url("/ionicons-3.0.0/roboto-bold.woff2") format("woff2"), url("/ionicons-3.0.0/roboto-bold.woff") format("woff"), url("/ionicons-3.0.0/roboto-bold.ttf") format("truetype");
		}`,
		`@font-face {
			font-family: "Ionicons";
			src: url("/ionicons-3.0.0/ionicons.woff2?v=3.0.0-alpha.3") format("woff2"),
			url("/ionicons-3.0.0/ionicons.woff?v=3.0.0-alpha.3}") format("woff"),
			url("/ionicons-3.0.0/ionicons.ttf?v=3.0.0-alpha.3") format("truetype");
			font-weight: normal;
			font-style: normal;
		}`,
		`@font-face {
			font-family: "Noto Sans";
			font-style: normal;
			font-weight: 300;
			src: local("Noto Sans"), local("Noto-Sans-Regular"), url("/ionicons-3.0.0/noto-sans-regular.woff") format("woff"), url("/ionicons-3.0.0/noto-sans-regular.ttf") format("truetype");
		}`,
		`@font-face {
			font-family: "Noto Sans";
			font-style: normal;
			font-weight: 400;
			src: local("Noto Sans"), local("Noto-Sans-Regular"), url("/ionicons-3.0.0/noto-sans-regular.woff") format("woff"), url("/ionicons-3.0.0/noto-sans-regular.ttf") format("truetype");
		}`,
		`@font-face {
			font-family: "Noto Sans";
			font-style: normal;
			font-weight: 500;
			src: local("Noto Sans Bold"), local("Noto-Sans-Bold"), url("/ionicons-3.0.0/noto-sans-bold.woff") format("woff"), url("/ionicons-3.0.0/noto-sans-bold.ttf") format("truetype");
		}`,
		`@font-face {
			font-family: "Noto Sans";
			font-style: normal;
			font-weight: 700;
			src: local("Noto Sans Bold"), local("Noto-Sans-Bold"), url("/ionicons-3.0.0/noto-sans-bold.woff") format("woff"), url("/ionicons-3.0.0/noto-sans-bold.ttf") format("truetype");
		}`
	]
};

type InjectStyleRulesCreator = (blockClass: string, iconFonts?: Array<string>) => Array<string>;
const getInjectStyleRules: InjectStyleRulesCreator = (blockClass: string, iconFonts: Array<string> = []) => {
	const styles = iconFonts.map(iconFont => ICON_FONTS[iconFont])
		.map(styles => Array.isArray(styles) ? styles : [ styles ])
		.reduce((all, styles) => {
			return [ ...all, ...styles ];
		}, [] as Array<string>);
	return [
		`.${blockClass} { background-color: #f9f9f9; }`,
		// 微信小程序原生标准组件CSS
		...WeixinMiniProgramStyles,
		...styles,
		//手机scrollbar隐藏
		//isMobile ? 'div::-webkit-scrollbar { width: 0px; height: 0px }' : 'div {}',
		'noscript { display: none !important; }'
	];
};

class Replayer {
	public wrapper: HTMLDivElement;
	public iframe: HTMLIFrameElement;
	public service: ReturnType<typeof createPlayerService>;
	public speedService: ReturnType<typeof createSpeedService>;
	public config: PlayerConfig;
	private mouse: HTMLDivElement;
	private dateText: HTMLDivElement;
	private emitter: Emitter = mitt();
	private nextUserInteractionEvent: WebEventWithTime | null = null;
	// tslint:disable-next-line: variable-name
	private legacyMissingNodeRetryMap: MissingNodeMap = {};
	private treeIndex!: TreeIndex;
	private fragmentParentMap!: Map<INode, INode>;

	constructor(events: Array<WebEventWithTime | string>, config?: Partial<PlayerConfig>) {
		if (!config?.liveMode && events.length < 2) {
			throw new Error('Replayer need at least 2 events.');
		}
		this.config = Object.assign({}, DEFAULT_CONFIG, config);
		// console.log(this.config);

		this.emitter.on(ReplayerEvents.Resize, this.handleResize as Handler);

		this.emitter.on('refreshTimestamp', this.refreshTimestamp as Handler);

		SmoothScroll.polyfill();
		polyfillNodeForEach();

		// setup dom
		this.wrapper = document.createElement('div');
		this.wrapper.classList.add('replayer-wrapper');
		this.config.root!.appendChild(this.wrapper);

		this.dateText = document.createElement('div');
		this.dateText.classList.add('replayer-dateText');
		this.wrapper.appendChild(this.dateText);

		this.mouse = document.createElement('div');
		this.mouse.classList.add('replayer-mouse');
		this.wrapper.appendChild(this.mouse);

		this.iframe = document.createElement('iframe');
		// 添加allow-scripts
		// 三井的桌面页面会报以下异常
		// Blocked script execution in '<URL>' because the document's frame is sandboxed and the 'allow-scripts' permission is not set.
		this.iframe.setAttribute('sandbox', 'allow-same-origin allow-scripts');
		this.disableInteract();
		this.wrapper.appendChild(this.iframe);

		this.treeIndex = new TreeIndex();
		this.fragmentParentMap = new Map<INode, INode>();
		this.emitter.on(ReplayerEvents.Flush, () => {
			const { scrollMap, inputMap } = this.treeIndex.flush();

			scrollMap.forEach((data) => {
				this.applyScroll(data,-1);//TODO
			});
			inputMap.forEach((data) => {
				this.applyInput(data);
			});

			// @ts-ignore
			for (const [ frag, parent ] of this.fragmentParentMap.entries()) {
				mirror.map[parent.__sn.id] = parent;
				/**
				 * If we have already set value attribute on textarea,
				 * then we could not apply text content as default value any more.
				 */
				if (parent.__sn.type === NodeType.Element && parent.__sn.tagName === 'textarea' && frag.textContent) {
					((parent as unknown) as HTMLTextAreaElement).value = frag.textContent;
				}
				try {
					parent.appendChild(frag);
				} catch (e) {
					// ignore the exception
					console.error('Error occurred when add child to parent', e, 'parent is ', parent, 'add target is ', frag);
				}
			}
			this.fragmentParentMap.clear();
		});

		const timer = new Timer([], config?.speed || DEFAULT_CONFIG.speed);
		this.service = createPlayerService(
			{
				events: events.map((e) => {
					if (config && config.deserializeEvent) {
						return config.deserializeEvent(e as string);
					}
					return e as WebEventWithTime;
				}),
				timer,
				timeOffset: 0,
				baselineTime: 0,
				lastPlayedEvent: null
			},
			{
				getCastFn: this.getCastFn,
				emitter: this.emitter
			}
		);
		this.service.start();
		this.service.subscribe((state) => {
			this.emitter.emit(ReplayerEvents.StateChange, {
				player: state
			});
		});
		this.speedService = createSpeedService({
			normalSpeed: -1,
			timer
		});
		this.speedService.start();
		this.speedService.subscribe((state) => {
			this.emitter.emit(ReplayerEvents.StateChange, {
				speed: state
			});
		});

		// rebuild first full snapshot as the poster of the player
		// maybe we can cache it for performance optimization
		const firstMeta = this.service.state.context.events.find(
			(e) => e.type === WebEventType.Meta
		);
		const firstFullSnapshot = this.service.state.context.events.find(
			(e) => e.type === WebEventType.FullSnapshot
		);
		if (firstMeta) {
			const { width, height } = firstMeta.data as MetaEvent['data'];
			setTimeout(() => this.emitter.emit(ReplayerEvents.Resize, { width, height }), 0);
		}
		if (firstFullSnapshot) {
			this.rebuildFullSnapshot(firstFullSnapshot as FullSnapshotEvent & { timestamp: number });
		}
	}

	private handleResize = (dimension: ViewportResizeData) => {
		this.iframe.setAttribute('width', String(dimension.width));
		if (dimension.height && dimension.height < 400 && dimension.width && dimension.width < 600) {
			return;
		}
		this.iframe.setAttribute('height', String(dimension.height));
	};

	private refreshTimestamp = (timestamp: string) => {
		this.dateText.innerText = '操作日期:'+this.formatDate(timestamp);
	};

	private formatDate = (timestamp: string):string => {
		let datetime = new Date(timestamp);
		let year = datetime.getFullYear(),
				month = ("0" + (datetime.getMonth() + 1)).slice(-2),
				date = ("0" + datetime.getDate()).slice(-2),
				hour = ("0" + datetime.getHours()).slice(-2),
				minute = ("0" + datetime.getMinutes()).slice(-2),
				second = ("0" + datetime.getSeconds()).slice(-2);
		 return year + "-"+ month +"-"+ date +" "+ hour +":"+ minute +":" + second;
	};

	public enableInteract() {
		this.iframe.setAttribute('scrolling', 'auto');
		this.iframe.style.pointerEvents = 'auto';
	}

	public disableInteract() {
		this.iframe.setAttribute('scrolling', 'no');
		this.iframe.style.pointerEvents = 'none';
	}

	private getCastFn = (event: WebEventWithTime, isSync = false) => {
		let castFn: undefined | (() => void);
		switch (event.type) {
			case WebEventType.DomContentLoaded:
			case WebEventType.Loaded:
				break;
			case WebEventType.Custom:
				castFn = () => {
					/**
					 * emit custom-event and pass the event object.
					 *
					 * This will add more value to the custom event and allows the client to react for custom-event.
					 */
					this.emitter.emit(ReplayerEvents.CustomEvent, event);
				};
				break;
			case WebEventType.Meta:
				castFn = () =>{
					this.emitter.emit(ReplayerEvents.Resize, {
						width: event.data.width,
						height: event.data.height
					});
				};
				break;
			case WebEventType.FullSnapshot:
				castFn = () => {
					this.rebuildFullSnapshot(event);
					this.iframe.contentWindow!.scrollTo(event.data.initialOffset);
				};
				break;
			case WebEventType.IncrementalSnapshot:
				castFn = () => {
					this.applyIncremental(event, isSync);
					if (isSync) {
						// do not check skip in sync
						return;
					}
					if (event === this.nextUserInteractionEvent) {
						this.nextUserInteractionEvent = null;
						this.backToNormal();
					}
					if (this.config.skipInactive && !this.nextUserInteractionEvent) {
						for (const _event of this.service.state.context.events) {
							if (_event.timestamp! <= event.timestamp!) {
								continue;
							}
							if (Replayer.isUserInteraction(_event)) {
								if (
									_event.delay! - event.delay! >
									SKIP_TIME_THRESHOLD *
									this.speedService.state.context.timer.speed
								) {
									this.nextUserInteractionEvent = _event;
								}
								break;
							}
						}
						if (this.nextUserInteractionEvent) {
							const skipTime = this.nextUserInteractionEvent.delay! - event.delay!;
							const payload = { speed: Math.min(Math.round(skipTime / SKIP_TIME_INTERVAL), 360) };
							this.speedService.send({ type: 'FAST_FORWARD', payload });
							this.emitter.emit(ReplayerEvents.SkipStart, payload);
						}
					}
				};
				break;
			default:
		}
		return () => {
			if (castFn) {
				castFn();
			}
			this.service.send({ type: 'CAST_EVENT', payload: { event } });
			if (event === this.service.state.context.events[this.service.state.context.events.length - 1]) {
				this.backToNormal();
				this.service.send('END');
				this.emitter.emit(ReplayerEvents.Finish);
			}
		};
	};

	private applyScroll(data: ScrollEventData,timestamp: number) {
		const target = mirror.getNode(data.id);
		if (!target) {
			return this.debugNodeNotFound(data, data.id);
		}
		if ((target as Node) === this.iframe.contentDocument) {
			this.iframe.contentWindow!.scrollTo({
				top: data.y,
				left: data.x,
				behavior: 'smooth'
			});
		} else if (target.nodeType === 9) {
			(target as unknown as Document).scrollingElement?.scrollTo({
				top: data.y,
				left: data.x,
				behavior: 'smooth'
			});
		} else {
			try {
				let nextThreeEventsHaveScroll = false;
				let events = this.service.state.context.events;
				for (let i = 0;i < events.length;i++) {
					if(timestamp < 0){
						break;
					}
					if (events[i].timestamp! <= timestamp) {
						continue;
					}
					for(let j = 0;j < 3; j++){
						if(events[i+j].type == WebEventType.IncrementalSnapshot
							&& (events[i+j].data as IncrementalEventData).source == IncrementalEventType.Scroll
							&& (events[i+j].data as ScrollEventData).id == data.id){
							nextThreeEventsHaveScroll = true;
							break;
						}
					}
					break;
				}
				if(!nextThreeEventsHaveScroll){
					let total = ((target as Node) as Element).scrollHeight;
					if(total - data.y < total*0.05){
						data.y += (total - data.y)/2;
					}
				}
				((target as Node) as Element).scrollTop = data.y;
				((target as Node) as Element).scrollLeft = data.x;
			} catch (error) {
				/**
				 * Seldomly we may found scroll target was removed before
				 * its last scroll event.
				 */
			}
		}
	}

	private applyCanvas(data: CanvasEventData) {
		// console.log(data);
		const target = mirror.getNode(data.id);
		if (!target) {
			return this.debugNodeNotFound(data, data.id);
		}

		try {
			const image = (target as Node) as HTMLImageElement;
			image.src = data.image;
			image.style.height = data.rr_height;
			image.style.width = data.rr_width;
		} catch (error) {
			// for safe
		}
	}

	private applyInput(data: InputEventData) {
		const target = mirror.getNode(data.id);
		if (!target) {
			return this.debugNodeNotFound(data, data.id);
		}
		try {
			((target as Node) as HTMLInputElement).checked = data.isChecked;
			((target as Node) as HTMLInputElement).value = data.text;
		} catch (error) {
			// for safe
		}
	}

	private applyMutation(d: MutationEventData, useVirtualParent: boolean, timestamp: number) {
		d.removes.forEach((mutation: RemovedNodeMutation) => {
			const target = mirror.getNode(mutation.id);
			if (!target) {
				return this.warnNodeNotFound(d, mutation.id);
			}
			const parent = mirror.getNode(mutation.parentId);
			if (!parent) {
				return this.warnNodeNotFound(d, mutation.parentId);
			}
			// target may be removed with its parents before
			mirror.removeNodeFromMap(target);
			if (parent) {
				const realParent = this.fragmentParentMap.get(parent);
				if (realParent && realParent.contains(target)) {
					realParent.removeChild(target);
				} else {
					try {
						parent.removeChild(target);
					} catch (e) {
						// ignore the exception
						console.error('Error occurred when remove child from parent', e, 'parent is ', parent, 'remove target is ', target);
					}
				}
			}
		});

		const legacy_missingNodeMap: MissingNodeMap = {
			...this.legacyMissingNodeRetryMap
		};
		const queue: AddedNodeMutation[] = [];

		const appendNode = (mutation: AddedNodeMutation) => {
			if (!this.iframe.contentDocument) {
				return console.warn('Looks like your replayer has been destroyed.');
			}
			let parent = mirror.getNode(mutation.parentId);
			if (!parent) {
				return queue.push(mutation);
			}

			const parentInDocument = this.iframe.contentDocument.contains(parent);
			if (useVirtualParent && parentInDocument) {
				const virtualParent = (document.createDocumentFragment() as unknown) as INode;
				mirror.map[mutation.parentId] = virtualParent;
				this.fragmentParentMap.set(virtualParent, parent);
				while (parent.firstChild) {
					virtualParent.appendChild(parent.firstChild);
				}
				parent = virtualParent;
			}

			// start
			// mutation事件是延迟的(由浏览器控制), 如果在mutation接收到之前, 刚好有一次full snapshot, 那么有可能节点在full snapshot中已经存在.
			// 这个时候不需要再处理相关的mutation.
			const nodeId = mutation.node.id;
			if (mirror.getNode(nodeId)) {
				// 已经有了不需要再加
				return;
			}
			// ending

			let previous: Node | null = null;
			let next: Node | null = null;
			if (mutation.previousId) {
				previous = mirror.getNode(mutation.previousId) as Node;
			}
			if (mutation.nextId) {
				next = mirror.getNode(mutation.nextId) as Node;
			}
			// next not present at this moment
			if (mutation.nextId !== null && mutation.nextId !== -1 && !next) {
				return queue.push(mutation);
			}

			const afterBuilt: Array<() => void> = [];
			const target = buildNodeWithSN(
				mutation.node,
				this.iframe.contentDocument,
				mirror.map,
				true,
				true,
				afterBuilt
			) as Node;

			// legacy data, we should not have -1 siblings any more
			if (mutation.previousId === -1 || mutation.nextId === -1) {
				legacy_missingNodeMap[mutation.node.id] = {
					node: target,
					mutation
				};
				return;
			}

			try {
				if (previous && previous.nextSibling && previous.nextSibling.parentNode) {
					parent.insertBefore(target, previous.nextSibling);
				} else if (next && next.parentNode) {
					// making sure the parent contains the reference nodes
					// before we insert target before next.
					parent.contains(next) ? parent.insertBefore(target, next) : parent.insertBefore(target, null);
				} else {
					parent.appendChild(target);
				}
			} catch (e) {
				// ignore the exception
				console.error('Error occurred when add child to parent', e, 'parent is ', parent, 'add target is ', target);
			}

			if (mutation.previousId || mutation.nextId) {
				this.legacyResolveMissingNode(
					legacy_missingNodeMap,
					parent,
					target,
					mutation
				);
			}

			afterBuilt.forEach(after => after());
		};

		d.adds.forEach((mutation) => {
			appendNode(mutation);
		});

		const dealWithNodeInQueue = () => {
			let nodes = [ ...queue ];
			const lengthBefore = nodes.length;
			if (nodes.every((m) => !Boolean(mirror.getNode(m.parentId)))) {
				return nodes.forEach((m) => this.warnNodeNotFound(d, m.node.id));
			}
			nodes.forEach(() => {
				const mutation = queue.shift()!;
				appendNode(mutation);
			});
			nodes = [ ...queue ];
			if (nodes.length !== lengthBefore && nodes.length !== 0) {
				// 还没处理完, 但是有变动
				dealWithNodeInQueue();
			}
		};
		dealWithNodeInQueue();

		if (Object.keys(legacy_missingNodeMap).length) {
			Object.assign(this.legacyMissingNodeRetryMap, legacy_missingNodeMap);
		}

		d.texts.forEach((mutation) => {
			let target = mirror.getNode(mutation.id);
			if (!target) {
				return this.warnNodeNotFound(d, mutation.id);
			}
			/**
			 * apply text content to real parent directly
			 */
			if (this.fragmentParentMap.has(target)) {
				target = this.fragmentParentMap.get(target)!;
			}
			target.textContent = mutation.value;
		});
		d.attributes.forEach((mutation) => {
			let target = mirror.getNode(mutation.id);
			if (!target) {
				return this.warnNodeNotFound(d, mutation.id);
			}
			if (this.fragmentParentMap.has(target)) {
				target = this.fragmentParentMap.get(target)!;
			}
			for (const attributeName in mutation.attributes) {
				// eslint-disable-next-line
				if (typeof attributeName === 'string') {
					const value = mutation.attributes[attributeName];
					if (value !== null) {
						try {
							((target as Node) as Element).setAttribute(attributeName, value);
						} catch (e) {
							// ignore the exception
							console.error('Error occurred when set attribute', e, 'target is ', target, 'attribute name is ', attributeName, 'value is ', value);
						}
					} else {
						((target as Node) as Element).removeAttribute(attributeName);
					}
				}
			}
		});
	}

	private applyIncremental(e: IncrementalSnapshotEvent & { timestamp: number }, isSync: boolean) {
		const { data: d } = e;
		switch (d.source) {
			case IncrementalEventType.Mutation: {
				if (isSync) {
					d.adds.forEach((m) => this.treeIndex.add(m));
					d.texts.forEach((m) => this.treeIndex.text(m));
					d.attributes.forEach((m) => this.treeIndex.attribute(m));
					d.removes.forEach((m) => this.treeIndex.remove(m));
				}
				this.applyMutation(d, isSync, e.timestamp);
				break;
			}
			case IncrementalEventType.MouseMove:
				if (isSync) {
					const lastPosition = d.positions[d.positions.length - 1];
					this.moveAndHover(d, lastPosition.x, lastPosition.y, lastPosition.id);
				} else {
					d.positions.forEach((p) => {
						const action = {
							doAction: () => {
								this.moveAndHover(d, p.x, p.y, p.id);
							},
							delay:
								p.timeOffset +
								e.timestamp -
								this.service.state.context.baselineTime
						};
						this.timer.addAction(action);
					});
				}
				break;
			case IncrementalEventType.MouseInteraction: {
				/**
				 * Same as the situation of missing input target.
				 */
				if (d.id === -1) {
					break;
				}
				const event = new Event(MouseInteraction[d.type].toLowerCase());
				const target = mirror.getNode(d.id);
				if (!target) {
					return this.debugNodeNotFound(d, d.id);
				}
				this.emitter.emit(ReplayerEvents.MouseInteraction, {
					type: d.type,
					target
				});
				const { triggerFocus } = this.config;
				switch (d.type) {
					case MouseInteraction.Blur:
						if ('blur' in ((target as Node) as HTMLElement)) {
							((target as Node) as HTMLElement).blur();
						}
						break;
					case MouseInteraction.Focus:
						if (triggerFocus && ((target as Node) as HTMLElement).focus) {
							((target as Node) as HTMLElement).focus({
								preventScroll: true
							});
						}
						break;
					case MouseInteraction.Click:
					case MouseInteraction.TouchStart:
					case MouseInteraction.TouchEnd:
						/**
						 * Click has no visual impact when replaying and may
						 * trigger navigation when apply to an <a> link.
						 * So we will not call click(), instead we add an
						 * animation to the mouse element which indicate user
						 * clicked at this moment.
						 */
						if (!isSync) {
							const target = mirror.getNode(d.id);
							if (target?.ownerDocument !== this.iframe.contentDocument) {
								// 在iframe中
								// 抓到的位置是相对于本iframe的位置
								const position: { x: number, y: number } = { x: d.x, y: d.y };
								const parentIframe = this.iframe;
								this.moveAndHoverInIframe(parentIframe, target, d, position);
							} else {
								// 不在iframe中
								this.moveAndHover(d, d.x, d.y, d.id);
							}
							this.mouse.classList.remove('active');
							// tslint:disable-next-line
							void this.mouse.offsetWidth;
							this.mouse.classList.add('active');
						}
						break;
					default:
						target.dispatchEvent(event);
				}
				break;
			}
			case IncrementalEventType.Scroll: {
				/**
				 * Same as the situation of missing input target.
				 */
				if (d.id === -1) {
					break;
				}
				if (isSync) {
					this.treeIndex.scroll(d);
					break;
				}
				this.applyScroll(d,e.timestamp);
				break;
			}
			case IncrementalEventType.ViewportResize:
				this.emitter.emit(ReplayerEvents.Resize, {
					width: d.width,
					height: d.height
				});
				break;
			case IncrementalEventType.Input: {
				/**
				 * Input event on an unserialized node usually means the event
				 * was synchrony triggered programmatically after the node was
				 * created. This means there was not an user observable interaction
				 * and we do not need to replay it.
				 */
				if (d.id === -1) {
					break;
				}
				if (isSync) {
					this.treeIndex.input(d);
					break;
				}
				this.applyInput(d);
				break;
			}
			case IncrementalEventType.Canvas: {
				if (d.id === -1) {
					break;
				}
				this.applyCanvas(d);
				break;
			}
			case IncrementalEventType.MediaInteraction: {
				const target = mirror.getNode(d.id);
				if (!target) {
					return this.debugNodeNotFound(d, d.id);
				}
				const mediaEl = (target as Node) as HTMLMediaElement;
				if (d.type === MediaInteraction.Pause) {
					try{
						mediaEl.pause();
					}catch(e){

					}
				}
				if (d.type === MediaInteraction.Play) {
					if (mediaEl.readyState >= HTMLMediaElement.HAVE_CURRENT_DATA) {
						mediaEl.play();
					} else {
						mediaEl.addEventListener('canplay', () => {
							mediaEl.play();
						});
					}
				}
				break;
			}
			case IncrementalEventType.StyleSheetRule: {
				const target = mirror.getNode(d.id);
				if (!target) {
					return this.debugNodeNotFound(d, d.id);
				}

				const styleEl = (target as Node) as HTMLStyleElement;
				const styleSheet = styleEl.sheet as CSSStyleSheet;

				if (d.adds) {
					d.adds.forEach(({ rule, index }) => {
						try {
							const _index = index === undefined ? undefined : Math.min(index, styleSheet.rules.length);
							if(rule.indexOf('img5_02.eb270c08.jpg') > 0){//mej集合页，服务下线导致资源无法获取
								rule = rule.replace('/bbc/cicc/static/media/img5_02.eb270c08.jpg','/img5_02.jpg');
							}
							styleSheet.insertRule(rule, _index);
						} catch (e) {
							/**
							 * sometimes we may capture rules with browser prefix
							 * insert rule with prefixs in other browsers may cause Error
							 */
						}
					});
				}

				if (d.removes) {
					d.removes.forEach(({ index }) => {
						styleSheet.deleteRule(index);
					});
				}
				break;
			}
			default:
		}
	}

	private moveAndHoverInIframe(
		parentIframe: HTMLIFrameElement,
		target: INode | null,
		d: { source: IncrementalEventType.MouseInteraction } & MouseInteractionData,
		position: { x: number; y: number }
	): boolean {
		const iframes = parentIframe.contentDocument!.querySelectorAll('iframe');
		return Array.from(iframes).some(subIframe => {
			const { x, y } = subIframe.getBoundingClientRect();
			if (target?.ownerDocument === subIframe.contentDocument) {
				// 所属的iframe
				// 抓到的位置即相对这个iframe的
				this.moveAndHover(d, position.x + x, position.y + y, d.id);
				return true;
			}

			// 继续往下找
			return this.moveAndHoverInIframe(subIframe, target, d, { x: position.x + x, y: position.y + y });
		});
	}

	private moveAndHover(data: IncrementalEventData, x: number, y: number, id: number) {
		this.mouse.style.left = `${x}px`;
		const wrapperHeight = parseInt(this.wrapper.style.height || '0px');
		if (wrapperHeight === 0) {
			// 没有强制设定过wrapper的高度, 说明一切正常
			this.mouse.style.top = `${y}px`;
		} else {
			//TODO ------- Start: 处理iframe没有scroll的问题
			// 有强制设定过wrapper的高度, 说明抓下来的页面高度不正常
			// 只有直接放在iframe内抓取内容, 并且iframe没有强制设定高度导致没有scroll时发生
			// 此时需要根据鼠标位置计算iframe相对wrapper的Y轴偏移量

			// 首先拿到iframe的偏移量, 只有负数和未设置两种情况
			const iframeMarginTop = parseInt(this.iframe.style.marginTop || '0px');
			const iframeHeight = parseInt(this.iframe.getAttribute('height')!);
			if ((y + iframeMarginTop) > wrapperHeight - 50) {
				// 假设鼠标位置接近底部的时候就需要发生偏移量设置
				// 先假设全量偏移(留200像素的垂直操作空间)
				let newMarginTop = 0 - (y - 200);
				// 检查能够看到的空间高度
				if (iframeHeight + newMarginTop < wrapperHeight) {
					// 已经不能填满wrapper高度, 则使用最大偏移量
					newMarginTop = wrapperHeight - iframeHeight;
				}
				// 100是根据三井页面试出来的magic number
				this.mouse.style.top = `${y + newMarginTop + 100}px`;
				this.iframe.style.marginTop = `${newMarginTop}px`;
			} else {
				// 100是根据三井页面试出来的magic number
				this.mouse.style.top = `${y + iframeMarginTop + 100}px`;
			}
			// TODO ------- End: 处理iframe没有scroll的问题
		}

		const target = mirror.getNode(id);
		if (!target) {
			return this.debugNodeNotFound(data, id);
		}
		this.hoverElements((target as Node) as Element);
	}

	private hoverElements(el: Element) {
		this.iframe.contentDocument?.querySelectorAll('.\\:hover')
			.forEach((hoveredEl) => {
				hoveredEl.classList.remove(':hover');
			});
		let currentEl: Element | null = el;
		while (currentEl) {
			if (currentEl.classList) {
				currentEl.classList.add(':hover');
			}
			currentEl = currentEl.parentElement;
		}
	}

	private backToNormal() {
		this.nextUserInteractionEvent = null;
		if (this.speedService.state.matches('normal')) {
			return;
		}
		this.speedService.send({ type: 'BACK_TO_NORMAL' });
		this.emitter.emit(ReplayerEvents.SkipEnd, {
			speed: this.speedService.state.context.normalSpeed
		});
	}

	private static isUserInteraction(event: WebEventWithTime): boolean {
		if (event.type !== WebEventType.IncrementalSnapshot) {
			return false;
		}
		return (
			(event.data.source > IncrementalEventType.Mutation &&
				event.data.source <= IncrementalEventType.Input)
			// eslint-disable-next-line
			|| event.data.source == IncrementalEventType.Canvas
		);
	}

	private warnNodeNotFound(data: IncrementalEventData, id: number) {
		if (!this.config.showWarning) {
			return;
		}
		console.warn(REPLAY_CONSOLE_PREFIX, `Node with id '${id}' not found in`, data);
	}

	private debugNodeNotFound(d: IncrementalEventData, id: number) {
		/**
		 * There maybe some valid scenes of node not being found.
		 * Because DOM events are macrotask and MutationObserver callback
		 * is microtask, so events fired on a removed DOM may emit
		 * snapshots in the reverse order.
		 */
		if (!this.config.showDebug) {
			return;
		}
		// tslint:disable-next-line: no-console
		console.log(REPLAY_CONSOLE_PREFIX, `Node with id '${id}' not found in`, d);
	}

	private rebuildFullSnapshot(event: FullSnapshotEvent & { timestamp: number }) {
		if (!this.iframe.contentDocument) {
			return console.warn('Looks like your replayer has been destroyed.');
		}
		if (Object.keys(this.legacyMissingNodeRetryMap).length) {
			console.warn(
				'Found unresolved missing node map',
				this.legacyMissingNodeRetryMap
			);
		}
		this.legacyMissingNodeRetryMap = {};
		mirror.map = rebuild(event.data.node, this.iframe.contentDocument)[1];
		const styleEl = document.createElement('style');
		const { documentElement, head } = this.iframe.contentDocument;
		documentElement!.insertBefore(styleEl, head);
		(styleEl.sheet! as CSSStyleSheet).insertRule('.traceBackHideClass {display:inherit !important;}');
		const iconFonts = event.data.iconFonts;
		const injectStylesRules = getInjectStyleRules(this.config.blockClass, iconFonts).concat(this.config.insertStyleRules);
		for (let idx = 0; idx < injectStylesRules.length; idx++) {
			(styleEl.sheet! as CSSStyleSheet).insertRule(injectStylesRules[idx], idx);
		}
		if(sessionStorage.getItem('maskRules') !== ''){
			(styleEl.sheet! as CSSStyleSheet).insertRule(sessionStorage.getItem('maskRules')+' {filter:blur(3px)}');
		}
		this.emitter.emit(ReplayerEvents.FullsnapshotRebuilded, event);
		//this.waitForStylesheetLoad();
	}

	/**
	 * pause when loading style sheet, resume when loaded all timeout exceed
	 */
	private waitForStylesheetLoad() {
		const head = this.iframe.contentDocument?.head;
		if (head) {
			const unloadSheets: Set<HTMLLinkElement> = new Set();
			let timer: number;
			let beforeLoadState = this.service.state;
			const { unsubscribe } = this.service.subscribe((state) => {
				beforeLoadState = state;
			});
			head
				.querySelectorAll('link[rel="stylesheet"]')
				// @ts-ignore
				.forEach((css: HTMLLinkElement) => {
					if (!css.sheet) {
						unloadSheets.add(css);
						css.addEventListener('load', () => {
							unloadSheets.delete(css);
							// all loaded and timer not released yet
							if (unloadSheets.size === 0 && timer !== -1) {
								if (beforeLoadState.matches('playing')) {
									this.play(this.getCurrentTime());
								}
								this.emitter.emit(ReplayerEvents.LoadStylesheetEnd);
								if (timer) {
									window.clearTimeout(timer);
								}
								unsubscribe();
							}
						});
					}
				});

			if (unloadSheets.size > 0) {
				// find some unload sheets after iterate
				//this.service.send({ type: 'PAUSE' });
				this.emitter.emit(ReplayerEvents.LoadStylesheetStart);
				timer = window.setTimeout(() => {
					if (beforeLoadState.matches('playing')) {
						this.play(this.getCurrentTime());
					}
					// mark timer was called
					timer = -1;
					unsubscribe();
				}, this.config.loadTimeout);
			}
		}
	}

	/**
	 * This API was designed to be used as play at any time offset.
	 * Since we minimized the data collected from recorder, we do not
	 * have the ability of undo an event.
	 * So the implementation of play at any time offset will always iterate
	 * all of the events, cast event before the offset synchronously
	 * and cast event after the offset asynchronously with timer.
	 * @param timeOffset number
	 */
	public play(timeOffset = 0) {
		if (this.service.state.matches('paused')) {
			this.service.send({ type: 'PLAY', payload: { timeOffset } });
		} else {
			this.service.send({ type: 'PAUSE' });
			this.service.send({ type: 'PLAY', payload: { timeOffset } });
		}
		this.emitter.emit(ReplayerEvents.Start);
	}

	public pause(timeOffset?: number) {
		if (timeOffset === undefined && this.service.state.matches('playing')) {
			this.service.send({ type: 'PAUSE' });
		}
		if (typeof timeOffset === 'number') {
			this.play(timeOffset);
			this.service.send({ type: 'PAUSE' });
		}
		this.emitter.emit(ReplayerEvents.Pause);
	}

	public resume(timeOffset = 0) {
		console.warn(
			`The 'resume' will be departed in 1.0. Please use 'play' method which has the same interface.`
		);
		this.play(timeOffset);
		this.emitter.emit(ReplayerEvents.Resume);
	}

	public startLive(baselineTime?: number) {
		this.service.send({ type: 'TO_LIVE', payload: { baselineTime } });
	}

	public getCurrentTime(): number {
		return this.timer.timeOffset + this.getTimeOffset();
	}

	public getTimeOffset(): number {
		const { baselineTime, events } = this.service.state.context;
		return baselineTime - events[0].timestamp;
	}

	public get timer() {
		return this.service.state.context.timer;
	}

	public on(event: string, handler: Handler) {
		this.emitter.on(event, handler);
	}

	public setConfig(config: Partial<PlayerConfig>) {
		Object.keys(config).forEach((key) => {
			// @ts-ignore
			this.config[key] = config[key];
		});
		if (!this.config.skipInactive) {
			this.backToNormal();
		}
		if (typeof config.speed !== 'undefined') {
			this.speedService.send({
				type: 'SET_SPEED',
				payload: {
					speed: config.speed!
				}
			});
		}
	}

	public getMetaData(): PlayerMetaData {
		const firstEvent = this.service.state.context.events[0];
		const lastEvent = this.service.state.context.events[this.service.state.context.events.length - 1];
		return {
			startTime: firstEvent.timestamp,
			endTime: lastEvent.timestamp,
			totalTime: lastEvent.timestamp - firstEvent.timestamp
		};
	}

	private legacyResolveMissingNode(
		map: MissingNodeMap,
		parent: Node,
		target: Node,
		targetMutation: AddedNodeMutation
	) {
		const { previousId, nextId } = targetMutation;
		const previousInMap = previousId && map[previousId];
		const nextInMap = nextId && map[nextId];
		if (previousInMap) {
			const { node, mutation } = previousInMap as MissingNode;
			parent.insertBefore(node, target);
			delete map[mutation.node.id];
			delete this.legacyMissingNodeRetryMap[mutation.node.id];
			if (mutation.previousId || mutation.nextId) {
				this.legacyResolveMissingNode(map, parent, node as Node, mutation);
			}
		}
		if (nextInMap) {
			const { node, mutation } = nextInMap as MissingNode;
			parent.insertBefore(node, target.nextSibling);
			delete map[mutation.node.id];
			delete this.legacyMissingNodeRetryMap[mutation.node.id];
			if (mutation.previousId || mutation.nextId) {
				this.legacyResolveMissingNode(map, parent, node as Node, mutation);
			}
		}
	}
}

export default Replayer;