export interface FetchOptions {
	method?: 'GET' | 'POST';
	headers?: { [key in string]: string };
	credentials?: RequestCredentials;
	mode?: RequestMode;
	redirect?: RequestRedirect;
	cache?: RequestCache;
	ignoreAuth?: true
}

export interface CorrectResponse {
	returnCode: 'RC-00001',
	headers: { [key in string]: string },
	body: any,
	status: 200,
	statusText: string
}

const AJAX_SERVER_CONTEXT = process.env.REACT_APP_AJAX_SERVER_CONTEXT ? process.env.REACT_APP_AJAX_SERVER_CONTEXT.substr(1) : undefined;
const SERVER_CONTEXT = process.env.ENV_BACKEND_CONTEXT || AJAX_SERVER_CONTEXT || 'glutton';

const getServiceLocation = (host: string, relativePath: string): string => {
	if (relativePath && (relativePath.startsWith('https://') || relativePath.startsWith('http://'))) {
		// 已经是绝对路径
		return relativePath;
	}

	const prodURL = (host.startsWith('http://') || host.startsWith('https://')) ? `${host}/${SERVER_CONTEXT}` : `https://${host}/${SERVER_CONTEXT}`;
	if (prodURL) {
		return prodURL + relativePath;
	} else {
		console.error("No Glutton Request Domain URL indicated.");
		return "";
	}
};

const getResponseHeaders = (response: Response) => {
	const headers: { [key in string]: string } = {};
	response.headers.forEach((value: string, key: string) => {
		headers[key] = value;
	});
	return headers;
};

const createCorrectResponseData = (data: any, responseHeaders: { [key in string]: string }, response?: Response) => {
	return {
		returnCode: data.returnCode,
		headers: responseHeaders,
		body: data.body,
		status: 200,
		statusText: response?.statusText
	} as CorrectResponse;
};

const createHandledErrorResponseData = (data: any, responseHeaders: { [key in string]: string }, status: number, response?: Response) => {
	return {
		headers: responseHeaders,
		returnCode: data.returnCode,
		errors: data.errors,
		status,
		statusText: response?.statusText
	};
};

const createUnhandledErrorResponseData = (
	responseHeaders: { [key in string]: string },
	status: number,
	errorCode: string,
	e?: any,
	response?: Response
) => {
	return {
		headers: responseHeaders,
		returnCode: 'RC-99999',
		errors: [ { code: errorCode } ],
		status,
		statusText: response?.statusText,
		originError: e
	};
};

const hasWX = () => {
	try {
		// @ts-ignore
		return wx && wx.request && (typeof wx.request === 'function');
	} catch {
		return false;
	}
};

export const doFetch = async (host: string, url: string, token: string, options: FetchOptions, data?: any): Promise<CorrectResponse> => {
	options.headers = {
		'Content-Type': 'application/json',
		...(options.headers || {})
	};

	if (!options.ignoreAuth) {
		if (token) {
			options.headers.Authorization = token!;
		}
	}


	const opts = {
		method: (options.method || 'GET').toUpperCase(), //请求方式
		headers: options.headers, // 请求头设置
		credentials: options.credentials || 'same-origin', // 设置cookie是否一起发送
		mode: options.mode || 'cors', // 可以设置 cors, no-cors, same-origin
		redirect: options.redirect || 'follow', // follow, error, manual
		cache: options.cache || 'default', // 设置 cache 模式 (default, reload, no-cache)
		body: (options.method || '').toUpperCase() === 'POST' ? JSON.stringify(data || {}) : undefined
	};

	if (hasWX()) {
		// 微信小程序环境
		return new Promise((resolve, reject) => {
			// @ts-ignore
			wx.request({
				url: getServiceLocation(host, url),
				method: opts.method,
				header: options.headers,
				data: opts.body,
				success: (res: { data: any, statusCode: number, header: { [key in string]: string } }) => {
					const { data, statusCode, header } = res;
					if (statusCode === 200) {
						try {
							if (data.returnCode === 'RC-00001') {
								resolve(createCorrectResponseData(data, header));
							} else {
								reject(createHandledErrorResponseData(data, header, statusCode));
							}
						} catch (e) {
							reject(createUnhandledErrorResponseData(header, statusCode, 'PARSE_ERROR', e));
						}
					} else {
						reject(createUnhandledErrorResponseData(header, statusCode, 'FETCH_ERROR', null));
					}
				},
				fail: (e: any) => {
					reject(createUnhandledErrorResponseData({}, 0, 'FETCH_ERROR', e));
				}
			});
		});
	} else {
		// 一般浏览器环境
		return new Promise((resolve, reject) => {
			fetch(getServiceLocation(host, url), opts as any)
				.then((response: Response) => {
					const responseHeaders = getResponseHeaders(response);

					// 设置登录串
					const token = responseHeaders.authorization;
					if (token && token.startsWith('Glutton ')) {
						const [ accountName ] = atob(token.substr('Glutton '.length)).split(':');
						sessionStorage.setItem("glutton-account", JSON.stringify({
							accountName,
							token,
							isAuthorized: true
						}));
					}

					if (response.ok) {
						response.json().then(data => {
							if (data.returnCode === 'RC-00001') {
								resolve(createCorrectResponseData(data, responseHeaders, response));
							} else {
								reject(createHandledErrorResponseData(data, responseHeaders, response.status, response));
							}
						}).catch(e => {
							reject(createUnhandledErrorResponseData(responseHeaders, response.status, 'PARSE_ERROR', e, response));
						});
					} else {
						// 返回码是不成功, 以reject结束
						reject(createUnhandledErrorResponseData(responseHeaders, response.status, 'FETCH_ERROR', null, response));
					}
				})
				.catch((e: Error) => {
					reject(createUnhandledErrorResponseData({}, 0, 'FETCH_ERROR', e));
				});
		});
	}
};

export const doGet = async (host: string, url: string, token: string): Promise<CorrectResponse> => {
	return doFetch(host, url, token, {});
};

export const doPost = (host: string, url: string, token: string, body: any, options?: FetchOptions): Promise<CorrectResponse> => {
	return doFetch(host, url, token, { ...(options || {}), method: 'POST' }, body);
};