import { faUndoAlt,faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { doPost } from '../service/ajax';
import {TraceData} from "../service/trace-service";
import Account from "../service/account-model";
import {OssStsToken} from "../service/oss-service";
import Path from "../path";

const Container = styled.div`
	display: flex;
`;
const Title = styled.div`
	position: relative;
	line-height: var(--title-height);
	font-size: var(--title-font-size);
	font-weight: 600;
	user-select: none;
	padding-left: var(--gap);
	margin-bottom: var(--gap);
	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 25%;
		left: 0;
		width: 5px;
		height: 50%;
		border-radius: 2px;
		background-color: var(--title-border-color);
	}
`;
const Placeholder = styled.div`
	flex-grow: 1;
`;
const Back = styled.div`
	height: var(--title-height);
	display: flex;
	align-items: center;
	cursor: pointer;
	&:hover {
		color: var(--primary-color);
	}
	> svg {
		margin-right: var(--gap-thinner);
	}
`;

const QualityInspectionInput = styled.div`
	height: var(--title-height);
	display: flex;
	align-items: center;
	font-size:x-large;
	cursor: pointer;
	&:hover {
		color: var(--primary-color);
	}
	> svg {
		margin-right: var(--gap-thinner);
	}
`;

export default (props: { title: string, back?: boolean, trace?: TraceData | null,account?:Account | null
	,ossToken?:OssStsToken | null,taskName?:string,createAt?:string}) => {
	const { title, back = false, trace, createAt,taskName } = props;

	const history = useHistory();

	return <Container>
		<Title>{title}</Title>
		<Placeholder/>
		{document.getElementsByClassName('replayer-wrapper')!.length > 0 && !taskName ? <Back id='downloadBtn' onClick={
			async () => {
				console.log(trace);
				if(document.getElementById('downloadBtn')!.style.cursor == 'not-allowed'){
					return;
				}
				const response = await doPost('/api/trace/download', [trace]);
				alert(response.body);
				document.getElementById('downloadBtn')!.style.cursor = 'not-allowed';
			}}>
			<FontAwesomeIcon icon={faDownload}/>
			<div>合成视频</div>
		</Back> : null}
		{taskName && trace?.traceNo && createAt? <QualityInspectionInput onClick={
			async () => {
				history.push(Path.QUALITY_INSPECTION_ADD.replace(':traceNo', trace?.traceNo)
					.replace(':taskName',taskName)
					.replace(':createAt',createAt))
			}}>
			<FontAwesomeIcon icon={faDownload}/>
			<div>质检录入</div>
			</QualityInspectionInput> : null
		}
		{/*{back ? <Back onClick={() => history.goBack()}>
			<FontAwesomeIcon icon={faUndoAlt}/>
			<div>返回</div>
		</Back> : null}*/}
	</Container>;
}