export type DeviceOS = {
	android?: boolean;
	ios?: boolean;
	iphone?: boolean;
	ipad?: boolean;
	ipod?: boolean;
	wp?: boolean;
	webos?: boolean;
	touchpad?: boolean;
	blackberry?: boolean;
	bb10?: boolean;
	rimtabletos?: boolean;
	kindle?: boolean;
	firefoxos?: boolean;
	version?: string;
	tablet?: boolean;
	phone?: boolean;
	desk?: boolean;
	app?: boolean;
	wechat?: boolean;
	wechatMini?: boolean;
}
export type DeviceBrowser = {
	webkit?: boolean;
	playbook?: boolean;
	silk?: boolean;
	chrome?: boolean;
	firefox?: boolean;
	ie?: boolean;
	edge?: boolean;
	safari?: boolean;
	webview?: boolean;
	version?: string;
};

const detectDevice = (): [ DeviceOS, DeviceBrowser ] => {
	const ua = window.navigator.userAgent;
	const platform = window.navigator.platform;

	const os: DeviceOS = {},
		browser: DeviceBrowser = {},
		// eslint-disable-next-line
		webkit: any = ua.match(/Web[kK]it[\/]{0,1}([\d.]+)/),
		// eslint-disable-next-line
		android = ua.match(/(Android);?[\s\/]+([\d.]+)?/),
		// mac
		// eslint-disable-next-line
		osx = !!ua.match(/\(Macintosh\; Intel /) || ua.match(/(Mac)/),
		ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
		ipod = ua.match(/(iPod)(.*OS\s([\d_]+))?/),
		iphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
		// eslint-disable-next-line
		webos = ua.match(/(webOS|hpwOS)[\s\/]([\d.]+)/),
		win = /Win\d{2}|Windows/.test(platform) || ua.match(/(win)/),
		wp = ua.match(/Windows Phone ([\d.]+)/),
		touchpad = webos && ua.match(/TouchPad/),
		kindle = ua.match(/Kindle\/([\d.]+)/),
		silk = ua.match(/Silk\/([\d._]+)/),
		blackberry = ua.match(/(BlackBerry).*Version\/([\d.]+)/),
		bb10 = ua.match(/(BB10).*Version\/([\d.]+)/),
		rimtabletos = ua.match(/(RIM\sTablet\sOS)\s([\d.]+)/),
		playbook = ua.match(/PlayBook/),
		chrome = ua.match(/Chrome\/([\d.]+)/) || ua.match(/CriOS\/([\d.]+)/),
		firefox = ua.match(/Firefox\/([\d.]+)/),
		firefoxos = ua.match(/\((?:Mobile|Tablet); rv:([\d.]+)\).*Firefox\/[\d.]+/),
		ie =
			ua.match(/MSIE\s([\d.]+)/) ||
			// eslint-disable-next-line
			ua.match(/Trident\/[\d](?=[^\?]+).*rv:([0-9.].)/),
		edge = ua.match(/(edge)\/([\w.]+)/),
		appleWebview = !chrome && ua.match(/(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/),
		safari = appleWebview || ua.match(/Version\/([\d.]+)([^S](Safari)|[^M]*(Mobile)[^S]*(Safari))/),
		linux = ua.match(/(Linux)/),
		// chrome os
		cros = ua.match(/(CrOS)/),
		// eslint-disable-next-line
		androidWebview = ua.match(/(wv\))/);

	if ((browser.webkit = !!webkit)) {
		browser.version = webkit[1];
	}

	if (android) {
		os.android = true;
		os.version = android[2];
	}
	if (iphone && !ipod) {
		os.ios = os.iphone = true;
		os.version = iphone[2].replace(/_/g, '.');
	}
	if (ipad) {
		os.ios = os.ipad = true;
		os.version = ipad[2].replace(/_/g, '.');
	}
	if (ipod) {
		os.ios = os.ipod = true;
		os.version = ipod[3] ? ipod[3].replace(/_/g, '.') : undefined;
	}
	if (wp) {
		os.wp = true;
		os.version = wp[1];
	}
	if (webos) {
		os.webos = true;
		os.version = webos[2];
	}
	if (touchpad) {
		os.touchpad = true;
	}
	if (blackberry) {
		os.blackberry = true;
		os.version = blackberry[2];
	}
	if (bb10) {
		os.bb10 = true;
		os.version = bb10[2];
	}
	if (rimtabletos) {
		os.rimtabletos = true;
		os.version = rimtabletos[2];
	}
	if (playbook) {
		browser.playbook = true;
	}
	if (kindle) {
		os.kindle = true;
		os.version = kindle[1];
	}
	if (silk) {
		browser.silk = true;
		browser.version = silk[1];
	}
	if (!silk && os.android && ua.match(/Kindle Fire/)) {
		browser.silk = true;
	}
	if (chrome) {
		browser.chrome = true;
		browser.version = chrome[1];
	}
	if (firefox) {
		browser.firefox = true;
		browser.version = firefox[1];
	}
	if (firefoxos) {
		os.firefoxos = true;
		os.version = firefoxos[1];
	}
	if (ie) {
		browser.ie = true;
		browser.version = ie[1];
	}
	if (edge) {
		browser.edge = true;
	}
	if (safari && (osx || os.ios || win)) {
		browser.safari = true;
		if (!os.ios) {
			browser.version = safari[1];
		}
	}
	os.tablet = !!(
		ipad ||
		playbook ||
		(android && !ua.match(/Mobile/)) ||
		(firefox && ua.match(/Tablet/)) ||
		(ie && !ua.match(/Phone/) && ua.match(/Touch/))
	);
	os.phone = !!(
		!os.tablet &&
		!os.ipod &&
		(android ||
			iphone ||
			webos ||
			blackberry ||
			bb10 ||
			(chrome && ua.match(/Android/)) ||
			(chrome && ua.match(/CriOS\/([\d.]+)/)) ||
			(firefox && ua.match(/Mobile/)) ||
			(ie && ua.match(/Touch/)))
	);
	os.desk = !!(osx || cros || win || linux) && !os.tablet && !os.phone;

	return [ os, browser ];
};

const [ os, browser ] = detectDevice();

export {
	os,
	browser,
};