import { faCompressArrowsAlt, faExpandArrowsAlt, faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect, useReducer, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Replayer from '../catcher/rrweb-replay/replay';
import { IncrementalEventType, MetaEvent, WebEventType, WebEventWithTime } from '../catcher/rrweb/types';
import { TIME_OFFSET_CHANGE, useTimeOffset } from './trace-view-context';

type CurrentSpeedState = 'normal' | 'skipping';
type CurrentPlayerState = 'playing' | 'paused' | 'live';

// TODO 目前不需要设置播放器能容纳的最大高度, 因此值设置为6个9
const MAX_TIME_MACHINE_HEIGHT = 999999;

const Player = styled.div`
   	position: relative;
    background-color: var(--video-bg-color);
    border-radius: var(--radius);
    box-shadow: 0 24px 48px rgba(17, 16, 62, 0.12);
	width: 100%;
	min-width: 800px;
    height: 755px;
    float: left;
`;
const PlayerFrameMouseClick = keyframes`
	0% {
		opacity: 0.3;
		width: 20px;
		height: 20px;
		border-radius: 10px;
		transform: translate(-10px, -10px);
	}
	50% {
		opacity: 0.5;
		width: 10px;
		height: 10px;
		border-radius: 5px;
		transform: translate(-5px, -5px);
	}
`;
const PlayerFrame = styled.div`
	overflow: hidden;
	//border-radius: var(--radius);
	//position: relative;
	width: 100%;
	min-width: 800px;
	height: 675px;
	> .replayer-wrapper {
		position: relative;
		float: left;
		clear: both;
		transform-origin: top left;
		left: 50%;
		top: 50%;
		transform: scale(${props => (props as any).scale}) translate(-50%, -50%);
		> .replayer-mouse {
			position: absolute;
			width: 20px;
			height: 20px;
			transition: 0.05s linear;
			background-size: contain;
			background-position: center center;
			background-repeat: no-repeat;
			background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMDAwMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGRhdGEtbmFtZT0iTGF5ZXIgMSIgdmlld0JveD0iMCAwIDUwIDUwIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPkRlc2lnbl90bnA8L3RpdGxlPjxwYXRoIGQ9Ik00OC43MSw0Mi45MUwzNC4wOCwyOC4yOSw0NC4zMywxOEExLDEsMCwwLDAsNDQsMTYuMzlMMi4zNSwxLjA2QTEsMSwwLDAsMCwxLjA2LDIuMzVMMTYuMzksNDRhMSwxLDAsMCwwLDEuNjUuMzZMMjguMjksMzQuMDgsNDIuOTEsNDguNzFhMSwxLDAsMCwwLDEuNDEsMGw0LjM4LTQuMzhBMSwxLDAsMCwwLDQ4LjcxLDQyLjkxWm0tNS4wOSwzLjY3TDI5LDMyYTEsMSwwLDAsMC0xLjQxLDBsLTkuODUsOS44NUwzLjY5LDMuNjlsMzguMTIsMTRMMzIsMjcuNThBMSwxLDAsMCwwLDMyLDI5TDQ2LjU5LDQzLjYyWiI+PC9wYXRoPjwvc3ZnPg==');
			&:after {
				content: '';
				display: inline-block;
				width: 20px;
				height: 20px;
				border-radius: 10px;
				background: rgb(73, 80, 246);
				transform: translate(-10px, -10px);
				opacity: 0.3;
			}
			&.active:after {
				animation: ${PlayerFrameMouseClick} 0.2s ease-in-out 1;
			}
		}
		> .replayer-dateText {
			position: absolute;
			color:rgba(247, 245, 245, 0.83);
			background-color:rgba(9, 9, 9, 0.44);
		}
		> iframe {
			border: none;
			background-color: var(--player-iframe-bg-color);
		}
	}
`;
const Controller = styled.div`
  	width: 100%;
    height: 80px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-radius: 0 0 5px 5px;
`;
const Timeline = styled.div`
	width: 80%;
    display: flex;
    align-items: center;
`;
const TimelineTime = styled.span`
	display: inline-block;
    width: 100px;
    text-align: center;
    color: #11103e;
`;
const TimelineProgress = styled.div`
	flex: 1;
    height: 12px;
    background: #eee;
    position: relative;
    border-radius: 3px;
    cursor: pointer;
    box-sizing: border-box;
    border-top: solid 4px #fff;
    border-bottom: solid 4px #fff;
    &[data-disabled=true] {
    	cursor: not-allowed;
    }
`;
const TimelineProgressStep = styled.div`
   	height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: var(--primary-color-hover);
    pointer-events: none;
`;
const TimelineProgressHandler = styled.div`
	width: 20px;
    height: 20px;
    border-radius: 10px;
    position: absolute;
    top: 2px;
    transform: translate(-50%, -50%);
    background: var(--primary-color);
    pointer-events: none;
`;
const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
`;
const Button = styled.button`
	width: 32px;
    height: 32px;
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    outline: none;
    &:hover {
    	color: var(--invert-color);
    	background-color: var(--primary-color-hover);
    }
    &:active {
    	color: var(--invert-color);
    	background-color: var(--primary-color-active);
    }
    &[data-active=true] {
	    color: var(--invert-color);
	    background: var(--primary-color);
    }
    &:disabled {
    	cursor: not-allowed;
    }
    &:not(:first-child) {
    	margin-left: var(--gap-thin);
    }
`;
const Switch = styled.div`
   	height: 1em;
    display: flex;
    align-items: center;
    margin-left: var(--gap);
    &[data-disabled=true] {
   		opacity: 0.5;
   		label {
   			cursor: not-allowed;
   		}
    }
  	input[type='checkbox'] {
	    position: absolute;
	    opacity: 0;
	    &:checked + label:before {
	    	background-color: var(--primary-color);
	    }
	    &:checked + label:after {
	    	animation: switch-on 0.2s ease-out;
    		left: 1.1em;
	    }
  	}
    label {
	    width: 2em;
	    height: 1em;
	    position: relative;
	    cursor: pointer;
	    display: block;
	    &:before {
			content: '';
		    position: absolute;
		    width: 2em;
		    height: 1em;
		    left: 0.1em;
		    transition: all 0.1s ease;
		    background: var(--waive-color);
		    border-radius: 50px;
	    }
	    &:after {
	    	content: '';
		    position: absolute;
		    width: 1em;
		    height: 1em;
		    border-radius: 50px;
		    left: 0;
		    transition: all 0.2s ease;
		    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
		    background-color: var(--invert-color);
		    animation: switch-off 0.2s ease-out;
		    z-index: 2;
	    }
    }
    span.label {
    	margin: 0 8px;
  	}	
`;

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const padZero = (num: number, len = 2): string => {
	let str = String(num);
	const threshold = Math.pow(10, len - 1);
	if (num < threshold) {
		while (String(threshold).length > str.length) {
			str = '0' + num;
		}
	}
	return str;
};

const formatTime = (ms: number): string => {
	if (ms <= 0) {
		return '00:00';
	}
	const hour = Math.floor(ms / HOUR);
	ms = ms % HOUR;
	const minute = Math.floor(ms / MINUTE);
	ms = ms % MINUTE;
	const second = Math.floor(ms / SECOND);
	if (hour) {
		return `${padZero(hour)}:${padZero(minute)}:${padZero(second)}`;
	}
	return `${padZero(minute)}:${padZero(second)}`;
};

const isFullScreen = (): boolean => {
	return (
		document.fullscreen ||
		// @ts-ignore
		document.webkitIsFullScreen ||
		// @ts-ignore
		document.mozFullScreen ||
		// @ts-ignore
		document.msFullscreenElement
	);
};
const closeFullScreen = (): Promise<void> => {
	if (document.exitFullscreen) {
		return document.exitFullscreen();
		// @ts-ignore
	} else if (document.mozExitFullscreen) {
		/* Firefox */
		// @ts-ignore
		return document.mozExitFullscreen();
		// @ts-ignore
	} else if (document.webkitExitFullscreen) {
		/* Chrome, Safari and Opera */
		// @ts-ignore
		return document.webkitExitFullscreen();
		// @ts-ignore
	} else if (document.msExitFullscreen) {
		/* IE/Edge */
		// @ts-ignore
		return document.msExitFullscreen();
	} else {
		return Promise.resolve();
	}
};
const openFullScreen = (el: HTMLElement): Promise<void> => {
	if (el.requestFullscreen) {
		return el.requestFullscreen();
		// @ts-ignore
	} else if (el.mozRequestFullScreen) {
		/* Firefox */
		// @ts-ignore
		return el.mozRequestFullScreen();
		// @ts-ignore
	} else if (el.webkitRequestFullscreen) {
		/* Chrome, Safari and Opera */
		// @ts-ignore
		return el.webkitRequestFullscreen();
		// @ts-ignore
	} else if (el.msRequestFullscreen) {
		/* IE/Edge */
		// @ts-ignore
		return el.msRequestFullscreen();
	} else {
		return Promise.resolve();
	}
};
const onFullScreenChange = (handler: () => void): () => void => {
	document.addEventListener('fullscreenchange', handler);
	document.addEventListener('webkitfullscreenchange', handler);
	document.addEventListener('mozfullscreenchange', handler);
	document.addEventListener('MSFullscreenChange', handler);

	return () => {
		document.removeEventListener('fullscreenchange', handler);
		document.removeEventListener('webkitfullscreenchange', handler);
		document.removeEventListener('mozfullscreenchange', handler);
		document.removeEventListener('MSFullscreenChange', handler);
	};
};

let timer: number | null = null;

const WebPlayer = (props: { autoPlay: boolean, events: Array<WebEventWithTime> }) => {
	const { autoPlay = true, events } = props;

	const playerRef = useRef<HTMLDivElement>(null);
	const rootRef = useRef<HTMLDivElement>(null);
	const switchRef = useRef<HTMLInputElement>(null);
	const [ , forceUpdate ] = useReducer(x => x + 1, 0);
	const [ player, setPlayer ] = useState<Replayer | null>(null);
	const [ finished, setFinished ] = useState<boolean>(false);
	const [ speedState ] = useState<CurrentSpeedState>('normal');
	const [ playerState, setPlayerState ] = useState<CurrentPlayerState>('paused');
	const [ scale, setScale ] = useState<{ ratio: number, width: number, height: number }>({
		ratio: 1,
		width: 1,
		height: 1
	});
	const [ speed, setSpeed ] = useState<number>(1);
	const [ fullScreen, setFullScreen ] = useState<boolean>(isFullScreen());

	const doSetSpeed = (replayer: Replayer, newSpeed: number) => {
		let needFreeze = playerState === 'playing';
		const speed = newSpeed;
		if (needFreeze) {
			replayer.pause();
		}
		replayer.setConfig({ speed });
		if (needFreeze) {
			replayer.play(replayer.getCurrentTime());
		}
		setSpeed(newSpeed);
	};
	const loopTimer = (player: Replayer) => {
		stopTimer();

		const update = () => {
			const currentTime = player.getCurrentTime();
			const shouldUpdate = currentTime < player.getMetaData().totalTime;
			// console.log(`currentTime=${currentTime}, totalTime=${player.getMetaData().totalTime}, should update=${shouldUpdate}`);
			if (shouldUpdate) {
				timer = requestAnimationFrame(update);
				forceUpdate();
			}
		};

		timer = requestAnimationFrame(update);
		forceUpdate();
	};
	const stopTimer = () => {
		if (timer) {
			cancelAnimationFrame(timer);
			timer = null;
		}
	};
	const updateScale = (fullscreen: boolean, width: number, height: number): void => {
		if (!rootRef.current) {
			return;
		}
		setTimeout(() => {
			const playerNode = playerRef.current!;
			const rootNode = rootRef.current!;
			if (fullscreen) {
				playerNode.style.width = `${playerNode.offsetWidth}px`;
				playerNode.style.height = `${playerNode.offsetHeight}px`;
				rootNode.style.width = `${playerNode.offsetWidth}px`;
				rootNode.style.height = `${playerNode.offsetHeight - 80}px`;
			} else {
				const playerNode = playerRef.current!;
				playerNode.style.width = '';
				playerNode.style.height = '';
				rootNode.style.width = '';
				rootNode.style.height = '';
			}
			const rect = rootNode.getBoundingClientRect();
			const meta = { data: { width, height } };
			if (meta) {
				const horizontalScale = rect.width / meta.data.width;
				const verticalScale = rect.height / Math.min(meta.data.height, MAX_TIME_MACHINE_HEIGHT);
				const scale = Math.min(horizontalScale, verticalScale, 1);
				// meta的高度超出了最大高度
				// 原因为录制脚本是在iframe中进行, 并且由于iframe没有强制设置高度, 导致内容高度超出viewport高度
				// 此时需要强制调整最大高度, 以便内容可以正确的定格显示
				if (meta.data.height > MAX_TIME_MACHINE_HEIGHT) {
					(rootNode.querySelector('.replayer-wrapper')! as HTMLDivElement).style.height = `${MAX_TIME_MACHINE_HEIGHT}px`;
				}
				setScale({ ratio: scale, width: meta.data.width, height: meta.data.height });
			} else {
				setScale({ ratio: 1, width: 1, height: 1 });
			}
		}, 50);
	};
	useEffect(() => {
		if (rootRef.current && !player && events.length !== 0) {
			// console.log(JSON.stringify(events));
			// TODO ------- Start: 处理iframe高度没有正确抓取的问题, 仅在iframe高度超过最大高度时需要
			const meta = events.find(event => event.type === WebEventType.Meta) as MetaEvent;
			if (meta && Math.min(meta.data.height, MAX_TIME_MACHINE_HEIGHT) === MAX_TIME_MACHINE_HEIGHT) {
				events
					.filter(event => event.type === WebEventType.Meta)
					.forEach((meta: any) => meta.data.height = meta.data.height + 200);
				events
					.filter(event => event.type === WebEventType.IncrementalSnapshot && event.data.source === IncrementalEventType.ViewportResize)
					.forEach((meta: any) => meta.data.height = meta.data.height + 200);
			}
			// TODO ------- End: 处理iframe高度没有正确抓取的问题
			const createPlayer = (): Replayer => {
				const player = new Replayer(events, { root: rootRef.current! });
				player.on('resize', (event) => {
					const { width, height } = event as { width: number, height: number };
					updateScale(isFullScreen(), width, height);
				});
				player.on('finish', () => {
					setFinished(true);
				});
				return player;
			};
			const newPlayer = createPlayer();
			setPlayer(newPlayer);
			if (meta) {
				updateScale(false, meta.data.width, meta.data.height);
			} else {
				updateScale(false, 1, 1);
			}

			if (autoPlay) {
				newPlayer.play();
				setPlayerState('playing');
				loopTimer(newPlayer);
			}

			return () => {
				stopTimer();
				newPlayer && newPlayer.pause();
			};
		} else if (!player) {
			setTimeout(forceUpdate, 1000);
		}
		// eslint-disable-next-line
	}, [ 0 ]);

	const toTimeOffset = (timeOffset: number): void => {
		const isPlaying = playerState === 'playing';
		player!.pause();
		player!.play(timeOffset);
		if (!isPlaying) {
			player!.pause();
		}
		forceUpdate();
	};

	const onTimeOffsetManuallyChanged = (timeOffset: number): void => {
		if (speedState === 'skipping') {
			return;
		}
		const totalTime = player!.getMetaData().totalTime;
		if (timeOffset < 0) {
			timeOffset = 0;
		} else if (timeOffset > totalTime) {
			// 在最后1秒处开始
			timeOffset = totalTime - 1000;
		}
		toTimeOffset(timeOffset);
	};

	const onTimelineProgressClicked = (event: React.MouseEvent): void => {
		if (speedState === 'skipping') {
			return;
		}
		const progress = event.target as HTMLDivElement;
		const progressRect = progress.getBoundingClientRect();
		const x = event.clientX - progressRect.left;
		let percent = x / progressRect.width;
		if (percent < 0) {
			percent = 0;
		} else if (percent > 1) {
			percent = 1;
		}
		const timeOffset = player!.getMetaData().totalTime * percent;
		toTimeOffset(timeOffset);
	};

	const { emitter } = useTimeOffset();
	useEffect(() => {
		const onTimeOffsetChanged = (timeOffsetInMillisecond: number) => {
			onTimeOffsetManuallyChanged(timeOffsetInMillisecond);
		};
		emitter.on(TIME_OFFSET_CHANGE, onTimeOffsetChanged);
		return () => {
			emitter.off(TIME_OFFSET_CHANGE, onTimeOffsetChanged);
		};
	});

	const createController = (): JSX.Element => {
		if (!player) {
			return <Fragment/>;
		}

		const doResume = () => {
			if (finished) {
				player!.play();
				setFinished(false);
			} else {
				player!.play(player!.getCurrentTime() < 0 ? 0 : player!.getCurrentTime());
			}
			loopTimer(player!);
			setPlayerState('playing');
		};
		const doPause = () => {
			stopTimer();
			player!.pause();
			setPlayerState('paused');
		};
		// @ts-ignore
		window.doResume = doResume;
		// @ts-ignore
		window.doPause = doPause;

		const onSpeedChanged = () => {
			const target = switchRef.current!;
			const skipInactive = !target.checked;
			target.checked = skipInactive;
			if (skipInactive !== player.config.skipInactive) {
				player.setConfig({ skipInactive });
			}
		};
		const enterFullScreen = async () => {
			await openFullScreen(playerRef.current!);
			updateScale(true, scale.width, scale.height);
			onFullScreenChange(() => {
				if (!isFullScreen()) {
					setFullScreen(false);
					updateScale(false, scale.width, scale.height);
				} else {
					setFullScreen(true);
					updateScale(true, scale.width, scale.height);
				}
			});
			setFullScreen(true);
		};
		const exitFullScreen = async () => {
			await closeFullScreen();
		};

		const meta = player.getMetaData();
		const currentTime = player.getCurrentTime();
		const percent = Math.min(1, currentTime / meta.totalTime);
		const percentage = `${100 * percent}%`;

		return <Controller>
			<Timeline>
				<TimelineTime>{formatTime(currentTime)}</TimelineTime>
				<TimelineProgress data-disabled={speedState === 'skipping'} onClick={onTimelineProgressClicked}>
					<TimelineProgressStep style={{ width: percentage }}/>
					{/*{#each customEvents as event}*/}
					{/*		<div title={event.name}*/}
					{/*		     style="width: 10px;height: 5px;position: absolute;top:*/}
					{/*2px;transform: translate(-50%, -50%);background: {event.background};left:*/}
					{/*{event.position};"/>*/}
					{/*{/each}*/}
					<TimelineProgressHandler style={{ left: percentage }}/>
				</TimelineProgress>
				<TimelineTime>{formatTime(meta.totalTime)}</TimelineTime>
			</Timeline>
			<Buttons>
				{(playerState === 'playing' && !finished)
					? <Button onClick={doPause}><FontAwesomeIcon icon={faPause}/></Button>
					: <Button onClick={doResume}><FontAwesomeIcon icon={faPlay}/></Button>}
				<Button onClick={() => doSetSpeed(player, 1)} data-active={speed === 1}>1x</Button>
				<Button onClick={() => doSetSpeed(player, 2)} data-active={speed === 2}>2x</Button>
				<Button onClick={() => doSetSpeed(player, 4)} data-active={speed === 4}>4x</Button>
				<Button onClick={() => doSetSpeed(player, 8)} data-active={speed === 8}>8x</Button>
				<Switch data-disabled={speedState === 'skipping'} onClick={onSpeedChanged}>
					<input type="checkbox" disabled={speedState === 'skipping'} ref={switchRef}
					       checked={player.config.skipInactive} onChange={() => {
					}}/>
					<label/>
					<span className="label">Skip Inactive Frames</span>
				</Switch>
				{!fullScreen
					? <Button id='fullScreen' onClick={() => enterFullScreen()}><FontAwesomeIcon icon={faExpandArrowsAlt}/></Button>
					: <Button onClick={() => exitFullScreen()}><FontAwesomeIcon icon={faCompressArrowsAlt}/></Button>}
			</Buttons>
		</Controller>;
	};

	// @ts-ignore
	const playerFrame = <PlayerFrame scale={scale.ratio} ref={rootRef}/>;

	return <Player ref={playerRef}>
		{playerFrame}
		{createController()}
	</Player>;
};

export default (props: { autoPlay: boolean, events?: Array<WebEventWithTime> }) => {
	const { autoPlay = true, events } = props;

	if (!events || events.length === 0) {
		return <Fragment/>;
	}

	return <WebPlayer autoPlay={autoPlay} events={events}/>;
};