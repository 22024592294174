import { sha256 } from 'js-sha256';
// @ts-ignore
import { deflate } from 'pako/lib/deflate';
import { v4 as uuidV4 } from 'uuid';
import fallbacker from '../oss/fallbacker';
import Logger from '../oss/logger';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { dateFormat } from '../oss/utils';
import { OssKit, TraceInfo, TraceItem, TraceItemInfo } from '../types';

export type WebEventWithTime = { timestamp: number };
export type ContextGetter = () => TraceInfo;
export type TraceNoSynchronizer = (traceNo: string) => void

class DataProcessor {
	private readonly ossKit: OssKit;
	private readonly logger: Logger;
	private readonly getContext: ContextGetter;
	private readonly syncTraceNoToContext: TraceNoSynchronizer;
	private readonly compress: boolean;

	private uuid: string = this.generateUuid();

	constructor(options: {
		ossKit: OssKit,
		logger: Logger,
		getContext: ContextGetter,
		syncTraceNoToContext: TraceNoSynchronizer,
		compress?: boolean
	}) {
		const { ossKit, logger, getContext, syncTraceNoToContext, compress = true } = options;

		this.ossKit = ossKit;
		this.logger = logger;
		this.getContext = getContext;
		this.syncTraceNoToContext = syncTraceNoToContext;
		this.compress = compress;
	}

	private generateUuid(): string {
		try {
			return uuidV4().replace(/-/g, '');
		} catch {
			// 微信小程序不支持uuid
			const timestamp = new Date().getTime();
			const high = parseInt(`${Math.random() * 10000000000}`);
			const low = parseInt(`${Math.random() * 1000000000}`);
			return `${high}${timestamp}${low}`;
		}
	}

	public resetKeptUuid(): void {
		this.uuid = this.generateUuid();
	}

	submit(events: Array<WebEventWithTime>): Promise<void> {
		return new Promise(async resolve => {
			const accountName = this.ossKit.getAccountName();
			const fileType = 'rrweb';

			// retrieve traceNo and current step from context
			// create one when traceNo doesn't exist
			// do nothing when current step doesn't exist
			const { traceNo = this.uuid, currentStep, source, version, ...rest } = this.getContext() || {};
			this.syncTraceNoToContext(traceNo);
			const fileUuid = this.generateUuid();
			const eventsToContent = process.env.REACT_APP_CUSTOM_STORAGE && process.env.REACT_APP_CUSTOM_STORAGE == 'fastdfs'
				? {content:events,uuid:fileUuid}
				: events;
			const content = this.compress ? deflate(JSON.stringify(eventsToContent), { to: 'string' }) : JSON.stringify(eventsToContent);
			// const content = JSON.stringify(events);
			const buffer = Buffer.from(content, 'utf8');
			let fileTraceNo = traceNo;


			const fileHash = sha256(content);
			const fileSize = buffer.length;

			try {
				const constantTraceNo = await this.ossKit.saveTraceInfo({
					...rest,
					source,
					version,
					traceNo,
					fileHash,
					fileType,
					fileSize
				});
				if (constantTraceNo) {
					fileTraceNo = constantTraceNo;
				}
				fallbacker.keepTheLatestSucceedSubmitItemTimeStamp(new Date().getTime());
			} catch (e) {
				fallbacker.onSubmitItemFailed({ ...rest, traceNo: fileTraceNo });
				this.logger.log("Failed to save trace, thus it is push to fallback stack.");
				this.logger.error(e);
			}

			const fileLocation = accountName + '/' + fileTraceNo + '/' + dateFormat(new Date(), 'yyyyMMddhhmmss') + '-' + fileUuid + '.' + fileType;

			const traceItemInfo: TraceItemInfo = {
				traceNo: fileTraceNo,
				uuid: fileUuid,
				userAgent: (window?.navigator?.userAgent || 'Native device.'),
				currentStep,
				fileHash,
				fileType,
				fileLocation,
				fileSize,
				duration: events.length > 0 ? (events[events.length - 1].timestamp - events[0].timestamp) : 0,
				ip: (window?.location?.host || ''),
				// 从Context抓到的来源系统和版本. 每段的来源系统和版本都可能不同, 因此每段都需要单独记录
				source,
				version
			};

			try {
				await this.ossKit.createTraceItemInfo(traceItemInfo);
			} catch (e) {
				fallbacker.onSubmitItemFailed(traceItemInfo);
				this.logger.log("Failed to save trace item, thus it is push to fallback stack.");
				this.logger.error(e);
			}

			try {
				await this.ossKit.uploadStageFile(buffer, fileLocation);
				this.logger.log("Succeed to upload captured file.");
			} catch (e) {
				fallbacker.onSubmitItemFailed({ content, postUrl: fileLocation });
				this.logger.log("Failed to upload captured file, thus it is push to fallback stack.");
				this.logger.error(e);
			}

			resolve();
		});
	}

	submitFailedTask(task: TraceInfo | TraceItemInfo | TraceItem): void {
		if (task.hasOwnProperty("postUrl")) {
			this.logger.log("fallback last unsuccessful TraceItem task");
			// TODO if the url has expired ?
			this.ossKit.uploadStageFile(Buffer.from((task as TraceItem).content), (task as TraceItem).postUrl);
		}

		if (task.hasOwnProperty("fileHash")) {
			this.logger.log("fallback last unsuccessful TraceItemInfo task");
			this.ossKit.createTraceItemInfo(task as TraceItemInfo);
		}

		if (task.hasOwnProperty("policyNo")) {
			// if the task submitTime is lessThan the latest succeed task submit time, ignore the current trace
			let submitTime = (task as TraceInfo).submitTime;
			if (submitTime && submitTime > fallbacker.popTheLatestSucceedSubmitItemTimeStamp()) {
				this.logger.log("fallback last unSuccceed TraceInfo task");
				delete (task as TraceInfo).submitTime;
				this.ossKit.saveTraceInfo(task as TraceInfo);
			}
		}
	}
}

export default DataProcessor;