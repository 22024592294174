import { NodeType, SerializedNodeWithId } from '../../types';
import { CommonDataSet } from '../common/types';
import { buildDefaultAttributes, buildFontStyle } from './utils';

export type StandRichTextDataSet = {
	nodes: string;
} & CommonDataSet;
export const buildStandardRichText = (dataset: StandRichTextDataSet, nodeIdIncrementer: () => number): Array<SerializedNodeWithId> => {
	return [ {
		type: NodeType.Element,
		id: nodeIdIncrementer(),
		tagName: 'div',
		attributes: {
			...buildDefaultAttributes('rich-text', dataset, buildFontStyle(dataset))
		},
		childNodes: dataset.nodes
			? [ {
				type: NodeType.Text,
				id: nodeIdIncrementer(),
				textContent: dataset.nodes,
				isHtml: true
			} ]
			: []
	} as SerializedNodeWithId ];
};

export const RichTextStyles = `
html[data-css=glutton-wmp] span:not([data-rdcs])[data-css=rich-text] {
	display: block;
}
`;