import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Path from '../path';
import FullTraceQuery from './full-trace-query';
import FullTraceDetail from './full-trace-detail';

export default () => {
	return <Switch>
		<Route path={Path.FULL_TRACE_DETAIL} exact render={({ match }) => {
			return <FullTraceDetail traceId={match.params.traceId} createAt={match.params.createAt}/>;
		}}/>
		<Route path={"/"}>
			<FullTraceQuery/>
		</Route>
	</Switch>;
}