import React, { useReducer, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { doPost } from '../service/ajax';
import styled from 'styled-components';
import {
	Button,
	ButtonBar,
	ButtonBarAlignType,
	ButtonType, Dropdown,
	FormSectionGapVertical,
	PanelSeparator,
	QueryPanel,
	QueryResultColumns,
	QueryResultPanel,
	QueryResultRow,
	Text,
	WorkAreaTitle
} from '../component';
import Path from '../path';
import {AccountList} from "../service/record-service";
import {getCurrentUserTenantList} from "../service/account-service";
import {DropdownOption} from "../component/dropdown";
const MaskQuery = styled.div`
	display: flex;
	flex-direction: column;
`;

const ButtonWrapper = styled.div`
	text-align: center;
	> *:last-child {
		display: inline-block;
		margin-left: var(--gap-thin);
	}
`;

const columns: QueryResultColumns = [
	{ label: '脱敏规则', width: '180px', name: 'maskRule'},
	{ label: '创建时间', width: '180px', name: 'createAt'},
	{ label: '操作', width: '100px', name: 'actions'}
];

export interface MaskRuleData {
	tenantId?:string,
	maskRule?:string;
	createAt?: string;
}

export interface MaskData {
	rows?: Array<MaskRuleData>
}

export default () => {
	const history = useHistory();
	const [ , forceUpdate ] = useReducer(x => x + 1, 0);
	const [ data, setData ] = useState<MaskData | null>(null);
	const [ searched, setSearched ] = useState(false);
	const [ searchRule,setSearchRule ] = useState<string>( '');
	const [ searchTenantId,setSearchTenantId ] = useState<string>( '');
	const [tenantList,setTenantList] = useState<Array<DropdownOption> | []>([]);

	useEffect(() => {
		(async () => {
			const tenantlist : AccountList =await getCurrentUserTenantList();
			const accounts = tenantlist?.accounts || [];
			const tenant = new Array<DropdownOption>();
			accounts.forEach((account)=>{
				tenant.push({value:account.tenantId,label:account.accountName});
			});
			setTenantList(tenant);
			setSearchTenantId(accounts[0]?.tenantId.toString());
		})();
	}, []);

	const onChanged = (para:string) => (event: React.ChangeEvent) => {
		// @ts-ignore
		if(para == 'mask'){
			setSearchRule((event.target as HTMLInputElement).value);
		}else{
			setSearchTenantId((event.target as HTMLInputElement).value);
		}
		forceUpdate();
	};

	const onSearchClicked = async (rule: string) => {
		const resultList = await doPost('/api/mask/list',{'rule':rule,'tenantId':searchTenantId});
		const result = {
			rows: resultList.body.map((row: any, index: number) => {
				row.seq = index + 1;
				return row;
			})
		};
		setData(result);
		setSearched(true);
	};

	const onAddClicked = () => {
		history.push(Path.MASK_EDIT);
	};

	const dataFound = data || { pageNumber: 1, pageSize: 10, totalPages: 1, totalItemCount: 0, rows: [] };

	const rows = (dataFound.rows || []).map((row): QueryResultRow => {
		const data = row as QueryResultRow;
		data.actions = <ButtonWrapper>
			<Button label="删除" type={ButtonType.LINK}
					onClick={async () => {
						const result = await doPost('/api/mask/delete',{'rule':row.maskRule,'tenantId':row.tenantId});
						if(result.body == 'OK'){
							alert('删除成功');
							await onSearchClicked(searchRule);
						}else{
							alert('删除失败，请联系管理员');
						}
					}}/>
		</ButtonWrapper>;
		return data;
	});

	return <MaskQuery>
		<WorkAreaTitle title="脱敏配置"/>
		<QueryPanel>
			<Text label="脱敏规则" placeholder="请输入" value={searchRule} onChange={onChanged('mask')}/>
			<Dropdown label="所属系统" options={tenantList} value = {searchTenantId} onChange ={onChanged("tenantId")}/>

			<PanelSeparator columns={3}/>
			<ButtonBar align={ButtonBarAlignType.CENTER}>
				<Button label="查询" onClick={() => onSearchClicked(searchRule)}/>
				<Button label="添加" type={ButtonType.WAIVE} onClick={onAddClicked}/>
			</ButtonBar>
			<PanelSeparator columns={3}/>
		</QueryPanel>
		<FormSectionGapVertical/>
		<QueryResultPanel columns={columns} rows={rows} pagination={false} searched={searched}/>
	</MaskQuery>;
}