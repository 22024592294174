import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Path from '../path';
import MaskQuery from './mask-query';
import MaskEdit from './mask-edit';

export default () => {
	return <Switch>
		<Route path={Path.MASK_EDIT} exact render={({ match }) => {
			return <MaskEdit/>;
		}}/>
		<Route path={"/"}>
			<MaskQuery/>
		</Route>
	</Switch>;
}