import DefaultOssKit from '../oss/default-oss-kit';
import { SaasGluttonRecorderConfig } from '../types';
import GluttonRecorder from './rrweb';

class SaasGluttonRecorder {
	private recorder: GluttonRecorder;

	constructor(config: SaasGluttonRecorderConfig) {
		// console.warn('construct saas glutton recorder:',config);
		const {
			serverHost,
			firstToken,
			autoPlay = false,
			disableLogs = false,
			compress,
			stsTokenTimeout,
			dataTokenTimeout,
			askDataToken,
			dataTokenTimeoutCallback,
			uploadToOss,
			recordOptions
		} = config;
		this.recorder = new GluttonRecorder({
			autoPlay,
			disableLogs,
			compress,
			oss: new DefaultOssKit(firstToken, disableLogs, {
				serverHost,
				stsTokenTimeout,
				dataTokenTimeout,
				askDataToken,
				uploadToOss,
				dataTokenTimeoutCallback
			}),
			recordOptions
		});
	}

	public async startRecord(): Promise<void> {
		return this.recorder.startRecord();
	}

	public forceSubmit(): void {
		this.recorder.forceSubmit();
	}

	public async stopRecord(): Promise<void> {
		return this.recorder.stopRecord();
	}
}

if (window) {
	// @ts-ignore
	window.GluttonRecorder = SaasGluttonRecorder;
}

export default SaasGluttonRecorder;
