import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AuditView from './audit-view';

export default () => {
	return <Switch>
		<Route path={"/"}>
			<AuditView/>
		</Route>
	</Switch>;
}