import React from 'react';
import { Route, Switch } from 'react-router-dom';
import RecordQuery from './record-query';

export default () => {
	return <Switch>
		<Route path={"/"}>
			<RecordQuery/>
		</Route>
	</Switch>;
}