import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Path from '../path';
import QualityInspectionQuery from './qualityInspection-query';
import QualityInspectionEdit from './qualityInspection-edit';

export default () => {
	return <Switch>
		<Route path={Path.QUALITY_INSPECTION_ADD} exact render={({ match }) => {
			return <QualityInspectionEdit createAt={match.params.createAt} taskName={match.params.taskName} traceNo={match.params.traceNo}/>;
		}}/>
		<Route path={Path.QUALITY_INSPECTION_QUERY} exact render={({ match }) => {
			return <QualityInspectionQuery taskName={match.params.taskName}/>;
		}}/>
		<Route path={"/"}>
			<QualityInspectionQuery taskName=''/>
		</Route>
	</Switch>;
}