import React from 'react';
import { Route, Switch } from 'react-router-dom';
import BlockchainQuery from './record-blockchain-query';



export default () => {
	return <Switch>
		<Route path={"/"}>
			<BlockchainQuery/>
		</Route>
	</Switch>;
}