import html2canvas from 'html2canvas';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
	Button,
	ButtonBar,
	ButtonBarAlignType,
	Dropdown,
	PanelSeparator,
	QueryPanel,
	Text,
	WorkAreaTitle
} from '../component';
import { ScreenRecorderContext, useScreenRecorder } from '../hooks/screen-recorder';

// @ts-ignore
window.html2canvas = html2canvas;

const DemoView = styled.div`
	display: flex;
	flex-direction: column;
	@media (max-width: 599px) {
		> div:last-child {
			display: flex;
			flex-direction: column;
			> div:nth-last-child(3) {
				height: calc(var(--gap) * 2);
				background-color: transparent;
			}
		}
	}
	div[data-demo-title] {
		font-weight: 600;
		grid-column: span 3;
		font-size: 1.4em;
	}
	canvas {
		border-radius: 4px;
		border: 1px solid var(--primary-color);
		grid-column: span 3;
		height: 200px;
		width: 200px;
		padding: var(--gap);
	}
	iframe {
		border: none;
		grid-column: span 3;
		height: 400px;
		width: 100%;
	}
`;

export default () => {
	const policyHolderNameRef = useRef<HTMLInputElement>(null);
	const policyHolderIdNoRef = useRef<HTMLInputElement>(null);
	const policyHolderMobileRef = useRef<HTMLInputElement>(null);
	const productNameRef = useRef<HTMLSelectElement>(null);

	const recorder: ScreenRecorderContext = useScreenRecorder();

	const onStartRecordClicked = () => recorder.startRecord();
	const onStopRecordClicked = () => {
		const data = {
			policyHolderName: policyHolderNameRef.current!.value,
			policyHolderIdNo: policyHolderIdNoRef.current!.value,
			policyHolderMobile: policyHolderMobileRef.current!.value,
			productName: productNameRef.current!.value,
			source: 'Glutton-Demo',
			version: '1.0.0-RC'
		};
		recorder.stopRecord(data);
	};

	const products = [
		{ value: 1, label: '陆家嘴国泰 护一生重大疾病保险' },
		{ value: 2, label: '长城人寿 金禧利菁华版A计划' }
	];

	const canvasRef = useRef<HTMLCanvasElement>(null);
	useEffect(() => {
		if (canvasRef.current) {
			const canvas = canvasRef.current;
			const ctx = canvasRef.current.getContext('2d')!;
			ctx.beginPath();
			ctx.moveTo(50, 50);
			ctx.lineTo(100, 100);
			ctx.stroke();
			ctx.beginPath();
			ctx.moveTo(100, 50);
			ctx.lineTo(50, 100);
			ctx.stroke();
			ctx.strokeText('I am canvas.', 0, 20);
			setTimeout(() => {
				ctx.lineCap = 'butt';
				ctx.clearRect(0, 0, canvas.width, canvas.height);
				ctx.beginPath();
				ctx.moveTo(50, 50);
				ctx.lineTo(50, 100);
				ctx.stroke();
				ctx.beginPath();
				ctx.moveTo(100, 50);
				ctx.lineTo(100, 100);
				ctx.stroke();
			}, 5000);
		}
	});

	return <DemoView>
		<WorkAreaTitle title="小试牛刀"/>
		<QueryPanel>
			<Text label="投保人姓名" placeholder="请输入" ref={policyHolderNameRef}/>
			<Text label="投保人证件号码" placeholder="请输入" ref={policyHolderIdNoRef}/>
			<Text label="投保人手机号码" placeholder="请输入" ref={policyHolderMobileRef}/>
			<Dropdown label="保险产品" placeholder="请输入" options={products} columns={2} ref={productNameRef}/>
			<div data-demo-title="">Following is a canvas.</div>
			<canvas ref={canvasRef}/>
			<div data-demo-title="">Following is an iframe.</div>
			<iframe src="/trace/query" title={"test"}/>
			<PanelSeparator columns={3}/>
			<ButtonBar align={ButtonBarAlignType.CENTER}>
				{!recorder.recording
					? <Button label="开始录制" onClick={onStartRecordClicked}/>
					: <Button label="结束" onClick={onStopRecordClicked}/>}
			</ButtonBar>
		</QueryPanel>
	</DemoView>;
}