import {faAngleDoubleLeft, faDownload,faPhotoVideo,faUserEdit,faEyeSlash,faStethoscope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import Path from './path';
import { isAdminDisplay} from './utils';

const fadeIn = keyframes`
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
`;
const fadeOut = keyframes`
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
`;
const rotateToRight = keyframes`
	from {
		transform: rotateY(0deg);
	}
	to {
		transform: rotateY(180deg);
	}
`;
const rotateToLeft = keyframes`
	from {
		transform: rotateY(180deg);
	}
	to {
		transform: rotateY(0deg);
	}
`;

const SideBar = styled.div`
	min-height: calc(var(--gap) * 4 + var(--gap-thinnest) * 3 + var(--side-bar-menu-height) * 4);
	min-width: var(--side-bar-width);
	width: var(--side-bar-width);
	background-color: var(--side-bar-bg-color);
	border-right: var(--side-bar-border);
	padding-top: var(--gap);
	padding-bottom: var(--gap);
	display: flex;
	flex-direction: column;
	transition: all 300ms ease-in-out;
	&[data-collapsed=true] {
		min-width: 60px;
		width: 60px;
		> div {
			> div {
				animation: 300ms ${fadeOut} ease-in-out forwards;
			}
			&:last-child > svg {
				animation: 300ms ${rotateToRight} ease-in-out forwards;
			}
		}
	}
`;
const FirstLevelMenu = styled.div`
	display: flex;
	align-items: center;
	height: var(--side-bar-menu-height);
	line-height: calc(var(--side-bar-menu-height) - 2px);
	font-size: var(--side-bar-menu-font-size);
	margin: 0 var(--gap-thin);
	padding: 0 var(--padding);
	border-radius: var(--radius);
	cursor: pointer;
	transition: all 300ms ease-in-out;
	border: 1px solid transparent;
	white-space: nowrap;
	overflow-x: hidden;
	background-color: transparent;
	color: var(--font-color);
	box-shadow: none;
	user-select: none;
	&:not(:last-child) {
		margin-bottom: var(--gap-thinnest);
	}
	&[data-active=true] {
		background-color: var(--side-bar-active-bg-color);
		color: var(--side-bar-active-color);
		border-color: var(--side-bar-active-border-color);
		box-shadow: none;
	}
	&:not([data-active=true]):hover {
		background-color: var(--side-bar-hover-bg-color);
		color: var(--side-bar-hover-color);
		border-color: var(--side-bar-hover-border-color);
		box-shadow: 0.5px 0.5px 0.5px 0.5px var(--side-bar-hover-box-shadow-color);
	}
	> svg {
		margin-right: var(--gap-thin);
		margin-left: -2px;
	}
	&:last-child > svg {
		animation: 300ms ${rotateToLeft} ease-in-out;
	}
	> div {
		animation: 300ms ${fadeIn} ease-in-out;
	}
`;

export default () => {
	const [ collapsed, setCollapsed ] = useState(window.location.href.indexOf('/qualityInspectionView') > 0 ? true : false);
	const history = useHistory();
	const location = useLocation();

	const onTraceClicked = () => history.push(Path.TRACE_QUERY);
	const onTraceDownloadClicked = () => history.push(Path.TRACE_DOWNLOAD_QUERY);
	const onMaskCliked = () => history.push(Path.MASK_QUERY);
	const onGluttonUserCliked = () => history.push(Path.GLUTTON_USER);
	const onQualityInspectionClick = () => history.push(Path.QUALITY_INSPECTION);
	const onQualityInspectionTaskClick = () => history.push(Path.QUALITY_INSPECTION_TASK_QUERY);
	const onToggleMenuClicked = () => {
		setCollapsed(!collapsed);
	};

	const onDemo = matchPath(location.pathname, { path: Path.DEMO }) != null;
	const onDemoClicked = () => history.push(Path.DEMO);


	const traceLabel = '销售回溯管理';
	const gluttonUserLabel = '可回溯帐号配置';
	const maskLabel = '脱敏配置';
	const expandLabel = '展开菜单';
	const downloadLabel = '视频下载记录';
	const qualityInspectionLabel = '质检报表';
	const qualityInspectionTaskLabel = '质检任务管理';

	const onTrace = matchPath(location.pathname, { path: Path.TRACE }) != null;
	const onGluttonUser = matchPath(location.pathname, { path: Path.GLUTTON_USER }) != null;
	const onMask = matchPath(location.pathname,{ path:Path.MASK}) != null;
	const onTraceDownload = matchPath(location.pathname, { path: Path.TRACE_DOWNLOAD_QUERY }) != null;
	const onQualityInspection = matchPath(location.pathname,{ path:Path.QUALITY_INSPECTION}) != null;
	const onQualityInspectionTask = matchPath(location.pathname,{ path:Path.QUALITY_INSPECTION_TASK}) != null;


	return <SideBar data-collapsed={collapsed}>
		<FirstLevelMenu onClick={onTraceClicked} title={collapsed ? traceLabel : undefined} data-active={onTrace}>
			<FontAwesomeIcon icon={faPhotoVideo}/>
			<div>{traceLabel}</div>
		</FirstLevelMenu>

		<FirstLevelMenu onClick={onTraceDownloadClicked} title={collapsed ? downloadLabel : undefined} data-active={onTraceDownload}>
			<FontAwesomeIcon icon={faDownload}/>
			<div>{downloadLabel}</div>
		</FirstLevelMenu>

		{isAdminDisplay()
			? <FirstLevelMenu onClick={onGluttonUserCliked} title={collapsed ? gluttonUserLabel : undefined}
							  data-active={onGluttonUser}>
				<FontAwesomeIcon icon={faUserEdit}/>
				<div>{gluttonUserLabel}</div>
			</FirstLevelMenu>
			: null
		}

		<FirstLevelMenu onClick={onMaskCliked} title={collapsed ? maskLabel : undefined}
						  data-active={onMask}>
			<FontAwesomeIcon icon={faEyeSlash}/>
			<div>{maskLabel}</div>
		</FirstLevelMenu>

		{isAdminDisplay()
			? <FirstLevelMenu onClick={onQualityInspectionClick} title={collapsed ? qualityInspectionLabel : undefined}
							  data-active={onQualityInspection}>
				<FontAwesomeIcon icon={faStethoscope}/>
				<div>{qualityInspectionLabel}</div>
			</FirstLevelMenu>
			: null
		}

		{isAdminDisplay()
			? <FirstLevelMenu onClick={onQualityInspectionTaskClick} title={collapsed ? qualityInspectionTaskLabel : undefined}
							  data-active={onQualityInspectionTask}>
			<FontAwesomeIcon icon={faStethoscope}/>
			<div>{qualityInspectionTaskLabel}</div>
		</FirstLevelMenu>
			: null
		}

		{/*<FirstLevelMenu onClick={onDemoClicked} title='demo'
			                  data-active={onDemo}>
				<FontAwesomeIcon icon={faStethoscope}/>
				<div>{qualityInspectionTaskLabel}</div>
			</FirstLevelMenu>*/}

		<FirstLevelMenu onClick={onToggleMenuClicked} title={collapsed ? expandLabel : undefined}>
			<FontAwesomeIcon icon={faAngleDoubleLeft}/>
			<div>隐藏菜单</div>
		</FirstLevelMenu>
	</SideBar>;
}