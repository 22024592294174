import { isAdminLogin,isTenantAdminLogin } from './service/storage';
export const isDemoHide = () => {
	return window.location.href.startsWith("https://glutton.ebaocloud.com.cn");
};
export const isBlockChainHide = () => {
	return false;
};
export const isCCICDisplay = () => {
	return window.location.host.indexOf('ccic-net.com.cn') >= 0;
};

export const isAdminDisplay = () => {
	return isAdminLogin();
};

export const isTenantAdminDisplay = () => {
	return isTenantAdminLogin();
};