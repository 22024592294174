class Logger {
	private readonly disableLogs: boolean;

	constructor(disableLogs: boolean) {
		this.disableLogs = disableLogs;
	}

	public error(message?: any, ...optionalParams: any[]): void {
		console.error(message, ...(optionalParams || []));
	}

	public warn(message?: any, ...optionalParams: any[]): void {
		if (!this.disableLogs) {
			console.warn(message, ...(optionalParams || []));
		}
	}

	public info(message?: any, ...optionalParams: any[]): void {
		if (!this.disableLogs) {
			console.info(message, ...(optionalParams || []));
		}
	}

	public log(message?: any, ...optionalParams: any[]): void {
		if (!this.disableLogs) {
			console.log(message, ...(optionalParams || []));
		}
	}

	public group(...label: any[]): void {
		if (!this.disableLogs) {
			console.group(...(label || []));
		}
	}

	public groupEnd(): void {
		if (!this.disableLogs) {
			console.groupEnd();
		}
	}

	public isDisabled() {
		return this.disableLogs;
	}
}

export default Logger;