import { NodeType, SerializedNodeWithId } from '../../types';
import { CommonDataSet } from '../common/types';
import { buildBooleanValue, buildDefaultAttributes, buildFontStyle } from './utils';

// <button data-type="button" data-text="选中" data-class?="" data-style?="" data-plain?="true|false" data-kind?="primary|warn|default" data-disabled?="true|false"/>

export type StandardButtonDataSet = {
	text: string;
	plain?: 'true' | 'false' | boolean;
	kind?: 'primary' | 'warn' | 'default';
	disabled?: 'true' | 'false' | boolean;
} & CommonDataSet;
export const buildStandardButton = (dataset: StandardButtonDataSet, nodeIdIncrementer: () => number): Array<SerializedNodeWithId> => {
	return [ {
		type: NodeType.Element,
		id: nodeIdIncrementer(),
		tagName: 'div',
		attributes: {
			...buildDefaultAttributes('button', dataset, buildFontStyle(dataset)),
			'data-plain': buildBooleanValue(dataset.plain),
			'data-kind': dataset.kind || 'default',
			'data-disabled': buildBooleanValue(dataset.disabled)
		},
		childNodes: [ {
			type: NodeType.Text,
			id: nodeIdIncrementer(),
			textContent: dataset.text
		} ]
	} as SerializedNodeWithId ];
};

export const ButtonStyles = `
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=button] {
	position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-left: 14px;
    padding-right: 14px;
    box-sizing: border-box;
    font-size: 18px;
    text-align: center;
    text-decoration: none;
    line-height: 2.55555556;
    border-radius: 5px;
    -webkit-tap-highlight-color: transparent;
    overflow: hidden;
    color: #000000;
    background-color: #F8F8F8;
    height: 100%;
}
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=button]:after {
	content: " ";
    width: 200%;
    height: 200%;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    box-sizing: border-box;
    border-radius: 10px;
}
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=button][data-kind=default] {
	color: #000000;
    background-color: #F8F8F8;
}
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=button][data-kind=primary] {
	color: #FFFFFF;
    background-color: #1AAD19;
}
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=button][data-kind=warn] {
	color: #FFFFFF;
    background-color: #E64340;
}
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=button][data-plain=true] {
	color: #353535;
    border: 1px solid #353535;
    background-color: transparent;
}
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=button][data-plain=true]:after {
	border-width: 0;
}
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=button][data-disabled=true] {
	color: rgba(255, 255, 255, 0.6);
}
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=button][data-kind=primary][data-plain=true] {
	color: #1aad19;
    border: 1px solid #1aad19;
    background-color: transparent;
}
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=button][data-kind=primary][data-plain=true]:after {
	border-width: 0;
}
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=button][data-kind=default][data-disabled=true] {
	color: rgba(0, 0, 0, 0.3);
	background-color: #F7F7F7;
}
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=button][data-kind=primary][data-disabled=true] {
	background-color: #9ED99D;
}
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=button][data-kind=warn][data-disabled=true] {
	background-color: #EC8B89;
}
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=button][data-kind=default][data-plain=true][data-disabled=true],
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=button][data-kind=primary][data-plain=true][data-disabled=true],
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=button][data-kind=warn][data-plain=true][data-disabled=true]{
	color: rgba(0, 0, 0, 0.2);
    border-color: rgba(0, 0, 0, 0.2);
}
`;