import React, { useRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBraille, faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import { useResponsive } from './hooks/responsive';
import { useSecurity } from './hooks/security';
import { clearAccount } from './service/storage';
import { useShares } from './hooks/shares';
import { isCCICDisplay } from './utils';
import { ccicLogout } from './ccic/cas-service';

const Header = styled.div`
	display: grid;
	grid-template-columns: auto 1fr auto;
	position: sticky;
	top: 0;
	width: 100%;
	height: var(--header-height);
	padding: 0 var(--side-margin);
	background-color: var(--header-bg-color);
	color: var(--header-color);
	z-index: var(--header-z-index);
`;
const HeaderTitle = styled.div`
	display: flex;
	height: var(--header-height);
	user-select: none;
	font-size: 1.2em;
	font-weight: 600;
	> svg {
		font-size: 1.4em;
		margin-right: var(--gap);
		color: var(--primary-color);
		align-self: center;
	}
	> span {
		align-self: center;
	}
`;
const Placeholder = styled.div``;
const Authorized = styled.div`
	line-height: var(--header-height);
	font-weight: 600;
	display: flex;
	align-content: center;
	> div:first-child {
		margin-right: var(--gap);
		display: none;
		&[data-show=true] {
			display: block;
		}
	}
	> div:last-child {
		cursor: pointer;
	}
`;

export default () => {
	const security = useSecurity();
	const responsive = useResponsive();
	const shares = useShares();
	const containerRef = useRef<HTMLDivElement>(null);

	const onQuitClicked = () => {
		clearAccount();
		// clean all shares info
		shares.discardAll();
		
		if (isCCICDisplay()) {
			ccicLogout();
		} else {
			security.accountChange();
		}
	};

	const label = responsive.mobile ? 'E录通互联网投保回溯服务' : 'E录通互联网投保可视化回溯服务';

	return <Header ref={containerRef}>
		<HeaderTitle>
			<FontAwesomeIcon icon={faBraille} />
			<span>{label}</span>
		</HeaderTitle>
		<Placeholder />
		{security.isAuthorized
			? <Authorized>
				<div data-show={!responsive.mobile}>你好, {security.accountName}</div>
				<div>
					<FontAwesomeIcon icon={faDoorOpen} onClick={onQuitClicked} />
				</div>
			</Authorized>
			: null}
	</Header>;
}