import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Path from '../path';
import SourceConfigQuery from './sourceConfig-query';
import SourceConfigEdit from './sourceConfig-edit';

export default () => {
	return <Switch>
		<Route path={Path.SOURCE_CONFIG_EDIT} exact render={({ match }) => {
			return <SourceConfigEdit/>;
		}}/>
		<Route path={"/"}>
			<SourceConfigQuery/>
		</Route>
	</Switch>;
}