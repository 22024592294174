import { SerializedNodeWithId } from './node';

/** 事件类型 */
export enum WebEventType {
	DomContentLoaded,
	// TODO never occurred in weixin mini-program
	Loaded,
	FullSnapshot,
	// TODO never occurred in weixin mini-program
	IncrementalSnapshot,
	Meta,
	// TODO never occurred in weixin mini-program
	Custom,
}

/** Meta事件 */
export type MetaEvent = {
	type: WebEventType.Meta;
	data: {
		href: string;
		width: number;
		height: number;
	};
};

/** DOM内容加载完毕事件 */
export type DomContentLoadedEvent = {
	type: WebEventType.DomContentLoaded;
	data: {};
};

/** 全量抓取事件 */
export type FullSnapshotEvent = {
	type: WebEventType.FullSnapshot;
	data: {
		node: SerializedNodeWithId;
		initialOffset: {
			top: number;
			left: number;
		};
		iconFonts?: Array<string>;
	};
};

/** 所有事件 */
export type WebEvent =
	| DomContentLoadedEvent
	| FullSnapshotEvent
	| MetaEvent;

/** 增加事件的时间 */
export type WebEventWithTime = WebEvent & {
	timestamp: number;
	delay?: number;
};
