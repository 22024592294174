import { NodeType, SerializedNodeWithId } from '../../types';
import { CommonDataSet } from '../common/types';
import { buildBooleanValue, buildDefaultAttributes, buildFontStyle } from './utils';

const REGULAR_COLOR = '#09BB07';

export type StandRadioDataSet = {
	text?: string;
	color?: string;
	checked: 'true' | 'false' | boolean;
	disabled: 'true' | 'false' | boolean;
} & CommonDataSet;
export const buildStandardRadio = (dataset: StandRadioDataSet, nodeIdIncrementer: () => number): Array<SerializedNodeWithId> => {
	const checked = buildBooleanValue(dataset.checked);
	const disabled = buildBooleanValue(dataset.disabled);
	const style = (checked === 'true' && disabled === 'false' && dataset.color && dataset.color !== REGULAR_COLOR) ? createColor(dataset.color) : undefined;
	return [ {
		type: NodeType.Element,
		id: nodeIdIncrementer(),
		tagName: 'div',
		attributes: {
			...buildDefaultAttributes('radio', dataset, style),
			'data-checked': checked,
			'data-disabled': disabled
		},
		childNodes: dataset.text ? [ {
			type: NodeType.Element,
			id: nodeIdIncrementer(),
			tagName: 'span',
			attributes: {
				style: buildFontStyle(dataset)
			},
			childNodes: [ {
				type: NodeType.Text,
				id: nodeIdIncrementer(),
				textContent: dataset.text
			} ]
		} ] : []
	} as SerializedNodeWithId ];
};
const createColor = (color: string) => {
	return `border-color: ${color};background-color: ${color};`;
};

// 标准
// 选中
// 标准 + 失效
// 选中 + 失效
export const RadioStyles = `
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=radio] {
	margin: 5px 5px 0 0;
	border: 1px solid #D1D1D1;
	background-color: #ffffff;
	border-radius: 50%;
	width: 22px;
	height: 22px;
	position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=radio][data-checked=true] {
	${createColor(REGULAR_COLOR)}
	background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8.657%2018.435L3%2012.778l1.414-1.414%204.95%204.95L20.678%205l1.414%201.414-12.02%2012.021a1%201%200%2001-1.415%200z%22%20fill%3D%22%23fff%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E);
}
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=radio][data-disabled=true] {
	background-color: #E1E1E1;
}
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=radio][data-checked=true][data-disabled=true] {
	${createColor(REGULAR_COLOR)}
	background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8.657%2018.435L3%2012.778l1.414-1.414%204.95%204.95L20.678%205l1.414%201.414-12.02%2012.021a1%201%200%2001-1.415%200z%22%20fill%3D%22%239e9e9e%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E);
}
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=radio] > span {
	display: inline-block;
	margin-left: 30px;
    line-height: 24px;
}
`;

