import { TraceInfo, TraceItem, TraceItemInfo } from "../types";

// hold the fallback tasks only. the processing logic decide by invoker
class Fallbacker {
	private readonly fallbackTaskHolder: FallbackTaskHolder;

	constructor(fallbackTaskHolder: FallbackTaskHolder) {
		this.fallbackTaskHolder = fallbackTaskHolder;
	}

	// failed to submit item video
	public onSubmitItemFailed(task: TraceInfo | TraceItemInfo | TraceItem): void {
		this.fallbackTaskHolder.holdTask(task);
	}

	// lost the last fragment video when window exit
	public onWindowForceExit(task: TraceInfo | TraceItemInfo | TraceItem): void {
		this.fallbackTaskHolder.holdTask(task);
	}

	// list all need fallback tasks 
	public listFallbackTasks(): Promise<Array<TraceInfo | TraceItemInfo | TraceItem>> {
		return this.fallbackTaskHolder.listTasks();
	}

	public cleanAllFallBackedTasks() {
		return this.fallbackTaskHolder.cleanTasks();
	}

	// keep the latest successful submit item timestamp.  
	public keepTheLatestSucceedSubmitItemTimeStamp(timestamp: number) {
		return this.fallbackTaskHolder.keepTheLatestSucceedSubmitItemTimeStamp(timestamp);
	}

	// pop the latest successful submit item timestamp.  
	public popTheLatestSucceedSubmitItemTimeStamp(): number {
		return this.fallbackTaskHolder.popTheLatestSucceedSubmitItemTimeStamp();
	}
}

interface FallbackTaskHolder {
	listTasks: () => Promise<Array<any>>;
	holdTask: (task: any) => void;
	cleanTasks: () => void;
	keepTheLatestSucceedSubmitItemTimeStamp: (timeStamp: number) => void;
	popTheLatestSucceedSubmitItemTimeStamp: () => number;
}

// TODO 微信小程序不支持localStorage, 需要使用wx.getStorageInfo
class LocalStorageTaskHolder implements FallbackTaskHolder {
	private static __FALLBACK_TASK_STORAGE_PREFIX = '__glutton_fallback_tasks';
	private static __FALLBACK_TASK_STORAGE_LATEST_SUCCEED_SUBMIT_TIME = '__glutton_fallback_latest_succeed_time';

	listTasks(): Promise<Array<TraceInfo | TraceItemInfo | TraceItem>> {
		return new Promise((resolve) => {
			if (localStorage) {
				let tasksStr = localStorage.getItem(LocalStorageTaskHolder.__FALLBACK_TASK_STORAGE_PREFIX);
				if (!!tasksStr) {
					// @ts-ignore
					resolve(JSON.parse(tasksStr));
				} else {
					resolve();
				}
			} else {
				resolve();
			}
		});
	};

	holdTask(task: TraceInfo | TraceItemInfo | TraceItem): void {
		if (task) {
			if (localStorage) {
				let tasksStr = localStorage.getItem(LocalStorageTaskHolder.__FALLBACK_TASK_STORAGE_PREFIX);
				if (!!!tasksStr) {
					tasksStr = '[]';
				}
				// @ts-ignore
				let tasks = JSON.parse(tasksStr);
				(tasks as Array<any>).push(task);
				// console.log("current fallbacked tasks::: ", tasks)
				localStorage.setItem(LocalStorageTaskHolder.__FALLBACK_TASK_STORAGE_PREFIX, JSON.stringify(tasks));
			}
		}
	}

	cleanTasks(): void {
		if (localStorage) {
			localStorage.removeItem(LocalStorageTaskHolder.__FALLBACK_TASK_STORAGE_PREFIX);
			localStorage.removeItem(LocalStorageTaskHolder.__FALLBACK_TASK_STORAGE_LATEST_SUCCEED_SUBMIT_TIME);
		}
	}

	keepTheLatestSucceedSubmitItemTimeStamp(timeStamp: number): void {
		// console.log("keepTheLatestSucceedSubmitItemTimeStamp:::");
		if (localStorage) {
			localStorage.setItem(LocalStorageTaskHolder.__FALLBACK_TASK_STORAGE_LATEST_SUCCEED_SUBMIT_TIME, timeStamp + '');
		}
	}

	popTheLatestSucceedSubmitItemTimeStamp(): number {
		if (localStorage) {
			let timeStamp = localStorage.getItem(LocalStorageTaskHolder.__FALLBACK_TASK_STORAGE_LATEST_SUCCEED_SUBMIT_TIME);
			if (!!timeStamp) {
				return Number(timeStamp);
			}
		}
		return Number.MAX_VALUE;
	}
}

export default new Fallbacker(new LocalStorageTaskHolder());

