import React from 'react';
import styled from 'styled-components';

const Separator = styled.div`
	background-color: var(--separator-color);
	height: 1px;
	&[data-columns="2"] {
		grid-column: span 2;
	}
	&[data-columns="3"] {
		grid-column: span 3;
	}
`;
export default (props: { columns?: number }) => {
	const { columns = 1 } = props;

	return <Separator data-columns={columns}/>;
}