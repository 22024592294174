import {
	Attributes, ElementNode,
	IdNodeMap,
	INode,
	MaskInputOptions,
	NodeType,
	SerializedNode,
	SerializedNodeWithId
} from './types';

let globalNodeId = 1;
const symbolAndNumberRegex = RegExp('[^a-z1-6-]');

const generateNodeId = (): number => globalNodeId++;

const getValidTagName = (tagName: string): string => {
	const processedTagName = tagName.toLowerCase().trim();

	if (symbolAndNumberRegex.test(processedTagName)) {
		// if the tag name is odd and we cannot extract
		// anything from the string, then we return a
		// generic div
		return 'div';
	}

	return processedTagName;
};

const absoluteToDoc = (doc: Document, attributeValue: string): string => {
	if (!attributeValue || attributeValue.trim() === '') {
		return attributeValue;
	}
	const a: HTMLAnchorElement = doc.createElement('a');
	a.href = attributeValue;
	return a.href;
};

const extractOrigin = (url: string): string => {
	let origin;
	if (url.indexOf('//') > -1) {
		origin = url.split('/').slice(0, 3).join('/');
	} else {
		origin = url.split('/')[0];
	}
	origin = origin.split('?')[0];
	return origin;
};

const URL_IN_CSS_REF = /url\((?:'([^']*)'|"([^"]*)"|([^)]*))\)/gm;
const RELATIVE_PATH = /^(?!www\.|(?:http|ftp)s?:\/\/|[A-Za-z]:\\|\/\/).*/;
// eslint-disable-next-line
const DATA_URI = /^(data:)([\w\/\+\-]+);(charset=[\w-]+|base64).*,(.*)/i;

const absoluteToStylesheet = (
	cssText: string | null,
	href: string
): string => {
	return (cssText || '').replace(
		URL_IN_CSS_REF,
		(origin, path1, path2, path3) => {
			const filePath = path1 || path2 || path3;
			if (!filePath) {
				return origin;
			}
			if (!RELATIVE_PATH.test(filePath)) {
				return `url('${filePath}')`;
			}
			if (DATA_URI.test(filePath)) {
				return `url(${filePath})`;
			}
			if (filePath[0] === '/') {
				return `url('${extractOrigin(href) + filePath}')`;
			}
			const stack = href.split('?')[0].split('/');
			const parts = filePath.split('/');
			stack.pop();
			for (const part of parts) {
				if (part === '.') {
					// continue;
				} else if (part === '..') {
					stack.pop();
				} else {
					stack.push(part);
				}
			}
			return `url('${stack.join('/')}')`;
		}
	);
};

const getAbsoluteSrcsetString = (doc: Document, attributeValue: string): string => {
	if (attributeValue.trim() === '') {
		return attributeValue;
	}

	// srcset attributes is defined as such:
	// srcset = "url size,url1 size1"
	if(attributeValue.indexOf('base64,') != -1){
		if(attributeValue.indexOf('x,') != -1){
			return attributeValue.split('x,')
				.map((srcItem) => {
					// removing all but middle spaces
					const trimmedSrcItem = srcItem.trim();
					const urlAndSize = trimmedSrcItem.split(' ');
					// this means we have both 0:url and 1:size
					if (urlAndSize.length === 2) {
						const absUrl = absoluteToDoc(doc, urlAndSize[0]);
						return `${absUrl} ${urlAndSize[1]}x`;
					} else if (urlAndSize.length === 1) {
						const absUrl = absoluteToDoc(doc, urlAndSize[0]);
						return `${absUrl}`;
					}
					return '';
				})
				.join(', ');
		}else if(attributeValue.indexOf('w,') != -1){
			return attributeValue.split('w,')
				.map((srcItem) => {
					// removing all but middle spaces
					const trimmedSrcItem = srcItem.trim();
					const urlAndSize = trimmedSrcItem.split(' ');
					// this means we have both 0:url and 1:size
					if (urlAndSize.length === 2) {
						const absUrl = absoluteToDoc(doc, urlAndSize[0]);
						return `${absUrl} ${urlAndSize[1]}w`;
					} else if (urlAndSize.length === 1) {
						const absUrl = absoluteToDoc(doc, urlAndSize[0]);
						return `${absUrl}`;
					}
					return '';
				})
				.join(', ');
		}

	}
	return attributeValue.split(',')
		.map((srcItem) => {
			// removing all but middle spaces
			const trimmedSrcItem = srcItem.trim();
			const urlAndSize = trimmedSrcItem.split(' ');
			// this means we have both 0:url and 1:size
			if (urlAndSize.length === 2) {
				const absUrl = absoluteToDoc(doc, urlAndSize[0]);
				return `${absUrl} ${urlAndSize[1]}`;
			} else if (urlAndSize.length === 1) {
				const absUrl = absoluteToDoc(doc, urlAndSize[0]);
				return `${absUrl}`;
			}
			return '';
		})
		.join(', ');
};

export const transformAttribute = (
	window: Window,
	doc: Document,
	name: string,
	value: string
): string => {
	// relative path in attribute
	if (name === 'src' || (name === 'href' && value)) {
		return absoluteToDoc(doc, value);
	} else if (name === 'srcset' && value) {
		return getAbsoluteSrcsetString(doc, value);
	} else if (name === 'style' && value) {
		return absoluteToStylesheet(value, window.location.href);
	} else {
		return value;
	}
};

const isCSSImportRule = (rule: CSSRule): rule is CSSImportRule => {
	return 'styleSheet' in rule;
};

const getCssRuleString = (rule: CSSRule): string => {
	return isCSSImportRule(rule) ? (getCssRulesString(rule.styleSheet) || '') : rule.cssText;
};

const getCssRulesString = (s: CSSStyleSheet): string | null => {
	try {
		const rules = s.rules || s.cssRules;
		return rules
			? Array.from(rules).reduce(
				(prev, cur) => prev + getCssRuleString(cur),
				''
			)
			: null;
	} catch (error) {
		return null;
	}
};

const isSVGElement = (el: Element): boolean => el.tagName === 'svg' || el instanceof SVGElement;

const serializeNode = (
	node: Node,
	window: Window,
	doc: Document,
	blockClass: string | RegExp,
	inlineStylesheet: boolean,
	maskInputOptions: MaskInputOptions = {}
): SerializedNode | false => {
	switch (node.nodeType) {
		case node.DOCUMENT_NODE:
			return {
				type: NodeType.Document,
				childNodes: []
			};
		case node.DOCUMENT_TYPE_NODE:
			return {
				type: NodeType.DocumentType,
				name: (node as DocumentType).name,
				publicId: (node as DocumentType).publicId,
				systemId: (node as DocumentType).systemId
			};
		case node.ELEMENT_NODE:
			let needBlock = false;
			if (typeof blockClass === 'string') {
				needBlock = (node as HTMLElement).classList.contains(blockClass);
			} else {
				(node as HTMLElement).classList.forEach((className) => {
					if (blockClass.test(className)) {
						needBlock = true;
					}
				});
			}
			const tagName = getValidTagName((node as HTMLElement).tagName);
			let attributes: Attributes = {};
			for (const { name, value } of Array.from((node as HTMLElement).attributes)) {
				attributes[name] = transformAttribute(window, doc, name, value);
			}
			// remote css
			if (tagName === 'link' && inlineStylesheet) {
				const stylesheet = Array.from(doc.styleSheets).find((s) => {
					return s.href === (node as HTMLLinkElement).href;
				});
				const cssText = getCssRulesString(stylesheet as CSSStyleSheet);
				if (cssText) {
					delete attributes.rel;
					delete attributes.href;
					attributes._cssText = absoluteToStylesheet(cssText, stylesheet!.href!);
				}
			}
			// dynamic stylesheet
			if (
				tagName === 'style' &&
				(node as HTMLStyleElement).sheet &&
				// TODO: Currently we only try to get dynamic stylesheet when it is an empty style element
				!(
					(node as HTMLElement).innerText ||
					(node as HTMLElement).textContent ||
					''
				).trim().length
			) {
				const cssText = getCssRulesString((node as HTMLStyleElement).sheet as CSSStyleSheet);
				if (cssText) {
					attributes._cssText = absoluteToStylesheet(cssText, window.location.href);
				}
			}
			// form fields
			if (tagName === 'input' || tagName === 'textarea' || tagName === 'select') {
				const value = (node as HTMLInputElement | HTMLTextAreaElement).value;
				if (
					attributes.type !== 'radio' &&
					attributes.type !== 'checkbox' &&
					attributes.type !== 'submit' &&
					attributes.type !== 'button' &&
					value
				) {
					attributes.value =
						maskInputOptions[attributes.type as keyof MaskInputOptions] ||
						maskInputOptions[tagName as keyof MaskInputOptions]
							? '*'.repeat(value.length)
							: value;
				} else if ((node as HTMLInputElement).checked) {
					attributes.checked = (node as HTMLInputElement).checked;
				}
			}
			if (tagName === 'option') {
				const selectValue = (node as HTMLOptionElement).parentElement;
				if (attributes.value === (selectValue as HTMLSelectElement).value) {
					attributes.selected = (node as HTMLOptionElement).selected;
				}
			}
			// canvas image data
			if (tagName === 'canvas') {
				attributes.rr_dataURL = (node as HTMLCanvasElement).toDataURL();
				// canvas在重建的时候会被替换为img, 因此可能会丢失样式, 特别是高宽
				const { width, height } = (node as HTMLElement).getBoundingClientRect();
				attributes.rr_width = `${width}px`;
				attributes.rr_height = `${height}px`;
			}
			// media elements
			if (tagName === 'audio' || tagName === 'video') {
				attributes.rr_mediaState = (node as HTMLMediaElement).paused
					? 'paused'
					: 'played';
			}
			// scroll
			if ((node as HTMLElement).scrollLeft) {
				attributes.rr_scrollLeft = (node as HTMLElement).scrollLeft;
			}
			if ((node as HTMLElement).scrollTop) {
				attributes.rr_scrollTop = (node as HTMLElement).scrollTop;
			}
			if (needBlock) {
				const { width, height } = (node as HTMLElement).getBoundingClientRect();
				attributes.rr_width = `${width}px`;
				attributes.rr_height = `${height}px`;
			}
			return {
				type: NodeType.Element,
				tagName,
				attributes,
				childNodes: [],
				isSVG: isSVGElement(node as Element) || undefined,
				needBlock
			};
		case node.TEXT_NODE:
			// The parent node may not be a html element which has a tagName attribute.
			// So just let it be undefined which is ok in this use case.
			const parentTagName =
				node.parentNode && (node.parentNode as HTMLElement).tagName;
			let textContent = (node as Text).textContent;
			const isStyle = parentTagName === 'STYLE' ? true : undefined;
			if (isStyle && textContent) {
				textContent = absoluteToStylesheet(textContent, window.location.href);
			}
			if (parentTagName === 'SCRIPT') {
				textContent = 'SCRIPT_PLACEHOLDER';
			}
			return {
				type: NodeType.Text,
				textContent: textContent || '',
				isStyle
			};
		case node.CDATA_SECTION_NODE:
			return {
				type: NodeType.CDATA,
				textContent: ''
			};
		case node.COMMENT_NODE:
			return {
				type: NodeType.Comment,
				textContent: (node as Comment).textContent || ''
			};
		default:
			return false;
	}
};

export const serializeNodeWithId = (
	node: Node | INode,
	window: Window,
	doc: Document,
	map: IdNodeMap,
	blockClass: string | RegExp,
	skipChild = false,
	inlineStylesheet = true,
	maskInputOptions?: MaskInputOptions
): SerializedNodeWithId | null => {
	const _serializedNode = serializeNode(
		node,
		window,
		doc,
		blockClass,
		inlineStylesheet,
		maskInputOptions
	);
	if (!_serializedNode) {
		// TODO: dev only
		console.warn(node, 'Not serialized');
		return null;
	}
	let id;
	// Try to reuse the previous id
	if ('__sn' in node) {
		id = node.__sn.id;
	} else {
		id = generateNodeId();
	}
	const serializedNode = Object.assign(_serializedNode, { id });
	(node as INode).__sn = serializedNode;
	map[id] = node as INode;
	let recordChild = !skipChild;
	if (serializedNode.type === NodeType.Element) {
		recordChild = recordChild && !serializedNode.needBlock;
		// this property was not needed in replay side
		delete serializedNode.needBlock;
	}
	if (serializedNode.type === NodeType.Element && (serializedNode as ElementNode).tagName.toLowerCase() === 'iframe') {
		// iframe
		const iframe = node as HTMLIFrameElement;
		const iframeDocument = iframe.contentDocument;
		let id;
		if (iframeDocument && '__sn' in iframeDocument) {
			// @ts-ignore
			id = iframeDocument.__sn.id;
		} else {
			id = generateNodeId();
		}
		const serializedIFrameDocNode = {
			type: NodeType.Document,
			id,
			childNodes: []
		} as any;
		serializedNode.childNodes.push(serializedIFrameDocNode);
		if (iframeDocument) {
			// @ts-ignore
			iframeDocument.__sn = serializedIFrameDocNode;
			for (const childN of Array.from(iframeDocument.childNodes)) {
				const serializedChildNode = serializeNodeWithId(
					childN,
					iframe.contentWindow!,
					iframeDocument,
					map,
					blockClass,
					skipChild,
					inlineStylesheet,
					maskInputOptions
				);
				if (serializedChildNode) {
					serializedIFrameDocNode.childNodes.push(serializedChildNode);
				}
			}
		}
	} else if ((serializedNode.type === NodeType.Document || serializedNode.type === NodeType.Element) && recordChild) {
		// document or element
		for (const childN of Array.from(node.childNodes)) {
			const serializedChildNode = serializeNodeWithId(
				childN,
				window,
				doc,
				map,
				blockClass,
				skipChild,
				inlineStylesheet,
				maskInputOptions
			);
			if (serializedChildNode) {
				serializedNode.childNodes.push(serializedChildNode);
			}
		}
	}
	return serializedNode;
};

const captureSnapshot = (
	window: Window,
	doc: Document,
	blockClass: string | RegExp = 'rr-block',
	inlineStylesheet = true,
	maskAllInputsOrOptions: boolean | MaskInputOptions
): [ SerializedNodeWithId | null, IdNodeMap ] => {
	const idNodeMap: IdNodeMap = {};
	const maskInputOptions: MaskInputOptions =
		maskAllInputsOrOptions === true
			? {
				color: true,
				date: true,
				'datetime-local': true,
				email: true,
				month: true,
				number: true,
				range: true,
				search: true,
				tel: true,
				text: true,
				time: true,
				url: true,
				week: true,
				textarea: true,
				select: true
			}
			: maskAllInputsOrOptions === false
			? {}
			: maskAllInputsOrOptions;
	return [
		serializeNodeWithId(
			doc,
			window,
			doc,
			idNodeMap,
			blockClass,
			false,
			inlineStylesheet,
			maskInputOptions
		),
		idNodeMap
	];
};

export default captureSnapshot;