import Account from '../service/account-model';
import { doGet } from '../service/ajax';
import { getAccount, clearAccount } from '../service/storage';

export const profile = async (access_ticket: string): Promise<Account> => {
	const resp = await doGet(`/api/ccic/account/profile?access_ticket=${access_ticket}`);
    sessionStorage.setItem("glutton-account-role", JSON.stringify(resp?.body?.roles ? resp.body.roles : []));
    sessionStorage.setItem("glutton-account-tenantId", JSON.stringify(resp?.body?.tenantId ? resp.body.roles : null));
    window.location.replace(window.location.href.split(window.location.host)[0]+window.location.host);
    return getAccount();
};

export const validateCcicTicket = async (onSigned: (account: Account) => void) => {
    const localHref = window.location.href;
    if (localHref.split('?').length > 1 && localHref.indexOf('ticket=') > 0) {
        const account = getAccount();
        if (!account.accountName || !account.token) {
            const urlParams = localHref.split('?')[1].split('&');

            urlParams.every(async (item) => {
                if (item.indexOf('ticket=')===0) {
                    const account = await profile(item.split('=')[1]);
                    onSigned(account);
                    // sessionStorage.setItem("glutton-account", JSON.stringify({accountName, token, isAuthorized: true}));
                    return false;
                }
            });
        }
    } else {
        ccicLogin();
    }
}

export const ccicLogin = () => {
    clearAccount();
    const signInUrl = process.env.REACT_APP_CAS_LOGIN_URL;
    window.location.href = signInUrl + '?service=' + window.location.origin;
}

export const ccicLogout = () => {
    clearAccount();
    const signOutUrl = process.env.REACT_APP_CAS_LOGOUT_URL;
	window.location.href = signOutUrl + '?service=' + window.location.origin;
}
