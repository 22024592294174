import { assign, createMachine, interpret } from '@xstate/fsm';
import { addDelay } from './replay-timer';
import {
	ActionWithDelay,
	IncrementalEventType,
	PlayerAssets,
	PlayerContext,
	PlayerEvent,
	PlayerState,
	ReplayerEvents,
	SpeedContext,
	SpeedEvent,
	SpeedState,
	WebEventType,
	WebEventWithTime,
} from '../rrweb/types';

const discardPriorSnapshots = (
	events: Array<WebEventWithTime>,
	baselineTime: number,
): Array<WebEventWithTime> => {
	for (let idx = events.length - 1; idx >= 0; idx--) {
		const event = events[idx];
		if (event.type === WebEventType.Meta) {
			if (event.timestamp <= baselineTime) {
				return events.slice(idx);
			}
		}
	}
	return events;
};

const needCastInSyncMode = (event: WebEventWithTime): boolean => {
	switch (event.type) {
		case WebEventType.DomContentLoaded:
		case WebEventType.Loaded:
		case WebEventType.Custom:
			return false;
		case WebEventType.FullSnapshot:
		case WebEventType.Meta:
			return true;
		default:
			break;
	}

	switch (event.data.source) {
		case IncrementalEventType.MouseMove:
		case IncrementalEventType.MouseInteraction:
		case IncrementalEventType.TouchMove:
		case IncrementalEventType.MediaInteraction:
			return false;
		case IncrementalEventType.ViewportResize:
		case IncrementalEventType.StyleSheetRule:
		case IncrementalEventType.Scroll:
		case IncrementalEventType.Input:
			return true;
		default:
			break;
	}

	return true;
};

export const createPlayerService = (
	context: PlayerContext,
	{ getCastFn, emitter }: PlayerAssets,
) => {
	const playerMachine = createMachine<PlayerContext, PlayerEvent, PlayerState>(
		{
			id: 'player',
			context,
			initial: 'paused',
			states: {
				playing: {
					on: {
						PAUSE: {
							target: 'paused',
							actions: [ 'pause' ],
						},
						CAST_EVENT: {
							target: 'playing',
							actions: 'castEvent',
						},
						END: {
							target: 'paused',
							actions: [ 'resetLastPlayedEvent', 'pause' ],
						},
					},
				},
				paused: {
					on: {
						PLAY: {
							target: 'playing',
							actions: [ 'recordTimeOffset', 'play' ],
						},
						CAST_EVENT: {
							target: 'paused',
							actions: 'castEvent',
						},
					},
				},
				live: {
					on: {
						ADD_EVENT: {
							target: 'live',
							actions: [ 'addEvent' ],
						},
					},
				},
			},
		},
		{
			actions: {
				castEvent: assign({
					lastPlayedEvent: (ctx, event) => {
						if (event.type === 'CAST_EVENT') {
							return event.payload.event;
						}
						return ctx.lastPlayedEvent;
					},
				}),
				recordTimeOffset: assign((ctx, event) => {
					let timeOffset = ctx.timeOffset;
					if ('payload' in event && 'timeOffset' in event.payload) {
						timeOffset = event.payload.timeOffset;
					}
					return {
						...ctx,
						timeOffset,
						baselineTime: ctx.events[0].timestamp + timeOffset,
					};
				}),
				play(ctx) {
					const { timer, events, baselineTime, lastPlayedEvent } = ctx;
					timer.clear();
					for (const event of events) {
						// TODO: improve this API
						addDelay(event, baselineTime);
					}
					const neededEvents = discardPriorSnapshots(events, baselineTime);

					const actions = new Array<ActionWithDelay>();
					for (const event of neededEvents) {
						if (
							lastPlayedEvent &&
							lastPlayedEvent.timestamp < baselineTime &&
							(event.timestamp <= lastPlayedEvent.timestamp ||
								event === lastPlayedEvent)
						) {
							continue;
						}
						const isSync = event.timestamp < baselineTime;
						if (isSync && !needCastInSyncMode(event)) {
							continue;
						}
						const castFn = getCastFn(event, isSync);
						if (isSync) {
							castFn();
						} else {
							actions.push({
								doAction: () => {
									castFn();
									emitter.emit(ReplayerEvents.EventCast, event);
									emitter.emit('refreshTimestamp', event.originalTimestamp);
								},
								delay: event.delay!,
							});
						}
					}
					emitter.emit(ReplayerEvents.Flush);
					timer.addActions(actions);
					if(!window.location.href.endsWith('/headlessPlayer')){
						timer.start();
					}else{
						// @ts-ignore
						window.doNextAction = timer.doNextAction;
					}
				},
				pause(ctx) {
					ctx.timer.clear();
				},
				resetLastPlayedEvent: assign((ctx) => {
					return {
						...ctx,
						lastPlayedEvent: null,
					};
				}),
				startLive: assign({
					baselineTime: (ctx, event) => {
						ctx.timer.toggleLiveMode(true);
						ctx.timer.start();
						if (event.type === 'TO_LIVE' && event.payload.baselineTime) {
							return event.payload.baselineTime;
						}
						return Date.now();
					},
				}),
				addEvent: assign((ctx, machineEvent) => {
					const { baselineTime, timer, events } = ctx;
					if (machineEvent.type === 'ADD_EVENT') {
						const { event } = machineEvent.payload;
						addDelay(event, baselineTime);
						events.push(event);
						const isSync = event.timestamp < baselineTime;
						const castFn = getCastFn(event, isSync);
						if (isSync) {
							castFn();
						} else {
							timer.addAction({
								doAction: () => {
									castFn();
									emitter.emit(ReplayerEvents.EventCast, event);
								},
								delay: event.delay!,
							});
						}
					}
					return { ...ctx, events };
				}),
			},
		},
	);
	return interpret(playerMachine);
};

export const createSpeedService = (context: SpeedContext) => {
	const speedMachine = createMachine<SpeedContext, SpeedEvent, SpeedState>(
		{
			id: 'speed',
			context,
			initial: 'normal',
			states: {
				normal: {
					on: {
						FAST_FORWARD: {
							target: 'skipping',
							actions: [ 'recordSpeed', 'setSpeed' ],
						},
						SET_SPEED: {
							target: 'normal',
							actions: [ 'setSpeed' ],
						},
					},
				},
				skipping: {
					on: {
						BACK_TO_NORMAL: {
							target: 'normal',
							actions: [ 'restoreSpeed' ],
						},
						SET_SPEED: {
							target: 'normal',
							actions: [ 'setSpeed' ],
						},
					},
				},
			},
		},
		{
			actions: {
				setSpeed: (ctx, event) => {
					if ('payload' in event) {
						ctx.timer.setSpeed(event.payload.speed);
					}
				},
				recordSpeed: assign({
					normalSpeed: (ctx) => ctx.timer.speed,
				}),
				restoreSpeed: (ctx) => {
					ctx.timer.setSpeed(ctx.normalSpeed);
				},
			},
		},
	);

	return interpret(speedMachine);
};