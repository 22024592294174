import React, { useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
	Button,
	ButtonBar,
	ButtonBarAlignType,
	ButtonType,
	FormSectionGapVertical,
	PanelSeparator,
	QueryPanel,
	QueryResultColumns,
	QueryResultPanel,
	QueryResultRow,
	Text,
	WorkAreaTitle
} from '../component';
import Path from '../path';
import {AccountData, AccountDataPage, findGluttonUser} from '../service/account-service';
const UserQuery = styled.div`
	display: flex;
	flex-direction: column;
`;

const ButtonWrapper = styled.div`
	text-align: center;
	> *:last-child {
		display: inline-block;
		margin-left: var(--gap-thin);
	}
`;

const columns: QueryResultColumns = [
	{ label: '系统名称', width: '180px', name: 'accountName'},
    { label: '有效标志', width: '180px', name: 'flag'},
	{ label: '创建时间', width: '180px', name: 'createAt'}
	];

type InputCriteria = Exclude<keyof AccountData, 'pageSize' | 'pageNumber'>

export default () => {
	const history = useHistory();
	const [ , forceUpdate ] = useReducer(x => x + 1, 0);
	const [ data, setData ] = useState<AccountDataPage | null>(null);
	const [ searched, setSearched ] = useState(false);
	const [ criteria ] = useState<AccountData>( {});

	const [ currentCriteria, setCurrentCriteria ] = useState<AccountData | null>(null);

	const onChanged = (propName: InputCriteria) => (event: React.ChangeEvent) => {
		// @ts-ignore
		criteria[propName] = (event.target as HTMLInputElement).value;
		forceUpdate();
	};

	const doSearch = async (account: AccountData) => {
		// 设置当前查询
		setCurrentCriteria(account);
		const data = await findGluttonUser(account);
		setData(data);
		setSearched(true);
	};
	const onSearchClicked = async (account: AccountData) => {	
		await doSearch(account);
	};

	const onAddClicked = () => {
		history.push(Path.GLUTTON_USER_EDIT);
	};

	const dataFound = data || { pageNumber: 1, pageSize: 10, totalPages: 1, totalItemCount: 0, rows: [] };

	const rows = (dataFound.rows || []).map((row): QueryResultRow => {
		const data = row as QueryResultRow;
		data.flag = row.enabled ? '有效' : '无效';
		return data;
	});

	return <UserQuery>
		<WorkAreaTitle title="账户配置信息"/>
		<QueryPanel>
			<Text label="系统名称" placeholder="请输入" value={criteria.accountName} onChange={onChanged('accountName')}/>
			<PanelSeparator columns={3}/>
			<ButtonBar align={ButtonBarAlignType.CENTER}>
				<Button label="查询" onClick={() => onSearchClicked(criteria)}/>
				<Button label="添加" type={ButtonType.WAIVE} onClick={onAddClicked}/>
			</ButtonBar>
			<PanelSeparator columns={3}/>
		</QueryPanel>
		<FormSectionGapVertical/>
		<QueryResultPanel columns={columns} rows={rows} pagination={false} searched={searched}/>
	</UserQuery>;
}