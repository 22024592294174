import React, { ReactNode, useReducer } from 'react';
import Account from '../service/account-model';
import { getAccount, getAccountRoles } from '../service/storage';

export interface SecurityContext extends Account {
	accountChange: () => void;
}

const Context = React.createContext<SecurityContext>({
	accountName: '',
	token: '',
	isAuthorized: false,
	roles: [],
	accountChange: (): void => {
	}
});

export const SecurityProvider = (props: { children?: ReactNode }) => {
	const { children } = props;

	const [, forceUpdate] = useReducer(x => x < 10 ? x + 1 : x < 0 ? x + 1 : x - 1, 0);
	const account = getAccount();
	const accountRoles = getAccountRoles();

	return <Context.Provider value={{
		accountName: account.accountName,
		token: account.token,
		isAuthorized: account.isAuthorized,
		roles: accountRoles,
		accountChange: () => forceUpdate()
	}}>{children}</Context.Provider>;
};

export const useSecurity = () => {
	return React.useContext(Context);
};
