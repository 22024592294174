import { NodeType, SerializedNodeWithId } from '../../types';
import { CommonDataSet } from '../common/types';
import { buildBooleanValue, buildDefaultAttributes, buildFontStyle } from './utils';

//  <input class="weui-input" type="number" placeholder="这是一个数字输入框" />

export type StandInputDataSet = {
	text?: string;
	value?: string;
	placeholder?: string;
	placeholderClass?: string;
	placeholderStyle?: string;
	disabled?: 'true' | 'false' | boolean;
} & CommonDataSet;
export const buildStandardInput = (dataset: StandInputDataSet, nodeIdIncrementer: () => number): Array<SerializedNodeWithId> => {
	const text = dataset.text || dataset.value;
	const hasValue = text ? true : false;
	return [ {
		type: NodeType.Element,
		id: nodeIdIncrementer(),
		tagName: 'div',
		attributes: {
			...buildDefaultAttributes('input', dataset, buildFontStyle(dataset)),
			'data-text': hasValue ? 'true' : 'false',
			'data-disabled': buildBooleanValue(dataset.disabled)
		},
		childNodes: [ {
			type: NodeType.Text,
			id: nodeIdIncrementer(),
			textContent: text ? text : (dataset.placeholder || '')
		} ]
	} as SerializedNodeWithId ];
};

export const InputStyles = `
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=input] {
	position: relative;
	background: #fff;
	min-height: 1.4rem;
    border: none;
    height: inherit;
    width: 100%;
    font-size: inherit;
    font-weight: inherit;
	display: inherit;
    padding: 0;
    margin: 0;
    outline: none;
    vertical-align: middle;
    text-align: inherit;
    overflow: inherit;
    white-space: inherit;
    text-overflow: inherit;
    -webkit-tap-highlight-color: transparent;
    z-index: 2;
}
html[data-css=glutton-wmp] div:not([data-rdcs])[data-css=input][data-text=false] {
    line-height: 100%;
	color: gray;
}
`;