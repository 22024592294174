import React, {useState, useEffect, useReducer} from 'react';
import styled from 'styled-components';
import { QueryResultPanel, QueryResultColumns, QueryResultRow, WorkAreaTitle, CheckBox, Button, ButtonBar, ButtonBarAlignType, } from '../component';
import { TraceViewContextProvider } from '../trace/trace-view-context';
import {initData } from '../trace/trace-view-data';
import PlayerPanel from './full-trace-detail-player-panel';
import { TraceDataPage,	findFullTraceData } from '../service/trace-service';
import { Details } from './trace-view-types';
import { aproveRecoverArchiveData } from '../service/archive-service';
import { WebEventWithTime } from '../catcher/rrweb/types';
import {doPost} from "../service/ajax";

const FullTraceDetail = styled.div`
	display: flex;
	grid-template-columns: calc(75%) 1fr;
	grid-column-gap: var(--gap);
	flex-direction: column;
	> div:first-child {
		grid-column: span 2;
	}
`;

const columns: QueryResultColumns = [
	{ label: '#', width: '40px', name: 'columnChecked' },
	{ label: '视频生成时间', width: '150px', name: 'createTime' },
	{ label: '保险产品计划', width: '1fr', name: 'productName' },
	{
		label: <React.Fragment>
			<div>报价单号</div>
			<div>保单号</div>
			<div>订单号</div>
		</React.Fragment>, width: '200px', name: [ 'quoteNo', 'policyNo', 'orderNo' ]
	},
	{
		label: <React.Fragment>
			<div>付款号</div>
			<div>支付订单号</div>
		</React.Fragment>, width: '200px', name: [ 'payNo', 'payOrderNo' ]
	},
	{
		label: <React.Fragment>
			<div>投保人</div>
			<div>投保人证件号码</div>
			<div>投保人手机号码</div>
		</React.Fragment>,
		width: '160px',
		name: [ 'policyHolderName', 'policyHolderIdNo', 'policyHolderMobile' ]
	},
	{ label: '来源', width: '100px', name: 'source' }
];

export default (props: { traceId: string ,createAt: string}) => {
	const { traceId,createAt } = props;

	const [ , forceUpdate ] = useReducer(x => x + 1, 0);
	const [ searched, setSearched ] = useState(false);
	const [ fullTrace, setFullTrace ] = useState<TraceDataPage | null>(null);

	useEffect(() => {
		(async () => {
			const fullTraceData = await findFullTraceData(traceId,createAt.substring(0,7).replace('-',''));
			setFullTrace(fullTraceData);
			setSearched(true);
		})();
	}, [ traceId,createAt ]);
	
	const fullTraceFound = fullTrace || { pageNumber: 1, pageSize: 10, totalPages: 1, totalItemCount: 0, rows: [] };
	const rows = (fullTraceFound.rows || []).map((row): QueryResultRow => {
		const data = row as QueryResultRow;
	
		const onChanged = (propName?: QueryResultRow) => (event: React.ChangeEvent) => {
			// @ts-ignore
			data.checked = (event.target as HTMLInputElement).checked;
			forceUpdate();
		};

		//<FontAwesomeIcon icon={faCheckSquare}/>
		data.columnChecked = (<CheckBox value={data.checked} onChange={onChanged()} />);

		return data;
	});

	const reCalcTimeStamp = (events: Array<WebEventWithTime>, previousDetailLastEvent: WebEventWithTime) => {
		if (events && events.length>0 && previousDetailLastEvent) {
			let timestampChanged = 0;
			const timestampDiff = (events[0].timestamp || 0) - (previousDetailLastEvent.timestamp || 0);
			if (timestampDiff > 5*1000) {
				timestampChanged = 5*1000 - timestampDiff;
			} else if (timestampDiff < 0) {
				timestampChanged = 0-timestampDiff + 2*1000;
			}

			if (timestampChanged !== 0) {
				for(let i = 0;i < events.length; i++){
					events[i].timestamp += timestampChanged;
				}
			}
		}
	};

	const [ details, setDetails ] = useState<Details>({ loaded: false });
	const onMergePlayClicked = async (rows: Array<any>) => {
		const detailList : Array<Details> = [];
		let maskRules : string = '';
		const checkedRows = rows.filter(row => {return row.checked}).sort((a,b)=>{
			return a.createTime<b.createTime ? -1:1;
		});
		if(checkedRows.length == 0){
			alert('请选择需要播放的Trace');
			return;
		}
		let tips = document.getElementsByClassName('tips');
		tips.item(0)!.innerHTML = '视频加载中...';
		let count = 0;
		checkedRows.forEach( async (rowData) => {
			if (rowData.checked) {
				const trace = {traceNo: rowData.traceNo, traceId: rowData.traceId, createTime: rowData.createTime, createdByAccountName: rowData.createdByAccountName};
				const detail = await initData(trace, {accountName:trace.createdByAccountName, isAuthorized:true});
				count ++;
				if (detail.events && detail.events.length >0) {
					detailList.push(detail);
					if(rowData.maskRules && rowData.maskRules != '') {
						maskRules = maskRules.concat(rowData.maskRules + ',');
					}
					if (count===checkedRows.length) {
						let detailCount = detailList.length;
						detailList.sort((a,b)=>{
							return (a.events||[])[0].timestamp - (b.events||[])[0].timestamp;
						}).forEach(detailData => {
		
							if (detailList.length > 1 && details.events) {
								reCalcTimeStamp(detailData.events || [], details.events[details.events.length-1]);
							}
		
							details.stepTimeOffsets = details.stepTimeOffsets ? Object.assign(details.stepTimeOffsets, detailData.stepTimeOffsets) : detailData.stepTimeOffsets;
							details.events = (details.events || []).concat(detailData.events || []);
							details.metas = (details.metas || []).concat(detailData.metas || []);
							details.contents = (details.contents || []).concat(detailData.contents || []);
				
							detailCount --;
							if (detailCount===0) {
								sessionStorage.setItem('maskRules',maskRules.length > 1 ? maskRules.substring(0,maskRules.length-1) : '');
								details.loaded = true;
								setDetails(details);
								forceUpdate();
							}
						});
					}
				} else {
					console.log(trace.traceNo,'没有视频被录制, 请联系系统管理员查询.');
				}
			}
		});
	};

	const onMergeDownloadClicked = async (rows: Array<any>) => {
		if(document.getElementById('downloadBtn')!.style.cursor == 'not-allowed'){
			return;
		}
		const checkedRows = rows.filter(row => {return row.checked});
		if(checkedRows.length == 0){
			alert('请选择需要合并下载的Trace');
			return;
		}
		let requestBody : Array<any> = [];
		checkedRows.forEach( async (rowData) => {
				const trace = {traceNo: rowData.traceNo, traceId: rowData.traceId,
					createTime: rowData.createTime, createdByAccountName: rowData.createdByAccountName,
					maskRules:rowData.maskRules};
				requestBody.push(trace);
		});
		console.log(requestBody);
		const response = await doPost('/api/trace/download', requestBody);
		alert(response.body);
		document.getElementById('downloadBtn')!.style.cursor = 'not-allowed';
	};

	const onQuitPlayClicked = () => {
		setDetails({ loaded: false });
		forceUpdate();
	};

	return <FullTraceDetail>
		<WorkAreaTitle title="销售资料回溯明细列表" back={false}/>
		<TraceViewContextProvider>
			<QueryResultPanel columns={columns} rows={rows} pagination={false} searched={searched}/>
			<ButtonBar align={ButtonBarAlignType.CENTER}>
				<Button label="合并播放" onClick={() => onMergePlayClicked(rows)}/>
				<Button label="退出播放" onClick={() => onQuitPlayClicked()}/>
				{details.loaded ? <Button id='downloadBtn' label="合并下载" onClick={() => onMergeDownloadClicked(rows)}/> : null}
			</ButtonBar>
			<PlayerPanel details={details}/>
		</TraceViewContextProvider>
	</FullTraceDetail>;
}