import { getAccount } from './storage';
import { isCCICDisplay } from '../utils';
import { ccicLogout } from '../ccic/cas-service';

export interface FetchOptions {
	method?: 'GET' | 'POST';
	headers?: { [key in string]: string };
	credentials?: RequestCredentials;
	mode?: RequestMode;
	redirect?: RequestRedirect;
	cache?: RequestCache;
	ignoreAuth?: true
}

export interface CorrectResponse {
	returnCode: 'RC-00001',
	headers: { [key in string]: string },
	body: any,
	status: 200,
	statusText: string
}

const getServiceLocation = (relativePath?: string | null): string => {
	if (relativePath && (relativePath.startsWith('https://') || relativePath.startsWith('http://'))) {
		// 已经是绝对路径
		return relativePath;
	}

	let url = window.location;

	let port = url.port;
	if (process.env.REACT_APP_AJAX_SERVER_PORT) {
		port = `:${process.env.REACT_APP_AJAX_SERVER_PORT}`;
	} else if (port) {
		port = `:${port}`;
	}
	let hostname = url.hostname;
	if (process.env.REACT_APP_AJAX_SERVER_HOST) {
		hostname = process.env.REACT_APP_AJAX_SERVER_HOST;
	}
	let context = process.env.REACT_APP_AJAX_SERVER_CONTEXT || '/glutton';
	let location = `${url.protocol}//${hostname}${port}${context}`;
	if (relativePath) {
		return location + relativePath;
	} else {
		return location;
	}
};

function getResponseHeaders(response: Response) {
	const headers: { [key in string]: string } = {};
	response.headers.forEach((value: string, key: string) => {
		headers[key] = value;
	});
	return headers;
}

export const doFetch = async (url: string, options: FetchOptions, data?: any): Promise<CorrectResponse> => {
	options.headers = {
		'Content-Type': 'application/json',
		...(options.headers || {})
	};
	if (!options.ignoreAuth) {
		const account = getAccount();
		if (account.isAuthorized) {
			options.headers.Authorization = account.token!;
		}
	}
	const opts = {
		method: (options.method || 'GET').toUpperCase(), //请求方式
		headers: options.headers, // 请求头设置
		credentials: options.credentials || 'same-origin', // 设置cookie是否一起发送
		mode: options.mode || 'cors', // 可以设置 cors, no-cors, same-origin
		redirect: options.redirect || 'follow', // follow, error, manual
		cache: options.cache || 'default', // 设置 cache 模式 (default, reload, no-cache)
		body: (options.method || '').toUpperCase() === 'POST' ? JSON.stringify(data || {}) : undefined
	};

	return new Promise((resolve, reject) => {
		fetch(getServiceLocation(url), opts as any)
			.then((response: Response) => {
				const responseHeaders = getResponseHeaders(response);

				// 设置登录串
				const token = responseHeaders.authorization
				if (token && token.startsWith('Glutton ')) {
					const [accountName] = atob(token.substr('Glutton '.length)).split(':');
					sessionStorage.setItem("glutton-account", JSON.stringify({
						accountName,
						token,
						isAuthorized: true
					}));
				}

				if (response.ok) {
					response.json().then(data => {
						if (data.returnCode === 'RC-00001') {
							resolve({
								returnCode: data.returnCode,
								headers: responseHeaders,
								body: data.body,
								status: 200,
								statusText: response.statusText
							});
						} else {
							reject({
								headers: responseHeaders,
								returnCode: data.returnCode,
								errors: data.errors,
								status: response.status,
								statusText: response.statusText
							});
						}
					}).catch(e => {
						reject({
							headers: responseHeaders,
							returnCode: 'RC-99999',
							errors: [{ code: 'PARSE_ERROR' }],
							status: response.status,
							statusText: response.statusText,
							originError: e
						});
					});
				} else {
					if (isCCICDisplay() && responseHeaders.redirect==='REDIRECT') {
						ccicLogout();
					}
					// 返回码是不成功, 以reject结束
					reject({
						headers: responseHeaders,
						returnCode: 'RC-99999',
						errors: [{ code: 'FETCH_ERROR' }],
						status: response.status,
						statusText: response.statusText
					});
				}
			})
			.catch((e: Error) => {
				reject({
					headers: {},
					returnCode: 'RC-99999',
					errors: [{ code: 'FETCH_ERROR' }],
					status: 0,
					statusText: '',
					originError: e
				});
			});
	});
};


//ajax无法实现文件下载，使用XMLHttpRequest
export function postExcelFile(URL: string, data: any) {
	var method = 'post';
	var url = getServiceLocation(URL);
	var xhr = new XMLHttpRequest();
	xhr.open(method, url, true);
	xhr.responseType = 'blob';//文件流使用blob
	xhr.setRequestHeader('Content-Type', 'application/json;charset=utf-8');

	//设置请求头的authorization，否则会被拒绝
	const account = getAccount();
	if (account.isAuthorized) {
		var authorization = account.token!;
		xhr.setRequestHeader("authorization", authorization);
	}

	xhr.onload = function ()//就绪
	{
		if (this.status == 200) {
			var filename = this.getResponseHeader("filename");
			var blob = this.response;//响应返回的blob对象

			//兼容IE
			if (typeof window.navigator.msSaveBlob !== 'undefined') {
				window.navigator.msSaveBlob(blob, filename+"");
				return;
			}

			var a = document.createElement('a');
			var fileurl = window.URL.createObjectURL(blob);//生成一个相对于浏览器的虚拟url，指向传入的blob对象，浏览器可以通过这个url找到这个blob对象
			a.href = fileurl;
			a.download = filename+"";
			a.click();//主动触发a标签点击事件
		}else{
			alert('下载失败，请联系运维人员');
		}
	};
	xhr.send(JSON.stringify(
		data
	));//查询条件

};


export const doGet = async (url: string): Promise<CorrectResponse> => {
	return doFetch(url, {});
};


export const doPost = (url: string, body: any, options?: FetchOptions): Promise<CorrectResponse> => {
	return doFetch(url, { ...(options || {}), method: 'POST' }, body);
};

//Excel文件导出请求
export const doExport = (url: string, data: any) => {
	return postExcelFile(url, data);
};