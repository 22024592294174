import styled from 'styled-components';

export default styled.div`
	display: flex;
	height: var(--form-line-height);
	&[data-columns="2"] {
		grid-column: span 2;
	}
	&[data-columns="3"] {
		grid-column: span 3;
	}
	> label:first-child {
		width: var(--form-label-width);
		padding-right: var(--gap);
		white-space: nowrap;
		overflow-x: hidden;
		text-overflow: ellipsis;
		line-height: var(--form-line-height);
		user-select: none;
	}
	> *:last-child {
		flex-grow: 1;
		margin-top: calc((var(--form-line-height) - var(--input-height)) / 2);
	}
	@media (max-width: 599px) {
		flex-direction: column;
		height: calc(var(--form-line-height) * 2);
		> label:first-child {
			margin-top: var(--gap-thin);
			margin-bottom: calc(var(--gap-thin) * -1);
		}
	}
`;
