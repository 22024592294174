import { CommonDataSet } from '../common/types';

const asClassNames = (className?: string): Array<string> => {
	return `${(className || '')}`.split(' ').map(x => x.trim()).filter(x => x);
};
export const buildDefaultAttributes = (name: string, dataset: CommonDataSet, style?: string) => {
	if (!style) {
		style = dataset.style || '';
	} else {
		style = `${style}${dataset.style || ''}`;
	}

	return {
		'data-css': name,
		class: [ ...asClassNames(dataset.className), ...asClassNames(dataset.class) ].reduce((all, className) => {
			(!all.includes(className)) && all.push(className);
			return all;
		}, [] as Array<string>).join(' '),
		style,
		'data-rdcs': dataset.rdcs ? 'true' : undefined
	};
};

export const buildFontStyle = (font: { fontFamily?: string, fontSize?: string, fontStyle?: string, fontWeight?: string }) => {
	const { fontFamily, fontSize, fontStyle, fontWeight } = font;

	const value = [ fontStyle, fontWeight, fontSize, fontFamily ].filter(x => x).map(x => x?.trim()).filter(x => x).join(' ');
	if (value) {
		return `font:${value};`;
	} else {
		return '';
	}
};

export const buildBooleanValue = (value?: 'true' | 'false' | boolean): ('true' | 'false') => {
	return (value === 'true' || value === true) ? 'true' : 'false';
};