import { OssKit, TraceInfo, TraceItemInfo } from '../types';
import { doPost } from './ajax';
import Logger from './logger';
import { askOssStsToken, OssStsToken, uploadFile } from './oss-service';
import { atob } from './utils';

const DEFAULT_OSS_STS_TOKEN_TIMEOUT = (process.env.ENV_OSS_STS_TOKEN_TIMEOUT || (25 * 60 * 1000)) as number;
const DEFAULT_DATA_TOKEN_TIMEOUT = (process.env.ENV_DATA_TOKEN_TIMEOUT || (110 * 60 * 1000)) as number;

class DefaultOssKit implements OssKit {
	public readonly stsTokenTimout: number;
	public readonly dataTokenTimeout: number;
	private readonly getDataToken: () => Promise<string>;
	private readonly logger: Logger;

	private readonly accountName: string;

	private readonly serverHost: string;
	private dataToken: string | null = null;
	private dataTokenRetrieveTime: number = -1;
	private stsToken: OssStsToken | null = null;
	private stsTokenRetrieveTime: number = -1;
	private readonly uploadFile: (file: Buffer, fileName: string, stsToken: OssStsToken) => Promise<void>;

	constructor(
		firstToken: string,
		disableLogs: boolean,
		others?: {
			serverHost?: string;
			stsTokenTimeout?: number,
			dataTokenTimeout?: number,
			askDataToken?: () => Promise<string>;
			uploadToOss?: (file: Buffer, fileName: string, stsToken: OssStsToken) => Promise<void>;
			dataTokenTimeoutCallback?: (error: Error) => void;
		}) {
		this.logger = new Logger(disableLogs);
		this.dataToken = firstToken;
		this.dataTokenRetrieveTime = Date.now();
		this.accountName = DefaultOssKit.parseAccountName(this.dataToken);

		const {
			serverHost,
			stsTokenTimeout = DEFAULT_OSS_STS_TOKEN_TIMEOUT,
			dataTokenTimeout = DEFAULT_DATA_TOKEN_TIMEOUT,
			dataTokenTimeoutCallback,
			uploadToOss,
			askDataToken
		} = others || {};

		this.serverHost = serverHost || process.env.ENV_BACKEND_HOST || 'glutton-demo.ebaocloud.com.cn';
		this.stsTokenTimout = stsTokenTimeout;
		this.dataTokenTimeout = dataTokenTimeout;
		const onDataTokenTimeout = async (error: Error) => {
			if (dataTokenTimeoutCallback) {
				dataTokenTimeoutCallback(error);
			} else {
				console.error('No data token timeout callback provided.', error);
			}
		};
		this.getDataToken = async (): Promise<string> => {
			return new Promise<string>(async (resolve, reject) => {
				if (askDataToken) {
					try {
						const token = await askDataToken();
						resolve(token);
					} catch (error) {
						onDataTokenTimeout(error);
						reject(error);
					}
				} else {
					const error = new Error('No askDataToken given, cannot continue token life.');
					onDataTokenTimeout(error);
					reject(error);
				}
			});
		};
		this.uploadFile = uploadToOss || uploadFile;
	}

	askDataToken(): Promise<string> {
		return this.getDataToken();
	}

	private static parseAccountName(dataToken: string): string {
		// console.warn('in parse account name:', dataToken);
		const [ accountName ] = atob(dataToken.substr('Glutton '.length)).split(':');
		return accountName;
	}

	getAccountName(): string {
		return this.accountName;
	}

	public async askOssStsToken(): Promise<OssStsToken> {
		return askOssStsToken(this.serverHost, this.dataToken!);
	}

	public async createTraceItemInfo(traceItemInfo: TraceItemInfo): Promise<void> {
		this.logger.log('Start to save trace item info:', traceItemInfo);
		const traceLocation = traceItemInfo.fileLocation!;
		const itemLocation = traceLocation.substring(0, traceLocation.lastIndexOf('/')) + '/items/' + traceItemInfo.uuid + '.json';
		if(process.env.REACT_APP_CUSTOM_STORAGE && process.env.REACT_APP_CUSTOM_STORAGE == 'fastdfs'){
			let traceItemInfoStr = JSON.stringify(traceItemInfo);
			if(traceItemInfoStr.length < 1024){
				const len:number = 1024 - traceItemInfoStr.length;
				const randomstr =',\"randomStr\":\"'+this.randomStr(len)+'\"}';
				traceItemInfoStr = traceItemInfoStr.replace(/(.*)}/,'$1'+randomstr);
			}
			await doPost(this.serverHost, '/api/fdfs/upload', this.dataToken!, {buffer:traceItemInfoStr,fileName:'meta/'+itemLocation});
		}else{
			await this.fetchOssStsToken();
			const traceItemBuffer = Buffer.from(JSON.stringify(traceItemInfo), 'utf8');
			await this.uploadFile(traceItemBuffer, 'meta/'+itemLocation, this.stsToken!);
		}

		this.logger.log("Succeed to upload trace item.");
		this.logger.info('Trace item info saved.', traceItemInfo);
	}

	// 因为客户端的traceNo可能会改变.save时后台根据其他字段找到关联的Trace并返回DB中第一次保存的traceNo
	public async saveTraceInfo(traceInfo: TraceInfo): Promise<string> {
		this.logger.log('Start to save trace info:', traceInfo);
		await this.fetchDataToken();
		const { body } = await doPost(this.serverHost, '/api/trace/save', this.dataToken!, traceInfo);
		this.logger.info('Trace info saved.', traceInfo);
		return body?.traceNo;
	}

	public async uploadStageFile(content: Buffer, fileLocation: string): Promise<void> {
		this.logger.log('Start to upload stage file:', fileLocation);
		if(process.env.REACT_APP_CUSTOM_STORAGE && process.env.REACT_APP_CUSTOM_STORAGE == 'fastdfs'){
			await doPost(this.serverHost, '/api/fdfs/upload', this.dataToken!, {buffer:content.toString("utf8"),fileName:'rrweb/'+fileLocation});
		}else{
			await this.fetchOssStsToken();
			this.logger.log('uploadStageFile location info:', fileLocation);
			// @ts-ignore
			await this.uploadFile(content, 'rrweb/'+fileLocation, this.stsToken!);
		}
		this.logger.info('Stage file uploaded.', fileLocation);
	}

	private randomStr(n:number):string {
		let str = 'abcdefghijklmnopqrstuvwxyz9876543210';
		let tmp = '',
			i = 0,
			l = str.length;
		for (i = 0; i < n; i++) {
			tmp += str.charAt(Math.floor(Math.random() * l));
		}
		return tmp;
	}

	private async fetchDataToken(): Promise<void> {
		if (this.dataTokenRetrieveTime === -1 || (Date.now() - this.dataTokenRetrieveTime > this.dataTokenTimeout)) {
			const currentTime = Date.now();
			this.dataToken = await this.askDataToken();
			this.dataTokenRetrieveTime = currentTime;
		}
	}

	private async fetchOssStsToken(): Promise<void> {
		if (this.stsTokenRetrieveTime === -1 || (Date.now() - this.stsTokenRetrieveTime > this.stsTokenTimout)) {
			await this.fetchDataToken();
			const currentTime = Date.now();
			this.stsToken = await this.askOssStsToken();
			this.stsTokenRetrieveTime = currentTime;
			this.logger.log('Oss sts token retrieved:', this.stsToken);
		}
	};
}

export default DefaultOssKit;