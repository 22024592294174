// @ts-ignore
import { listenerHandler } from 'rrweb/typings/types';
import DataProcessor from '../data/data-processor';
import fallbacker from '../oss/fallbacker';
import Logger from '../oss/logger';
import { GluttonRecorderConfig, TraceInfo, TraceItem, TraceItemInfo } from '../types';
import RRWebRecorder from './record';
import { RecorderOptions, WebEventWithTime } from './types';
import { browser } from './device';

class GluttonRecorder {
	private readonly logger?: Logger;
	private recordOptions?: RecorderOptions;
	private dataProcessor?: DataProcessor;

	private readonly browserSupported: boolean;

	private isRecordingStopped: boolean = true;
	private eventsMatrix: Array<Array<WebEventWithTime>> = [ [] ];

	private doStop: listenerHandler | undefined;

	constructor(config: GluttonRecorderConfig) {
		// ie仅支持11
		this.browserSupported = !browser.ie || browser.version === '11';
		if (!this.browserSupported) {
			console.error('Your browser is not supported by glutton recorder yet, the Chrome latest version is recommended.');
		} else {
			const { disableLogs = true, oss: ossKit, autoPlay, compress, recordOptions } = config;
			this.logger = new Logger(disableLogs);
			this.recordOptions = {
				onEvent: this.onEvent,
				checkoutEveryNms: 10 * 1000,
				...(recordOptions || {})
			};
			this.dataProcessor = new DataProcessor({
				ossKit,
				logger: this.logger,
				getContext: () => {
					return (window as any).GluttonContext || {};
				},
				syncTraceNoToContext: (traceNo) => {
					(window as any).GluttonContext = {
						...(window as any).GluttonContext,
						traceNo
					};
				},
				compress
			});

			if (autoPlay) {
				this.startRecord();
			}

			// 监听浏览器Tab或窗口是否关闭,如关闭尝试记住最后一段录制的视频  window.unload
			// (window as any).onunload = () => {
			// 	this.stopRecord();
			// };
			this.submitUnsuccessfulTaskIfNecessary();
		}
	}

	private async submitUnsuccessfulTaskIfNecessary(): Promise<void> {
		return new Promise((resolve) => {
			fallbacker.listFallbackTasks().then((tasks: Array<TraceInfo | TraceItemInfo | TraceItem>) => {
				// try only once, clean fallback tasks directly
				fallbacker.cleanAllFallBackedTasks();
				// @ts-ignore
				(tasks || []).forEach((task: any) => {
					this.dataProcessor!.submitFailedTask(task);
				});
			});
			resolve();
		});
	}

	private onEvent = (event: WebEventWithTime | string | null, isCheckout?: boolean) => {
		if (event != null) {
			this.eventsMatrix[this.eventsMatrix.length - 1].push(event as WebEventWithTime);
		}
		if (isCheckout) {
			// get last event and submit to remote
			const events = this.eventsMatrix[this.eventsMatrix.length - 1] as unknown as Array<WebEventWithTime>;
			if (events && events.length > 0) {
				this.dataProcessor!.submit(events);
				this.eventsMatrix.push([]);
			}
		}
	};

	public async startRecord(): Promise<void> {
		if (!this.browserSupported) {
			console.error('Your browser is not supported by glutton recorder yet, the Chrome latest version is recommended.');
			return;
		}
		this.logger!.info('Glutton: start recording.');
		if (!this.isRecordingStopped) {
			this.logger!.info('Glutton: recorder already started.');
			return;
		}

		this.isRecordingStopped = false;
		this.doStop = new RRWebRecorder(this.recordOptions!).startRecord();
	}

	public forceSubmit(): void {
		if (!this.isRecordingStopped) {
			// 录制中
			this.onEvent(null, true);
		}
	}

	public async stopRecord(): Promise<void> {
		if (!this.browserSupported) {
			console.error('Your browser is not supported by glutton recorder yet, the Chrome latest version is recommended.');
			return;
		}
		this.isRecordingStopped = true;
		if (this.doStop) {
			try {
				this.doStop();
			} catch (e) {
				console.error(e);
			}
			this.dataProcessor!.resetKeptUuid();
			try {
				const events = this.eventsMatrix[this.eventsMatrix.length - 1] as unknown as Array<WebEventWithTime>;
				if (events && events.length > 0) {
					await this.dataProcessor!.submit(events);
				}
			} catch (e) {
				console.error(e);
			}
			this.eventsMatrix = [ [] ];
		}
	}
}

if (window) {
	// @ts-ignore
	window.GluttonRecorder = GluttonRecorder;
}

export default GluttonRecorder;