import { parse } from './snapshot-css';
import { Attributes, IdNodeMap, INode, NodeType, SerializedNodeWithId } from '../rrweb/types';

type TagMap = { [key: string]: string };
type ElementNode = {
	type: NodeType.Element;
	tagName: string;
	attributes: Attributes;
	childNodes: SerializedNodeWithId[];
	isSVG?: true;
	needBlock?: boolean;
};


const tagMap: TagMap = {
	script: 'noscript',
	// camel case svg element tag names
	altglyph: 'altGlyph',
	altglyphdef: 'altGlyphDef',
	altglyphitem: 'altGlyphItem',
	animatecolor: 'animateColor',
	animatemotion: 'animateMotion',
	animatetransform: 'animateTransform',
	clippath: 'clipPath',
	feblend: 'feBlend',
	fecolormatrix: 'feColorMatrix',
	fecomponenttransfer: 'feComponentTransfer',
	fecomposite: 'feComposite',
	feconvolvematrix: 'feConvolveMatrix',
	fediffuselighting: 'feDiffuseLighting',
	fedisplacementmap: 'feDisplacementMap',
	fedistantlight: 'feDistantLight',
	fedropshadow: 'feDropShadow',
	feflood: 'feFlood',
	fefunca: 'feFuncA',
	fefuncb: 'feFuncB',
	fefuncg: 'feFuncG',
	fefuncr: 'feFuncR',
	fegaussianblur: 'feGaussianBlur',
	feimage: 'feImage',
	femerge: 'feMerge',
	femergenode: 'feMergeNode',
	femorphology: 'feMorphology',
	feoffset: 'feOffset',
	fepointlight: 'fePointLight',
	fespecularlighting: 'feSpecularLighting',
	fespotlight: 'feSpotLight',
	fetile: 'feTile',
	feturbulence: 'feTurbulence',
	foreignobject: 'foreignObject',
	glyphref: 'glyphRef',
	lineargradient: 'linearGradient',
	radialgradient: 'radialGradient'
};

const getTagName = (n: ElementNode): string => {
	let tagName = tagMap[n.tagName] ? tagMap[n.tagName] : n.tagName;
	if (tagName === 'link' && n.attributes._cssText) {
		tagName = 'style';
	}
	return tagName;
};

const HOVER_SELECTOR = /([^\\]):hover/g;
const addHoverClass = (cssText: string): string => {
	const ast = parse(cssText, { silent: true });
	if (!ast.stylesheet) {
		return cssText;
	}
	ast.stylesheet.rules.forEach(rule => {
		if ('selectors' in rule) {
			(rule.selectors || []).forEach((selector: string) => {
				if (HOVER_SELECTOR.test(selector)) {
					const newSelector = selector.replace(HOVER_SELECTOR, '$1.\\:hover');
					cssText = cssText.replace(selector, `${selector}, ${newSelector}`);
				}
			});
		}
	});
	return cssText;
};

const formatCss = (t:string):string => {
	return t=t.replace(/\s*([\{\}\:\;\,])\s*/g,"$1"),t=t.replace(/;\s*;/g,";"),t=t.replace(/\,[\s\.\#\d]*{/g,"{"),t=t.replace(/([^\s])\{([^\s])/g,"$1 {\n\t$2"),t=t.replace(/([^\s])\}([^\n]*)/g,"$1\n}\n$2"),t=t.replace(/([^\s]);([^\s\}])/g,"$1;\n\t$2")
};

const buildNode = (
	n: SerializedNodeWithId,
	doc: Document,
	HACK_CSS: boolean
): Node | null => {
	switch (n.type) {
		case NodeType.Document:
			return doc ? doc.implementation.createDocument(null, '', null): null;
		case NodeType.DocumentType:
			try {
				return doc.implementation.createDocumentType(
					n.name,
					n.publicId,
					n.systemId
				);
			} catch (error) {
				// TODO: buildNode error
				console.warn(error);
				return null;
			}
		case NodeType.Element:
			const tagName = getTagName(n);
			let node: Element;
			if (n.isSVG) {
				node = doc.createElementNS('http://www.w3.org/2000/svg', tagName);
			} else if (tagName.toLowerCase() === 'canvas') {
				node = doc.createElement('img');
			} else {
				node = doc.createElement(tagName);
				if (tagName === 'iframe') {
					node.setAttribute('sandbox', 'allow-same-origin allow-scripts');
					node.setAttribute('scrolling', 'no');
					(node as HTMLIFrameElement).style.pointerEvents = 'none';
				}
			}
			node.setAttribute('data-rrweb-id', `${n.id}`);
			for (const name in n.attributes) {
				if (!n.attributes.hasOwnProperty(name)) {
					continue;
				}
				let value = n.attributes[name];
				value = typeof value === 'boolean' || typeof value === 'number' ? '' : value;
				// attribute names start with rr_ are internal attributes added by rrweb
				if (!name.startsWith('rr_')) {
					const isTextarea = tagName === 'textarea' && name === 'value';
					const isRemoteOrDynamicCss = tagName === 'style' && name === '_cssText';
					if (isRemoteOrDynamicCss) {
						value = value.replace(/(;|{|\s|\S]+)content:(?:([^;]*));/gm, (origin, prefix, value) => {
							value = value.trim();
							if (value.startsWith('"') || value.startsWith("'")) {
								return `${prefix}content:${value};`;
							} else {
								return `${prefix}content:'${value}';`;
							}
						}).replace(/(;|{|\s|\S]+)background-clip:[\s|\S]?text;/gm, (origin, prefix) => {
							return `${prefix}-webkit-background-clip:text;`;
						});
						//scrollbar隐藏
						value += 'div::-webkit-scrollbar { width: 0px; height: 0px }';
					}
					if (isRemoteOrDynamicCss && HACK_CSS) {
						value = addHoverClass(value);
						//mej集合页，服务下线导致资源无法获取
						value = value.replace('https://www.mej.cn/bbc/cicc/static/media/img5_02.eb270c08.jpg','/img5_02.jpg');
						//guoren有个投保页面格式化css样式才能正确渲染，大地有个页面格式化后不能正确渲染
						if(window.location.href.indexOf('ccic') == -1){
							value = formatCss(value);
						}
					}
					if (isTextarea || isRemoteOrDynamicCss) {
						const child = doc.createTextNode(value);
						// https://github.com/rrweb-io/rrweb/issues/112
						for (const c of Array.from(node.childNodes)) {
							if (c.nodeType === node.TEXT_NODE) {
								node.removeChild(c);
							}
						}
						node.appendChild(child);
						if (isTextarea) {
							//@ts-ignore
							node.defaultValue = '';
						}
						continue;
					}
					if (tagName === 'iframe' && name === 'src') {
						continue;
					}
					try {
						if (n.isSVG && name === 'xlink:href') {
							node.setAttributeNS('http://www.w3.org/1999/xlink', name, value);
						} else if (name === 'onload' || name === 'onclick' || name.substring(0, 7) === 'onmouse') {
							// Rename some of the more common atttributes from https://www.w3schools.com/tags/ref_eventattributes.asp
							// as setting them triggers a console.error (which shows up despite the try/catch)
							// Assumption: these attributes are not used to css
							node.setAttribute('_' + name, value);
						} else {
							node.setAttribute(name, value);
							//junling签名图片比parent dom大
							if (tagName === 'canvas'){
								if(name == 'width' || name == 'height'){
									node.setAttribute(name, '100%');
								}
							}
						}
					} catch (error) {
						// skip invalid attribute
					}
				} else {
					// handle internal attributes
					if (tagName === 'canvas' && name === 'rr_dataURL') {
						/* start ================= change to image node here */
						// const image = document.createElement('img');
						// image.src = value;
						// image.onload = () => {
						// 	const ctx = (node as HTMLCanvasElement).getContext('2d');
						// 	if (ctx) {
						// 		ctx.drawImage(image, 0, 0, image.width, image.height);
						// 	}
						// };
						// change to image node here
						(node as HTMLImageElement).src = value;
						/* end ================= change to image node here */
					}
					if (name === 'rr_width') {
						(node as HTMLElement).style.width = value;
					}
					if (name === 'rr_height') {
						(node as HTMLElement).style.height = value;
					}
					if (name === 'rr_mediaState') {
						switch (value) {
							case 'played':
								(node as HTMLMediaElement).play();
								break;
							case 'paused':
								(node as HTMLMediaElement).pause();
								break;
							default:
						}
					}
				}
			}
			// if (tagName === 'canvas') {
			// 	const originalStyles: { [key in string]: string } = (node.getAttribute('style') || '').split(';')
			// 		.map(x => x.trim())
			// 		.map(x => x.split(':').map(x => x.trim()))
			// 		.reduce((styles, pair) => {
			// 			styles[pair[0]] = pair[1];
			// 			return styles;
			// 		}, {} as { [key in string]: string });
			// 	if (n.attributes.hasOwnProperty('rr_width')) {
			// 		originalStyles.width = n.attributes['rr_width'] as string;
			// 	}
			// 	if (n.attributes.hasOwnProperty('rr_height')) {
			// 		originalStyles.height = n.attributes['rr_height'] as string;
			// 	}
			// 	node.setAttribute('style', Object.keys(originalStyles).map(key => `${key}:${originalStyles[key]}`).join(';'));
			// }
			return node;
		case NodeType.Text:
			if (n.isHtml) {
				const newNode = doc.createElement('div');
				newNode.innerHTML = n.textContent;
				return newNode;
			} else {
				return doc.createTextNode(
					n.isStyle && HACK_CSS ? addHoverClass(n.textContent) : n.textContent
				);
			}
		case NodeType.CDATA:
			try {
				return doc.createCDATASection(n.textContent);
			} catch {
				return doc.createTextNode(n.textContent);
			}
		case NodeType.Comment:
			return doc.createComment(n.textContent);
		default:
			return null;
	}
};

export const buildNodeWithSN = (
	n: SerializedNodeWithId,
	doc: Document,
	map: IdNodeMap,
	skipChild = false,
	HACK_CSS = true,
	afterBuilt: Array<() => void>
): INode | null => {
	let node = buildNode(n, doc, HACK_CSS);
	if (!node) {
		return null;
	}

	if (n.type === NodeType.Element && n.tagName.toLowerCase() === 'iframe') {
		(node as INode).__sn = n;
		// 由于iframe在加到父节点之前不会有contentDocument,
		// 如果强行先加入, 那么在父节点被加入到其document时, contentDocument会被刷新, 导致构建的dom tree丢失
		// 因此必须放到全部完成之后构建内部内容
		afterBuilt.push(
			// 使用document节点作为root开始构建iframe内部内容
			() => {
				if (n.childNodes && n.childNodes.length > 0) {
					buildNodeWithSN(n.childNodes[0], (node as HTMLIFrameElement).contentDocument!, map, skipChild, HACK_CSS, afterBuilt);
				}
			}
		);
		return node as INode;
	}

	if (n.type === NodeType.Document) {
		// use target document as root document
		// close before open to make sure document was closed
		doc.close();
		doc.open();
		node = doc;
	}

	(node as INode).__sn = n;
	map[n.id] = node as INode;
	if ((n.type === NodeType.Document || n.type === NodeType.Element) && !skipChild) {
		for (const childN of (n.childNodes || [])) {
			const childNode = buildNodeWithSN(childN, doc, map, false, HACK_CSS, afterBuilt);
			if (!childNode) {
				console.warn('Failed to rebuild', childN);
			} else {
				node.appendChild(childNode);
			}
		}
	}
	return node as INode;
};

const sideEffects = (idNodeMap: IdNodeMap) => {
	for (let id in idNodeMap) {
		const node = idNodeMap[id];
		const n = node.__sn;
		if (n.type !== NodeType.Element) {
			continue;
		}
		const el = node as Node as HTMLElement;
		for (const name in n.attributes) {
			if (!(n.attributes.hasOwnProperty(name) && name.startsWith('rr_'))) {
				continue;
			}
			const value = n.attributes[name];
			if (name === 'rr_scrollLeft') {
				el.scrollLeft = value as number;
			}
			if (name === 'rr_scrollTop') {
				el.scrollTop = value as number;
			}
		}
	}
};
export const rebuild = (
	n: SerializedNodeWithId,
	doc: Document,
	/**
	 * not a public API yet, just for POC
	 */
	HACK_CSS: boolean = true
): [ Node | null, IdNodeMap ] => {
	const idNodeMap: IdNodeMap = {};
	const afterBuilt: Array<() => void> = [];
	const node = buildNodeWithSN(n, doc, idNodeMap, false, HACK_CSS, afterBuilt);
	afterBuilt.forEach(after => after());
	sideEffects(idNodeMap);
	return [ node, idNodeMap ];
};
