import { AddedNodeMutation, AttributeMutation, TextMutation } from './mutation-event-data';
import { WebEventWithTime } from './web-event';

export declare type PlayerMetaData = {
	startTime: number;
	endTime: number;
	totalTime: number;
};

export interface ITimer {
	timeOffset: number;
	speed: number;
	addAction: (action: ActionWithDelay) => void;
	addActions: (actions: Array<ActionWithDelay>) => void;
	start: () => void;
	clear: () => void;
	setSpeed: (speed: number) => void;
	toggleLiveMode: (mode: boolean) => void;
	doNextAction : () => number | null;
}

export type PlayerContext = {
	events: Array<WebEventWithTime>;
	timer: ITimer;
	timeOffset: number;
	baselineTime: number;
	lastPlayedEvent: WebEventWithTime | null;
};

export type Handler = (event?: any) => void;

export type Emitter = {
	on(type: string, handler: Handler): void;
	emit(type: string, event?: unknown): void;
};

export type PlayerAssets = {
	emitter: Emitter;
	getCastFn(event: WebEventWithTime, isSync: boolean): () => void;
};

export type PlayerEvent =
	| {
	type: 'PLAY';
	payload: {
		timeOffset: number;
	};
}
	| {
	type: 'CAST_EVENT';
	payload: {
		event: WebEventWithTime;
	};
}
	| { type: 'PAUSE' }
	| { type: 'TO_LIVE'; payload: { baselineTime?: number } }
	| {
	type: 'ADD_EVENT';
	payload: {
		event: WebEventWithTime;
	};
}
	| {
	type: 'END';
};

export type PlayerState =
	| {
	value: 'playing';
	context: PlayerContext;
}
	| {
	value: 'paused';
	context: PlayerContext;
}
	| {
	value: 'live';
	context: PlayerContext;
};

export type ActionWithDelay = {
	doAction: () => void;
	delay: number;
};

export enum ReplayerEvents {
	Start = 'start',
	Pause = 'pause',
	Resume = 'resume',
	Resize = 'resize',
	Finish = 'finish',
	FullsnapshotRebuilded = 'fullsnapshot-rebuilded',
	LoadStylesheetStart = 'load-stylesheet-start',
	LoadStylesheetEnd = 'load-stylesheet-end',
	SkipStart = 'skip-start',
	SkipEnd = 'skip-end',
	MouseInteraction = 'mouse-interaction',
	EventCast = 'event-cast',
	CustomEvent = 'custom-event',
	Flush = 'flush',
	StateChange = 'state-change',
}

export type WebEventDeserializer = (raw: string) => WebEventWithTime;

export type PlayerConfig = {
	speed: number;
	root: Element;
	loadTimeout: number;
	skipInactive: boolean;
	showWarning: boolean;
	showDebug: boolean;
	blockClass: string;
	liveMode: boolean;
	insertStyleRules: string[];
	triggerFocus: boolean;
	deserializeEvent?: WebEventDeserializer;
};

export type SpeedContext = {
	normalSpeed: PlayerConfig['speed'];
	timer: ITimer;
};

export type SpeedEvent =
	| {
	type: 'FAST_FORWARD';
	payload: { speed: PlayerConfig['speed'] };
}
	| {
	type: 'BACK_TO_NORMAL';
}
	| {
	type: 'SET_SPEED';
	payload: { speed: PlayerConfig['speed'] };
};

export type SpeedState =
	| {
	value: 'normal';
	context: SpeedContext;
}
	| {
	value: 'skipping';
	context: SpeedContext;
};

export type MissingNode = {
	node: Node;
	mutation: AddedNodeMutation;
};
export type MissingNodeMap = {
	[id: number]: MissingNode;
};

export type TreeNode = {
	id: number;
	mutation: AddedNodeMutation;
	parent?: TreeNode;
	children: Record<number, TreeNode>;
	texts: TextMutation[];
	attributes: AttributeMutation[];
};
