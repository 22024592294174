import React, { useReducer, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {doExport, doPost} from '../service/ajax';
import styled from 'styled-components';
import {
	Button,
	ButtonBar,
	ButtonBarAlignType,
	ButtonType, DateTimeRange,
	FormSectionGapVertical,
	PanelSeparator,
	QueryPanel,
	QueryResultColumns,
	QueryResultPanel,
	QueryResultRow,
	Text,
	WorkAreaTitle
} from '../component';
import Path from '../path';
import {endOfDate, formatFull, startOfDate} from "../service/date";
const QualityInspectionTaskQuery = styled.div`
	display: flex;
	flex-direction: column;
`;

const ButtonWrapper = styled.div`
	text-align: center;
	> *:last-child {
		display: inline-block;
		margin-left: var(--gap-thin);
	}
`;

const columns: QueryResultColumns = [
	{ label: '质检任务名称', width: '120px', name: 'taskNameLink'},
	{ label: '质检总可回溯记录数', width: '170px', name: 'countSum'},
	{ label: '质检通过数量', width: '150px', name: 'countPass'},
	{ label: '质检失败数量', width: '150px', name: 'countFailed'},
	{ label: '创建人', width: '180px', name: 'createUserName'},
	{ label: '创建时间', width: '180px', name: 'createAt'},
	{ label: '操作', width: '150px', name: 'actions'}
];

export interface QualityInspectionTask {
	createFrom?: string;
	createTo?: string;
	taskName?:string,
	taskDesc?:string;
	createUserName?:string;
	taskResultComment?:string;
	countPass?:string;
	countFailed?:string;
	countSum?:string;
	pageSize?: number;
	pageNumber?: number;
}

export interface QueryResultData {
	pageSize: number;
	pageNumber: number;
	totalPages: number;
	totalItemCount: number;
	rows?: Array<QualityInspectionTask>
}

export default () => {
	const history = useHistory();
	const now = new Date();
	const [ , forceUpdate ] = useReducer(x => x + 1, 0);
	const [ data, setData ] = useState<QueryResultData | null>(null);
	const [ searched, setSearched ] = useState(false);
	const [ criteria ] = useState<QualityInspectionTask>({
		createFrom: formatFull(startOfDate(now)),
		createTo: formatFull(endOfDate(now))
	});

	const onChanged = (propName:string) => (event: React.ChangeEvent) => {
		// @ts-ignore
		criteria[propName] = (event.target as HTMLInputElement).value;
		forceUpdate();
	};

	const onSearchClicked = async (pageNum: number) => {
		const { pageNumber = pageNum, pageSize = 10, ...rest } = criteria;
		const response = await doPost(`/api/qualityInspection/listTask?pageNumber=${pageNumber}&pageSize=${pageSize}`, rest);
		const responseData = response.body || {
			pageNumber: 1,
			pageSize: 10,
			totalPages: 0,
			totalItemCount: 0,
			data: []
		};
		const { data, ...pages } = responseData;
		const result = {
			...pages,
			rows: data.map((row: any, index: number) => {
				row.seq = index + 1;
				return row;
			})
		};
		setData(result);
		setSearched(true);
	};

	const onAddClicked = () => {
		history.push(Path.QUALITY_INSPECTION_TASK_ADD);
	};

	const dataFound = data || { pageNumber: 1, pageSize: 10, totalPages: 1, totalItemCount: 0, rows: [] };

	const rows = (dataFound.rows || []).map((row): QueryResultRow => {
		const data = row as QueryResultRow;
		data.taskNameLink = <Button label={`${row.taskName}`} type={ButtonType.LINK}
								   onClick={() => {
									   history.push(Path.QUALITY_INSPECTION_TASK_VIEW.replace(':taskName',`${row.taskName}`));}
								   }/>;
		data.actions = <ButtonWrapper>
			<Button label="开始质检" type={ButtonType.LINK}
					onClick={() => {window.open(Path.TRACE_QUALITY_INSPECTION_QUERY.replace(':taskName', `${row.taskName}`))}}/>
			<br/>
			<Button label="任务更新" type={ButtonType.LINK}
					onClick={() => {
						history.push(Path.QUALITY_INSPECTION_TASK_MODIFY.replace(':taskName',`${row.taskName}`));}
					}/>
			<br/>
			<Button label="查看质检记录" type={ButtonType.LINK}
					onClick={() => {window.open(Path.QUALITY_INSPECTION_QUERY.replace(':taskName', `${row.taskName}`))}
					}/>
		</ButtonWrapper>;
		return data;
	});
	const onDateChanged = (startDate?: Date, endDate?: Date) => {
		criteria.createFrom = formatFull(startOfDate(startDate));
		criteria.createTo = formatFull(endOfDate(endDate));
		forceUpdate();
	};
	const onPageChanged = async (pageNumber: number) => {
		await onSearchClicked(pageNumber);
	};
	const onExportClicked = async () => {
		await doExport('/api/qualityInspection/exportTask',criteria);
	};
	return <QualityInspectionTaskQuery>
		<WorkAreaTitle title="质检任务列表"/>
		<QueryPanel>
			<DateTimeRange label="创建时间" columns={2} startDate={criteria.createFrom!} endDate={criteria.createTo!}
						   onChange={onDateChanged} />
			<Text label="质检任务名称" placeholder="请输入" value={criteria.taskName} onChange={onChanged('taskName')}/>
			<Text label="创建人" placeholder="请输入" value={criteria.createUserName} onChange={onChanged('createUserName')}/>

			<PanelSeparator columns={3}/>
			<ButtonBar align={ButtonBarAlignType.CENTER}>
				<Button label="查询" onClick={() => onSearchClicked(1)}/>
				<Button label="新增质检任务" type={ButtonType.WAIVE} onClick={onAddClicked}/>
				<Button label="导出" type={ButtonType.WAIVE} onClick={onExportClicked}/>
			</ButtonBar>
			<PanelSeparator columns={3}/>
		</QueryPanel>
		<FormSectionGapVertical/>
		<QueryResultPanel columns={columns} rows={rows} pagination={true} searched={searched}
						  pageCount={dataFound.totalPages} pageNumber={dataFound.pageNumber}
						  totalItemCount={dataFound.totalItemCount}
						  pageChange={onPageChanged}/>
	</QualityInspectionTaskQuery>;
}